const CreditIcon = ({ size }: { size?: string }) => {
	return (
		<svg
			width={size || "38px"}
			height={size || "38px"}
			viewBox="0 0 34 34"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g filter="url(#filter0_d_2624_5845)">
				<g filter="url(#filter1_i_2624_5845)">
					<path
						d="M32 17C32 25.2843 25.427 32 17 32C8.74157 32 2 26.1011 2 17C2 8.23596 9.24719 2 17 2C25.764 2 32 8.71573 32 17Z"
						fill="url(#paint0_linear_2624_5845)"
					/>
					<path
						d="M32 17C32 25.2843 25.427 32 17 32C8.74157 32 2 26.1011 2 17C2 8.23596 9.24719 2 17 2C25.764 2 32 8.71573 32 17Z"
						fill="url(#pattern0)"
						fillOpacity="0.1"
					/>
				</g>
				<g filter="url(#filter2_ii_2624_5845)">
					<ellipse
						cx="17.6774"
						cy="17"
						rx="14.3258"
						ry="15"
						fill="url(#paint1_linear_2624_5845)"
					/>
				</g>
				<g filter="url(#filter3_i_2624_5845)">
					<path
						d="M30.1839 17C30.1839 23.916 24.6517 29.5121 17.8402 29.5121C11.0288 29.5121 5.49663 23.916 5.49663 17C5.49663 10.084 11.0288 4.48784 17.8402 4.48784C24.6517 4.48784 30.1839 10.084 30.1839 17Z"
						stroke="url(#paint2_linear_2624_5845)"
						strokeWidth="0.930752"
					/>
				</g>
				<g filter="url(#filter4_i_2624_5845)">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M12.1094 22.8851C13.5409 24.1511 15.4367 24.9218 17.516 24.9218C17.9475 24.9218 18.371 24.8887 18.7842 24.8247V22.2504C18.3769 22.3432 17.9523 22.3923 17.516 22.3923C16.3514 22.3923 15.2707 22.0426 14.3788 21.4451V9.69887C13.5408 10.0457 12.7749 10.5272 12.1094 11.1157V22.8851ZM18.7842 20.6842V11.7505C21.2327 12.3086 23.0561 14.4473 23.0561 17.0005C23.0561 19.2664 21.6199 21.2058 19.5852 22.0036V24.6594C23.0432 23.7632 25.5925 20.6738 25.5925 17.0005C25.5925 12.6256 21.9765 9.0791 17.516 9.0791C17.177 9.0791 16.8428 9.09959 16.5148 9.13938V20.7778C16.8351 20.8758 17.1705 20.9278 17.516 20.9278C17.9588 20.9278 18.3851 20.8423 18.7842 20.6842Z"
						fill="white"
					/>
				</g>
			</g>
			<defs>
				<filter
					id="filter0_d_2624_5845"
					x="0.758997"
					y="0.758997"
					width="32.482"
					height="32.482"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation="0.620501" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_2624_5845"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_2624_5845"
						result="shape"
					/>
				</filter>
				<filter
					id="filter1_i_2624_5845"
					x="2"
					y="2"
					width="30.3103"
					height="30.3103"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dx="0.310251" dy="0.310251" />
					<feGaussianBlur stdDeviation="0.155125" />
					<feComposite
						in2="hardAlpha"
						operator="arithmetic"
						k2="-1"
						k3="1"
					/>
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.366667 0 0 0 0 0.366667 0 0 0 0 0.366667 0 0 0 0.2 0"
					/>
					<feBlend
						mode="normal"
						in2="shape"
						result="effect1_innerShadow_2624_5845"
					/>
				</filter>
				<pattern
					id="pattern0"
					patternContentUnits="objectBoundingBox"
					width="1"
					height="1"
				>
					<use
						href="#image0_2624_5845"
						transform="translate(-0.00819672) scale(0.00819672)"
					/>
				</pattern>
				<filter
					id="filter2_ii_2624_5845"
					x="3.35156"
					y="1.80749"
					width="29.0335"
					height="30.1925"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dx="0.385022" dy="-0.192511" />
					<feGaussianBlur stdDeviation="0.288766" />
					<feComposite
						in2="hardAlpha"
						operator="arithmetic"
						k2="-1"
						k3="1"
					/>
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.560784 0 0 0 0 0.196078 0 0 0 0 0.870588 0 0 0 0.2 0"
					/>
					<feBlend
						mode="normal"
						in2="shape"
						result="effect1_innerShadow_2624_5845"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dx="0.0962555" />
					<feGaussianBlur stdDeviation="0.192511" />
					<feComposite
						in2="hardAlpha"
						operator="arithmetic"
						k2="-1"
						k3="1"
					/>
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.531354 0 0 0 0 0.208906 0 0 0 0 0.795833 0 0 0 1 0"
					/>
					<feBlend
						mode="normal"
						in2="effect1_innerShadow_2624_5845"
						result="effect2_innerShadow_2624_5845"
					/>
				</filter>
				<filter
					id="filter3_i_2624_5845"
					x="5.03125"
					y="4.02246"
					width="25.6172"
					height="25.9551"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feMorphology
						radius="0.310251"
						operator="dilate"
						in="SourceAlpha"
						result="effect1_innerShadow_2624_5845"
					/>
					<feOffset dx="0.310251" dy="0.620501" />
					<feComposite
						in2="hardAlpha"
						operator="arithmetic"
						k2="-1"
						k3="1"
					/>
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.537255 0 0 0 0 0.203922 0 0 0 0 0.815686 0 0 0 0.15 0"
					/>
					<feBlend
						mode="normal"
						in2="shape"
						result="effect1_innerShadow_2624_5845"
					/>
				</filter>
				<filter
					id="filter4_i_2624_5845"
					x="12.1094"
					y="9.0791"
					width="13.4844"
					height="15.939"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="0.0962555" />
					<feGaussianBlur stdDeviation="0.0481277" />
					<feComposite
						in2="hardAlpha"
						operator="arithmetic"
						k2="-1"
						k3="1"
					/>
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.0980392 0 0 0 0 0.372549 0 0 0 0 0.780392 0 0 0 0.5 0"
					/>
					<feBlend
						mode="normal"
						in2="shape"
						result="effect1_innerShadow_2624_5845"
					/>
				</filter>
				<linearGradient
					id="paint0_linear_2624_5845"
					x1="5.8764"
					y1="8.74157"
					x2="28.2921"
					y2="50.8764"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#942FE8" />
					<stop offset="0.997885" stopColor="#611C9C" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_2624_5845"
					x1="0.992013"
					y1="16.6629"
					x2="22.9021"
					y2="7.73034"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#AE30FC" />
					<stop offset="1" stopColor="#B174FF" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_2624_5845"
					x1="21.3728"
					y1="31.376"
					x2="10.3338"
					y2="-4.74752"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#45126A" stopOpacity="0.3" />
					<stop offset="1" stopColor="#283394" stopOpacity="0" />
					<stop offset="1" stopColor="#B174FF" stopOpacity="0.2" />
				</linearGradient>
				<image
					id="image0_2624_5845"
					width="124"
					height="122"
					href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHwAAAB6CAYAAAB9RzejAAAAAXNSR0IArs4c6QAAAJ1JREFUeJzt1zERxAAQxLBL+HP+R5HZwhICt37u7ndkvOsAAACAtud8eIoPBwAAmPLhMT4cAABgyofH+HAAAIApHx7jwwEAAKZ8eIwPBwAAmPLhMT4cAABgyofH+HAAAIApHx7jwwEAAKZ8eIwPBwAAmPLhMT4cAABgyofH+HAAAIApHx7jwwEAAKZ8eIwPBwAAmPLhMT4cAAAA4CN/6lMPH9CQcTwAAAAASUVORK5CYII="
				/>
			</defs>
		</svg>
	)
}
export default CreditIcon

import React from "react"
import BaseIcon from "./BaseIcon"

interface EllipseIconProps {
	className?: string
}

const EllipseIcon = ({ className }: EllipseIconProps) => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="10"
			height="10"
			viewBox="0 0 10 10"
			fill="none"
			className={className}
		>
			<circle cx="5" cy="5" r="5" />
		</BaseIcon>
	)
}

export default EllipseIcon

import { useEffect, useState } from "react"
import { OnboardingStepProps } from "pages/MyDocumentsPage/subcomponents/ModalOnboarding"
import LogoIcon from "icons/LogoIcon"
import InputField from "components/input-field"
import { Button } from "components/button"
import { IS_STAGING } from "config"
import { ButtonVariant } from "components/button/types"
import useCurrentUser from "hooks/useCurrentUser"

export function FirstStep({
	currentStep,
	formData,
	setFormData,
	onStepChanges,

	setSize,
}: OnboardingStepProps) {
	const [name, setName] = useState("")
	const user = useCurrentUser()
	const [acceptTerms, setAcceptTerms] = useState(false)
	const termsOfUseUrl = IS_STAGING
		? "https://staging.lawdeck.ai/termos-de-uso"
		: "https://www.lawdeck.ai/termos-de-uso"
	const privacyPolicyUrl = IS_STAGING
		? "https://staging.lawdeck.ai/politica-de-privacidade"
		: "https://www.lawdeck.ai/politica-de-privacidade"
	const cookiesPolicyUrl = IS_STAGING
		? "https://staging.lawdeck.ai/politica-de-cookies"
		: "https://www.lawdeck.ai/politica-de-cookies"

	useEffect(() => {
		setSize("397")
	}, [])
	useEffect(() => {
		if (user && user.first_name && user.last_name)
			return setName(user.first_name + " " + user.last_name)
		if (user && user.first_name) return setName(user.first_name)
	}, [user])

	function handleButton() {
		const nameSplitted = name.split(" ")
		const first_name = name.split(" ")[0]
		const last_name = nameSplitted.slice(1).join(" ")
		setFormData({ ...formData, first_name, last_name })
		onStepChanges(currentStep + 1)
	}

	function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
		const { value } = event.target
		const regex = /^[a-zA-ZÀ-ÖØ-öø-ÿ\s]*$/
		if (regex.test(value)) {
			setName(event.target.value)
		}
	}
	return (
		<div className="flex  justify-center flex-col p-[24px]">
			<div className="flex items-center mb-8 text-[20.073px] text-dark-blue-1 w-[102.102px] justify-between">
				<LogoIcon className="fill-light-blue-4" />
				Lawdeck
			</div>

			<div className="flex items-center mb-8 text-[22px] font-lato font-semibold">
				Seja bem-vindo(a) à revolução da advocacia!
			</div>

			<div className="flex items-center mb-8">
				<InputField
					className="w-[301px]"
					label="Como devemos te chamar?"
					value={name}
					onChange={handleChangeName}
					autoFocus
					maxLength={30}
				/>
			</div>
			<div className="flex items-center mb-4">
				<input
					checked={acceptTerms}
					onClick={() => setAcceptTerms(!acceptTerms)}
					type="checkbox"
					className="border-dark-blue-1 cursor-pointer mr-2 rounded-sm focus:shadow-none focus:ring-0 focus:outline-none"
				/>
				<p className="text-cta-2 ">
					Eu li e aceito os{" "}
					<span
						className="text-light-blue-4 cursor-pointer"
						onClick={() => window.open(termsOfUseUrl, "_blank")}
					>
						Termos de Uso
					</span>
					,{" "}
					<span
						className="text-light-blue-4 cursor-pointer"
						onClick={() => window.open(privacyPolicyUrl, "_blank")}
					>
						Políticas de Privacidade
					</span>{" "}
					e{" "}
					<span
						className="text-light-blue-4 cursor-pointer"
						onClick={() => window.open(cookiesPolicyUrl, "_blank")}
					>
						Políticas de Cookies
					</span>
					.
				</p>
			</div>

			<Button
				variant={ButtonVariant.Contained}
				className="justify-center"
				disabled={!name || !acceptTerms}
				onClick={handleButton}
			>
				Confirmar
			</Button>
		</div>
	)
}

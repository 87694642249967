import CreditIcon from "icons/CreditIcon"
import React from "react"

const gradientDarkBlue =
	"text-transparent bg-clip-text bg-gradient-to-r from-dark-blue-gradient-from to-dark-blue-gradient-to"

function DisplayCredits({
	credits,
	isSuccess,
}: {
	credits: number
	isSuccess?: boolean
}) {
	return (
		<div className={`flex items-center  ${isSuccess && "mt-[24px]"}`}>
			<CreditIcon size={isSuccess ? "46px" : "38px"} />
			<p
				className={`${
					isSuccess ? "text-h0" : "text-h1"
				} mx-2 font-extrabold text-center ${gradientDarkBlue}`}
			>
				{credits}
			</p>{" "}
			<p
				className={`${
					isSuccess ? "text-[23px]" : "text-h3"
				}  font-normal ${gradientDarkBlue}`}
			>
				créditos
			</p>
		</div>
	)
}

export default DisplayCredits

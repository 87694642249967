import React from "react"
import BaseIcon from "./BaseIcon"

const DashboardIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.42857 2H20.5714C20.9502 2.00043 21.3133 2.15108 21.5811 2.4189C21.8489 2.68671 21.9996 3.04982 22 3.42857V20.5714C21.9995 20.9502 21.8488 21.3132 21.581 21.581C21.3132 21.8488 20.9502 21.9995 20.5714 22H3.42857C3.04982 21.9996 2.68671 21.8489 2.4189 21.5811C2.15108 21.3133 2.00043 20.9502 2 20.5714V3.42857C2.00038 3.04981 2.15101 2.68666 2.41884 2.41884C2.68666 2.15101 3.04981 2.00038 3.42857 2ZM10.5714 8.42857H20.5714V3.42857H10.5714V8.42857ZM9.14286 8.42857V3.42857H3.42857V8.42857H9.14286ZM3.42857 9.85714V20.5714H20.5729L20.5719 9.85714H3.42857ZM8.42857 19.1429C7.48171 19.1417 6.57395 18.7651 5.90442 18.0956C5.23489 17.426 4.85826 16.5183 4.85714 15.5714H6.28571C6.28571 15.9952 6.41139 16.4095 6.64685 16.7619C6.88231 17.1143 7.21698 17.389 7.60854 17.5512C8.00009 17.7134 8.43095 17.7558 8.84662 17.6731C9.2623 17.5904 9.64412 17.3863 9.9438 17.0867C10.2435 16.787 10.4476 16.4052 10.5303 15.9895C10.6129 15.5738 10.5705 15.1429 10.4083 14.7514C10.2461 14.3598 9.97147 14.0252 9.61908 13.7897C9.26669 13.5542 8.85239 13.4286 8.42857 13.4286V12C9.37577 12 10.2842 12.3763 10.954 13.046C11.6237 13.7158 12 14.6242 12 15.5714C12 16.5186 11.6237 17.427 10.954 18.0968C10.2842 18.7666 9.37577 19.1429 8.42857 19.1429ZM14.8571 12H16.2857V19.1429H14.8571V12ZM17.7143 15.5714H19.1429V19.1429H17.7143V15.5714Z"
			/>
		</BaseIcon>
	)
}

export default DashboardIcon

import { motion } from "framer-motion"
import DropdownSectionItem from "components/dropdown/subcomponents/DropdownItem"
import { ReactNode } from "react"
import { DropdownItemProps } from "components/dropdown"

interface Props {
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	children: ReactNode
	sections: DropdownItemProps[]
	className?: string
}

const FlyoutMenu = ({ isOpen, setIsOpen, children, sections, className }: Props) => {
	return (
		<div className={`relative ${isOpen ? "z-[999]" : ""}`}>
			{children}
			{isOpen && (
				<motion.div
					initial={{ opacity: 0, scale: 0.7 }}
					animate={{ opacity: 1, scale: 1 }}
					transition={{ ease: "easeOut", duration: 0.2 }}
				>
					<div
						className={className ?? "absolute mt-2 rounded bg-white shadow-custom z-[9999] shadow-[#00000024] right-[105px] bottom-[-120px]"}
					>
						{sections.map((item, index) => (
							<DropdownSectionItem
								key={index}
								item={item}
								index={index}
								setIsOpen={setIsOpen}
							/>
						))}
					</div>
				</motion.div>
			)}
		</div>
	)
}

export default FlyoutMenu

import { useMutation, useQuery } from "@tanstack/react-query"
import { format } from "date-fns"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"
import useLegalDocumentTemplatesAPI from "hooks/useLegalDocumentTemplatesAPI"
import EyeIcon from "icons/EyeIcon"
import ModelIcon from "icons/ModelIcon"
import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { ROUTES } from "routes"
import BasicPlanUpgrade from "./BasicPlanUpgrade"
import PlansModal from "pages/BasePage/subcomponents/PlansModal"
import Loading from "components/loading"
import UpgradeButton from "components/upgrade-button"
import ColoredLawdeck from "icons/ColoredLawdeck"
import NewDocumentTextArea from "components/document-text-area"
import { LegalDocumentType } from "types/legalDocument"
import { TooltipPosition } from "components/tooltip/enums"
import Tooltip from "components/tooltip"
import useFreePlan from "hooks/useFreePlan"
import useBasicPlan from "hooks/useBasicPlan"

export default function ModelPage() {
	const { getLegalDocumentTemplateById } = useLegalDocumentTemplatesAPI()
	const { createLegalDocument } = useLegalDocumentAPI()
	const navigate = useNavigate()
	const location = useLocation()
	const isFreeUser = useFreePlan()
	const isBasicUser = useBasicPlan()

	const [openModal, setOpenModal] = useState(false)

	const id = location ? location.pathname.split("/").pop() : ""
	const { data, isLoading } = useQuery(
		["template", id],
		() => getLegalDocumentTemplateById(id),
		{
			refetchOnWindowFocus: false,
			enabled: !!id,
		},
	)
	const legalDocumentMutation = useMutation({
		mutationFn: () =>
			createLegalDocument({
				template_id: id || "",
				template_type: "TEMPLATE",
				title: "",
				legal_document_type: "Petição Inicial",
			}),
		onSuccess: (data) => {
			navigate(ROUTES.documentDetail({ id: data.id }), {
				state: { documentAsModel: true },
			})
		},
	})

	if (isLoading && !data && isFreeUser !== undefined) return <Loading />

	return (
		<div>
			<div className={`flex  relative w-full `}>
				<div className="w-[calc(100%-330px)] ">
					<div className="flex w-full items-center justify-between mb-8">
						<div className="flex items-center gap-2">
							{(isFreeUser || isBasicUser) && data?.is_premium && (
								<UpgradeButton />
							)}
							<h3 className="text-current">{data?.title}</h3>
						</div>
						<div className="flex items-center  gap-4">
							{data && data?.view_count >= 100 && (
								<div className="flex items-center justify-center  gap-2">
									<EyeIcon
										size="20"
										className="fill-brand-gray-2"
									/>
									<p className="text-cta-2 text-brand-gray-2">
										{data?.view_count}
									</p>
								</div>
							)}

							<div
								onClick={() => {
									if (isFreeUser || (isBasicUser && data?.is_premium)) {
										setOpenModal(true)

									} else {
										legalDocumentMutation.mutate()
									}

								}}
							>
								<Tooltip
									text="Usar como modelo"
									position={TooltipPosition.Below}
								>
									<ModelIcon className="fill-dark-blue-1 hover:fill-dark-blue-2 cursor-pointer" />
								</Tooltip>
							</div>
						</div>
					</div>

					<div className="relative">
						<NewDocumentTextArea
							id={data?.id || ""}
							type={LegalDocumentType.Petition}
							text={data?.text}
							status={""}
							disabled
							data-cy="document-text-area"
						/>
						{(isFreeUser || isBasicUser) && data?.is_premium && (
							<BasicPlanUpgrade />
						)}
					</div>
				</div>

				<div className="w-[330px] fixed top-0 right-2 bg-white overflow-auto h-[100vh]">
					<div className="p-6 shadow-outlined">
						<h4>Informações do modelo</h4>
					</div>
					<div className="p-6">
						<div>
							<p className="font-semibold">Resumo:</p>
						</div>
						<p className="text-brand-gray-3 mt-2">
							{data?.summary}
						</p>
						<p className="mt-6 font-semibold">
							Origem das informações da Lawdeck:
						</p>
						<p className="text-brand-gray-3 mt-2">
							Este conteúdo foi produzido pela equipe Lawdeck com
							pesquisas automatizadas em fontes oficiais e uso de
							modelos de linguagem. Recomendamos que o
							profissional revise as informações. Para dúvidas,
							entre em contato ou consulte nossos Termos de Uso.
						</p>
						<p className="mt-6 font-semibold">Criado por:</p>
						<div className="flex items-center gap-2 mt-2">
							<ColoredLawdeck size={24} />
							<p className="text-brand-gray-3">Lawdeck</p>
						</div>
						<p className="mt-6 font-semibold">Publicado em:</p>
						<p className="text-brand-gray-3 mt-2">
							{data &&
								format(
									new Date(data?._created_at),
									"dd/MM/yyyy",
								)}
						</p>
						<p className="mt-6 font-semibold">Área do direito:</p>
						<p className="text-brand-gray-3 mt-2">
							{data?.area_of_law}
						</p>
					</div>
				</div>
			</div>
			<PlansModal open={openModal} setOpen={setOpenModal} />
		</div>
	)
}

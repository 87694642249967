import Tag from "components/tag"
import { ProductCatalogItem } from "types/productCatalog"

export const businessPlan: ProductCatalogItem = {
	display_name: "Escritório",
	commingSoon: true,
	titleColor: "",
	tag: (
		<Tag
			text="Em Breve"
			background="bg-orange-opacity"
			textColor="text-brand-orange"
			small
		/>
	),
	available: true,
	marketing_features_headline: "",
	marketing_features: [],
	prices: {
		monthly: {
			credits_to_add: 0,
			monthly_price: 0,
			full_price: 0,
			lookup_key: "",
			tag: "",
			creditsPerMonth: false,
		},
		annual: {
			credits_to_add: 0,
			monthly_price: 0,
			full_price: 0,
			lookup_key: "",
			tag: "",
			creditsPerMonth: false,
		},
	},
}

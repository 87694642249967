import BaseIcon from "./BaseIcon"

const InternationalIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path d="M20.5604 3.9101C21.1504 4.5001 21.1504 5.4501 20.5604 6.0301L16.6704 9.9201L18.7904 19.1101L17.3804 20.5301L13.5004 13.1001L9.60043 17.0001L9.96043 19.4701L8.89043 20.5301L7.13043 17.3501L3.94043 15.5801L5.00043 14.5001L7.50043 14.8701L11.3704 11.0001L3.94043 7.0901L5.36043 5.6801L14.5504 7.8001L18.4404 3.9101C19.0004 3.3301 20.0004 3.3301 20.5604 3.9101Z" />
		</BaseIcon>
	)
}

export default InternationalIcon

import { Button } from "components/button"
import CreditIcon from "icons/CreditIcon"

import working from "assets/working.png"
import PlanBenefits from "./subcomponents/PlanBenefits"

import { ProductCatalogItem } from "types/productCatalog"
import { formatPrice } from "utils/formatPrice"
import { ButtonIconPlacement, ButtonVariant } from "components/button/types"

interface PlanCardProps {
	plan: ProductCatalogItem
	isMonthlyPlan: boolean
	withoutButton?: boolean | "" | undefined
	smallCard?: boolean
	smallerCard?: boolean
	dividePlan?: boolean
	cardHeader?: React.ReactNode
	withoutTag?: boolean
}

const NewPlanCard = ({ plan, isMonthlyPlan, withoutButton, cardHeader, withoutTag }: PlanCardProps) => {
	return (
		<div
			className={`w-[332px] bg-white rounded-lg outline outline-[1px] outline-brand-gray-1  ${plan.containerClassName
				} ${plan.selected &&
				"border-none  outline-offset outline-[3px] !outline-light-blue-4 "
				} p-6  flex-none`}
		>
			<div className="flex flex-col h-full justify-between">
				{cardHeader && (
					<div>{cardHeader}</div>
				)}
				<div>
					{plan.commingSoon ? (
						<>
							<div className="flex items-center justify-between">
								<h3 className={`font-semibold text-cta-1`}>
									{plan.display_name}
								</h3>
								{plan.tag && !withoutTag && plan.tag}
							</div>

							<div className="p-6 lg:flex lg:flex-col lg:items-center lg:justify-center lg:mt-[135px] ">
								<img
									src={working}
									alt="#"
									className="w-[216px]"
								/>
								<p
									className={`text-cta-1 mt-2  text-brand-gray-3 text-center`}
								>
									Estamos trabalhando nisso!
								</p>
							</div>
						</>
					) : (
						<>
							<div className="flex items-center justify-between h-[28px]">
								<p
									className={`font-semibold text-cta-1 ${plan.titleColor}`}
								>
									{plan.display_name}
								</p>

								{plan.tag && !withoutTag && plan.tag}
							</div>

							<div className="flex flex-row items-center  mt-6">
								<p
									className={`text-[36px] font-bold  text-light-blue-1`}
								>
									R${" "}
									{isMonthlyPlan
										? formatPrice(
											plan?.prices?.monthly
												.monthly_price,
											-1,
										)
										: formatPrice(
											plan?.prices?.annual
												.monthly_price,
											-1,
										)}
									<span className="text-cta-2 font-normal text-brand-gray-2">
										/mês
									</span>
								</p>
							</div>

							<div>
								<div className={`flex items-center mt-2 py-3`}>
									<CreditIcon size="24px" />
									<p
										className={`ml-2 font-semibold text-[20px] text-dark-blue-1`}
									>
										{plan?.prices?.monthly.credits_to_add}
									</p>

									<p
										className={`ml-1 font-semibold text-[20px] text-dark-blue-1`}
									>
										créditos
									</p>
								</div>

								{plan.marketing_features_headline && (
									<p className="font-semibold text-dark-blue-1 text-cta-2 mb-[24px] ">
										{plan.marketing_features_headline}
									</p>
								)}

								{plan.marketing_features.map((item) => (
									<PlanBenefits
										key={item.description}
										item={item.description}
										allowed
									/>
								))}
							</div>
						</>
					)}
				</div>
				{!withoutButton && (
					<Button
						className="w-[100%] flex items-center justify-center mt-6"
						variant={
							plan?.button?.buttonVariant
								? plan?.button?.buttonVariant
								: ButtonVariant.Contained
						}
						icon={plan?.button?.buttonIcon}
						isLoading={plan?.button?.buttonIsLoading}
						onClick={() => {
							if (plan?.button?.buttonOnClick) {
								plan.button?.buttonOnClick()
							}
						}}
						iconPlacement={ButtonIconPlacement.End}
					>
						{plan?.button?.buttonText || "Escolher"}
					</Button>
				)}
			</div>
		</div>
	)
}

export default NewPlanCard

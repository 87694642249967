import React from "react"
import BaseIcon from "./BaseIcon"

interface CheckIconProps {
	className?: string
	size?: string
}

const CheckIcon = ({ className, size = "24" }: CheckIconProps) => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox={`0 0 24 24`}
			fill="none"
			className={className}
		>
			<path d="M10 17L4 10.9686L4.94267 10.021L10 15.1041L19.0573 6L20 6.94761L10 17Z" />
		</BaseIcon>
	)
}

export default CheckIcon

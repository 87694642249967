import BaseIcon from "./BaseIcon"

const LawdeckIcon = ({ className }: { className?: string }) => {
	return (
		<BaseIcon
			width="23"
			height="26"
			viewBox="0 0 23 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 22.6576C2.34291 24.7352 5.44564 26 8.84874 26C9.55491 26 10.2482 25.9456 10.9244 25.8407V21.6158C10.2578 21.7682 9.56291 21.8487 8.84874 21.8487C6.9427 21.8487 5.17402 21.2748 3.71429 20.2943V5.70567V3.4958V1.01712C2.34273 1.58625 1.08925 2.37644 0 3.3424V3.4958V11.0604V14.9396V21.6303V22.6576ZM10.9244 19.0455V17.916V4.38417C14.9318 5.30009 17.916 8.80994 17.916 13C17.916 16.7187 15.5655 19.9016 12.2353 21.2109V25.5694C17.8949 24.0985 22.0672 19.0284 22.0672 13C22.0672 5.82029 16.1491 0 8.84874 0C8.29387 0 7.74699 0.033632 7.21008 0.0989221V3.52355V4.2954V19.1991C7.73432 19.3599 8.28331 19.4454 8.84874 19.4454C9.57346 19.4454 10.2712 19.305 10.9244 19.0455Z"
				fill="#3083FF"
			/>
		</BaseIcon>
	)
}

export default LawdeckIcon

import { CellContext } from "@tanstack/react-table"
import { RetrieveLegalDocumentResponse } from "types/legalDocument"
import { formatUserName } from "utils/profile"
import { formatDate } from "utils/formatDate"

export default function ColumnUpdatedAt({
	info,
}: {
	info: CellContext<RetrieveLegalDocumentResponse, string | undefined>
}) {
	const name = formatUserName(info.row.original.updated_by)
	return (
		<div className="flex min-w-[100px] max-w-[244px] flex-col mr-5">
			<div className="flex gap-2 pb-2">
				<div className="h-6 w-6 overflow-hidden rounded-full">
					<img
						src={info.row.original.updated_by.picture}
						height={24}
						width={24}
						alt="Avatar"
					/>
				</div>
				<p className="whitespace-nowrap text-cta-1 font-normal text-gray-600">
					{name}
				</p>
			</div>
			<div className="text-cta-1 text-gray-400">
				{formatDate(info.getValue() ?? "")}
			</div>
		</div>
	)
}

export const toastClassName = {
	success: {
		background: "bg-green-opacity",
		progress: {
			start: "bg-brand-green",
			end: "border-light-green",
		},
		text: "text-brand-green",
	},
	error: {
		background: "bg-red-opacity",
		progress: {
			start: "bg-brand-red",
			end: "border-light-red",
		},
		text: "text-brand-red",
	},
	warning: {
		background: "bg-orange-opacity",
		progress: {
			start: "bg-brand-orange",
			end: "border-light-orange",
		},
		text: "text-brand-orange",
	},
}

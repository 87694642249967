import BaseIcon from "./BaseIcon"

const NewDocumentIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
		>
			<path d="M21 17.7141H18V14.857H16.5V17.7141H13.5V19.1426H16.5V21.9997H18V19.1426H21V17.7141Z" />
			<path d="M10.5 20.5714H4.5V3.4288H10.5V7.71446C10.5011 8.093 10.6596 8.45572 10.9406 8.72339C11.2217 8.99106 11.6025 9.14192 12 9.14301H16.5V12.0001H18V7.71446C18.0027 7.62058 17.984 7.52726 17.9451 7.44094C17.9063 7.35462 17.8482 7.27735 17.775 7.21446L12.525 2.21453C12.459 2.14476 12.3778 2.08948 12.2872 2.05248C12.1966 2.01548 12.0986 1.99766 12 2.00025H4.5C4.10253 2.00134 3.72166 2.1522 3.44061 2.41987C3.15955 2.68754 3.00115 3.05026 3 3.4288V20.5714C3.00115 20.95 3.15955 21.3127 3.44061 21.5804C3.72166 21.848 4.10253 21.9989 4.5 22H10.5V20.5714ZM12 3.71451L16.2 7.71446H12V3.71451Z" />
		</BaseIcon>
	)
}

export default NewDocumentIcon

import EllipseIcon from "icons/EllipseIcon"

interface EllipseFooterProps {
	activeIndex: number
}

function EllipseFooter({ activeIndex }: EllipseFooterProps) {
	return (
		<div className="flex flex-start justify-center gap-[6px]">
			{Array.from({ length: 3 }).map((_, index) => {
				if (activeIndex === index) {
					return (
						<EllipseIcon
							key={index}
							className="fill-light-blue-4"
						/>
					)
				}
				return <EllipseIcon key={index} className="fill-gray-light" />
			})}
		</div>
	)
}

export default EllipseFooter

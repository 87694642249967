import Loading from "components/loading"

const RedirectingPage = () => {
	return (
		<div className="flex flex-col justify-center items-center h-[100vh] w-full font-lato">
			<Loading className="mb-6" />
			<div className="text-brand-gray-3 text-center text-[24px]">
				Estamos te redirecionando pra outra página
			</div>
		</div>
	)
}

export default RedirectingPage

import Modal from "components/modal"
import { useState } from "react"
import { OnboardUserRequestBody } from "types/currentUser"
import { FirstStep } from "pages/MyDocumentsPage/onboarding-steps/FirstStep"
import { SecondStep } from "pages/MyDocumentsPage/onboarding-steps/SecondStep"
import { ThirdStep } from "pages/MyDocumentsPage/onboarding-steps/ThirdStep"
import { FourthStep } from "pages/MyDocumentsPage/onboarding-steps/FourthStep"

interface ModalOnboardingProps {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export interface OnboardingStepProps {
	currentStep: number
	formData: OnboardUserRequestBody
	setFormData: (formData: OnboardUserRequestBody) => void
	onStepChanges: (step: number) => void
	setSize: (size: string) => void
	setOpen: (value: boolean) => void
}

const ModalOnboarding = ({ open, setOpen }: ModalOnboardingProps) => {
	const [currentStep, setCurrentStep] = useState(0)
	const [size, setSize] = useState("")
	const [formData, setFormData] = useState<OnboardUserRequestBody>()

	const component = [FirstStep, SecondStep, ThirdStep, FourthStep]

	const Step = component[currentStep]

	return (
		<Modal
			size={size}
			width={`w-full`}
			openModal={open}
			setOpenModal={setOpen}
		>
			<Step
				currentStep={currentStep}
				formData={formData!}
				setFormData={setFormData}
				onStepChanges={setCurrentStep}
				setSize={setSize}
				setOpen={setOpen}
			/>
		</Modal>
	)
}

export default ModalOnboarding

import { useState } from "react"
import MagicWandIcon from "icons/MagicWandIcon"

import { Button } from "components/button"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
	LegalDocumentContent,
	LDProcess,
	ProcessType,
	RetrieveLegalDocumentResponse,
} from "types/legalDocument"
import { useAtom } from "jotai"
import { ClausesData } from "types/theses"
import { getDocumentStatus } from "utils/getDocumentStatus"

import { AxiosError } from "axios"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"
import { ButtonColor, ButtonVariant } from "components/button/types"
import { discardClausesAtom } from "pages/DocumentPage/steps/atoms"
import { openAtom } from "./atoms"
import { NewChooseStrategiesStep } from "./NewChooseStrategiesStep"
import { useClauseContentContext } from "hooks/useClauseContentContext"
import { contractStepAtom } from "../ContractDisplay/atoms"
import { useThesesContext } from "hooks/useThesesContext"
import useContractAPI from "hooks/useContractAPI"
import useCurrentUser from "hooks/useCurrentUser"

export interface Strategy extends ClausesData {
	orderHint: number
}

export function newContractStrategiesStep(documentId: string) {
	function PrimaryButton({ documentId }: { documentId: string }) {
		const queryClient = useQueryClient()
		const [loadingContent, setLoadingContent] = useState(false)
		const [, setOpen] = useAtom(openAtom)
		const user = useCurrentUser()

		const { generateContractContentV2, retrieveContract } = useContractAPI()
		const { clausesContent } = useClauseContentContext()

		const generateLegalDocumentContentV2Mutation = useMutation({
			mutationFn: () =>
				generateContractContentV2(documentId, {
					clauses_contents: clausesContent,
				}),

			onSuccess: () => {
				queryClient.setQueryData(
					["legalDocument", documentId],
					(
						oldDocument: RetrieveLegalDocumentResponse | undefined,
					) => {
						if (oldDocument) {
							const process = {
								process_type: ProcessType.GenerateContent,
								max_processing_time: 360,
								start_time: new Date().toISOString(),
							} as LDProcess
							const content = { text: "" } as LegalDocumentContent
							return {
								...oldDocument,
								content,
								processes: [process],
							}
						}

						return oldDocument
					},
				)
				setLoadingContent(false)
			},
			onError: (error: AxiosError<any>) => {
				if (error.response?.status === 403) {
					showToast(
						"Você não tem créditos para concluir essa ação.",
						ToastType.Error,
					)
					setOpen(true)
				}
			},
		})

		const { data: legalDocument } = useQuery(
			["legalDocument", documentId],
			() => retrieveContract(documentId!),
			{
				staleTime: Infinity,
			},
		)
		const onClickGenerateContract = () => {
			if (!user) return

			if (user.balance <= 0) {
				showToast(
					"Você não tem créditos para concluir essa ação.",
					ToastType.Error,
				)
				setOpen(true)
			} else {
				setLoadingContent(true)
				generateLegalDocumentContentV2Mutation.mutate()
			}
		}
		return (
			<Button
				className="ml-4"
				variant={ButtonVariant.Contained}
				color={ButtonColor.Secondary}
				icon={<MagicWandIcon />}
				data-cy="generate-contract"
				isLoading={loadingContent}
				disabled={getDocumentStatus(legalDocument) === "processing"}
				onClick={onClickGenerateContract}
			>
				Gerar documento
			</Button>
		)
	}

	function SecondaryButton({ documentId }: { documentId: string }) {
		const { retrieveContract } = useContractAPI()
		const [, setCurrentStep] = useAtom(contractStepAtom)
		const [, setOpenDiscardThesesModal] = useAtom(discardClausesAtom)

		const { data: legalDocument } = useQuery(
			["legalDocument", documentId],
			() => retrieveContract(documentId!),
			{
				staleTime: Infinity,
			},
		)

		async function handleBack() {
			getDocumentStatus(legalDocument) === "completed"
				? setCurrentStep(4)
				: setOpenDiscardThesesModal(true)
		}
		return (
			<Button
				variant={ButtonVariant.Outlined}
				onClick={handleBack}
				disabled={getDocumentStatus(legalDocument) === "processing"}
			>
				{getDocumentStatus(legalDocument) === "completed"
					? "Ver contrato"
					: "Voltar"}
			</Button>
		)
	}

	function SelectedTesesNumber() {
		const { theses } = useThesesContext()

		return (
			<div className="flex items-center gap-2">
				<p>Estratégias selecionadas</p>
				<div className="p-2 bg-[#EEF7FF] w-fit h-[29px] flex items-center rounded-[4px] justify-center">
					<p>{theses.length}</p>
				</div>
			</div>
		)
	}
	return {
		subtitle: "Subtitulo Teses 2",
		Component: <NewChooseStrategiesStep documentId={documentId} />,
		primaryButton: <PrimaryButton documentId={documentId} />,
		secondaryButton: <SecondaryButton documentId={documentId} />,
		footerText: <SelectedTesesNumber />,
	}
}

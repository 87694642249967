import React from "react"
import BaseIcon from "./BaseIcon"

const ArrowDownEditorIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path d="M10 11.9444L5.83333 8.04581L6.41667 7.5L10 10.8528L13.5833 7.5L14.1667 8.04581L10 11.9444Z" />
		</BaseIcon>
	)
}

export default ArrowDownEditorIcon

import MagicWandIcon from "icons/MagicWandIcon"
import { KeyboardEvent, useEffect, useRef, useState } from "react"

interface LabelProps {
	setShowRewriteSection: (value: boolean) => void
	setOpen: (value: boolean) => void
	onMutate: (value: string) => void
}

function RewriteLabel({
	setShowRewriteSection,
	onMutate,
	setOpen,
}: LabelProps) {
	const textareaRef = useRef<HTMLTextAreaElement>(null)
	const [rewrite, setRewrite] = useState("")

	function handleKeyDown(event: KeyboardEvent<HTMLTextAreaElement>) {
		if (event.key === "Enter" && rewrite) {
			onMutate(rewrite)
			setOpen(false)
		}
		if (event.key === "Enter") event.preventDefault()
	}

	useEffect(() => {
		if (textareaRef.current) {
			textareaRef.current.focus()
		}
	}, [])

	function handleClick() {
		onMutate(rewrite)
		setShowRewriteSection(false)
		setOpen(false)
		setRewrite("")
	}
	return (
		<div className="relative h-[92px]">
			<div onMouseDown={handleClick}>
				<MagicWandIcon className="absolute top-4 right-3 fill-[#AE30FC] cursor-pointer" />
			</div>
			<textarea
				className="resize-none h-[92px] p-3 pr-10 w-full focus:border-[#AE30FC] focus:!outline-none !ring-0 rounded-[4px] !outline-offset-0 border-2 border-gray-300"
				placeholder="Cite o artigo 23 cdc"
				value={rewrite}
				ref={textareaRef}
				maxLength={200}
				onChange={(e) => {
					setRewrite(e.target.value)
				}}
				onKeyDown={handleKeyDown}
				onBlur={() => {
					setShowRewriteSection(false)
					setOpen(false)
					setRewrite("")
				}}
			/>
		</div>
	)
}

export default RewriteLabel

import { useMutation } from "@tanstack/react-query"
import { Button } from "components/button"
import { ButtonVariant } from "components/button/types"
import Modal from "components/modal"
import useUserAPI from "hooks/useUserAPI"
import { useEffect, useState } from "react"
import { FeatureModal } from "types/featureModal"

interface StepModalProps {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	featureModal: FeatureModal
}

export default function StepModal({
	open,
	setOpen,
	featureModal,
}: StepModalProps) {
	const [currentStep, setCurrentStep] = useState(0)
	const { updateUserState } = useUserAPI()

	const updateUserStateMutation = useMutation({
		mutationFn: updateUserState,
	})

	useEffect(() => {
		if (open) {
			setCurrentStep(0)
			updateUserStateMutation.mutate()
		}
	}, [open])

	const selectedStep =
		featureModal.childs[currentStep] || featureModal.childs[0]
	const isLastStep = currentStep === featureModal.childs.length - 1
	const isFirstStep = currentStep === 0

	return (
		<Modal
			openModal={open}
			setOpenModal={setOpen}
			width="w-[550px]"
			dismissible
			size="lg"
		>
			<div className="w-full p-2">
				<img
					src={selectedStep.image_url}
					className="mb-[24px] w-[486px] h-[300px] "
				/>
				<p className="text-h3 font-semibold">{selectedStep.title}</p>
				<p className="text-cta-1 mt-[16px]">{selectedStep.content}</p>

				<div className="flex items-center justify-between mt-[24px] ">
					<div className="flex items-center gap-[6px]">
						{featureModal.childs.map((_, index) => (
							<div
								onClick={() => setCurrentStep(index)}
								key={index}
								className={`cursor-pointer w-[10px] h-[10px]  rounded-full ${currentStep === index
									? " bg-light-blue-4"
									: "bg-gray-light"
									}`}
							/>
						))}
					</div>
					<div className="flex items-center gap-[16px]">
						{featureModal.childs.length > 1 && (
							<Button
								variant={
									isFirstStep
										? ButtonVariant.Text
										: ButtonVariant.Outlined
								}
								className="px-[24px] w-[110px]  flex items-center justify-center"
								onClick={() => {
									if (isFirstStep) {
										setOpen(false)
									} else {
										setCurrentStep(currentStep - 1)
									}
								}}
							>
								{isFirstStep ? "Pular" : "Voltar"}
							</Button>
						)}
						<Button
							className=" w-[110px] flex items-center justify-center"
							variant={ButtonVariant.Contained}
							onClick={() => {
								if (isLastStep) {
									setOpen(false)
								} else {
									setCurrentStep(currentStep + 1)
								}
							}}
						>
							{isLastStep ? "Fechar" : "Próximo"}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

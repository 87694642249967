const DocIcon = ({ fill }: { fill?: string }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.775 6.475L13.525 1.225C13.375 1.075 13.225 1 13 1H5.5C4.675 1 4 1.675 4 2.5V20.5C4 21.325 4.675 22 5.5 22H17.5C18.325 22 19 21.325 19 20.5V7C19 6.775 18.925 6.625 18.775 6.475ZM13 2.8L17.2 7H13V2.8ZM17.5 20.5H5.5V2.5H11.5V7C11.5 7.825 12.175 8.5 13 8.5H17.5V20.5ZM7 16V17.5H16V16H7ZM7 13V11.5H16V13H7Z"
				fill={fill || "#ACACAC"}
			/>
		</svg>
	)
}

export default DocIcon

import BaseIcon from "./BaseIcon"

const SuccessIcon = ({
	size = "48px",
	className,
}: {
	size?: string
	className?: string
}) => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 48 48"
			fill="none"
			className={className}
		>
			<path
				d="M24 3C19.8466 3 15.7865 4.23163 12.333 6.53914C8.8796 8.84665 6.18798 12.1264 4.59854 15.9636C3.0091 19.8009 2.59323 24.0233 3.40352 28.0969C4.21381 32.1705 6.21386 35.9123 9.15077 38.8492C12.0877 41.7861 15.8295 43.7862 19.9031 44.5965C23.9767 45.4068 28.1991 44.9909 32.0364 43.4015C35.8736 41.812 39.1534 39.1204 41.4609 35.667C43.7684 32.2135 45 28.1534 45 24C45 18.4305 42.7875 13.089 38.8493 9.15076C34.911 5.21249 29.5696 3 24 3ZM21 32.3862L13.5 24.8862L15.8859 22.5L21 27.6138L32.115 16.5L34.5086 18.8789L21 32.3862Z"
				fill="#068F2D"
				fillOpacity="0.2"
			/>
			<path
				d="M21 32.3865L13.5 24.8865L15.8865 22.5L21 27.6135L32.115 16.5L34.5075 18.8775L21 32.3865Z"
				fill="#068F2D"
			/>
		</BaseIcon>
	)
}

export default SuccessIcon

import { useEffect } from "react"
import Lottie from "react-lottie"
import loading from "lotties/dotTheses.json"

const LottieComponent = Lottie as any
const lottieOptions = {
	loop: true,
	autoplay: true,
	animationData: loading,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
		className: "cursor-default",
	},
}

export function LoadingComponent() {
	const scrollToBottom = () => {
		const element = document.getElementById("document-content")

		if (element) {
			element.scrollTo({
				behavior: "smooth",
				top: element.scrollHeight,
			})
		}
	}

	useEffect(() => {
		scrollToBottom()
	}, [])

	return (
		<>
			<div className="mb-6 p-6 shadow-1 flex items-end justify-center">
				<LottieComponent
					options={lottieOptions}
					height={10}
					width={30}
					style={{ margin: "0 8px 4.5px 0" }}
					isClickToPauseDisabled
				/>
				<p className="text-dark-blue-2 text-cta-2">
					Gerando estratégia
				</p>
			</div>
		</>
	)
}

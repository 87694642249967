import { AxiosResponse } from "axios"
import axiosInstance from "axiosInstance"
import { RetrieveLegalDocumentResponse, UpdateLegalDocumentRequestBody, UpdateLegalDocumentResponse } from "types/legalDocument"

const useUploadedDocumentAPI = () => {
	const retrieveUploadedDocument = async (id: string) => {
		if (!id) throw new Error("No id provided")

		const response: AxiosResponse<RetrieveLegalDocumentResponse> =
			await axiosInstance.get(`/uploaded-document/${id}/`)

		return response.data
	}

	const createNewRevisionUploadedDocument = async ({
		id,
		payload,
	}: {
		id: string
		payload: UpdateLegalDocumentRequestBody
	}) => {
		const response: AxiosResponse<UpdateLegalDocumentResponse> =
			await axiosInstance.post(`/uploaded-document/${id}/create_new_revision/`, payload)

		return response.data
	}

	const copyUploadedDocument = async (id: string) => {
		const response: AxiosResponse<RetrieveLegalDocumentResponse> =
			await axiosInstance.post(`/uploaded-document/${id}/create_copy/`)

		return response.data
	}

	return {
		retrieveUploadedDocument,
		copyUploadedDocument,
		createNewRevisionUploadedDocument,
	}
}

export default useUploadedDocumentAPI

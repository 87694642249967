import { Button } from "components/button"
import { ButtonVariant } from "components/button/types"
import Modal from "components/modal"
import { showToast } from "components/toast/functions"
import React, { useState } from "react"

interface ChangePasswordModalProps {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	userId?: string
}

const ChangePasswordModal = ({ open, setOpen }: ChangePasswordModalProps) => {
	const [reason, setReason] = useState("")
	return (
		<Modal
			size="550"
			width="w-[550px]"
			openModal={open}
			setOpenModal={setOpen}
			dismissible
			onlyCloseButton
		>
			<div className="flex  justify-center flex-col p-2">
				<p className={"text-[22px] font-semibold mb-2 font-lato"}>
					Forçar a troca de senha
				</p>
				<div className="text-brand-gray-3 mb-8 text-[16px] font-lato">
					Essa ação vai obrigar o usuário a criar uma nova senha. Tem
					certeza de que deseja continuar?
				</div>
				<span className="mb-2 text-brand-gray-2 font-lato">
					Motivo:
				</span>
				<textarea
					className="resize-none h-[120px] p-3 pr-10 w-full focus:!outline-none !ring-0 rounded-[4px] !outline-offset-0 border-2 border-gray-300 mb-8"
					placeholder="ex Comportamento inapropriado"
					value={reason}
					onChange={(e) => {
						setReason(e.target.value)
					}}
				/>
				<div className="flex items-center justify-between">
					<Button
						variant={ButtonVariant.Outlined}
						className="mr-4 w-[235px] justify-center"
						onClick={() => {
							setOpen(false)
						}}
					>
						Cancelar
					</Button>
					<Button
						variant={ButtonVariant.Contained}
						className="flex w-[235px] justify-center"
						disabled={reason === ""}
						onClick={() => {
							showToast(
								"Enviamos um email para “Devon Lane” solicitando a troca de senha.",
							)
							setOpen(false)
						}}
					>
						Continuar
					</Button>
				</div>
			</div>
		</Modal>
	)
}

export default ChangePasswordModal

import BaseIcon from "./BaseIcon"

const ExitIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
		>
			<path d="M13 2H14V14H13V2ZM5.707 10.293L3.914 8.5H11V7.5H3.914L5.707 5.707L5 5L2 8L5 11L5.707 10.293Z" />
		</BaseIcon>
	)
}

export default ExitIcon

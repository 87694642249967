import React from "react"
import BaseIcon from "./BaseIcon"

const ListBulletIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.25 5.625C6.25 6.66053 5.41053 7.5 4.375 7.5C3.33947 7.5 2.5 6.66053 2.5 5.625C2.5 4.58947 3.33947 3.75 4.375 3.75C5.41053 3.75 6.25 4.58947 6.25 5.625ZM6.25 14.375C6.25 15.4105 5.41053 16.25 4.375 16.25C3.33947 16.25 2.5 15.4105 2.5 14.375C2.5 13.3395 3.33947 12.5 4.375 12.5C5.41053 12.5 6.25 13.3395 6.25 14.375ZM18.75 13.75H10V15H18.75V13.75ZM10 5H18.75V6.25H10V5Z"
			/>
		</BaseIcon>
	)
}

export default ListBulletIcon

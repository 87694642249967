import BaseIcon from "./BaseIcon"

const CloseSquareIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="3 0 28 28"
			fill="none"
		>
			<path
				d="M9.34115 3.90637C6.39581 3.90637 4.00781 6.29437 4.00781 9.23971V22.573C4.00781 25.5184 6.39581 27.9064 9.34115 27.9064H22.6745C25.6198 27.9064 28.0078 25.5184 28.0078 22.573V9.23971C28.0078 6.29437 25.6198 3.90637 22.6745 3.90637H9.34115ZM12.0078 10.573C12.3491 10.573 12.7051 10.6877 12.9665 10.9477L16.0078 13.989L19.0492 10.9477C19.3092 10.6877 19.6665 10.573 20.0078 10.573C20.3491 10.573 20.7051 10.6877 20.9665 10.9477C21.4865 11.469 21.4865 12.3437 20.9665 12.865L17.9251 15.9064L20.9665 18.9477C21.4865 19.469 21.4865 20.3437 20.9665 20.865C20.4451 21.385 19.5705 21.385 19.0492 20.865L16.0078 17.8237L12.9665 20.865C12.4451 21.385 11.5705 21.385 11.0492 20.865C10.5292 20.3437 10.5292 19.469 11.0492 18.9477L14.0905 15.9064L11.0492 12.865C10.5292 12.3437 10.5292 11.469 11.0492 10.9477C11.3092 10.6877 11.6665 10.573 12.0078 10.573Z"
				fill="#021F2E"
			/>
		</BaseIcon>
	)
}

export default CloseSquareIcon

import BaseIcon from "./BaseIcon"

const QuickActionIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="25"
			viewBox="0 0 24 25"
			fill="none"
		>
			<path d="M14.125 7.75L16 8.5L16.75 10.375C16.8125 10.4688 16.875 10.5312 17 10.5312C17.0938 10.5312 17.1562 10.4688 17.2188 10.375L18 8.5L19.8438 7.75C19.9375 7.6875 20 7.625 20 7.5C20 7.40625 19.9375 7.34375 19.8438 7.28125L18 6.5L17.2188 4.65625C17.1562 4.5625 17.0938 4.5 16.9688 4.5C16.875 4.5 16.8125 4.5625 16.75 4.65625L16 6.5L14.125 7.28125C14.0312 7.34375 13.9688 7.40625 13.9688 7.53125C13.9688 7.625 14.0312 7.6875 14.125 7.75ZM19.8438 17.2812L18 16.5L17.2188 14.6562C17.1562 14.5625 17.0938 14.5 16.9688 14.5C16.875 14.5 16.8125 14.5625 16.75 14.6562L16 16.5L14.125 17.2812C14.0312 17.3438 13.9688 17.4062 13.9688 17.5312C13.9688 17.625 14.0312 17.6875 14.125 17.75L16 18.5L16.75 20.375C16.8125 20.4688 16.875 20.5312 17 20.5312C17.0938 20.5312 17.1562 20.4688 17.2188 20.375L18 18.5L19.8438 17.75C19.9375 17.6875 20 17.625 20 17.5C20 17.4062 19.9375 17.3438 19.8438 17.2812ZM16 12.5C15.9688 12.2188 15.8125 11.9688 15.5625 11.8438L12.2812 10.2188L10.6562 6.9375C10.4062 6.4375 9.5625 6.4375 9.3125 6.9375L7.6875 10.2188L4.40625 11.8438C4.15625 11.9688 3.96875 12.2188 3.96875 12.5312C3.96875 12.8125 4.15625 13.0625 4.40625 13.1875L7.6875 14.8125L9.3125 18.0938C9.4375 18.3438 9.6875 18.5312 10 18.5312C10.2812 18.5312 10.5312 18.3438 10.6562 18.0938L12.2812 14.8125L15.5625 13.1875C15.8125 13.0625 15.9688 12.8125 16 12.5ZM11.4062 13.5938C11.25 13.6562 11.125 13.7812 11.0625 13.9375L10 16.0938L8.90625 13.9375C8.84375 13.7812 8.71875 13.6562 8.5625 13.5938L6.40625 12.5L8.5625 11.4375C8.71875 11.375 8.84375 11.25 8.90625 11.0938L10 8.9375L11.0625 11.0938C11.125 11.25 11.25 11.375 11.4062 11.4375L13.5625 12.5L11.4062 13.5938Z" />
		</BaseIcon>
	)
}

export default QuickActionIcon

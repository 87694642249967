import { AxiosResponse } from "axios"
import axiosInstance from "axiosInstance"
import {
	RetrieveLegalDocumentResponse,
	UpdateLegalDocumentRequestBody,
	UpdateLegalDocumentResponse,
	PaginatedResponse,
	DefaultQueryParams,
	LegalDocumentGenerateContent,
	CreatePetitionRequestBody,
	LegalDocumentPropmts,
} from "types/legalDocument"

const useLegalDocumentAPI = () => {
	const createLegalDocument = async (payload: CreatePetitionRequestBody) => {
		const response: AxiosResponse<RetrieveLegalDocumentResponse> =
			await axiosInstance.post("/legal-document/", payload)

		return response.data
	}

	const deleteLegalDocument = async (id: string) => {
		await axiosInstance.delete(`/legal-document/${id}/`)
	}

	const retrieveLegalDocument = async (id: string) => {
		if (!id) throw new Error("No id provided")

		const response: AxiosResponse<RetrieveLegalDocumentResponse> =
			await axiosInstance.get(`/legal-document/${id}/`)

		return response.data
	}

	const listLegalDocuments = async (params?: DefaultQueryParams) => {
		const response: AxiosResponse<
			PaginatedResponse<RetrieveLegalDocumentResponse>
		> = await axiosInstance.get("/legal-document/", { params })

		return response.data.results
	}

	const restoreLegalDocument = async (id: string) => {
		const response: AxiosResponse<RetrieveLegalDocumentResponse> =
			await axiosInstance.post(`/legal-document/${id}/restore/`)

		return response.data
	}

	const uploadDocument = async (payload: any) => {
		const response: AxiosResponse<any> = await axiosInstance.post(
			"/legal-document/upload/",
			payload,
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			},
		)

		return response.data
	}

	const uploadFileToDocument = async ({ id, payload }: any) => {
		const response: AxiosResponse<any> = await axiosInstance.post(
			`/legal-document/${id}/attach_document/`,
			payload,
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			},
		)

		return response.data
	}

	const downloadFile = async (params: {
		id: string
		type: string
		name: string
	}) => {
		const response = await axiosInstance.get(
			`/legal-document/${params.id}/download?doc_type=${params.type}`,
			{
				responseType: "blob",
			},
		)

		const replacedName = params.name.replace(/ /g, "-")
		const file = new Blob([response.data], {
			type: "application/octet-stream",
		})

		const fileURL = URL.createObjectURL(file)
		const link = document.createElement("a")
		link.href = fileURL
		link.setAttribute("download", `${replacedName}.${params.type}`)
		document.body.appendChild(link)
		link.click()

		if (link && link.parentNode) {
			link.parentNode.removeChild(link)
		}

		URL.revokeObjectURL(fileURL)
	}

	const updateLegalDocument = async ({
		id,
		payload,
	}: {
		id: string
		payload: UpdateLegalDocumentRequestBody
	}) => {
		const response: AxiosResponse<UpdateLegalDocumentResponse> =
			await axiosInstance.patch(`/legal-document/${id}/`, payload)

		return response.data
	}

	const legalDocumentGenerateContent = async ({ id, payload }: {
		id: string
		payload: LegalDocumentGenerateContent
	}) => {
		const response: AxiosResponse<any> = await axiosInstance.post(
			`/legal-document/${id}/generate_content/`, payload
		)

		return response.data
	}

	const copyLegalDocument = async (id: string) => {
		const response: AxiosResponse<RetrieveLegalDocumentResponse> =
			await axiosInstance.post(`/legal-document/${id}/create_copy/`)

		return response.data
	}

	const legalDocumentPropmts = async () => {
		const response: AxiosResponse<PaginatedResponse<LegalDocumentPropmts>> = await axiosInstance.get(
			"/legal-document-prompts/"
		)
		const otherOption = response.data.results.find((option) => option.document_name === "Outros")
		const sortedOptions = response.data.results.sort((a, b) => a.document_name.localeCompare(b.document_name)).filter((option) => option.id !== otherOption?.id)
		return [...sortedOptions, otherOption] as LegalDocumentPropmts[]
	}
	return {
		createLegalDocument,
		retrieveLegalDocument,
		listLegalDocuments,
		deleteLegalDocument,
		restoreLegalDocument,
		uploadDocument,
		uploadFileToDocument,
		downloadFile,
		updateLegalDocument,
		legalDocumentGenerateContent,
		copyLegalDocument,
		legalDocumentPropmts
	}
}

export default useLegalDocumentAPI

import BaseIcon from "./BaseIcon"

const AddCreditsIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M17 22C18.3261 22 19.5979 21.5083 20.5355 20.6332C21.4732 19.758 22 18.571 22 17.3333C22 16.0957 21.4732 14.9087 20.5355 14.0335C19.5979 13.1583 18.3261 12.6667 17 12.6667C15.6739 12.6667 14.4021 13.1583 13.4645 14.0335C12.5268 14.9087 12 16.0957 12 17.3333C12 18.571 12.5268 19.758 13.4645 20.6332C14.4021 21.5083 15.6739 22 17 22ZM17.7143 15.3333V16.6667H19.1429C19.3323 16.6667 19.514 16.7369 19.6479 16.8619C19.7819 16.987 19.8571 17.1565 19.8571 17.3333C19.8571 17.5101 19.7819 17.6797 19.6479 17.8047C19.514 17.9298 19.3323 18 19.1429 18H17.7143V19.3333C17.7143 19.5101 17.639 19.6797 17.5051 19.8047C17.3711 19.9298 17.1894 20 17 20C16.8106 20 16.6289 19.9298 16.4949 19.8047C16.361 19.6797 16.2857 19.5101 16.2857 19.3333V18H14.8571C14.6677 18 14.486 17.9298 14.3521 17.8047C14.2181 17.6797 14.1429 17.5101 14.1429 17.3333C14.1429 17.1565 14.2181 16.987 14.3521 16.8619C14.486 16.7369 14.6677 16.6667 14.8571 16.6667H16.2857V15.3333C16.2857 15.1565 16.361 14.987 16.4949 14.8619C16.6289 14.7369 16.8106 14.6667 17 14.6667C17.1894 14.6667 17.3711 14.7369 17.5051 14.8619C17.639 14.987 17.7143 15.1565 17.7143 15.3333Z"
				fill="#020812"
			/>
			<path
				d="M16.4229 8.964C16.8784 8.77342 17.3112 8.53881 17.7143 8.264V10C17.7143 10.3853 17.41 10.872 16.6386 11.3427C17.4887 11.2979 18.34 11.4112 19.1429 11.676V6C19.1429 4.65733 17.8929 3.66 16.4229 3.036C14.8886 2.38533 12.8186 2 10.5714 2C8.32429 2 6.25429 2.38533 4.72 3.036C3.25 3.66 2 4.65733 2 6V18C2 19.3427 3.25 20.34 4.72 20.964C6.25429 21.6133 8.32429 22 10.5714 22C11.3371 22 12.0829 21.9547 12.7929 21.8707C12.3468 21.5104 11.956 21.0945 11.6314 20.6347C11.2848 20.656 10.9314 20.6667 10.5714 20.6667C8.48 20.6667 6.62 20.3067 5.31143 19.7507C3.93714 19.1667 3.42857 18.4973 3.42857 18V16.264C3.81571 16.5333 4.25714 16.768 4.72 16.964C6.25429 17.6133 8.32429 18 10.5714 18H10.61C10.5581 17.5569 10.5581 17.1098 10.61 16.6667H10.5714C8.48 16.6667 6.62 16.3067 5.31143 15.7507C3.93714 15.1667 3.42857 14.4973 3.42857 14V12.264C3.81571 12.5333 4.25714 12.768 4.72 12.964C6.25429 13.6133 8.32429 14 10.5714 14C10.9457 14 11.3143 13.9893 11.6771 13.968C12.1024 13.3822 12.6354 12.8712 13.2514 12.4587C12.4257 12.592 11.5229 12.6667 10.5714 12.6667C8.48 12.6667 6.62 12.3067 5.31143 11.7507C3.93714 11.1667 3.42857 10.4973 3.42857 10V8.264C3.81571 8.53333 4.25714 8.768 4.72 8.964C6.25429 9.61467 8.32429 10 10.5714 10C12.8186 10 14.8886 9.61467 16.4229 8.964ZM3.42857 6C3.42857 5.50133 3.93714 4.83333 5.31143 4.24933C6.62 3.69467 8.48 3.33333 10.5714 3.33333C12.6629 3.33333 14.5229 3.69333 15.8314 4.24933C17.2057 4.83333 17.7143 5.50267 17.7143 6C17.7143 6.49867 17.2057 7.16667 15.8314 7.75067C14.5229 8.30533 12.6629 8.66667 10.5714 8.66667C8.48 8.66667 6.62 8.30667 5.31143 7.75067C3.93714 7.16667 3.42857 6.49733 3.42857 6Z"
				fill="#020812"
			/>
		</BaseIcon>
	)
}

export default AddCreditsIcon

const BigUpgradeIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
		>
			<path
				d="M12.5 15.39L8.74 17.66L9.73 13.38L6.41 10.5L10.79 10.13L12.5 6.09L14.21 10.13L18.59 10.5L15.27 13.38L16.26 17.66M22.5 9.24L15.31 8.63L12.5 2L9.69 8.63L2.5 9.24L7.95 13.97L6.32 21L12.5 17.27L18.68 21L17.04 13.97L22.5 9.24Z"
				fill="white"
			/>
		</svg>
	)
}

export default BigUpgradeIcon

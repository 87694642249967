import { Button } from "components/button"
import Modal from "components/modal"
import DisplayCredits from "./DisplayCredits"
import WelcomeImage from "assets/welcome-image.jpg"
import { ReactNode, SetStateAction } from "react"
import { useAtom } from "jotai"
import handleCheckoutError from "utils/handleCheckoutError"
import { useQuery } from "@tanstack/react-query"
import { formatPrice } from "utils/formatPrice"
import { ButtonVariant } from "components/button/types"
import { creditsAtom } from "./atoms"
import useProductCatalogAPI from "hooks/useProductCatalogAPI"
import useSubscriptionAPI from "hooks/useSubscriptionAPI"

export interface PlansModalProps {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	isClosedByButton?: React.MutableRefObject<boolean>
	setHasBuyCredits?: React.Dispatch<React.SetStateAction<boolean>>
}
const CreditsModal = ({
	open,
	setOpen,
	isClosedByButton,
	setHasBuyCredits,
}: PlansModalProps) => {
	const { productCatalogCredits } = useProductCatalogAPI()
	const { data } = useQuery(
		["product-catalog-credits"],
		productCatalogCredits,
	)
	interface BuyCreditsCardProps {
		credits: number
		value: string
		tag?: ReactNode
		lookupKey: string
	}
	function BuyCreditsCard({
		credits,
		tag,
		value,
		lookupKey,
	}: BuyCreditsCardProps) {
		function handleClick() {
			const tab = window.open(`${window.location.origin}/redirect`)
			subscriptionBuyCredits({
				successUrl: `${window.location.origin}/checkout-success`,
				lookupKey: lookupKey,
			})
				.then((data) => {
					setCredits(credits)
					tab!.location.href = data.checkout_url
					if (setHasBuyCredits) {
						setHasBuyCredits(true)
					}
				})
				.catch(() => handleCheckoutError(tab))
		}

		return (
			<div className="flex p-2 justify-between items-center self-stretch bg-semi-white-4">
				<div className="flex items-center">
					<DisplayCredits credits={credits} />
					{tag}
				</div>
				<div>
					<Button
						variant={ButtonVariant.Contained}
						className="w-[144px] justify-center"
						onClick={handleClick}
					>
						R$ {value}
					</Button>
				</div>
			</div>
		)
	}

	const [, setCredits] = useAtom(creditsAtom)

	const { subscriptionBuyCredits } = useSubscriptionAPI()

	const productCatalogCredit = data
		? Object.values(data).map((credit) => {
				return { ...credit, value: formatPrice(credit.price) }
		  })
		: []
	const creditOptions = productCatalogCredit

	function onOpen(value: SetStateAction<boolean>) {
		if (isClosedByButton) isClosedByButton.current = true
		setOpen(value)
	}
	return (
		<>
			<Modal
				size="550"
				width="w-[100%] p-[32px]"
				openModal={open}
				setOpenModal={onOpen}
				dismissible={true}
				title="Comprar créditos"
			>
				<span className="prose max-w-none font-lato">
					<img
						src={WelcomeImage}
						width={504}
						height={200}
						className="my-6"
					/>
					{creditOptions.map((option, index) => (
						<div
							key={index}
							className={
								index !== creditOptions.length - 1 ? "mb-2" : ""
							}
						>
							<BuyCreditsCard
								credits={option.credits}
								value={option.value}
								lookupKey={option.lookup_key}
							/>
						</div>
					))}
				</span>
			</Modal>
		</>
	)
}

export default CreditsModal

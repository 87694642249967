import { QueryKey, useQueryClient } from "@tanstack/react-query"
import { DependencyList, useEffect } from "react"

interface UseLongPollVars {
	conditionToStop?: boolean
	queryKey: QueryKey
	deps?: DependencyList
	ms?: number
}

export function useLongPoll({
	conditionToStop,
	queryKey,
	deps,
	ms = 1000,
}: UseLongPollVars) {
	const queryClient = useQueryClient()

	useEffect(() => {
		if (conditionToStop) return

		const interval = setInterval(() => {
			queryClient.invalidateQueries({
				queryKey,
			})
			if (conditionToStop) {
				clearInterval(interval)
			}
		}, ms)

		return () => clearInterval(interval)
	}, deps)
}

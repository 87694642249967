import React, { InputHTMLAttributes, ReactNode, forwardRef, Ref } from "react"

export interface InputFieldProps
	extends React.DetailedHTMLProps<
		InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	> {
	leftIcon?: ReactNode
	rightIcon?: ReactNode
	className?: string
	label?: string
	error?: string
	value?: string
	onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
}

const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
	(
		{
			leftIcon,
			rightIcon,
			className,
			label,
			value,
			error,
			onKeyDown,
			...props
		},
		ref: Ref<HTMLInputElement>,
	) => {
		const leftIconClassName = leftIcon ? "pl-12" : "pl-3"
		const rightIconClassName = rightIcon ? "pr-8" : "pr-3"
		return (
			<div className="relative flex items-center ">
				{leftIcon && <div className="absolute pl-4">{leftIcon}</div>}
				<div className="w-full">
					{label && (
						<div className="mb-2 text-brand-gray-3">{label}</div>
					)}
					<input
						ref={ref}
						className={`${
							error && "!border-brand-red focus:!outline-none"
						} !outline-offset-0 block rounded-md border border-brand-gray-1 py-3 placeholder:text-[#ACACAC] focus:border-light-blue-4 focus:outline-none  ${leftIconClassName} ${rightIconClassName} ${className}`}
						value={value}
						onKeyDown={onKeyDown}
						{...props}
					/>
				</div>
				{rightIcon && (
					<div className="absolute right-3 inset-y-0 top-[14px]">
						{rightIcon}
					</div>
				)}
			</div>
		)
	},
)

InputField.displayName = "InputField"

export default InputField

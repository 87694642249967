import React from "react"
import BaseIcon from "./BaseIcon"

const SecurityIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path d="M12 0.000488281L3 4.48391V15.0333C3 17.4203 3.94821 19.7095 5.63604 21.3973C7.32387 23.0851 9.61305 24.0333 12 24.0333C14.3869 24.0333 16.6761 23.0851 18.364 21.3973C20.0518 19.7095 21 17.4203 21 15.0333V4.48391L12 0.000488281ZM17.1225 20.4858L12 17.934V19.6051L15.7771 21.4867C14.6392 22.1494 13.347 22.5008 12.0302 22.5058C10.7135 22.5108 9.41864 22.1691 8.27578 21.5151C7.13293 20.8611 6.18235 19.9178 5.51957 18.7801C4.85678 17.6423 4.50515 16.3501 4.5 15.0333V5.40761L12 1.67141L19.5 5.40761V8.22011L12 4.48384V6.15476L19.5 9.89096V12.7035L12 8.96726V10.6382L19.5 14.3743V15.0333C19.5004 15.7147 19.407 16.3928 19.2225 17.0487L12 13.4506V15.1215L18.6714 18.445C18.2757 19.2089 17.7518 19.8992 17.1225 20.4858Z" />
		</BaseIcon>
	)
}

export default SecurityIcon

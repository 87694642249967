import { useAuth0 } from "@auth0/auth0-react"
import ExitIcon from "icons/ExitIcon"

const ExitButton = ({
	className,
	style,
}: {
	className?: string
	style?: React.CSSProperties
}) => {
	const { logout } = useAuth0()

	async function handleLogout() {
		logout({
			logoutParams: {
				returnTo: window.location.origin,
			},
		})
	}

	return (
		<div
			className={`flex text-brand-gray-3 gap-2 items-center cursor-pointer ${className}`}
			style={style}
			onClick={handleLogout}
		>
			<ExitIcon /> Sair
		</div>
	)
}

export default ExitButton

import BigUpgradeIcon from "icons/BigUpgradeIcon"
import UpgradeIcon from "icons/UpgradeIcon"
import { useAtom } from "jotai"
import { plansModalAtom } from "pages/BasePage/atoms"

interface UpgradeButtonProps {
	isBig?: boolean
	className?: string
	onClick?: () => void
	text?: string
}

const UpgradeButton = ({
	isBig,
	className,
	onClick,
	text = "Assinar agora",
}: UpgradeButtonProps) => {
	const [, setPlansModalOpen] = useAtom(plansModalAtom)

	return (
		<button
			className={` ${
				isBig ? "gap-2 w-[156px] h-[48px]" : "h-[24px] w-[64px] gap-1"
			} text-base px-2 py-1 rounded-[4px] flex items-center  cursor-pointer text-white  !outline-none bg-gradient-to-r from-yellow-gradient-from to-yellow-gradient-to hover:bg-none hover:bg-yellow ${className}`}
			onClick={() => {
				if (onClick) {
					onClick()
				} else {
					setPlansModalOpen(true)
				}
			}}
		>
			<div>{isBig ? <BigUpgradeIcon /> : <UpgradeIcon />}</div>
			{isBig ? (
				<span>{text}</span>
			) : (
				<span className="text-[14px]">PRO</span>
			)}
		</button>
	)
}

export default UpgradeButton

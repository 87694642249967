import { useAtom } from "jotai"
import { openAtom } from "./atoms"
import { useEffect, useState } from "react"
import { Strategy } from "../ChooseStrategiesStep"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { getDocumentStatus } from "utils/getDocumentStatus"
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd"
import { useLongPoll } from "hooks/useLongPoll"
import Loading from "components/loading"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"
import Tooltip from "components/tooltip"
import ExclamationIcon from "icons/ExclamationIcon"
import { Button } from "components/button"
import { ButtonColor, ButtonVariant } from "components/button/types"
import MagicWandIcon from "icons/MagicWandIcon"
import {
	LegalDocumentStatus,
	RetrieveLegalDocumentResponse,
} from "types/legalDocument"
import ClauseCard from "../ClauseCard"
import CreditsModal from "pages/SidebarPages/subcomponents/CreditsModal"
import DocumentInformationModal from "../../DocumentInformationModal"
import DraggableClause from "../DraggableClause"
import { LoadingComponent } from "./LoadingComponent"
import Lottie from "react-lottie"
import loading from "lotties/dotTheses.json"
import { useClauseContentContext } from "hooks/useClauseContentContext"
import { TooltipPosition } from "components/tooltip/enums"
import useContractAPI from "hooks/useContractAPI"
import { plansModalAtom } from "pages/BasePage/atoms"
import useCheckIfHasCredits from "hooks/useCheckIfHasCredits"

const LottieComponent = Lottie as any

const lottieOptions = {
	loop: true,
	autoplay: true,
	animationData: loading,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
		className: "cursor-default",
	},
}

export const NewChooseStrategiesStep = ({
	documentId,
}: {
	documentId: string
}) => {
	const [open, setOpen] = useAtom(openAtom)
	const [strategies, setStrategies] = useState<Strategy[]>([])
	const [openInformations, setOpenInformations] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [, setOpenPlansModal] = useAtom(plansModalAtom)
	const hasCredits = useCheckIfHasCredits()

	const queryClient = useQueryClient()
	const { listClauses, retrieveContract, createNewClause } = useContractAPI()

	const { clausesContent, setClausesContent } = useClauseContentContext()

	const { data: listClausesData } = useQuery(
		["listClauses", documentId],
		() => listClauses(documentId),
	)

	const { data: legalDocument } = useQuery(
		["legalDocument", documentId],
		() => retrieveContract(documentId!),
		{
			staleTime: Infinity,
		},
	)
	const lastProcess = legalDocument?.processes?.slice(-1)[0]

	const createNewClauseMutation = useMutation({
		mutationFn: createNewClause,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["legalDocument", documentId],
			})
			queryClient.invalidateQueries({
				queryKey: ["listClauses", documentId],
			})
			setTimeout(() => {
				setAlreadyInvalidated(false)
			}, 2000)
		},
	})

	useEffect(() => {
		if (listClausesData && listClausesData?.length > 0) {
			setAlreadyInvalidated(false)
		}
	}, [listClausesData?.length])

	const [alreadyInvalidated, setAlreadyInvalidated] = useState(false)
	const firstClause = getObjectClause()
	const clausesWithText = listClausesData?.filter((clause) => clause.text)
	const documentStatus = getDocumentStatus(legalDocument)
	const conditionToFilter =
		documentStatus === "processing" &&
		lastProcess?.process_type !== "generate_ai_editor_text"

	const filteredStrategies = conditionToFilter
		? strategies
				.filter((thesis) => thesis?.text && thesis?.text?.text)
				.filter((strategy) => {
					const strategyInListClausesData = clausesContent.find(
						(clauseContent) => clauseContent.id === strategy.id,
					)
					return !strategyInListClausesData
				})
		: strategies.filter((strategy) => {
				const strategyInListClausesData = clausesContent.find(
					(clauseContent) => clauseContent.id === strategy.id,
				)
				return !strategyInListClausesData
		  })

	const compareOrderByClauses = (a: any, b: any) => {
		const indexA = clausesContent.findIndex(
			(clauseContent) => clauseContent.id === a.id,
		)
		const indexB = clausesContent.findIndex(
			(clauseContent) => clauseContent.id === b.id,
		)
		return indexA - indexB
	}
	const selectedStrategies = strategies
		.map((strategy) => {
			return { ...strategy, orderHint: 0 }
		})
		.filter((strategy) => {
			const strategyInListClausesData = clausesContent.find(
				(clauseContent) => clauseContent.id === strategy.id,
			)
			return !!strategyInListClausesData
		})
		.slice()
		.sort(compareOrderByClauses)

	function reorderClausesContentArray(
		baseArray: any[],
		orderArray: any[],
	): any[] {
		const newArray = [...baseArray]

		newArray.sort((a, b) => {
			const indexA = orderArray.indexOf(a.id)
			const indexB = orderArray.indexOf(b.id)

			return indexA - indexB
		})

		return newArray
	}

	const handleDragEnd = (result: DropResult) => {
		if (!result.destination) return

		const reorderedStrategies = Array.from(
			strategies.filter((strategy) => {
				const strategyInListClausesData = clausesContent.find(
					(clauseContent) => clauseContent.id === strategy.id,
				)
				return !strategyInListClausesData
			}),
		)

		const reorderedStrategiesSelected = Array.from(
			strategies.filter((strategy) => {
				const strategyInListClausesData = clausesContent.find(
					(clauseContent) => clauseContent.id === strategy.id,
				)
				return !!strategyInListClausesData
			}),
		)

		if (result.source.droppableId === "strategiesDroppable") {
			const removedSelected = reorderedStrategiesSelected.splice(
				result.source.index,
				1,
			)
			reorderedStrategiesSelected.splice(
				result.destination.index,
				0,
				removedSelected[0],
			)
		}
		if (result.source.droppableId === "strategiesDroppable2") {
			const [removed] = reorderedStrategies.splice(result.source.index, 1)
			reorderedStrategies.splice(result.destination.index, 0, removed)
		}

		const reorderedStrategiesIds = reorderedStrategiesSelected.map(
			(value) => value.id,
		)

		const reorderedStrategiesWithOrderHint = reorderedStrategies.map(
			(strategy, index) => {
				return { ...strategy, orderHint: index }
			},
		)

		const reorderedStrategiesSelectedWithOrderHint =
			reorderedStrategiesSelected.map((strategy, index) => {
				return { ...strategy, orderHint: index }
			})

		setStrategies([
			...reorderedStrategiesSelectedWithOrderHint,
			...reorderedStrategiesWithOrderHint,
		])
		queryClient.setQueryData(["listClauses", documentId], () => [
			firstClause,
			...reorderedStrategiesSelectedWithOrderHint,
			...reorderedStrategiesWithOrderHint,
		])

		setClausesContent(
			reorderClausesContentArray(clausesContent, reorderedStrategiesIds),
		)
	}

	function getConditionToStop() {
		let shouldStop = false

		if (alreadyInvalidated) {
			return true
		}

		if (listClausesData) {
			shouldStop =
				clausesWithText?.length === 0
					? false
					: clausesWithText?.length === listClausesData.length
		}

		if (shouldStop && !alreadyInvalidated) {
			setAlreadyInvalidated(true)
			queryClient.invalidateQueries(["legalDocument", documentId])
			queryClient.invalidateQueries(["listClauses", documentId])
		}

		return shouldStop
	}

	useLongPoll({
		conditionToStop: getConditionToStop(),
		queryKey: ["listClauses", documentId],
		deps: [
			listClausesData?.length,
			alreadyInvalidated,
			clausesWithText?.length,
		],
		ms: 3000,
	})

	useEffect(() => {
		if (listClausesData && firstClause?.id) {
			const filteredStrategies = listClausesData.filter(
				(thesis) => thesis.id !== firstClause?.id,
			)
			const filteredNewStrategies = filteredStrategies.filter(
				(strategy) => !strategies.find((s) => s.id === strategy.id),
			)

			const reorderedStrategies = strategies.map((strategy) => {
				const strategyInListClausesData = listClausesData.find(
					(s) => s.id === strategy.id,
				)
				if (!strategyInListClausesData) return strategy
				return {
					...strategyInListClausesData,
				}
			})

			const newStrategies = [
				...reorderedStrategies,
				...filteredNewStrategies,
			].map((strategy, index) => {
				return { ...strategy, orderHint: index }
			})

			setStrategies(newStrategies)
		}
	}, [listClausesData?.length, clausesWithText?.length, listClausesData])

	if (!listClausesData) return <Loading />

	if (
		strategies &&
		strategies.length <= 0 &&
		getDocumentStatus(legalDocument) === "processing"
	) {
		return (
			<>
				<ManualStrategy />
				<div className="mb-6 w-full p-6 shadow-1 flex items-end justify-center">
					<LottieComponent
						options={lottieOptions}
						height={10}
						width={30}
						style={{ margin: "0 8px 4.5px 0" }}
						isClickToPauseDisabled
					/>
					<p className="text-dark-blue-2 text-cta-2">
						Gerando estratégia
					</p>
				</div>
			</>
		)
	}

	function getObjectClause() {
		if (listClausesData) {
			const objectClause = listClausesData.find(
				(strategy) => strategy.is_object_clause,
			)
			return objectClause
		}
	}

	function ManualStrategy() {
		const [newClause, setNewClause] = useState("")
		const { createManualClause } = useContractAPI()
		const queryClient = useQueryClient()

		const createNewStrategyMutation = useMutation({
			mutationFn: createManualClause,
			onSuccess: () => {
				queryClient.invalidateQueries({
					queryKey: ["legalDocument", documentId],
				})
				setTimeout(() => {
					queryClient.invalidateQueries({
						queryKey: ["listClauses", documentId],
					})
					setAlreadyInvalidated(false)
				}, 1000)
			},
		})

		function generateNewStrategy() {
			if (!hasCredits) return handleNoBalance()
			setNewClause("")
			createNewStrategyMutation.mutate({
				id: documentId,
				payload: {
					instructions:
						newClause.charAt(0).toUpperCase() + newClause.slice(1),
				},
			})
		}

		function handleNoBalance() {
			setOpenPlansModal(true)
			showToast(
				"Você não tem créditos para concluir essa ação.",
				ToastType.Error,
			)
		}

		return (
			<div>
				<div className={`flex items-center justify-between `}>
					<p className="text-brand-gray-3">
						Encontramos essas cláusulas pra você, clique para
						selecionar:
					</p>

					<div className="flex items-center ">
						<Tooltip
							text="Informações do documento"
							bg={"bg-dark-blue-3"}
							position={TooltipPosition.Below}
							className="right-[86px]"
						>
							<div onClick={() => setOpenInformations(true)}>
								<ExclamationIcon className="cursor-pointer" />
							</div>
						</Tooltip>
						<Button
							disabled={documentStatus === "processing"}
							onClick={() => {
								if (!hasCredits) {
									setOpenPlansModal(true)
									return showToast(
										"Você não tem créditos para concluir essa ação.",
										ToastType.Error,
									)
								}
								createNewClauseMutation.mutate(documentId)
							}}
							variant={ButtonVariant.Contained}
							color={ButtonColor.Secondary}
							icon={<MagicWandIcon />}
							className="ml-4"
						>
							Gerar nova estratégia
						</Button>
					</div>
				</div>

				<div className="relative my-[24px] flex items-center ">
					<div
						className="absolute right-3 pl-4"
						onClick={() => newClause && generateNewStrategy()}
					>
						<MagicWandIcon
							className={`${
								newClause
									? "fill-brand-purple-3 cursor-pointer"
									: "fill-brand-gray-1"
							}`}
						/>
					</div>

					<input
						type="text"
						className="block w-full rounded-md border border-brand-gray-1 p-3 pr-3"
						placeholder="Não encontrou o que buscava? Peça estratégias específicas pra IA"
						value={newClause}
						disabled={
							getDocumentStatus(legalDocument) === "processing"
						}
						onChange={(e) => setNewClause(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === "Enter" && newClause) {
								generateNewStrategy()
							}
						}}
					/>
				</div>
			</div>
		)
	}
	return (
		<div>
			<div
				className={`w-full ${
					getDocumentStatus(legalDocument) ===
						LegalDocumentStatus.Completed && "pb-[75px]"
				}`}
			>
				<ManualStrategy />
				<>
					<div className="mt-3 flex">
						<div className="w-full ">
							{getObjectClause() && getObjectClause()?.text && (
								<ClauseCard
									documentId={documentId}
									thesis={getObjectClause()}
									data-cy="clauses"
									number={1}
									isLoading={isLoading}
									setIsLoading={setIsLoading}
									selected
									legalDocument={
										legalDocument as RetrieveLegalDocumentResponse
									}
								/>
							)}

							{selectedStrategies &&
								selectedStrategies.length > 0 && (
									<DragDropContext onDragEnd={handleDragEnd}>
										<Droppable droppableId="strategiesDroppable">
											{(provided) => (
												<div
													ref={provided.innerRef}
													{...provided.droppableProps}
												>
													{selectedStrategies &&
														selectedStrategies
															.filter(
																(thesis) =>
																	thesis?.id !==
																	getObjectClause()
																		?.id,
															)

															.map(
																(
																	thesis,
																	index,
																) => (
																	<DraggableClause
																		key={
																			thesis.id
																		}
																		documentId={
																			documentId
																		}
																		thesis={
																			thesis
																		}
																		legalDocument={
																			legalDocument
																		}
																		index={
																			index
																		}
																	/>
																),
															)}

													{provided.placeholder}
												</div>
											)}
										</Droppable>
									</DragDropContext>
								)}

							{strategies && strategies.length > 0 && (
								<DragDropContext onDragEnd={handleDragEnd}>
									<Droppable droppableId="strategiesDroppable2">
										{(provided) => (
											<div
												ref={provided.innerRef}
												{...provided.droppableProps}
											>
												{filteredStrategies
													.filter(
														(thesis) =>
															thesis.id !==
															getObjectClause()
																?.id,
													)
													.sort(
														(a, b) =>
															a.orderHint -
															b.orderHint,
													)
													.map((thesis, index) => (
														<DraggableClause
															key={thesis.id}
															documentId={
																documentId
															}
															thesis={thesis}
															index={index}
															noRomanNumber
															legalDocument={
																legalDocument as RetrieveLegalDocumentResponse
															}
														/>
													))}

												{provided.placeholder}
											</div>
										)}
									</Droppable>
								</DragDropContext>
							)}

							{getDocumentStatus(legalDocument) ===
								"processing" &&
								(lastProcess?.process_type ===
									"generate_new_clause" ||
									lastProcess?.process_type ===
										"generate_manual_clause" ||
									lastProcess?.process_type ===
										"generate_clauses") && (
									<LoadingComponent />
								)}
						</div>
					</div>
				</>
			</div>
			<CreditsModal open={open} setOpen={setOpen} />
			<DocumentInformationModal
				setOpenModal={setOpenInformations}
				openModal={openInformations}
				title={legalDocument?.title || ""}
				userInput={legalDocument?.user_input}
				toFavor={legalDocument?.to_favor}
				type={legalDocument?.type}
				specificType={legalDocument?.legal_document_type}
			/>
		</div>
	)
}

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import UploadPdf from "./subcomponents/UploadPdf"
import WordIcon from "icons/WordIcon"

import JusticeIcon from "icons/JusticeIcon"
import ContractIcon from "icons/ContractIcon"
import StatusChip from "components/status-chip"
import { formatDate } from "utils/formatDate"
import Tooltip from "components/tooltip"
import DownloadIcon from "icons/DownloadIcon"
import { Dropdown } from "components/dropdown"
import DotsIcon from "icons/DotsIcon"
import { useEffect, useRef, useState } from "react"
import DeleteDocumentModal from "pages/MyDocumentsPage/subcomponents/DeleteDocumentModal"
import { useNavigate } from "react-router-dom"
import { ROUTES } from "routes"
import {
	LegalDocumentStatus,
	RetrieveLegalDocumentResponse,
} from "types/legalDocument"
import { formatUserName } from "utils/profile"
import { useJwt } from "hooks/useJwt"
import { TypeIconProps } from "pages/MyDocumentsPage/legal-document-table/subcomponents/ColumnType"
import { getDocumentStatus } from "utils/getDocumentStatus"

import NotFoundIcon from "icons/NotFoundIcon"
import ChatMagicIcon from "icons/ChatMagicIcon"
import PdfDocICon from "icons/PdfDocIcon"
import LawdeckDocIcon from "icons/LawdeckDocIcon"
import TxtDocIcon from "icons/TxtDocIcon"
import Loading from "components/loading"
import PadlockIcon from "icons/PadlockIcon"
import { TooltipPosition } from "components/tooltip/enums"
import { showToast } from "components/toast/functions"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"
import usePetitionAPI from "hooks/usePetitionAPI"
import useContractAPI from "hooks/useContractAPI"
import useUploadedDocumentAPI from "hooks/useUploadedDocumentAPI"

export default function ChatPdfPage() {
	const [documentId, setDocumentId] = useState<string>()
	const [open, setOpen] = useState(false)
	const [title, setTitle] = useState<string>()
	const navigate = useNavigate()
	const [jwt] = useJwt()
	const initialRender = useRef(false)

	const { listLegalDocuments, downloadFile } = useLegalDocumentAPI()
	const { copyPetition } = usePetitionAPI()
	const { copyContract } = useContractAPI()
	const { copyUploadedDocument } = useUploadedDocumentAPI()
	const queryClient = useQueryClient()
	const [recentDocuments, setRecentDocuments] = useState<
		RetrieveLegalDocumentResponse[]
	>([])


	const { data, isLoading } = useQuery(["listLegalDocuments", jwt], () =>
		listLegalDocuments(),
	)

	function TypeIcon({ type }: TypeIconProps) {
		const icon = {
			petition: <JusticeIcon />,
			contract: <ContractIcon />,
			power_of_attorney: <JusticeIcon />,
			uploaded_document: <ChatMagicIcon />,
		}

		return icon[type]
	}

	const translate = {
		petition: "Petição",
		contract: "Contrato",
		power_of_attorney: "Procuração",
		uploaded_document: "Importado",
	}

	useEffect(() => {
		if (data) {
			const recents = data
				.filter((document) => {
					return (
						getDocumentStatus(document) ===
						LegalDocumentStatus.Completed ||
						document.type === "uploaded_document"
					)
				})
				.slice(0, 3)
			setRecentDocuments(recents)
			initialRender.current = true
		}
	}, [data])

	const copyPetitionMutation = useMutation({
		mutationFn: copyPetition,
		onSuccess(data) {
			onSucessCopyDocument(data)
		},
	})

	const copyContractMutation = useMutation({
		mutationFn: copyContract,
		onSuccess(data) {
			onSucessCopyDocument(data)
		},
	})

	const copyUploadedDocumentMutation = useMutation({
		mutationFn: copyUploadedDocument,
		onSuccess(data) {
			onSucessCopyDocument(data)
		},
	})
	const downloadLegalDocumentMutation = useMutation({
		mutationFn: downloadFile,
	})

	async function handleDownload(
		document: RetrieveLegalDocumentResponse,
		type: string,
	) {
		await downloadLegalDocumentMutation.mutateAsync({
			id: document.id,
			type: type,
			name: document.title || "",
		})
	}

	function onCopyDocument(document: RetrieveLegalDocumentResponse) {
		if (document) {
			const { id } = document

			const { type } = document

			if (type === "petition") {
				copyPetitionMutation.mutateAsync(id)
			}

			if (type === "uploaded_document") {
				copyUploadedDocumentMutation.mutateAsync(id)
			}

			if (type === "contract") {
				copyContractMutation.mutateAsync(id)
			}
		}

	}

	function onSucessCopyDocument(data: RetrieveLegalDocumentResponse) {
		showToast(`O documento “${data.title}” foi duplicado.`)
		queryClient.setQueryData(
			["listLegalDocuments", jwt],
			(oldDocuments: RetrieveLegalDocumentResponse[] | undefined) => {
				if (oldDocuments) {
					return [data, ...oldDocuments]
				}

				return oldDocuments
			},
		)
		queryClient.invalidateQueries(["listLegalDocuments", jwt])
		queryClient.invalidateQueries({
			queryKey: ["current-user", jwt],
		})
	}

	function getIcon(document: RetrieveLegalDocumentResponse) {
		switch (document.extension) {
			case "docx":
				return <WordIcon />
			case "pdf":
				return <PdfDocICon />
			case "lawdeck":
				return <LawdeckDocIcon />
			case "txt":
				return <TxtDocIcon />
			default:
				return <LawdeckDocIcon />
		}
	}

	return !initialRender.current || isLoading ? (
		<Loading />
	) : (
		<div>
			<UploadPdf />
			<h3 className="font-semibold mb-6">Meus documentos</h3>

			<div>
				{recentDocuments.length > 0 ? (
					recentDocuments?.map((document) => {
						const sections = [
							{
								items: [
									{
										label: "Duplicar",
										onClick: () => {
											onCopyDocument(document)
										},
									},
									{
										label: "Excluir",
										deleteLabel: true,
										onClick: () => {
											setOpen(true)
											setDocumentId(document.id)
											setTitle(document.title)
										},
									},
								],
							},
						]

						return (
							<div
								key={document.id}
								className="text-dark-blue-1  gap-[20px]  bg-white mb-4 flex items-center  hover:cursor-pointer hover:shadow-[0px_0px_10px_0px_rgba(0,0,0,0.14)] hover:text-dark-blue-2 border-[3px] border-transparent rounded-[4px]"
							>
								<div
									className=" flex items-stretch w-full"
									onClick={() =>
										navigate(
											ROUTES.documentDetail({
												id: document.id,
											}),
										)
									}
								>
									<div className="basis-[40%] grow flex items-center pl-4 py-4">
										<div className="flex  group-hover:text-blue-500 items-center w-[100%]">
											<div className="w-[56px] h-[56px] pl-2 ">
												{getIcon(document)}
											</div>
											<div className="flex flex-col ms-4">
												<p className="font-semibold text-cta-2 line-clamp-1 xl:text-h4">
													{document.title ||
														"Documento sem título"}
												</p>
												<div className="text-brand-gray-2">
													Criado por:{" "}
													{
														document.created_by
															.first_name
													}
												</div>
											</div>
										</div>
									</div>

									<div className="basis-[15%] shrink flex items-center py-4">
										<div className="flex min-w-[110px] max-w-[244px] text-brand-gray-3">
											<div className="me-2">
												<TypeIcon
													type={document.type}
												/>
											</div>
											{translate[document.type]}
										</div>
									</div>

									<div className="basis-[15%] shrink flex items-center py-4">
										<div className="min-w-[110px] max-w-[244px]">
											<StatusChip document={document} />
										</div>
									</div>

									<div className="basis-[20%] shrink flex items-center py-4">
										<div className="flex min-w-[100px] max-w-[244px] flex-col">
											<div className="flex gap-2 pb-2">
												<div className="h-6 w-6 overflow-hidden rounded-full">
													<img
														src={
															document.updated_by
																.picture
														}
														alt="Avatar"
													/>
												</div>
												<p className="whitespace-nowrap text-cta-1 font-normal text-gray-600">
													{formatUserName(
														document.updated_by,
													)}
												</p>
											</div>
											<div className="text-cta-1 text-gray-400">
												{formatDate(
													document._updated_at,
												)}
											</div>
										</div>
									</div>
								</div>
								<div className="basis-[10%] shrink flex items-center pr-4 py-4">
									<div className="flex justify-end gap-4">
										<Tooltip
											text="Privado"
											position={TooltipPosition.Below}
										>
											<div className="text-dark-blue-1 hover:text-dark-blue-2 cursor-pointer">
												<PadlockIcon className="fill-current" />
											</div>
										</Tooltip>

										<div
											onClick={() =>
												handleDownload(document, "docx")
											}
										>
											<Tooltip
												text="Baixar"
												position={TooltipPosition.Below}
											>
												<DownloadIcon className="cursor-pointer fill-dark-blue-1 hover:fill-dark-blue-2" />
											</Tooltip>
										</div>

										<Dropdown
											sections={sections}
											className="right-0 w-[165px]"
										>
											<div className="text-dark-blue-1 hover:text-dark-blue-2 cursor-pointer">
												<DotsIcon />
											</div>
										</Dropdown>
									</div>
								</div>
							</div>
						)
					})
				) : (
					<div className=" flex items-center justify-center h-full flex-col mb-20 ">
						<NotFoundIcon height="190" />
						<div className="flex items-center justify-center flex-col ">
							<h3 className="text-brand-gray-3 mb-2 font-semibold">
								Nenhum documento encontrado
							</h3>
							<p className="text-brand-gray-2 text-cta-1">
								Parece que você ainda não criou nenhum documento
								dentro da plataforma.
							</p>
						</div>
					</div>
				)}
			</div>
			{documentId && (
				<DeleteDocumentModal
					documentId={documentId}
					title={title ?? ""}
					open={open}
					setOpen={setOpen}
				/>
			)}
		</div>
	)
}

import { useState, useRef } from 'react';

interface TitleTooltipProps {
  children: React.ReactNode;
  text: string;
  disable?: boolean;

  className?: string;
}

export default function TitleTooltip({ children, text, disable, className }: TitleTooltipProps) {
  const [isVisible, setIsVisible] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  function showTooltip() {
    if (disable) return
    timeoutRef.current = setTimeout(() => {
      setIsVisible(true);
    }, 1000);
  };

  function hideTooltip() {
    if (timeoutRef && timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      setIsVisible(false);
    }
  };

  return (
    <div className="relative flex items-center"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}>
      {children}
      {isVisible && (
        <div className={`w-[500px] break-words absolute z-10  p-3 text-xs text-white bg-brand-gray-3 rounded    top-full left-[250px] -translate-x-1/2 ${className}`}>
          {text}
        </div>
      )}
    </div>
  );
};



import Modal from "components/modal"
import { Button } from "components/button"
import { ButtonVariant } from "components/button/types"

interface NewLexChatModalProps {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	onConfirm: () => void
}

const NewLexChatModal = ({
	open,
	setOpen,
	onConfirm,
}: NewLexChatModalProps) => {
	return (
		<Modal
			size="md"
			width="w-[550px]"
			openModal={open}
			setOpenModal={setOpen}
			dismissible
			onlyCloseButton
		>
			<div className="flex  justify-center flex-col p-2">
				<h3 className={"font-semibold mb-4 text-[22px]"}>
					Deseja iniciar um novo chat?
				</h3>
				<p className="text-brand-gray-3 mb-8 text-cta-1">
					Tem certeza que deseja continuar? Todo o histórico do chat
					atual será perdido, e esta é uma ação irreversível.
				</p>

				<div className="flex items-center justify-between">
					<Button
						variant={ButtonVariant.Outlined}
						className="mr-4 w-[235px] justify-center"
						onClick={() => setOpen(false)}
					>
						Cancelar
					</Button>
					<Button
						variant={ButtonVariant.Contained}
						className="flex w-[235px] justify-center"
						onClick={() => onConfirm()}
					>
						Iniciar novo chat
					</Button>
				</div>
			</div>
		</Modal>
	)
}

export default NewLexChatModal

import React from "react"
import BaseIcon from "./BaseIcon"

const ArrowChangeIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11 2L14 5L11 8L10.295 7.295L12.085 5.5H2V4.5H12.085L10.295 2.705L11 2ZM3.915 11.5L5.705 13.295L5 14L2 11L5 8L5.705 8.705L3.915 10.5H14V11.5H3.915Z"
			/>
		</BaseIcon>
	)
}

export default ArrowChangeIcon

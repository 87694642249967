import React, { useState } from "react"
import { Draggable } from "react-beautiful-dnd"
import { RetrieveLegalDocumentResponse } from "types/legalDocument"
import ClauseCard from "./ClauseCard"
import { getDocumentStatus } from "utils/getDocumentStatus"

interface DraggableClauseProps {
	thesis: any
	index: number
	documentId: string
	noRomanNumber?: boolean
	legalDocument?: RetrieveLegalDocumentResponse
}

const DraggableClause = ({
	thesis,
	index,
	documentId,
	noRomanNumber,
	legalDocument,
}: DraggableClauseProps) => {
	const [isContainerHovered, setIsContainerHovered] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const isProcessing = getDocumentStatus(legalDocument) === "processing"
	return (
		<div
			onMouseEnter={() => {
				if (!isProcessing) setIsContainerHovered(true)
			}}
			onMouseLeave={() => {
				if (!isProcessing) setIsContainerHovered(false)
			}}
		>
			<Draggable
				draggableId={thesis.id}
				index={index}
				isDragDisabled={isLoading || isProcessing}
			>
				{(provided) => (
					<div
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
					>
						<ClauseCard
							documentId={documentId}
							thesis={thesis}
							data-cy="clauses"
							number={index + 2}
							isLoading={isLoading}
							setIsLoading={setIsLoading}
							isContainerHovered={isContainerHovered}
							noRomanNumber={noRomanNumber}
							legalDocument={
								legalDocument as RetrieveLegalDocumentResponse
							}
						/>
					</div>
				)}
			</Draggable>
		</div>
	)
}

export default DraggableClause

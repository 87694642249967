import Tooltip from "components/tooltip"
import ExclamationIcon from "icons/ExclamationIcon"
import { RetrieveLegalDocumentResponse } from "types/legalDocument"
import { getDocumentStatus } from "utils/getDocumentStatus"
import { useAtom } from "jotai"
import { Button } from "components/button"
import { stepAtom } from "./DocumentDisplayV3/atoms"
import { TooltipPosition } from "components/tooltip/enums"
import { ButtonColor, ButtonVariant } from "components/button/types"

interface HeaderProps {
	legalDocument?: RetrieveLegalDocumentResponse
	openObjection: boolean
	setOpenInformations: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Header({
	legalDocument,
	openObjection,
	setOpenInformations,
}: HeaderProps) {
	const [, setCurrentStep] = useAtom(stepAtom)
	function handleStrategyNavigation() {
		setCurrentStep(2)
	}

	if (!legalDocument) return null

	if (getDocumentStatus(legalDocument) === "processing") {
		if (
			legalDocument?.content?.status === "processing" &&
			!legalDocument?.objection
		) {
			return null
		}

		if (legalDocument?.objection?.status === "processing") {
			if (openObjection) {
				return (
					<div className="flex items-center mb-8 h-[48px]">
						<h3 className="text-2xl">Meu documento</h3>
					</div>
				)
			}

			return (
				<div className="flex items-center justify-between ">
					<div className="text-center text-brand-gray-3">
						Documento gerado com sucesso. Copie ou salve abaixo:
					</div>
				</div>
			)
		}
	} else {
		if (openObjection) {
			return (
				<div className="flex items-center mb-8 h-[48px]">
					<h3 className="text-2xl">Meu documento</h3>
				</div>
			)
		}

		if (
			getDocumentStatus(legalDocument) === "completed" &&
			!legalDocument?.objection
		) {
			return (
				<div className="flex items-center justify-between ">
					<div className="text-center text-brand-gray-3">
						Documento gerado com sucesso. Copie ou salve abaixo:
					</div>
					<div className="flex items-center">
						<Tooltip
							text="Informações do documento"
							bg={"bg-dark-blue-3"}
							position={TooltipPosition.Below}
							className="right-[86px] top-[35px]"
						>
							<div onClick={() => setOpenInformations(true)}>
								<ExclamationIcon className="cursor-pointer" />
							</div>
						</Tooltip>
						<Button
							className="ml-6"
							variant={ButtonVariant.Outlined}
							color={ButtonColor.Primary}
							onClick={handleStrategyNavigation}
						>
							Ver estratégias
						</Button>
					</div>
				</div>
			)
		}

		if (
			getDocumentStatus(legalDocument) === "completed" ||
			(legalDocument?.processes &&
				legalDocument?.processes.at(-1)?.process_type ===
					"generate_objection")
		) {
			return (
				<div className="flex items-center justify-between ">
					<div className="text-center text-brand-gray-3">
						Documento gerado com sucesso. Copie ou salve abaixo:
					</div>
				</div>
			)
		}
	}
	return null
}

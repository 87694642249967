const OutlineCircleCheckIcon = ({ className }: { className?: string }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="128"
			height="128"
			viewBox="0 0 128 128"
			fill="none"
			className={className}
		>
			<circle
				cx="64"
				cy="63.6201"
				r="61"
				stroke="#21B027"
				strokeWidth="5"
			/>
			<path
				d="M37 67.1201L52.5 82.6201L91 44.1201"
				stroke="#21B027"
				strokeWidth="5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

export default OutlineCircleCheckIcon

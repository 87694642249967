import React from "react"
import BaseIcon from "./BaseIcon"

const AdministrativeIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
		>
			<path d="M12.5 3C14.71 3 16.5 4.79 16.5 7C16.5 9.21 14.71 11 12.5 11C10.29 11 8.5 9.21 8.5 7C8.5 4.79 10.29 3 12.5 3ZM16.5 13.54C16.5 14.6 16.22 17.07 14.31 19.83L13.5 15L14.44 13.12C13.82 13.05 13.17 13 12.5 13C11.83 13 11.18 13.05 10.56 13.12L11.5 15L10.69 19.83C8.78 17.07 8.5 14.6 8.5 13.54C6.11 14.24 4.5 15.5 4.5 17V21H20.5V17C20.5 15.5 18.9 14.24 16.5 13.54Z" />
		</BaseIcon>
	)
}

export default AdministrativeIcon

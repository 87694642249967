import BaseIcon from "./BaseIcon"

const DoubleCheckIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M6.75 18.3105L0 11.5605L1.0605 10.5L6.75 16.1887L16.9395 6L18 7.0605L6.75 18.3105Z"
				fill="url(#paint0_linear_6580_29727)"
			/>
			<path
				d="M12.75 18.621L6 11.871L7.0605 10.8105L12.75 16.4993L22.9395 6.31055L24 7.37105L12.75 18.621Z"
				fill="url(#paint1_linear_6580_29727)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_6580_29727"
					x1="0"
					y1="6"
					x2="23.567"
					y2="5.23748"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#3083FF" />
					<stop offset="1" stopColor="#78AEFF" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_6580_29727"
					x1="0"
					y1="6"
					x2="23.567"
					y2="5.23748"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#3083FF" />
					<stop offset="1" stopColor="#78AEFF" />
				</linearGradient>
			</defs>
		</BaseIcon>
	)
}

export default DoubleCheckIcon

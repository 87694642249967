import { DetailedHTMLProps, ReactNode } from "react"

interface PaperProps
	extends DetailedHTMLProps<
		React.HTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	> {
	children: ReactNode
}

const Paper = ({ children, className, ...props }: PaperProps) => {
	const className_ = `bg-white shadow-md p-4 rounded-lg ${className}`
	return (
		<div className={className_} {...props}>
			{children}
		</div>
	)
}

export default Paper

import Modal from "components/modal"

import Tag from "components/tag"
import { ReactNode, useEffect, useState } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import handleCheckoutError from "utils/handleCheckoutError"
import useBasicPlan from "hooks/useBasicPlan"
import useCurrentUser from "hooks/useCurrentUser"
import { sanitizePlan } from "utils/sanitizePlan"
import PostLauchPlanCard from "../plan-card/post-lauch-plan-card"
import { businessPlan } from "utils/businessPlan"
import { ProductCatalogItem } from "types/productCatalog"
import { ButtonVariant } from "components/button/types"
import useProductCatalogAPI from "hooks/useProductCatalogAPI"
import useSubscriptionAPI from "hooks/useSubscriptionAPI"
import { useFeatureFlag } from "hooks/useFeatureFlag"
import FEATURE_FLAGS from "feature-flags"
import NewPlansModal from "./NewPlansModal"

export interface PlansModalProps {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export interface Plan {
	title: string
	titleColor: string
	isSale?: boolean
	tag?: JSX.Element
	allowed: string[]
	noAllowed: string[]
	containerClassName?: string
	commingSoon?: boolean
	buttonVariant?: ButtonVariant
	buttonText?: string
	buttonIcon?: ReactNode
	buttonIsLoading?: boolean
	selected?: boolean
	yearly?: {
		credits?: string | number
		price?: string | number
		discount?: string | number
		creditsPerMonth?: boolean
	}
	monthly?: {
		credits?: string | number
		price?: string | number
		discount?: string | number
		creditsPerMonth?: boolean
	}
	warning?: ReactNode
	buttonClick?: () => void
}

const PlansModal = ({ open, setOpen }: PlansModalProps) => {
	const [isMonthlyPlan, setIsMonthlyPlan] = useState(false)
	const { productCatalog } = useProductCatalogAPI()
	const { subscriptionCheckout } = useSubscriptionAPI()
	const subscriptionCheckoutMutation = useMutation({
		mutationFn: subscriptionCheckout,
	})
	const isBasicPlan = useBasicPlan()
	const user = useCurrentUser()

	const { data: newBussinessModel } = useFeatureFlag(
		FEATURE_FLAGS.newBussinessModel,
	)

	const { data: productCatalogList } = useQuery(
		["product-catalog"],
		() => productCatalog(),
		{
			refetchOnWindowFocus: false,
		},
	)

	async function handleProfessionalPlanClick() {
		const tab = window.open(`${window.location.origin}/redirect`)
		let lookupKey = isMonthlyPlan
			? "professional_monthly"
			: "professional_annual"

		if (productCatalogList) {
			lookupKey = isMonthlyPlan
				? productCatalogList.professional_plan.prices.monthly
						.lookup_key || "professional_monthly"
				: productCatalogList.professional_plan.prices.annual
						.lookup_key || "professional_annual"
		}

		return subscriptionCheckoutMutation
			.mutateAsync({
				successUrl: `${window.location.origin}/checkout-success`,
				lookupKey: lookupKey,
			})
			.then((data) => {
				tab!.location.href = data.checkout_url
			})
			.catch(() => handleCheckoutError(tab))
	}

	useEffect(() => {
		setOpen(false)
	}, [user?.subscription && user?.subscription?.active])

	if (newBussinessModel) {
		return <NewPlansModal open={open} setOpen={setOpen} />
	}

	return (
		<Modal
			size="6xl"
			width="w-[100%]"
			openModal={open}
			setOpenModal={setOpen}
			dismissible
			onlyCloseButton
		>
			<span className="prose max-w-none">
				<div className="flex  justify-center flex-col p-6">
					<div className="mb-6 flex items-center justify-between w-full">
						<h3 className={"font-semibold "}>
							Qual plano se encaixa melhor na sua rotina?
						</h3>

						<div className="flex items-center justify-between">
							<p
								className={`text-cta-1 font-semibold ${
									!isMonthlyPlan && "text-brand-gray-2"
								}`}
							>
								Mensal
							</p>
							<label className="relative inline-flex items-center cursor-pointer mx-4">
								<input
									type="checkbox"
									value=""
									className="sr-only peer"
									checked={!isMonthlyPlan}
									onChange={() =>
										setIsMonthlyPlan(!isMonthlyPlan)
									}
								/>
								<div className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-light peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
							</label>
							<p
								className={`text-cta-1 font-semibold  mr-[10px] ${
									isMonthlyPlan && "text-brand-gray-2"
								}`}
							>
								Anual
							</p>
							<Tag
								text="Economize 20%"
								background="bg-semi-white-3"
								textColor="text-light-blue-4"
							/>
						</div>
					</div>
					<div className="flex  justify-center">
						{productCatalogList &&
							Object.entries(productCatalogList).map(
								([planKey, planDetails]: [
									string,
									ProductCatalogItem,
								]) => {
									const professionalButton = {
										buttonVariant: ButtonVariant.Contained,
										buttonText: "Assinar agora",
									}
									const freeButton = {
										buttonVariant: ButtonVariant.Outlined,
									}

									const buttonProps =
										planKey === "professional_plan"
											? professionalButton
											: freeButton

									return (
										<PostLauchPlanCard
											buttonClick={
												planKey === "free_plan"
													? () => setOpen(false)
													: handleProfessionalPlanClick
											}
											key={planKey}
											plan={sanitizePlan(
												planKey,
												planDetails,
												isBasicPlan,
												false,

												isMonthlyPlan,
												null,
												buttonProps,
											)}
											isMonthlyPlan={isMonthlyPlan}
											withoutButton={
												(planKey === "free_plan" &&
													isBasicPlan) ||
												(planKey ===
													"professional_plan" &&
													!isBasicPlan)
											}
											dividePlan={
												planKey === "professional_plan"
											}
										/>
									)
								},
							)}
						<PostLauchPlanCard
							plan={businessPlan}
							isMonthlyPlan={isMonthlyPlan}
							withoutButton
						/>
					</div>
				</div>
			</span>
		</Modal>
	)
}

export default PlansModal

const ImportDocIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M20.0306 7.71938L14.7806 2.46938C14.7109 2.39975 14.6282 2.34454 14.5371 2.3069C14.4461 2.26926 14.3485 2.24992 14.25 2.25H5.25C4.85218 2.25 4.47064 2.40804 4.18934 2.68934C3.90804 2.97064 3.75 3.35218 3.75 3.75V20.25C3.75 20.6478 3.90804 21.0294 4.18934 21.3107C4.47064 21.592 4.85218 21.75 5.25 21.75H18.75C19.1478 21.75 19.5294 21.592 19.8107 21.3107C20.092 21.0294 20.25 20.6478 20.25 20.25V8.25C20.2501 8.15148 20.2307 8.05391 20.1931 7.96286C20.1555 7.87182 20.1003 7.78908 20.0306 7.71938ZM14.7806 15.5306L12.5306 17.7806C12.461 17.8504 12.3783 17.9057 12.2872 17.9434C12.1962 17.9812 12.0986 18.0006 12 18.0006C11.9014 18.0006 11.8038 17.9812 11.7128 17.9434C11.6217 17.9057 11.539 17.8504 11.4694 17.7806L9.21937 15.5306C9.07864 15.3899 8.99958 15.199 8.99958 15C8.99958 14.801 9.07864 14.6101 9.21937 14.4694C9.36011 14.3286 9.55098 14.2496 9.75 14.2496C9.94902 14.2496 10.1399 14.3286 10.2806 14.4694L11.25 15.4397V11.25C11.25 11.0511 11.329 10.8603 11.4697 10.7197C11.6103 10.579 11.8011 10.5 12 10.5C12.1989 10.5 12.3897 10.579 12.5303 10.7197C12.671 10.8603 12.75 11.0511 12.75 11.25V15.4397L13.7194 14.4694C13.8601 14.3286 14.051 14.2496 14.25 14.2496C14.449 14.2496 14.6399 14.3286 14.7806 14.4694C14.9214 14.6101 15.0004 14.801 15.0004 15C15.0004 15.199 14.9214 15.3899 14.7806 15.5306ZM14.25 8.25V4.125L18.375 8.25H14.25Z"
				fill="#646464"
			/>
		</svg>
	)
}

export default ImportDocIcon

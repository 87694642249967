import BaseIcon from "./BaseIcon"

const FilterClearIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="25"
			viewBox="0 0 24 25"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.93934 3.93934C2.22064 3.65804 2.60218 3.5 3 3.5H18C18.3978 3.5 18.7794 3.65804 19.0607 3.93934C19.342 4.22064 19.5 4.60218 19.5 5V6.5H18V5H3V7.37818L8.56058 12.939L9 13.3782V20H12V18.5H13.5V20C13.5 20.3978 13.342 20.7794 13.0607 21.0607C12.7794 21.342 12.3978 21.5 12 21.5H9C8.60218 21.5 8.22064 21.342 7.93934 21.0607C7.65804 20.7794 7.5 20.3978 7.5 20V14L1.93943 8.43905C1.8001 8.29974 1.68958 8.13435 1.61418 7.95233C1.53878 7.7703 1.49998 7.5752 1.5 7.37818V5C1.5 4.60218 1.65804 4.22064 1.93934 3.93934ZM21.4395 8L22.5 9.0605L19.0605 12.5L22.5 15.9403L21.4403 17L18 13.5605L14.5613 17L13.5 15.9387L16.9395 12.5L13.5 9.0605L14.5605 8L18 11.4395L21.4395 8Z"
				fill="url(#paint0_linear_4376_11008)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_4376_11008"
					x1="1.5"
					y1="3.5"
					x2="22.1346"
					y2="3.09039"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#3083FF" />
					<stop offset="1" stopColor="#78AEFF" />
				</linearGradient>
			</defs>
		</BaseIcon>
	)
}

export default FilterClearIcon

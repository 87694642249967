import _ from "lodash"

export const ROUTES = {
	basePage: _.template("/"),
	myDocuments: _.template(""),
	documentDetail: _.template("/document/<%= id %>"),
	requestAccessDocument: _.template("request-access-document/<%= id %>"),
	settings: _.template("settings"),
	checkoutSuccess: _.template("/checkout-success"),
	noCredits: _.template("no-credits"),
	analizeDocument: _.template("analize-document"),
	chatPdf: _.template("chat-pdf"),
	verifyEmail: _.template("/verify-email"),
	jurisprudencesSearch: _.template("search-jurisprudence"),
	notification: _.template("notification"),
	contract: _.template("contract"),
	outOfArea: _.template("out-of-area"),
	admin: _.template("admin"),
	userDetail: _.template("/user/<%= id %>"),
	redirect: _.template("/redirect"),
	model: _.template("/model/<%= id %>"),
	listModels: _.template("list-models"),
	legalAssistant: _.template("legal-assistant"),
}

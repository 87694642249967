export function bytesToMB(bytes: number) {
  if (!bytes) return 0;
  const MB = bytes / (1024 * 1024);
  return Number(MB.toFixed(2));
}

export function formatBytes(bytes: number) {
  if (!bytes) return '0 KB';

  const KB = 1024;
  const MB = KB * 1024;
  const GB = MB * 1024;

  if (bytes < MB) {
    return `${(bytes / KB).toFixed()} KB`;
  } else if (bytes < GB) {
    return `${(bytes / MB).toFixed()} MB`;
  } else {
    return `${(bytes / GB).toFixed()} GB`;
  }
}

import { useMutation, useQueryClient } from "@tanstack/react-query"
import { CellContext } from "@tanstack/react-table"
import { Dropdown } from "components/dropdown"
import Tooltip from "components/tooltip"
import { useJwt } from "hooks/useJwt"
import DotsIcon from "icons/DotsIcon"
import DownloadIcon from "icons/DownloadIcon"
import { useAtom } from "jotai"

import { RetrieveLegalDocumentResponse } from "types/legalDocument"
import { getDocumentStatus } from "utils/getDocumentStatus"
import PadlockIcon from "icons/PadlockIcon"
import { isEditTextAtom } from "./ColumnTitle/atoms"
import { TooltipPosition } from "components/tooltip/enums"
import { showToast } from "components/toast/functions"
import usePetitionAPI from "hooks/usePetitionAPI"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"
import useUploadedDocumentAPI from "hooks/useUploadedDocumentAPI"
import useContractAPI from "hooks/useContractAPI"

interface ColumnActionsProps {
	row: CellContext<
		RetrieveLegalDocumentResponse,
		RetrieveLegalDocumentResponse
	>
	setOpen: (open: boolean) => void
	setDocumentId: (id: string) => void
	setTitle: (title: string) => void
}

export default function ColumnActions({
	row,
	setOpen,
	setDocumentId,
	setTitle,
}: ColumnActionsProps) {
	const { copyPetition } = usePetitionAPI()
	const { downloadFile } = useLegalDocumentAPI()
	const { copyUploadedDocument } = useUploadedDocumentAPI()
	const { copyContract } = useContractAPI()
	const [jwt] = useJwt()
	const [, setIsEditText] = useAtom(isEditTextAtom)

	const queryClient = useQueryClient()
	const copyPetitionMutation = useMutation({
		mutationFn: copyPetition,
		onSuccess(data) {
			onSucessCopyDocument(data)
		},
	})

	const copyContractMutation = useMutation({
		mutationFn: copyContract,
		onSuccess(data) {
			onSucessCopyDocument(data)
		},
	})

	const copyUploadedDocumentMutation = useMutation({
		mutationFn: copyUploadedDocument,
		onSuccess(data) {
			onSucessCopyDocument(data)
		},
	})

	function onSucessCopyDocument(data: RetrieveLegalDocumentResponse) {
		showToast(`O documento “${row.row.original.title}” foi duplicado.`)
		queryClient.setQueryData(
			["listLegalDocuments", jwt],
			(oldDocuments: RetrieveLegalDocumentResponse[] | undefined) => {
				if (oldDocuments) {
					return [data, ...oldDocuments]
				}

				return oldDocuments
			},
		)
		queryClient.invalidateQueries(["listLegalDocuments", jwt])
		queryClient.invalidateQueries({
			queryKey: ["current-user", jwt],
		})
	}

	const downloadLegalDocumentMutation = useMutation({
		mutationFn: downloadFile,
	})

	async function handleDownload(type: string) {
		await downloadLegalDocumentMutation.mutateAsync({
			id: row.row.original.id,
			type: type,
			name: row.row.original.title || "",
		})
	}

	// const downloadSections = [
	// 	{
	// 		items: [
	// 			{
	// 				label: "Download PDF",
	// 				icon: <PdfIcon className="fill-current" />,
	// 				onClick: () => {
	// 					handleDownload("pdf")
	// 				},
	// 			},
	// 			{
	// 				label: "Download Word",
	// 				icon: <WordIcon className="fill-current" />,
	// 				onClick: () => {
	// 					handleDownload("word")
	// 				},
	// 			},
	// 		],
	// 	},
	// ]

	function onCopyDocument() {
		const rowValue = row.getValue()

		if (
			typeof rowValue === "object" &&
			rowValue !== null &&
			"id" in rowValue
		) {
			const { id } = rowValue

			const type = row.row.original.type

			if (type === "petition") {
				copyPetitionMutation.mutateAsync(id)
			}

			if (type === "uploaded_document") {
				copyUploadedDocumentMutation.mutateAsync(id)
			}

			if (type === "contract") {
				copyContractMutation.mutateAsync(id)
			}

		}
	}
	const sections = [
		{
			items: [
				{
					label: "Renomear",
					onClick: () => {
						setIsEditText(row.row.original.id)
					},
				},
				{
					disabled:
						getDocumentStatus(row.row.original) === "processing",
					label: "Duplicar",
					onClick: () => {
						onCopyDocument()
					},
				},
				{
					label: "Excluir",
					deleteLabel: true,
					onClick: () => {
						setOpen(true)
						const rowValue = row.getValue() as
							| RetrieveLegalDocumentResponse
							| undefined
						if (
							rowValue &&
							"id" in rowValue &&
							"title" in rowValue
						) {
							setDocumentId(rowValue.id)
							setTitle(rowValue?.title || "")
						} else {
							console.error("Invalid row value:", rowValue)
							setDocumentId("")
							setTitle("")
						}
					},
				},
			],
		},
	]

	const isDowloadDisabled =
		getDocumentStatus(row.row.original) !== "completed"

	return (
		<div className="flex justify-end gap-4">
			<Tooltip text="Privado" position={TooltipPosition.Above}>
				<div className="text-dark-blue-1 hover:text-dark-blue-2 cursor-pointer">
					<PadlockIcon className="fill-current" />
				</div>
			</Tooltip>
			{isDowloadDisabled ? (
				<div className={`text-brand-gray-2`}>
					<DownloadIcon className="cursor-pointer fill-current" />
				</div>
			) : (
				<div onClick={() => handleDownload("docx")}>
					<Tooltip text="Baixar" position={TooltipPosition.Below}>
						<DownloadIcon className="cursor-pointer fill-dark-blue-1 hover:fill-dark-blue-2" />
					</Tooltip>
				</div>

				// <Dropdown
				// 	sections={downloadSections}
				// 	className="right-0 w-[165px]"

				// >
				// 	<Tooltip text="Download" position={TooltipPosition.Above}>
				// 		<div className={`text-dark-blue-1 hover:text-dark-blue-2 cursor-pointer`} >
				// 			<DownloadIcon className="cursor-pointer fill-current" />
				// 		</div>
				// 	</Tooltip>
				// </Dropdown>
			)}
			<Dropdown sections={sections} className="right-0 w-[165px]">
				<div className="text-dark-blue-1 hover:text-dark-blue-2 cursor-pointer">
					<DotsIcon />
				</div>
			</Dropdown>
		</div>
	)
}

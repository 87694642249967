import { ReactNode, createContext, useState } from "react"

interface ThesesContextProps {
	theses: string[]
	setTheses: (params: string[]) => void
}

interface ThesesProviderProps {
	children: ReactNode
}

export const ThesesContext = createContext<ThesesContextProps>({
	theses: [],
	setTheses: () => {},
})

export default function ThesesProvider({ children }: ThesesProviderProps) {
	const [theses, setTheses] = useState<string[]>([])

	return (
		<ThesesContext.Provider value={{ theses, setTheses }}>
			{children}
		</ThesesContext.Provider>
	)
}

export const options = [
  {
    title: "Agravo de Instrumento",
    description:
      "O autor, João Silva, recorre contra decisão que negou o pedido de tutela antecipada para suspensão de cláusula abusiva em contrato de prestação de serviços educacionais da Faculdade Alfa.",
  },
  {
    title: "Agravo de Instrumento Trabalhista",
    description:
      "João Silva foi demitido sem justa causa após 5 anos na empresa XYZ Ltda. Requer indenização por danos morais devido a assédio moral sofrido nos últimos meses de trabalho.",
  },
  {
    title: "Agravo de Petição",
    description:
      "João Silva, empregado da Construções XYZ Ltda., teve seu pedido de horas extras negado na sentença. Recorre para reformar a decisão, alegando provas suficientes nos autos.",
  },
  {
    title: "Agravo em Execução Penal",
    description:
      "João Silva, condenado a 8 anos por tráfico, teve progressão de regime negada pelo juiz da execução penal. A defesa alega cumprimento dos requisitos legais e bom comportamento carcerário.",
  },
  {
    title: "Alegações Finais",
    description:
      "O autor alega que o réu não cumpriu com o contrato de fornecimento de mercadorias, causando prejuízos financeiros e operacionais, conforme comprovado pelos documentos anexados e testemunhos apresentados durante o processo.",
  },
  {
    title: "Apelação",
    description:
      "O apelante foi condenado por um crime de furto que não cometeu, com base em provas insuficientes e testemunhas contraditórias, sendo prejudicado por um julgamento injusto e tendencioso.",
  },
  {
    title: "Apelação Criminal",
    description:
      "João Silva foi condenado a 5 anos de reclusão por furto qualificado, mas novos depoimentos de testemunhas comprovam sua inocência no caso ocorrido em 15 de março de 2023.",
  },
  {
    title: "Contra-razões de Apelação",
    description:
      "O apelante não cumpriu o contrato firmado, resultando em prejuízo financeiro significativo para a apelada, além de tentar modificar os fatos já decididos em primeira instância sem apresentar novas provas.",
  },
  {
    title: "Contra-razões de Recurso Especial",
    description:
      "O recorrente alega falta de provas na sentença, mas há testemunhos e documentos que comprovam a responsabilidade dele, além de erros processuais que comprometem a argumentação apresentada no recurso especial.",
  },
  {
    title: "Contra-razões de Recurso Extraordinário",
    description:
      "A sentença deve ser mantida porque a decisão recorrida respeitou todos os preceitos constitucionais e jurídicos, assegurando o devido processo legal e garantindo os direitos fundamentais das partes envolvidas.",
  },
  {
    title: "Contrato",
    description:
      "Contrato de compra e venda de um apartamento situado na Rua das Flores, número 123, por R$ 500.000,00, com pagamento em três parcelas e entrega do imóvel em 30 dias.",
  },
  {
    title: "Contestação",
    description:
      "O autor alegou defeito no produto adquirido em 15 de março de 2023, mas não realizou as manutenções necessárias conforme o manual do fabricante, comprometendo assim o funcionamento adequado do bem.",
  },
  {
    title: "Contestação Trabalhista",
    description:
      "João da Silva, empregado da Empresa XYZ Ltda., alega ter sofrido assédio moral e reivindica horas extras não pagas. A empresa nega as acusações, apresentando registros de ponto e testemunhas que contradizem as alegações do reclamante.",
  },
  {
    title: "Defesa Prévia",
    description:
      "Acusado de furto de mercadorias, o réu alega que estava fora da cidade no momento do crime, apresentando provas de localização e testemunhas que confirmam seu álibi.",
  },
  {
    title: "Denúncia",
    description:
      "O réu foi flagrado em posse de substâncias ilícitas durante uma operação policial, portando quantidade que caracteriza tráfico de drogas, violando o artigo 33 da Lei de Drogas.",
  },
  {
    title: "Embargos de Declaração",
    description:
      "O réu, João Silva, solicita esclarecimento sobre contradição na sentença de nº 12345/2024, onde a decisão menciona valores divergentes sobre o pagamento de danos morais.",
  },
  {
    title: "Embargos de Terceiro",
    description:
      "A autora, Maria Silva, requer a liberação de seu imóvel, localizado na Rua das Flores, 123, penhorado indevidamente em execução fiscal movida contra João Souza, terceiro estranho ao processo.",
  },
  {
    title: "Embargos à Execução",
    description:
      "João Silva, credor, executa Maria Souza por dívida de R$ 10.000,00 referente a contrato de empréstimo, alegando falta de pagamento apesar de acordos prévios estabelecidos entre as partes.",
  },
  {
    title: "Habeas Corpus",
    description:
      "Fulano de Tal, preso preventivamente por suposto furto qualificado, alega nulidade do processo devido à ausência de provas concretas, requerendo imediata soltura por violação de direitos fundamentais.",
  },
  {
    title: "Mandado de Segurança",
    description:
      "Pedro Silva, servidor público, requer anulação de ato administrativo que negou sua promoção por suposta falta de requisitos, alegando ter todos os certificados e tempo de serviço necessários.",
  },
  {
    title: "Notificação Extrajudicial",
    description:
      "Solicito a desocupação do imóvel localizado na Rua das Flores, 123, Centro, em 30 dias, devido a inadimplência de três meses do aluguel, totalizando R$ 4.500,00 em aberto.",
  },
  {
    title: "Outros",
    description:
      "Litígio por danos materiais resultantes de um acidente de trânsito em que o veículo do autor foi danificado após uma colisão com o réu em uma rua movimentada.",
  },
  {
    title: "Pedido de Liberdade Provisória",
    description:
      "José da Silva, acusado de furto simples, primário, residência fixa, emprego estável, solicita liberdade provisória alegando ausência de risco à ordem pública e comprometimento de comparecimento aos atos processuais.",
  },
  {
    title: "Pedido de Relaxamento de Prisão",
    description:
      "João Silva foi preso em flagrante por suspeita de roubo, mas as provas são insuficientes e há nulidade no auto de prisão em flagrante.",
  },
  {
    title: "Petição Inicial",
    description:
      "O autor sofreu um acidente de trabalho no dia tal e teve o benefício do INSS negado, necessitando entrar com uma ação judicial para obter o benefício devido.",
  },
  {
    title: "Procuração",
    description:
      "Procuração para representação em processo de divórcio litigioso, onde Maria Silva autoriza João Pereira a atuar em seu nome na ação judicial contra Pedro Oliveira, buscando a partilha de bens e guarda dos filhos.",
  },
  {
    title: "Queixa-Crime",
    description:
      "João foi agredido fisicamente por Carlos em um bar no dia 12 de março de 2024, resultando em lesões corporais.",
  },
  {
    title: "Reclamação Trabalhista",
    description:
      "João Silva, demitido sem justa causa da empresa XYZ Ltda., busca receber verbas rescisórias não pagas, horas extras não remuneradas e indenização por assédio moral sofrido durante seu período de trabalho.",
  },
  {
    title: "Recurso Administrativo",
    description:
      "O Recurso Administrativo é baseado na negativa de benefício previdenciário ao Sr. João Silva por alegada falta de comprovação de tempo de contribuição, apesar da apresentação de documentos comprobatórios.",
  },
  {
    title: "Recurso Especial",
    description:
      "O autor recorre da decisão que negou o pedido de indenização por danos morais devido à publicação difamatória realizada pelo réu, causando prejuízos irreparáveis à sua reputação profissional.",
  },
  {
    title: "Recurso Extraordinário",
    description:
      "O autor busca a revisão da decisão que negou a compensação de créditos tributários alegando erro material no cálculo realizado pelo fisco, resultando em prejuízo financeiro indevido e violação de direito constitucional.",
  },
  {
    title: "Recurso Ordinário",
    description:
      "João dos Santos foi demitido injustamente após 15 anos de serviço na Empresa X, alegando perseguição e falta de justificativa legal, buscando reintegração e indenização por danos morais.",
  },
  {
    title: "Resposta à Acusação",
    description:
      "João Silva foi acusado de furto em uma loja de eletrônicos. Alega-se que ele subtraiu um smartphone no valor de R$ 2.000,00 em 15/06/2023, às 14h30, na Rua das Flores, 123, Centro.",
  },
  {
    title: "Réplica",
    description:
      "O réu alega que o contrato foi rescindido por inadimplência, mas as provas indicam que todos os pagamentos foram realizados conforme os termos acordados.",
  },
  {
    title: "Réplica Trabalhista",
    description:
      "João Silva, demitido sem justa causa após 5 anos na empresa ABC Ltda., não recebeu verbas rescisórias. Réu alega falta grave, mas provas demonstram excelente desempenho do autor e ausência de advertências.",
  },
]
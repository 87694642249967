import xlLex from "assets/xlLex.png"
import smallLex from "assets/small-lex.png"
import QuickActionIcon from "icons/QuickActionIcon"
import { useEffect, useMemo, useRef, useState } from "react"
import ReactTextareaAutosize from "react-textarea-autosize"
import Lottie from "react-lottie"
import loading from "lotties/varinha_magica_-_loading.json"
import Send from "icons/Send"
import ChatPlusIcon from "icons/ChatPlusIcon"
import MessageItem from "pages/AnalizeDocumentPage/subcomponents/MessageItem"
import useCurrentUser from "hooks/useCurrentUser"
import useLexChatAPI from "hooks/useLexChatAPI"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { Button } from "components/button"
import { ButtonVariant } from "components/button/types"
import useCheckIfHasCredits from "hooks/useCheckIfHasCredits"
import { AxiosError } from "axios"
import { useAtom } from "jotai"
import { plansModalAtom } from "pages/BasePage/atoms"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"
import { useLongPoll } from "hooks/useLongPoll"
import NewLexChatModal from "pages/AnalizeDocumentPage/subcomponents/NewLexChatModal"

const options = [
  "Revise um trecho da minha petição.",
  "Quais são as principais defesas em um caso criminal?",
  "Como calcular o valor de uma indenização?",
  "Como citar corretamente uma decisão judicial em um trabalho acadêmico?",
  "Como elaborar uma petição inicial para um processo civil?",
  "Dicas para melhorar meu documento.",
  "Estou criando um contrato de trabalho. Por onde começar?",
  "Qual a diferença entre divórcio consensual e litigioso?",
  "Como preparar um parecer jurídico para uma negociação?",
  "Como fazer uma procuração pública?",
  "Quais são os passos para interpor um recurso especial?",
  "Calcule honorários conforme a tabela da OAB.",
  "Me atualize sobre o novo Código de Processo Civil.",
  "Como funciona a recuperação judicial?",
  "Elabore uma defesa preliminar para danos morais.",
  "Entenda melhor a reforma trabalhista.",
  "Como contestar uma cláusula contratual abusiva?",
  "Como redigir uma notificação extrajudicial eficaz?"
];


const LottieComponent = Lottie as any

const lottieOptions = {
  autoplay: true,
  animationData: loading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
    className: "cursor-default",
  },
}

export default function LegalAssistantPage() {
  const [, setOpenPlansModal] = useAtom(plansModalAtom)
  const [messageToSend, setMessageToSend] = useState("")
  const [inputFocused, setInputFocused] = useState(false)
  const [hasScroll, setHasScroll] = useState(false)
  const [lexId, setLexId] = useState<string>()
  const [shiftKeyPressed, setShiftKeyPressed] = useState(false)
  const [openNewChatModal, setOpenNewChatModal] = useState(false)
  const [lastMessage, setLastMessage] = useState<string>("")

  const inputRef = useRef<HTMLTextAreaElement>(null)
  const scrollDivRef = useRef<HTMLDivElement>(null)
  const bottomDivRef = useRef<HTMLDivElement>(null)

  const queryClient = useQueryClient()
  const hasCredits = useCheckIfHasCredits()
  const user = useCurrentUser()



  const { createLexChat, listLexChat, sendMessageLexChat, retrieveLexChat } =
    useLexChatAPI()

  const { data, isLoading: loadingList } = useQuery(
    ["listLexChat"],
    () => listLexChat(),
  )

  const {
    data: lexChat,
    refetch,
    isError,


  } = useQuery(["lexChat", lexId], () => retrieveLexChat(lexId!), {
    enabled: !!lexId,
  })
  const sendMessageLexChatMutation = useMutation({
    mutationFn: sendMessageLexChat,
    onSuccess: () => queryClient.invalidateQueries(["lexChat", lexId]),
    onError: (error: AxiosError<any>) => {
      if (error.response?.status === 403) handleNoBalance()
    },
  })

  const createLexChatMutation = useMutation({
    mutationFn: createLexChat,
    onSuccess: () => queryClient.invalidateQueries(["lexChat", lexId]),
  })


  const messages =
    lexChat && lexChat.messages.length > 0
      ? lexChat.messages.slice().reverse()
      : []

  const isLoading = !!(messages?.at(-1)?.sender === "USER" || lastMessage)


  function handleKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (event.key === "Enter" && messageToSend) {
      if (shiftKeyPressed) return
      event.preventDefault()
      if (lexChat) {
        {
          if (!hasCredits) return handleNoBalance()
          const message = messageToSend
          setLastMessage(message)
          setMessageToSend("")
          sendMessageLexChatMutation
            .mutateAsync({
              id: lexChat.id,
              payload: {
                message: messageToSend,
              },
            })
            .then(() => {
              setLastMessage("")
            })
            .catch(() => {
              setLastMessage("")
              setMessageToSend(message)
            })
        }
      } else {
        setLastMessage(messageToSend)
        createLexChatMutation
          .mutateAsync({
            document_id: null,
          })
          .then((data) => {
            if (!hasCredits) return handleNoBalance()
            setLexId(data.id)
            const message = messageToSend
            setMessageToSend("")
            setLastMessage(message)
            sendMessageLexChatMutation
              .mutateAsync({
                id: data.id,
                payload: {
                  message: messageToSend,
                },
              })
              .then(() => {
                setMessageToSend("")
                setLastMessage("")
              })
              .catch(() => {
                setLastMessage("")
                setMessageToSend(message)
              })
          })
      }
    }
    if (event.key === "Enter" && !messageToSend) {
      event.preventDefault()
    }
  }

  function handleNoBalance() {
    setOpenPlansModal(true)
    showToast(
      "Você não tem créditos para concluir essa ação.",
      ToastType.Error,
    )
  }

  function handleNewChat() {
    if (messages && messages.length > 0) {
      const message = messageToSend

      setLastMessage(messageToSend)
      setMessageToSend("")

      createLexChatMutation
        .mutateAsync({
          document_id: null,
        })
        .then((data) => {
          setLexId(data.id)
          setOpenNewChatModal(false)
          refetch()
          setLastMessage("")
        })
        .catch(() => {
          setLastMessage("")
          setMessageToSend(message)
        })
    }

  }

  const scrollToBottom = () => {
    const element = document.getElementById("document-content")

    if (element) {
      element.scrollTo({
        behavior: "smooth",
        top: element.scrollHeight,
      })
    }
  }

  const shuffleArray = (options: string[]) => {
    return options.sort(() => Math.random() - 0.5);
  };

  const randomOptions = useMemo(() => shuffleArray(options).slice(0, 3), [options])


  useEffect(() => {
    const div = scrollDivRef.current
    if (div) {
      if (div.scrollHeight > div.clientHeight) {
        setHasScroll(true)
      } else {
        setHasScroll(false)
      }
    }
  }, [scrollDivRef, messages])

  useEffect(() => {
    const handleShiftDown = (event: KeyboardEvent) => {
      if (event.key === "Shift") {
        setShiftKeyPressed(true)
      }
    }

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === "Shift") {
        setShiftKeyPressed(false)
      }
    }

    window.addEventListener("keydown", handleShiftDown)
    window.addEventListener("keyup", handleKeyUp)

    return () => {
      window.removeEventListener("keyup", handleKeyUp)
      window.removeEventListener("keydown", handleShiftDown)
    }
  }, [])

  useEffect(() => {
    if (data && data?.length) setLexId(data[0].id)
  }, [data?.length])

  useEffect(() => {
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current?.focus()
      }, 100)
    }
  }, [])

  useEffect(() => {
    if (messages?.length > 0) {
      scrollToBottom()
    }
  }, [messages, lastMessage])



  useLongPoll({
    queryKey: ["lexChat", lexId],
    conditionToStop: !isLoading,
    deps: [messages?.length, lexId],
    ms: 5000,
  })

  if (loadingList) return null

  return (
    <div className="h-[calc(100vh-104px)]  w-full relative ">
      {messages && messages.length > 0 ? (
        <div className="w-full flex justify-center flex-col items-center">
          <div className="fixed top-[0] bg-ice-white left-[222px] z-50 h-[64px] shadow-outlined w-[calc(100%-231px);] px-6 py-4 flex items-center justify-between">
            <h3>Assistente jurídico</h3>
            <div
              className={
                messages.length > 0
                  ? "cursor-pointer"
                  : ""
              }
              onClick={() => {
                if (messages.length < 1)
                  return
                setOpenNewChatModal(true)
              }}
            >
              <ChatPlusIcon
                fill={
                  messages.length > 0
                    ? "#3083FF"
                    : "#ACACAC"
                }
              />
            </div>
          </div>
          <div className="mt-[80px] w-full max-w-[824px] mb-[80px]">
            <div
              ref={scrollDivRef}
              className={`overflow-hidden h-full hover:overflow-auto  pl-6 pr-[32px]  ${hasScroll && "hover:pr-6"
                }`}
            >
              <div>
                {messages.map((message, index) => {
                  return (
                    <MessageItem
                      key={index}
                      message={message}
                      user={user}
                      index={index}
                      isBig
                    />
                  )
                })}
                {lastMessage && (

                  <MessageItem
                    message={{
                      chat: "",
                      _created_at: "",
                      _updated_at: "",
                      sender: "USER",
                      message: {
                        text: lastMessage,
                        suggestions: [],
                        jurisprudences: [],
                      },
                    }}
                    user={user}
                    index={-1}
                    isBig
                  />
                )}
              </div>

              {isLoading && (
                <div className="mb-6 ">
                  <div className="flex items-center gap-2 mb-2 ">
                    <img
                      src={smallLex}
                      className="w-[20px] h-[20px] rounded-full "
                    />
                    <p className="text-small-1 font-semibold">
                      Lex
                    </p>
                  </div>
                  <div
                    role="status"
                    className="animate-pulse flex flex-col gap-2"
                  >
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-full mb-0 mt-0"></div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-full mb-0 mt-0"></div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-[80%] mb-0 mt-0"></div>
                  </div>
                </div>
              )}


              <div ref={bottomDivRef} />
            </div>

          </div>
        </div>
      ) : (
        <div className="w-full h-full flex items-center justify-center">
          <div className="flex-col flex items-center justify-center mb-[72px]">
            <img
              src={xlLex}
              className="w-[120px] h-[120px] rounded-full "
            />
            <p className='text-h0 font-semibold text-dark-blue-1 my-4'>Oi, eu sou o Lex!</p>
            <p className='text-h4 font-semibold text-brand-gray-3'>Sou seu assistente jurídico e aqui estão algumas ações rápidas pra te ajudar:</p>

            <div className="flex items-center justify-center gap-4 mt-16">
              {randomOptions.length > 0 && randomOptions.map((option, index) => (
                <button
                  key={index}
                  onClick={() => {
                    createLexChatMutation
                      .mutateAsync({
                        document_id: null,
                      })
                      .then((data) => {
                        if (!hasCredits) {
                          return handleNoBalance()
                        }
                        setLexId(data.id)
                        sendMessageLexChatMutation.mutate(
                          {
                            id: data.id,
                            payload: {
                              message:
                                option,
                            },
                          },
                        )
                      })
                  }}
                  className={`p-4 flex flex-col justify-between w-[264px] h-[174px] bg-white rounded shadow-outlined text-brand-gray-3 hover:text-dark-blue-2`}
                >
                  <span className="ml-2 text-h4 font-semibold">
                    {option}
                  </span>
                  <div className="w-full flex justify-end">
                    <QuickActionIcon />
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="w-full mb-[-60px] flex flex-col justify-center items-center fixed bottom-[55px] right-[-101px]" >
        {isError && (
          <div className="flex w-full justify-center">
            <Button
              variant={ButtonVariant.Contained}
              className="mb-8"
              onClick={() =>
                queryClient.invalidateQueries([
                  "lexChat",
                  lexId,
                ])
              }
            >
              Gerar novamente
            </Button>
          </div>
        )}
        <div className="relative flex items-center w-full max-w-[848px]">
          <div className="shadow-4 w-full">
            <ReactTextareaAutosize
              className={`!outline-offset-0 block rounded-[4px] border !border-1 w-full max-h-[112px] pr-[50px] resize-none focus:!outline-none border-brand-gray-1 py-3 focus:border focus:border-light-blue-4  ${isError &&
                "border-brand-gray-1 bg-brand-white-3 placeholder:text-brand-gray-2 "
                }`}
              placeholder={
                isLoading
                  ? "Estou escrevendo..."
                  : "Converse com o Lex"
              }
              name="lex"
              ref={inputRef}
              value={messageToSend}
              onChange={(e) =>
                setMessageToSend(e.target.value)
              }
              onKeyDown={handleKeyDown}
              disabled={isLoading || isError}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
            />
          </div>
          <div className="flex items-center absolute right-[16px] bottom-[12px] gap-[8px]">
            {isLoading ? (
              <>
                <div>
                  <LottieComponent
                    options={lottieOptions}
                    height={24}
                    width={24}
                    isClickToPauseDisabled
                  />
                </div>
              </>
            ) : (
              <div
                className={`cursor-pointer ${isError && "cursor-not-allowed"
                  }`}
                onClick={() => {
                  if (isError) return
                  if (messageToSend) {
                    if (lexChat) {
                      if (!hasCredits)
                        return handleNoBalance()

                      const message = messageToSend
                      setLastMessage(messageToSend)
                      setMessageToSend("")


                      sendMessageLexChatMutation
                        .mutateAsync({
                          id: lexChat.id,
                          payload: {
                            message:
                              messageToSend,
                          },
                        })
                        .then(() => {
                          setLastMessage("")
                        })
                        .catch(() => {
                          setLastMessage("")
                          setMessageToSend(message)
                        })
                    } else {
                      createLexChatMutation
                        .mutateAsync({
                          document_id: null
                        })
                        .then((data) => {
                          if (!hasCredits)
                            return handleNoBalance()
                          setLexId(data.id)
                          sendMessageLexChatMutation.mutate(
                            {
                              id: data.id,
                              payload: {
                                message:
                                  messageToSend,
                              },
                            },
                          )
                        })
                    }
                  }
                }}
              >
                <Send
                  className={`${inputFocused ? "fill-dark-blue-1" : "fill-brand-gray-2"} hover:fill-dark-blue-2 ${isError &&
                    "!fill-brand-gray-2 hover:fill-brand-gray-2 cursor-auto"
                    }`}
                />
              </div>
            )}
          </div>
        </div>
        <div className="w-full max-w-[848px] h-[24px] bg-ice-white" />
      </div>
      <NewLexChatModal
        open={openNewChatModal}
        setOpen={setOpenNewChatModal}
        onConfirm={handleNewChat}
      />
    </div >

  )
}
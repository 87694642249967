import { ToastOptions, toast } from "react-toastify"
import { ToastType } from "./types"
import { ToastMessage } from "."
import { toastClassName } from "./variables"

export function showToast(
	message: string,
	type: ToastType = ToastType.Success,
	ctaButton?: { text: string; onClick: () => void },
	options?: ToastOptions,
): void {
	toast(
		<ToastMessage message={message} type={type} ctaButton={ctaButton} />,
		{
			icon: false,
			className: `${toastClassName[type].background} !p-0 flex  rounded-md justify-between overflow-hidden cursor-pointer !min-h-[0] `,
			bodyClassName() {
				return (
					toastClassName[type].background +
					" text-sm font-white font-med block  w-full"
				)
			},
			progressClassName() {
				return `Toastify__progress-bar Toastify__progress-bar--animated ${toastClassName[type].progress.start}`
			},
			closeButton: false,
			autoClose: 3000,
			position: "bottom-right",
			closeOnClick: !ctaButton,
			...options,
		},
	)
}

import {
	LegalDocumentType,
	RetrieveLegalDocumentResponse,
} from "types/legalDocument"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useParams } from "react-router-dom"
import { useAtom } from "jotai"
import { useEffect } from "react"
import { useJwt } from "hooks/useJwt"
import { openLexAtom } from "pages/SidebarPages/subcomponents/atoms"
import { NewDocumentComplete } from "pages/DocumentPage/subcomponents/NewDocumentComplete"
import { HeaderButtonsCompleteDocument } from "pages/DocumentPage/subcomponents/HeaderButtonsCompleteDocument"
import NewHeader from "pages/DocumentPage/header"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"
import useUploadedDocumentAPI from "hooks/useUploadedDocumentAPI"

export default function AnalizeDocumentPage() {
	const { retrieveUploadedDocument } = useUploadedDocumentAPI()
	const { updateLegalDocument } = useLegalDocumentAPI()
	const { documentId } = useParams()
	const { data } = useQuery(["legalDocument", documentId], () =>
		retrieveUploadedDocument(documentId!),
	)
	const [openLex] = useAtom(openLexAtom)
	const [jwt] = useJwt()
	const queryClient = useQueryClient()

	const updateLegalDocumentMutation = useMutation({
		mutationFn: updateLegalDocument,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["retrieveLegalDocument", documentId],
			})
		},
	})

	useEffect(() => {
		queryClient.setQueryData(
			["listLegalDocuments", jwt],
			(oldDocuments: RetrieveLegalDocumentResponse[] | undefined) => {
				if (oldDocuments && data) {
					const newDocument = data
					const oldDocumentsFiltered = oldDocuments.filter(
						(doc) => doc.id !== documentId,
					)
					return [newDocument, ...oldDocumentsFiltered]
				}

				return oldDocuments
			},
		)
	}, [documentId, data])

	return (
		<div>
			<div
				className={`flex items-center mb-[32px] justify-between ${
					!openLex ? "w-full" : "w-[calc(100%-324px)]"
				}`}
			>
				{data && (
					<NewHeader
						documentId={documentId!}
						data={data}
						updateMutation={updateLegalDocumentMutation}
					/>
				)}
				<HeaderButtonsCompleteDocument
					documentId={documentId!}
					type={LegalDocumentType.Uploaded}
				/>
			</div>

			<NewDocumentComplete
				documentId={documentId!}
				type={LegalDocumentType.Uploaded}
			/>
		</div>
	)
}

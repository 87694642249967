import React from "react"
import BaseIcon from "./BaseIcon"

interface LogoIconProps {
	className: string
}

const LogoIcon = ({ className }: LogoIconProps) => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="24"
			viewBox="0 0 20 24"
			fill="none"
			className={className}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 20.5794C2.08134 22.4251 4.83768 23.5487 7.86084 23.5487C8.48817 23.5487 9.10404 23.5004 9.70474 23.4072V19.654C9.11258 19.7893 8.49528 19.8609 7.86084 19.8609C6.1676 19.8609 4.59638 19.351 3.29961 18.48V5.52009V3.55693V1.35498C2.08118 1.86057 0.967646 2.56255 0 3.42066V3.55693V10.277V13.7231V19.6668V20.5794ZM9.70474 17.3706V16.3672V4.34612C13.2647 5.15979 15.9158 8.27779 15.9158 12.0001C15.9158 15.3036 13.8277 18.1311 10.8693 19.2943V23.1661C15.8971 21.8595 19.6036 17.3555 19.6036 12.0001C19.6036 5.62191 14.3462 0.451416 7.86084 0.451416C7.36792 0.451416 6.8821 0.481293 6.40513 0.539294V3.58158V4.26726V17.5071C6.87084 17.65 7.35854 17.7259 7.86084 17.7259C8.50465 17.7259 9.12446 17.6012 9.70474 17.3706Z"
			/>
		</BaseIcon>
	)
}

export default LogoIcon

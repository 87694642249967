import axios from "axios"
import { API } from "config"

const axiosInstance = axios.create({
	baseURL: API.baseUrl,
	headers: {
		"Content-Type": "application/json",
	},
})

axiosInstance.interceptors.request.use(
	(config) => {
		// Do something before request is sent
		const jwt = localStorage.getItem("jwt")

		if (jwt) {
			config.headers.Authorization = `Bearer ${jwt.replace(/['"]+/g, "")}`
		}

		return config
	},
	(error) => {
		// Do something with request error
		console.error(error)
		return Promise.reject(error)
	},
)
export default axiosInstance

import React from "react"
import BaseIcon from "./BaseIcon"

const QuoteIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.81875 9.375H7.5C7.83152 9.375 8.14946 9.5067 8.38388 9.74112C8.6183 9.97554 8.75 10.2935 8.75 10.625V14.375C8.75 14.7065 8.6183 15.0245 8.38388 15.2589C8.14946 15.4933 7.83152 15.625 7.5 15.625H3.75C3.41848 15.625 3.10054 15.4933 2.86612 15.2589C2.6317 15.0245 2.5 14.7065 2.5 14.375V10.2188C2.50038 9.08711 2.7801 7.97305 3.31433 6.97545C3.84857 5.97785 4.6208 5.12755 5.5625 4.5L6.68125 3.75L7.36875 4.7875L6.25 5.5375C5.6002 5.97295 5.04837 6.53914 4.62974 7.1999C4.21112 7.86066 3.93491 8.60146 3.81875 9.375ZM12.5688 9.375H16.25C16.5815 9.375 16.8995 9.5067 17.1339 9.74112C17.3683 9.97554 17.5 10.2935 17.5 10.625V14.375C17.5 14.7065 17.3683 15.0245 17.1339 15.2589C16.8995 15.4933 16.5815 15.625 16.25 15.625H12.5C12.1685 15.625 11.8505 15.4933 11.6161 15.2589C11.3817 15.0245 11.25 14.7065 11.25 14.375V10.2188C11.2504 9.08711 11.5301 7.97305 12.0643 6.97545C12.5986 5.97785 13.3708 5.12755 14.3125 4.5L15.4375 3.75L16.1188 4.7875L15 5.5375C14.3502 5.97295 13.7984 6.53914 13.3797 7.1999C12.9611 7.86066 12.6849 8.60146 12.5688 9.375Z"
			/>
		</BaseIcon>
	)
}

export default QuoteIcon

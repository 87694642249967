import React from "react"
import BaseIcon from "./BaseIcon"

const ListNumberIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5 7.5V2.5H3.75V3.125H2.5V4.375H3.75V7.5H2.5V8.75H3.75H5H6.25V7.5H5ZM10 5H18.75V6.25H10V5ZM10 13.75H18.75V15H10V13.75ZM2.5 17.5H6.25V16.25H3.75V15H5C5.33152 15 5.64946 14.8683 5.88388 14.6339C6.1183 14.3995 6.25 14.0815 6.25 13.75V12.5C6.25 12.1685 6.1183 11.8505 5.88388 11.6161C5.64946 11.3817 5.33152 11.25 5 11.25H2.5V12.5H5V13.75H3.75C3.41848 13.75 3.10054 13.8817 2.86612 14.1161C2.6317 14.3505 2.5 14.6685 2.5 15V17.5Z"
			/>
		</BaseIcon>
	)
}

export default ListNumberIcon

import Lottie from "react-lottie"
import check from "lotties/check.json"
import { useEffect } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { useJwt } from "hooks/useJwt"

export default function CheckoutSuccess() {
	const LottieComponent = Lottie as any

	const lottieOptions = {
		autoplay: true,
		animationData: check,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
			className: "cursor-default",
		},
	}
	const queryClient = useQueryClient()
	const [jwt] = useJwt()

	useEffect(() => {
		queryClient.invalidateQueries(["current-user", jwt])
	}, [])

	useEffect(() => {
		setTimeout(() => {
			queryClient.invalidateQueries(["current-user", jwt])
			window.close()
		}, 3000)
	}, [])

	return (
		<div className="flex flex-col items-center justify-center w-full h-[100vh]">
			<LottieComponent
				options={lottieOptions}
				height={240}
				width={240}
				isClickToPauseDisabled
			/>
			<h3 className="font-semibold mb-4 text-center">
				Pagamento confirmado!
			</h3>

			<h4 className="font-normal text-center text-brand-gray-3">
				Você ja pode fechar esta tela.
			</h4>
		</div>
	)
}

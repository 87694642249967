export default function ExternalLinkIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.77778 4.75C5.50519 4.75 5.24377 4.85828 5.05103 5.05103C4.85828 5.24377 4.75 5.50519 4.75 5.77778V18.2222C4.75 18.4948 4.85828 18.7562 5.05103 18.949C5.24378 19.1417 5.50519 19.25 5.77778 19.25H18.2222C18.4948 19.25 18.7562 19.1417 18.949 18.949C19.1417 18.7562 19.25 18.4948 19.25 18.2222V13.7778C19.25 13.3636 19.5858 13.0278 20 13.0278C20.4142 13.0278 20.75 13.3636 20.75 13.7778V18.2222C20.75 18.8926 20.4837 19.5356 20.0096 20.0096C19.5356 20.4837 18.8926 20.75 18.2222 20.75H5.77778C5.10737 20.75 4.46442 20.4837 3.99037 20.0096C3.51632 19.5356 3.25 18.8926 3.25 18.2222V5.77778C3.25 5.10737 3.51632 4.46442 3.99037 3.99037C4.46442 3.51632 5.10737 3.25 5.77778 3.25H10.2222C10.6364 3.25 10.9722 3.58579 10.9722 4C10.9722 4.41421 10.6364 4.75 10.2222 4.75H5.77778ZM14.8056 4C14.8056 3.58579 15.1413 3.25 15.5556 3.25H20C20.4142 3.25 20.75 3.58579 20.75 4V8.44444C20.75 8.85866 20.4142 9.19444 20 9.19444C19.5858 9.19444 19.25 8.85866 19.25 8.44444V5.81066L13.8637 11.197C13.5708 11.4899 13.0959 11.4899 12.803 11.197C12.5101 10.9041 12.5101 10.4292 12.803 10.1363L18.1893 4.75H15.5556C15.1413 4.75 14.8056 4.41421 14.8056 4Z"
				fill="url(#paint0_linear_2836_1214)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_2836_1214"
					x1="3.25"
					y1="3.25"
					x2="20.4473"
					y2="2.95739"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#3083FF" />
					<stop offset="1" stopColor="#78AEFF" />
				</linearGradient>
			</defs>
		</svg>
	)
}

import { TooltipPosition } from "components/tooltip/enums"
import CopyIcon from "icons/CopyIcon"
import { useRef, useState } from "react"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"
import { LexChatMessage } from "types/lexChat"
import smallLex from "assets/small-lex.png"
import Tooltip from "components/tooltip"
import JurisprudenceCard from "components/jurisprudence-card"

interface MessageItemProps {
	message: LexChatMessage
	user: any
	index: number
	isBig?: boolean
}

const MessageItem = ({ message, user, index, isBig = false }: MessageItemProps) => {
	const [textIsCopied, setTextIsCopied] = useState(false)
	const textRef = useRef<HTMLDivElement>(null)

	const handleCopyClick = async () => {
		const textElement = textRef.current
		if (!textElement) return

		const originalText = textElement.innerText
		const htmlText = textElement.innerHTML
		const blobHtml = new Blob([htmlText], { type: "text/html" })
		const blobText = new Blob([originalText], { type: "text/plain" })
		const data = [
			new ClipboardItem({
				["text/plain"]: blobText,
				["text/html"]: blobHtml,
			}),
		]

		try {
			await navigator.clipboard.write(data)
		} catch (err) {
			console.error("Erro ao copiar texto: ", err)
		}
		setTextIsCopied(true)
	}

	const messageIsFromUser = message.sender === "USER"

	return (
		<div className={`${index === 0 ? "mt-6" : "mt-0"} mb-6 group/item`}>
			<div className="flex items-center gap-2 mb-2">
				<img
					src={messageIsFromUser ? user?.picture : smallLex}
					className={`${isBig ? "w-[24px] h-[24px]" : "w-[20px] h-[20px]"} rounded-full`}
				/>
				<p className={`${isBig ? "text-h4" : "text-small-1"} text-dark-blue-1 font-semibold`}>
					{messageIsFromUser ? "Você" : "Lex"}
				</p>
			</div>
			<div ref={textRef}>
				<Markdown
					remarkPlugins={[remarkGfm]}
					className="text-dark-blue-1"
					// disallowedElements={["code", "table"]}
					components={{
						h1: (props) => {
							return (
								<h1
									className="text-cta-1 mb-4 leading-6"
									{...props}
								/>
							)
						},
						h2: (props) => {
							return (
								<h1
									className="text-cta-1 mb-4 leading-6"
									{...props}
								/>
							)
						},
						h3: (props) => {
							return (
								<h1
									className="text-cta-1 mb-4 leading-6"
									{...props}
								/>
							)
						},
						h4: (props) => {
							return (
								<h1
									className="text-cta-1 mb-4 leading-6"
									{...props}
								/>
							)
						},
						p: (props) => {
							return (
								<p
									className="text-dark-blue-1 text-cta-2 mb-4 leading-5"
									{...props}
								/>
							)
						},
						strong: (props) => {
							return (
								<strong
									className="font-bold leading-5"
									{...props}
								/>
							)
						},
						a: (props) => {
							return (
								<a
									target="_blank"
									className="text-light-blue-4 hover:text-light-blue-2 text-cta-2 mb-4 leading-5"
									{...props}
								/>
							)
						},
						ul: (props) => {
							return (
								<ul
									className=" text-cta-2 mb-4 text-dark-blue-1 leading-5"
									{...props}
								/>
							)
						},
						li: (props) => {
							return (
								<li
									className=" text-cta-2 mb-4 text-dark-blue-1 leading-5"
									{...props}
								/>
							)
						},
						ol: (props) => {
							return (
								<ol
									className=" text-cta-2 mb-4 text-dark-blue-1 leading-5"
									{...props}
								/>
							)
						},
						blockquote: (props) => {
							return (
								<blockquote
									className=" text-cta-2 pl-[24px]  mb-4 leading-5"
									{...props}
								/>
							)
						},
						hr: (props) => {
							return (
								<hr
									className="border-t-2 my-4 border-brand-white-3"
									{...props}
								/>
							)
						},
						table: (props) => {
							return (
								<p
									className="text-dark-blue-1 text-cta-2 mb-4 leading-5"
									{...props}
								/>
							)
						},
						tr: (props) => {
							return (
								<p
									className="text-dark-blue-1 text-cta-2 mb-4 leading-5"
									{...props}
								/>
							)
						},
						td: (props) => {
							return (
								<p
									className="text-dark-blue-1 text-cta-2 mb-4 leading-5"
									{...props}
								/>
							)
						},
						pre: ({ children }) => {
							return (
								<p className="text-dark-blue-1 text-cta-2 mb-4 !font-lato leading-5">
									{children}
								</p>
							)
						},

						code: ({ children }) => {
							return (
								<p className="text-dark-blue-1 text-cta-2 mb-4 !font-lato leading-5">
									{children}
								</p>
							)
						},
					}}
				>
					{message?.message?.text}
				</Markdown>
				<div className="flex justify-end items-end flex-wrap gap-4 my-4">
					{message?.message?.jurisprudences &&
						message?.message?.jurisprudences.length > 0 &&
						message.message.jurisprudences.map((jurisprudence) => (
							<JurisprudenceCard
								key={jurisprudence.id}
								jurisprudence={jurisprudence}
								isBig={isBig}
							/>
						))}
				</div>
			</div>
			<div
				className={`w-[20px] h-[20px]  hover:text-light-blue-4 mt-4 ${!messageIsFromUser && "cursor-pointer"
					}`}
				onClick={() => !messageIsFromUser && handleCopyClick()}
				onMouseLeave={() => {
					setTextIsCopied(false)
				}}
			>
				{!messageIsFromUser && (
					<Tooltip
						position={TooltipPosition.Above}
						text={textIsCopied ? "Copiado!" : "Copiar"}
						bg={textIsCopied ? "bg-[#323232EB]" : ""}
						containerClassName="w-fit  hidden  group-hover/item:block"
					>
						<CopyIcon className="fill-current" size="20px" />
					</Tooltip>
				)}
			</div>
		</div>
	)
}

export default MessageItem

import type { DropdownMenuProps } from "@radix-ui/react-dropdown-menu"

import {
	collapseSelection,
	focusEditor,
	getNodeEntries,
	isBlock,
	toggleNodeType,
	useEditorRef,
	useEditorSelector,
} from "@udecode/plate-common"
import { ELEMENT_H1, ELEMENT_H2 } from "@udecode/plate-heading"
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph"

import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuRadioGroup,
	DropdownMenuRadioItem,
	DropdownMenuTrigger,
	useOpenState,
} from "./dropdown-menu"
import { ToolbarButton } from "./toolbar"
import TextFontIcon from "icons/TextFontIcon"
import ArrowDownEditorIcon from "icons/ArrowDownEditorIcon"
import CircleCheckEditorIcon from "icons/CircleCheckEditorIcon"

const items = [
	{
		description: "Paragraph",
		label: "Normal",
		className: "text-[12px] text-brand-gray-3",
		value: ELEMENT_PARAGRAPH,
	},
	{
		description: "Heading 2",
		label: "Médio",
		className: "font-bold text-brand-gray-3",
		value: ELEMENT_H2,
	},
	{
		description: "Heading 1",
		label: "Grande",
		className: "text-base font-bold text-brand-gray-3",
		value: ELEMENT_H1,
	},
]

const defaultItem = items.find((item) => item.value === ELEMENT_PARAGRAPH)!

export function TurnIntoDropdownMenu(props: DropdownMenuProps) {
	const value: string = useEditorSelector((editor) => {
		let initialNodeType: string = ELEMENT_PARAGRAPH
		let allNodesMatchInitialNodeType = false
		const codeBlockEntries = getNodeEntries(editor, {
			match: (n) => isBlock(editor, n),
			mode: "highest",
		})
		const nodes = Array.from(codeBlockEntries)

		if (nodes.length > 0) {
			initialNodeType = nodes[0][0].type as string
			allNodesMatchInitialNodeType = nodes.every(([node]) => {
				const type: string = (node?.type as string) || ELEMENT_PARAGRAPH

				return type === initialNodeType
			})
		}

		return allNodesMatchInitialNodeType
			? initialNodeType
			: ELEMENT_PARAGRAPH
	}, [])

	const editor = useEditorRef()
	const openState = useOpenState()

	const selectedItem =
		items.find((item) => item.value === value) ?? defaultItem

	return (
		<DropdownMenu modal={false} {...openState} {...props}>
			<DropdownMenuTrigger asChild>
				<ToolbarButton
					className="p-0 m-0"
					isDropdown
					pressed={openState.open}
				>
					<div
						className={`flex cursor-pointer text-[#646464] ${
							openState.open
								? "text-light-blue-1 bg-semi-white-3"
								: "hover:text-dark-blue-2"
						}`}
					>
						<TextFontIcon />
						<ArrowDownEditorIcon />
					</div>
				</ToolbarButton>
			</DropdownMenuTrigger>

			<DropdownMenuContent
				align="start"
				className="border-0 p-0 ml-[-8px]"
			>
				<DropdownMenuRadioGroup
					className="flex flex-col"
					onValueChange={(type) => {
						toggleNodeType(editor, { activeType: type })

						collapseSelection(editor)
						focusEditor(editor)
					}}
					value={value}
				>
					{items.map(({ label, className, value: itemValue }) => (
						<DropdownMenuRadioItem
							className={`bg-white shadow-custom shadow-[#00000024] w-[96px] justify-between ${className}`}
							key={itemValue}
							value={itemValue}
						>
							{label}
							{selectedItem.value === itemValue && (
								<CircleCheckEditorIcon />
							)}
						</DropdownMenuRadioItem>
					))}
				</DropdownMenuRadioGroup>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}

import { formatDate } from "utils/formatDate"
import { InfoWithTitle } from "./UserDetails"
import convertAmountToReal from "utils/convertAmountToReal"
import LinkOutIcon from "icons/LinkOutIcon"
import VisaImage from "assets/visa.png"
import MastercardImage from "assets/mastercard.jpg"

const firstRowInfos = [
	{ title: "Nome", info: "Douglas Neves" },
	{ title: "Email", info: "douglas@lawdeck.ai" },
	{ title: "Telefone", info: "21972421304" },
	{ title: "Localidade", info: "São Paulo" },
]
const secondRowInfos = [
	{ title: "Contexto atual", info: "Advogado(a) Autonomo" },
	{ title: "Anos de atuação", info: "5 - 7 anos" },
	{ title: "Especialidade", info: "Civil" },
	{ title: "Última troca de senha", info: "08 de julho, 2023" },
]
const thirdRowInfos = [
	{ title: "Primeiro acesso", info: "05 de maio, 2023" },
	{ title: "Último acesso", info: "23 de novembro, 2023" },
	{ title: "Indicações feitas", info: "12 Indicações" },
	{ title: "", info: "" },
]

const paymentHistory = [
	{
		created_at: "2024-02-29 16:17:58+00:00",
		amount: 2490,
		description: "10 créditos",
		card: {
			brand: "visa",
			last4: "4242",
		},
		invoice_pdf: null,
		invoice_url: null,
		receipt_url:
			"https://pay.stripe.com/receipts/payment/CAcaFwoVYWNjdF8xT2ZuU2VKMVR2Sjh4RXJYKObl5LAGMgajOtn0wc86LBYKcUqIylf3gKNgrfsuJa8VU_alM9bFE0K3YK_fGIa1DVePXprTPTn2D27O",
	},
	{
		created_at: "2024-02-26 12:25:33+00:00",
		amount: 2490,
		description: "10 créditos",
		card: {
			brand: "visa",
			last4: "4242",
		},
		invoice_pdf: null,
		invoice_url: null,
		receipt_url:
			"https://pay.stripe.com/receipts/payment/CAcaFwoVYWNjdF8xT2ZuU2VKMVR2Sjh4RXJYKObl5LAGMgaxUPer7u86LBanHQtKjWTIgd1fkwiKvAWCSJQfyasoRwZ7AURK5q9vDsvLHpp1HaYH3590",
	},
	{
		created_at: "2024-02-26 12:24:26+00:00",
		amount: 2490,
		description: "10 créditos",
		card: {
			brand: "visa",
			last4: "4242",
		},
		invoice_pdf: null,
		invoice_url: null,
		receipt_url:
			"https://pay.stripe.com/receipts/payment/CAcaFwoVYWNjdF8xT2ZuU2VKMVR2Sjh4RXJYKObl5LAGMgYXfTDowcM6LBbfHaoCN2RzPxMSlszOCqMTOEkKxBG5C46qizG5ZVIF0Lhw9BNECWBHRQSg",
	},
	{
		created_at: "2024-02-26 12:22:43+00:00",
		amount: 2490,
		description: "10 créditos",
		card: {
			brand: "visa",
			last4: "4242",
		},
		invoice_pdf: null,
		invoice_url: null,
		receipt_url:
			"https://pay.stripe.com/receipts/payment/CAcaFwoVYWNjdF8xT2ZuU2VKMVR2Sjh4RXJYKObl5LAGMgbAtLR57fQ6LBYbtf0dR571Qv1ysUU13zlKnJe1kfTxRJHWRX14znKtGaoUl02agOdLvgfU",
	},
	{
		created_at: "2024-02-26 12:19:20+00:00",
		amount: 2490,
		description: "10 créditos",
		card: {
			brand: "visa",
			last4: "4242",
		},
		invoice_pdf: null,
		invoice_url: null,
		receipt_url:
			"https://pay.stripe.com/receipts/payment/CAcaFwoVYWNjdF8xT2ZuU2VKMVR2Sjh4RXJYKObl5LAGMgYVMKD80rs6LBYboJFWEuRypfukqKcS-TCNcxBVcJfKc6kAhSxbPdHaSffYSu5DcY-phPJX",
	},
]
const cardBrand: { [key: string]: any } = {
	visa: VisaImage,
	mastercard: MastercardImage,
}
export default function UserInfo() {
	return (
		<div className="flex flex-col w-auto">
			<div className="mr-6 w-[431px] mb-6">
				<div className=" text-h3 text-dark-blue-1 font-semibold">
					Dados da conta
				</div>
			</div>
			<div className="flex justify-between">
				{firstRowInfos.map(({ title, info }) => (
					<InfoWithTitle
						className="w-[263px] mr-6 mb-6"
						key={title}
						title={title}
						info={info}
					/>
				))}
			</div>
			<div className="flex justify-between">
				{secondRowInfos.map(({ title, info }) => (
					<InfoWithTitle
						className="w-[263px] mr-6 mb-6"
						key={title}
						title={title}
						info={info}
					/>
				))}
			</div>
			<div className="flex justify-between">
				{thirdRowInfos.map(({ title, info }) => (
					<InfoWithTitle
						className="w-[263px] mr-6 mb-6"
						key={title}
						title={title}
						info={info}
					/>
				))}
			</div>
			<div className="h-[1px] w-full bg-[#F0F0F0] my-8" />
			<div className="w-full mb-6">
				<h3 className="font-semibold mb-2">Histórico de Pagamento</h3>
			</div>
			<div className="flex w-full">
				<div className="flex flex-col w-full gap-[20px]">
					<div className="font-bold w-full flex justify-between p-2">
						<div className="w-[20%]">Data</div>
						<div className="w-[20%] mr-4">Descrição</div>
						<div className="w-[30%]">Forma de Pagamento</div>
						<div className="w-[15%]">Valor</div>
						<div className="flex w-[15%] justify-end">Fatura</div>
					</div>

					<div>
						{paymentHistory?.map((payment, idx) => (
							<div
								key={idx}
								className="flex mb-2 text-brand-gray-3 w-full p-2"
							>
								<div className="w-[20%]">
									{
										formatDate(payment.created_at).split(
											" ",
										)[0]
									}
								</div>
								<div className="w-[20%] mr-4">
									{payment.description}
								</div>
								<div className="w-[30%] flex items-center">
									<img
										src={cardBrand[payment.card.brand]}
										className="mr-2"
									/>
									**** {payment.card.last4}
								</div>
								<div className="w-[15%]">
									{convertAmountToReal(payment.amount)}
								</div>
								<div className="flex justify-end p-2 w-[15%]">
									<a
										className="cursor-pointer h-5"
										href={
											payment.invoice_url ??
											payment.receipt_url
										}
										target="_blank"
										rel="noopener noreferrer"
									>
										<LinkOutIcon className=" w-5 h-5" />{" "}
									</a>
								</div>
							</div>
						))}
						{/* {limit < 11 && paymentHistory.length > 10 && (
							<div className="flex w-full p-2">
								<div className="w-[20%]">{""}</div>
								<div className="w-[20%]">{""}</div>
								<div className="w-[30%]">
									<Button
										variant={ButtonVariant.Text}
										onClick={() => {
											setLimit(20)
										}}
									>
										Carregar mais
									</Button>
								</div>
							</div>
						)} */}
					</div>
				</div>
			</div>
		</div>
	)
}

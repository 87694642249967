import BaseIcon from "./BaseIcon"

const ArrowDownIcon = ({ className }: { className?: string }) => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			className={className}
		>
			<path d="M12 16L4.5 8.98246L5.55 8L12 14.0351L18.45 8L19.5 8.98246L12 16Z" />
		</BaseIcon>
	)
}

export default ArrowDownIcon

export default function TxtDocIcon() {
	return (
		<svg
			width="56"
			height="56"
			viewBox="0 0 56 56"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0 4C0 1.79086 1.79086 0 4 0H28H40.3431C41.404 0 42.4214 0.421427 43.1716 1.17157L49 7L54.8284 12.8284C55.5786 13.5786 56 14.596 56 15.6569V28V52C56 54.2091 54.2091 56 52 56H4C1.79086 56 0 54.2091 0 52V4Z"
				className="fill-current"
			/>
			<path
				d="M15 15H41M15 28H41M15 41H30.6"
				stroke="white"
				strokeWidth="3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_86_2759"
					x1="28"
					y1="0"
					x2="28"
					y2="56"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#020812" />
					<stop offset="1" stopColor="#212C3A" />
				</linearGradient>
			</defs>
		</svg>
	)
}

function convertAmountToReal(number: number): string {
	// Converte o número para uma string
	const numberStr: string = number.toString()

	// Adiciona a vírgula antes do penúltimo dígito
	const result: string = numberStr.slice(0, -2) + "," + numberStr.slice(-2)

	return "R$ " + result
}

export default convertAmountToReal

import Modal from "components/modal"
import { Button } from "components/button"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { RetrieveLegalDocumentResponse } from "types/legalDocument"
import { useJwt } from "hooks/useJwt"
import ExclusionButton from "components/exclusion-button"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"
import { ButtonVariant } from "components/button/types"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"

interface DeleteDocumentModalProps {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	documentId?: string
	title?: string
	documentIds?: string[]
	onRowSelectionChange?: (value: object) => void
	onIdsToDeleteChange?: (value: string[]) => void
}

const DeleteDocumentModal = ({
	open,
	setOpen,
	documentId,
	title,
	documentIds,
	onRowSelectionChange,
	onIdsToDeleteChange,
}: DeleteDocumentModalProps) => {
	const { deleteLegalDocument, restoreLegalDocument } = useLegalDocumentAPI()

	const queryClient = useQueryClient()

	const [jwt] = useJwt()

	const deleteDocumentMutation = useMutation({
		mutationFn: () => deleteLegalDocument(documentId!),
		onSuccess: () => {
			queryClient.setQueryData(
				["listLegalDocuments", jwt],
				(oldDocuments: RetrieveLegalDocumentResponse[] | undefined) => {
					if (oldDocuments) {
						const newDocuments = oldDocuments.filter(
							(doc) => doc.id !== documentId,
						)
						return newDocuments
					}

					return oldDocuments
				},
			)
			queryClient.invalidateQueries({
				queryKey: ["current-user", jwt],
			})
		},
	})

	const updateLegalDocumentMutation = useMutation({
		mutationFn: () => restoreLegalDocument(documentId!),
		onSuccess: (data) => {
			queryClient.setQueryData(
				["listLegalDocuments", jwt],
				(oldDocuments: RetrieveLegalDocumentResponse[] | undefined) => {
					if (oldDocuments) {
						return [data, ...oldDocuments]
					}

					return oldDocuments
				},
			)
		},
	})

	const onSave = async () => {
		if (documentId) {
			await deleteDocumentMutation.mutateAsync()
			setOpen(false)
			showToast(
				`O documento “${
					title || "Documento sem título"
				}” foi excluído.`,
				ToastType.Error,
				{
					text: "Desfazer",
					onClick: handleClick,
				},
			)
		}
		if (documentIds) {
			onRowSelectionChange && onRowSelectionChange({})
			await Promise.all(
				documentIds.map((id) => deleteLegalDocument(id)),
			).then(() => {
				queryClient.setQueryData(
					["listLegalDocuments", jwt],
					(
						oldDocuments:
							| RetrieveLegalDocumentResponse[]
							| undefined,
					) => {
						if (oldDocuments) {
							const newData = oldDocuments.filter(
								(doc) =>
									!documentIds.find(
										(docId) => docId === doc.id,
									),
							)
							return newData
						}

						return oldDocuments
					},
				)
			})
			setOpen(false)
			showToast(
				`Você excluiu ${documentIds.length} documentos.`,
				ToastType.Error,
				{
					text: "Desfazer",
					onClick: handleClick,
				},
				{
					onClose: () =>
						onIdsToDeleteChange && onIdsToDeleteChange([]),
				},
			)
		}
	}

	async function handleClick() {
		if (documentId) {
			await updateLegalDocumentMutation.mutateAsync()
			showToast(
				`O documento “${
					title || "Documento sem título"
				}” foi restaurado com sucesso!`,
			)
		}

		if (documentIds) {
			await Promise.all(
				documentIds.map((id) => restoreLegalDocument(id)),
			).then((newData) => {
				queryClient.setQueryData(
					["listLegalDocuments", jwt],
					(
						oldDocuments:
							| RetrieveLegalDocumentResponse[]
							| undefined,
					) => {
						if (oldDocuments) {
							return [...newData, ...oldDocuments]
						}

						return oldDocuments
					},
				)
				showToast(`Os documentos foram restaurados com sucesso!`)
			})
		}
	}

	return (
		<Modal
			size="md"
			width="w-[550px]"
			openModal={open}
			setOpenModal={setOpen}
			dismissible
			onlyCloseButton
		>
			<div className="flex  justify-center flex-col p-2">
				<h3 className={"font-semibold mb-2"}>Excluir documento</h3>
				<p className="text-brand-gray-3 mb-8 text-cta-1">
					Deseja realmente excluir{" "}
					{documentIds && documentIds?.length > 1 ? (
						<>os {documentIds?.length} documentos selecionados</>
					) : (
						`o documento “${title || "Documento sem título"}”`
					)}
					? Esta ação é irreversível.
				</p>

				<div className="flex items-center justify-between">
					<Button
						variant={ButtonVariant.Outlined}
						className="mr-4 w-[235px] justify-center"
						onClick={() => setOpen(false)}
					>
						Cancelar
					</Button>
					<ExclusionButton
						className="w-[235px]"
						onClick={() => onSave()}
					>
						Excluir
					</ExclusionButton>
				</div>
			</div>
		</Modal>
	)
}

export default DeleteDocumentModal

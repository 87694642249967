import BaseIcon from "./BaseIcon"

const CircleKeyIcon = ({
	size = "48px",
	className,
}: {
	size?: string
	className?: string
}) => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 48 48"
			fill="none"
			className={className}
		>
			<path
				d="M24 3C19.8466 3 15.7865 4.23163 12.333 6.53914C8.8796 8.84665 6.18798 12.1264 4.59854 15.9636C3.0091 19.8009 2.59323 24.0233 3.40352 28.0969C4.21381 32.1705 6.21386 35.9123 9.15077 38.8492C12.0877 41.7861 15.8295 43.7862 19.9031 44.5965C23.9767 45.4068 28.1991 44.9909 32.0364 43.4015C35.8736 41.812 39.1534 39.1204 41.4609 35.667C43.7684 32.2135 45 28.1534 45 24C45 18.4305 42.7875 13.089 38.8493 9.15076C34.911 5.21249 29.5696 3 24 3Z"
				fill="#EAEAEA"
			/>
			<path
				d="M27.75 13.5C26.696 13.4997 25.6565 13.7464 24.7149 14.2202C23.7734 14.694 22.9559 15.3817 22.328 16.2283C21.7001 17.0749 21.2793 18.0569 21.0992 19.0954C20.9191 20.134 20.9848 21.2003 21.2911 22.2088L13.5 30V34.5H18L25.7911 26.7088C26.7195 26.9907 27.6978 27.0692 28.6593 26.9388C29.6208 26.8083 30.5429 26.4722 31.3627 25.9532C32.1826 25.4341 32.8808 24.7445 33.41 23.9312C33.9392 23.1179 34.2868 22.2 34.4291 21.2402C34.5714 20.2804 34.5052 19.3012 34.2348 18.3693C33.9645 17.4374 33.4964 16.5748 32.8625 15.8402C32.2286 15.1056 31.4438 14.5163 30.5615 14.1124C29.6793 13.7086 28.7203 13.4997 27.75 13.5ZM27.75 25.5C27.2336 25.4997 26.72 25.4233 26.2258 25.2733L25.3657 25.0125L24.7303 25.6479L22.3445 28.0338L21.3106 27L20.25 28.0606L21.284 29.0945L20.0946 30.284L19.0606 29.25L18 30.3106L19.034 31.3445L17.3787 33H15V30.6213L22.3517 23.2696L22.9871 22.634L22.7266 21.774C22.4061 20.7172 22.4269 19.5863 22.7862 18.5421C23.1454 17.4978 23.8248 16.5934 24.7277 15.9576C25.6306 15.3217 26.711 14.9868 27.8152 15.0004C28.9195 15.014 29.9913 15.3754 30.8782 16.0333C31.7652 16.6912 32.4221 17.612 32.7556 18.6648C33.089 19.7176 33.082 20.8487 32.7355 21.8972C32.389 22.9458 31.7207 23.8584 30.8256 24.5052C29.9305 25.152 28.8543 25.5001 27.75 25.5Z"
				fill="#020812"
			/>
			<path
				d="M28.5 21C29.3284 21 30 20.3284 30 19.5C30 18.6716 29.3284 18 28.5 18C27.6716 18 27 18.6716 27 19.5C27 20.3284 27.6716 21 28.5 21Z"
				fill="black"
			/>
		</BaseIcon>
	)
}

export default CircleKeyIcon

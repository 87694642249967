export default function PlanName({ name }: { name: string }) {
  return (
    <p
      className={`font-semibold ${name === "Profissional"
        ? "text-brand-yellow"
        : name === "Básico"
          ? "text-brand-gray-3"
          : "text-brand-gray-2"
        }`}
    >
      {name}
    </p>
  )
}
import BaseIcon from "./BaseIcon"

const FitArrowIcon = ({
	className,
	fill,
}: {
	className?: string
	fill?: string
}) => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			className={className}
		>
			<path
				d="M12 14.3333L7 9.65497L7.7 9L12 13.0234L16.3 9L17 9.65497L12 14.3333Z"
				fill={fill}
			/>
		</BaseIcon>
	)
}

export default FitArrowIcon

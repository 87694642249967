import BaseIcon from "./BaseIcon"

const FilterArrowDownIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
		>
			<path
				d="M12.295 8.295L8.5 12.085V2H7.5V12.085L3.705 8.295L3 9L8 14L13 9L12.295 8.295Z"
				fill="#020812"
			/>
		</BaseIcon>
	)
}

export default FilterArrowDownIcon

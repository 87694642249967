import { AxiosResponse } from "axios"
import axiosInstance from "axiosInstance"
import { Template, TemplateData, TemplateRequestQuerys } from "types/templates"

const useLegalDocumentTemplatesAPI = () => {
	const getLegalDocumentTemplates = async (payload: TemplateRequestQuerys) => {
		let querys = `offset=${payload.offset || 0}`



		if (payload?.area_of_law && payload.area_of_law.length > 0) {
			querys += `&area_of_law=${payload.area_of_law.join(",")}`
		}

		if (payload?.search) {
			querys += `&search=${payload.search}`
		}

		if (payload?.ordering) {
			querys += `&ordering=${payload.ordering}`
		}


		const response: AxiosResponse<TemplateData> =
			await axiosInstance.get(`/legal-document-templates/?${querys}`)

		return response.data
	}

	const getLegalDocumentTemplateById = async (id: string | undefined) => {
		const response: AxiosResponse<Template> =
			await axiosInstance.get(`/legal-document-templates/${id}/`)

		return response.data
	}

	const getRetrieveTypesTemplates = async () => {
		const response: AxiosResponse<string[]> =
			await axiosInstance.get(`/legal-document-templates/retrieve_types/`)

		return response.data
	}

	return {
		getLegalDocumentTemplates,
		getLegalDocumentTemplateById,
		getRetrieveTypesTemplates

	}
}

export default useLegalDocumentTemplatesAPI
import React from "react"

import { cn, withRef } from "@udecode/cn"
import { PlateElement } from "@udecode/plate-common"

export const BlockquoteElement = withRef<typeof PlateElement>(
	({ children, className, ...props }, ref) => {
		return (
			<PlateElement
				asChild
				className={cn(
					"mb-12 border-l-0 pl-6 not-italic ml-[152px] text-[16px]",
					className,
				)}
				ref={ref}
				{...props}
			>
				<blockquote>{children}</blockquote>
			</PlateElement>
		)
	},
)

import Lottie from "react-lottie"
import loading from "lotties/loading.json"

const LottieComponent = Lottie as any

const lottieOptions = {
	loop: true,
	autoplay: true,
	animationData: loading,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
		className: "cursor-default",
	},
}

const Loading = ({ className }: { className?: string }) => {
	return (
		<div className={className ?? "fixed top-[40%] left-[45%]"}>
			<LottieComponent
				options={lottieOptions}
				height={87}
				width={95}
				isClickToPauseDisabled
			/>
		</div>
	)
}

export default Loading

import DoubleCheckIcon from "icons/DoubleCheckIcon"
import NotificationCard from "./subcomponents/NotificationCard"
import { Button } from "components/button"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { isThisMonth, isThisWeek, isToday } from "date-fns"
import LargeBellIcon from "icons/LargeBellIcon"
import { useEffect } from "react"
import { scrollToTop } from "utils/scrollToTop"
import { ButtonVariant } from "components/button/types"
import useNotificationAPI from "hooks/useNotificationAPI"

export default function NotificationPage() {
	const { listNotifications, markAllAsReadNotification } =
		useNotificationAPI()
	const queryClient = useQueryClient()
	const { data } = useQuery(["notifications"], () => listNotifications())

	const markAllAsReadMutation = useMutation({
		mutationFn: markAllAsReadNotification,
		onSuccess: () => {
			queryClient.invalidateQueries(["notifications"])
		},
	})

	const notVisualizedNotifications = data?.results.filter(
		(notif) => !notif.visualized,
	)

	async function markAllAsRead() {
		const ids: string[] | undefined = notVisualizedNotifications?.map(
			(notif) => notif.id,
		)
		if (ids) {
			await markAllAsReadMutation.mutateAsync(ids)
		}
	}

	function getTodayData() {
		if (data?.results && data?.results.length > 0) {
			const filteredTodayDates = data?.results.filter((notf) =>
				isToday(new Date(notf._created_at)),
			)
			return filteredTodayDates
		}
		return []
	}

	function getIsThisWeekData() {
		if (data?.results && data?.results.length > 0) {
			const filteredIsThisWeekDates = data?.results.filter(
				(notf) =>
					!isToday(new Date(notf._created_at)) &&
					isThisWeek(new Date(notf._created_at)),
			)
			return filteredIsThisWeekDates
		}
		return []
	}

	function getisThisMonth() {
		if (data?.results && data?.results.length > 0) {
			const filteredIsThisMonth = data?.results.filter(
				(notf) =>
					!isToday(new Date(notf._created_at)) &&
					!isThisWeek(new Date(notf._created_at)) &&
					isThisMonth(new Date(notf._created_at)),
			)
			return filteredIsThisMonth
		}
		return []
	}
	interface OtherDaysData {
		[key: string]: any[]
	}

	function getOtherDaysData(): OtherDaysData {
		if (data?.results && data?.results.length > 0) {
			const groupedByMonth: OtherDaysData = data?.results
				.filter(
					(notf) =>
						!isToday(new Date(notf._created_at)) &&
						!isThisWeek(new Date(notf._created_at)) &&
						!isThisMonth(new Date(notf._created_at)),
				)
				.reduce((acc, item) => {
					const date = new Date(item._created_at)
					const month: number = date.getMonth()
					acc[month] = acc[month] || []
					acc[month].push(item)
					return acc
				}, {} as OtherDaysData)

			return groupedByMonth
		}
		return {}
	}

	const monthNames = [
		"Janeiro",
		"Fevereiro",
		"Março",
		"Abril",
		"Maio",
		"Junho",
		"Julho",
		"Agosto",
		"Setembro",
		"Outubro",
		"Novembro",
		"Dezembro",
	]

	useEffect(() => {
		scrollToTop()
		localStorage.setItem("notificationsLastSeen", new Date().toISOString())
	}, [])

	return (
		<div className="relative">
			<h1 className="font-semibold mb-[44px]">Notificações</h1>
			{data?.results && data?.results.length < 1 ? (
				<div className="flex items-center justify-center flex-col h-[80vh]">
					<LargeBellIcon />
					<h3 className="text-brand-gray-3 font-semibold mt-6">
						Nenhuma notificação encontrada
					</h3>
					<p className="text-cta-1 mt-4 text-brand-gray-2">
						Assim que tivermos novidades ou ações pendentes, você
						será notificado aqui.
					</p>
				</div>
			) : (
				<>
					{notVisualizedNotifications?.length !== 0 && (
						<div className="flex items-center gap-2  cursor-pointer absolute top-[68px] right-0">
							<Button
								variant={ButtonVariant.Text}
								icon={<DoubleCheckIcon />}
								onClick={markAllAsRead}
							>
								Marcar todas como lida
							</Button>
						</div>
					)}

					<div>
						{getTodayData().length > 0 && (
							<>
								<div className="flex items-center justify-between mb-4">
									<h4 className="text-brand-gray-3 font-semibold ">
										Hoje
									</h4>
								</div>
								<div className="rounded-md bg-white ">
									{getTodayData().map((notif, index) => {
										return (
											<NotificationCard
												key={index}
												{...notif}
												isToday
											/>
										)
									})}
								</div>
							</>
						)}

						{getIsThisWeekData().length > 0 && (
							<>
								<div className="flex items-center justify-between mb-4 mt-8">
									<h4 className="text-brand-gray-3 font-semibold ">
										Últimos 7 dias
									</h4>
								</div>
								<div className="rounded-md bg-white ">
									{getIsThisWeekData().map((notif, index) => (
										<NotificationCard
											key={index}
											{...notif}
										/>
									))}
								</div>
							</>
						)}

						{getisThisMonth().length > 0 && (
							<>
								<div className="flex items-center justify-between mb-4 mt-8">
									<h4 className="text-brand-gray-3 font-semibold ">
										Últimos 30 dias
									</h4>
								</div>
								<div className="rounded-md bg-white ">
									{getisThisMonth().map((notif, index) => (
										<NotificationCard
											key={index}
											{...notif}
										/>
									))}
								</div>
							</>
						)}

						{Object.keys(getOtherDaysData())
							.sort((a, b) => parseInt(b) - parseInt(a))
							.map((month) => (
								<>
									<div className="flex items-center justify-between mb-4 mt-8">
										<h4 className="text-brand-gray-3 font-semibold ">
											{monthNames[parseInt(month)]}
										</h4>
									</div>
									<div className="rounded-md bg-white ">
										{getOtherDaysData()[month].map(
											(notif, index) => (
												<NotificationCard
													key={index}
													{...notif}
												/>
											),
										)}
									</div>
								</>
							))}
					</div>
				</>
			)}
		</div>
	)
}

import React from "react"
import BaseIcon from "./BaseIcon"

const TrabalhistaIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
		>
			<path d="M12.334 15C7.91398 15 4.33398 16.79 4.33398 19V21H20.334V19C20.334 16.79 16.754 15 12.334 15ZM8.33398 9C8.33398 10.0609 8.75541 11.0783 9.50556 11.8284C10.2557 12.5786 11.2731 13 12.334 13C13.3949 13 14.4123 12.5786 15.1624 11.8284C15.9126 11.0783 16.334 10.0609 16.334 9M11.834 2C11.534 2 11.334 2.21 11.334 2.5V5.5H10.334V3C10.334 3 8.08398 3.86 8.08398 6.75C8.08398 6.75 7.33398 6.89 7.33398 8H17.334C17.284 6.89 16.584 6.75 16.584 6.75C16.584 3.86 14.334 3 14.334 3V5.5H13.334V2.5C13.334 2.21 13.144 2 12.834 2H11.834Z" />
		</BaseIcon>
	)
}

export default TrabalhistaIcon

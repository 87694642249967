import React, { JSXElementConstructor, ReactNode, useEffect } from "react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
	LegalDocumentType,
	RetrieveLegalDocumentResponse,
} from "types/legalDocument"
import Footer from "components/footer"
import Header from "pages/DocumentPage/header"
import { getDocumentStatus } from "utils/getDocumentStatus"
import { useAtom } from "jotai"
import Loading from "components/loading"
import { openLexAtom } from "pages/SidebarPages/subcomponents/atoms"
import { HeaderButtonsCompleteDocument } from "../HeaderButtonsCompleteDocument"
import { stepAtom } from "../DocumentDisplayV3/atoms"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"
export interface StepProps {
	documentId: string
	type: LegalDocumentType
}

interface NavigationStep {
	headerButtons?: React.ReactNode
	footerText?: string | ReactNode
	subtitle: string
	Component: JSXElementConstructor<StepProps>
	primaryButton?: React.ReactNode
	secondaryButton?: React.ReactNode
}
interface NavigationModel {
	steps: NavigationStep[]
}

const getStep = (documentData: RetrieveLegalDocumentResponse) => {
	const { theses } = documentData

	const status = getDocumentStatus(documentData)

	switch (status) {
		case "draft":
			return 0
		case "strategy":
			return 0
		case "processing":
			return 1
		case "error":
			return 0

		case "completed":
			return 1
		default:
			throw new Error(`Invalid combination: ${status} and ${theses}`)
	}
}

export const Display = ({
	navigationModel,
	documentId,
}: {
	navigationModel: NavigationModel
	documentId: string
}) => {
	const { retrieveLegalDocument, updateLegalDocument } = useLegalDocumentAPI()
	const { data } = useQuery(["legalDocument", documentId], () =>
		retrieveLegalDocument(documentId),
	)
	const { steps } = navigationModel

	const [documentStep, setDocumentStep] = useAtom(stepAtom)
	const status = getDocumentStatus(data)
	const [openLex] = useAtom(openLexAtom)
	const queryClient = useQueryClient()
	const currentStep = documentStep || 0

	const updatePetitionMutation = useMutation({
		mutationFn: updateLegalDocument,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["legalDocument", documentId],
			})
		},
	})

	useEffect(() => {
		if (data) {
			setDocumentStep(getStep(data))
		}
	}, [data?.id, status])

	if (currentStep === undefined || !data) {
		return <Loading />
	}

	const Step = steps[currentStep]?.Component
	const PrimaryButton = steps[currentStep]?.primaryButton
	const SecondaryButton = steps[currentStep]?.secondaryButton
	const footerText = steps[currentStep]?.footerText

	if (!Step) return null

	return (
		<div className="w-full relative">
			<div
				className={`flex items-center mb-[44px] justify-between ${
					!openLex && currentStep === 1
						? "w-full"
						: "w-[calc(100%-310px)]"
				}`}
			>
				{data && (
					<Header
						documentId={documentId}
						data={data}
						updateMutation={updatePetitionMutation}
					/>
				)}
				{currentStep === 1 && (
					<HeaderButtonsCompleteDocument
						documentId={documentId}
						type={LegalDocumentType.Petition}
					/>
				)}
			</div>
			<div>
				<Step
					documentId={documentId}
					type={LegalDocumentType.Petition}
				/>
			</div>
			{(PrimaryButton || SecondaryButton) && (
				<Footer stepText={footerText}>
					<div className="flex items-center">
						{SecondaryButton && SecondaryButton}
						{PrimaryButton && PrimaryButton}
					</div>
				</Footer>
			)}
		</div>
	)
}

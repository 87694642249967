interface TagProps {
	background: string
	textColor: string
	text?: string | null
	className?: string
	textClassName?: string
	small?: boolean
}

const Tag = ({
	background,
	textColor,
	text = "",
	className,
	textClassName,
	small,
}: TagProps) => {
	const smallClassNameContainer = "px-2 py-1"
	const smallTextClassName = "text-small-1"
	return (
		<div
			className={`p-2 rounded-3xl ${background} w-fit ${
				small && smallClassNameContainer
			} ${className}`}
		>
			<p
				className={`${textColor} text-cta-2 font-semibold ${
					small && smallTextClassName
				} ${textClassName}`}
			>
				{text}
			</p>
		</div>
	)
}

export default Tag

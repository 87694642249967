import BaseIcon from "./BaseIcon"

const HammerIcon = ({ className, size = "48" }: { className?: string, size?: string }) => {
	return (
		<BaseIcon
			width={size}
			height={size}
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className || "fill-current"}
		>
			<g clipPath="url(#clip0_3497_14434)">
				<path d="M31.9107 30.3571C33.0536 31.5 34.125 31.5 35.125 30.3571L44.7679 20.8214C45.6964 19.75 45.6964 18.6786 44.7679 17.6071C43.6964 16.5357 42.625 16.5357 41.5536 17.6071L31.9107 7.96429C33.0536 6.96429 33.0536 5.92857 31.9107 4.85714C30.9107 3.71429 29.875 3.71429 28.8036 4.85714L19.1607 14.1786C18.0893 15.25 18.0893 16.3214 19.1607 17.3929C20.1607 18.3929 21.2321 18.3929 22.375 17.3929L25.5893 20.6071L19.1607 26.9286C18.0893 26 17.0179 26 15.9464 26.9286L4.80357 38.1786C3.73214 39.25 3.73214 40.3214 4.80357 41.3929L7.69643 45.0357C8.83929 46.1786 9.91071 46.1786 10.9107 45.0357L22.1607 33.7857C22.5893 33.3571 22.8036 32.8393 22.8036 32.2321C22.8036 31.625 22.5893 31.1071 22.1607 30.6786L28.4821 24.25L31.6964 27.4643C30.8393 28.3214 30.9107 29.2857 31.9107 30.3571ZM28.8036 17.6071L25.5893 14.3929L28.8036 11.1786L38.3393 20.8214L35.125 24.0357L31.9107 20.8214L28.8036 17.6071Z" />
			</g>
			<defs>
				<clipPath id="clip0_3497_14434">
					<rect width="48" height="48" fill="white" />
				</clipPath>
			</defs>
		</BaseIcon>
	)
}

export default HammerIcon

import { useQuery } from "@tanstack/react-query"
import FEATURE_FLAGS from "feature-flags"
import posthog from "posthog-js"

export function useFeatureFlag(featureFlag: FEATURE_FLAGS) {
	const featureFlagResult = useQuery([featureFlag], () => {
		const isFeatureEnabled = posthog.isFeatureEnabled(featureFlag)
		return isFeatureEnabled || false
	})
	return featureFlagResult
}

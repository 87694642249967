import { ReactNode } from "react"

interface FooterProps {
	stepText?: string | ReactNode
	children?: ReactNode
	className?: string
}

function Footer({ stepText, children, className }: FooterProps) {

	return (
		<div
			className={
				className ??
				`fixed bottom-0 left-[220px] flex w-[calc(100%-220px)] items-center justify-between bg-brand-white-1 py-4 pr-8 shadow-[0px_0px_4px_0px_rgba(0,0,0,0.08)]`
			}
		>
			{stepText && (
				<div className="pl-6 text-center text-light-blue-1">
					{stepText}
				</div>
			)}
			<div>{children}</div>
		</div>
	)
}

export default Footer

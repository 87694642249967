import { AxiosResponse } from "axios"
import axiosInstance from "axiosInstance"
import {
	UpdateLegalDocumentResponse,
	VersionDocumentResponse,
	ToneEnum,
	RewriteThesisResponse,
} from "types/legalDocument"

const useClauseAPI = () => {
	const createNewClauseRevision = async ({
		id,
		payload,
	}: {
		id: string
		payload: {
			text: string
		}
	}) => {
		const response: AxiosResponse<UpdateLegalDocumentResponse> =
			await axiosInstance.post(
				`/clause/${id}/create_new_revision/`,
				payload,
			)

		return response.data
	}

	const changeWritingToneClause = async ({
		id,
		payload,
	}: {
		id: string
		payload: {
			tone: ToneEnum
		}
	}) => {
		const response: AxiosResponse<RewriteThesisResponse> =
			await axiosInstance.post(
				`/clause/${id}/change_writing_tone/`,
				payload,
			)

		return response.data
	}

	const correctSpellingClause = async (id: string) => {
		const response: AxiosResponse<RewriteThesisResponse> =
			await axiosInstance.post(`/clause/${id}/correct_spelling/`)

		return response.data
	}

	const improveClause = async (id: string) => {
		const response: AxiosResponse<RewriteThesisResponse> =
			await axiosInstance.post(`/clause/${id}/improve/`)

		return response.data
	}

	const increaseClause = async (id: string) => {
		const response: AxiosResponse<RewriteThesisResponse> =
			await axiosInstance.post(`/clause/${id}/increase/`)

		return response.data
	}

	const rewriteClause = async (id: string) => {
		const response: AxiosResponse<RewriteThesisResponse> =
			await axiosInstance.post(`/clause/${id}/rewrite/`)

		return response.data
	}

	const rewriteHowClause = async ({
		id,
		payload,
	}: {
		id: string
		payload: {
			user_input: string
		}
	}) => {
		const response: AxiosResponse<RewriteThesisResponse> =
			await axiosInstance.post(`/clause/${id}/rewrite_how/`, payload)

		return response.data
	}

	const summarizeClause = async (id: string) => {
		const response: AxiosResponse<RewriteThesisResponse> =
			await axiosInstance.post(`/clause/${id}/summarize/`)

		return response.data
	}

	const selectClauseVersion = async (id: string, version: number) => {
		const response: AxiosResponse<VersionDocumentResponse> =
			await axiosInstance.get(
				`/clause/${id}/select_version/?version=${version}`,
			)

		return response.data
	}

	const clauseAIEditor = async ({
		id,
		payload,
	}: {
		id: string
		payload: any
	}) => {
		const response: AxiosResponse<any> = await axiosInstance.post(
			`/v2/clause/${id}/ai_editor/`,
			payload,
		)
		return response.data
	}

	return {
		createNewClauseRevision,
		selectClauseVersion,
		changeWritingToneClause,
		correctSpellingClause,
		improveClause,
		increaseClause,
		rewriteClause,
		rewriteHowClause,
		summarizeClause,
		clauseAIEditor,
	}
}

export default useClauseAPI

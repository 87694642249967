import { useMutation } from "@tanstack/react-query"
import { format } from "date-fns"
import { ptBR } from "date-fns/locale"
import useCurrentUser from "hooks/useCurrentUser"
import useSubscriptionAPI from "hooks/useSubscriptionAPI"
import ExclamationIcon from "icons/ExclamationIcon"
import handleCheckoutError from "utils/handleCheckoutError"
interface HeaderAlertProps {
	active: boolean
}
const HeaderAlert = ({ active }: HeaderAlertProps) => {
	const { subscriptionCustomerPortal } = useSubscriptionAPI()
	const user = useCurrentUser()

	const subscriptionCustomerPortalMutation = useMutation({
		mutationFn: subscriptionCustomerPortal,
	})

	function handleClick() {
		const tab = window.open(`${window.location.origin}/redirect`)
		subscriptionCustomerPortalMutation
			.mutateAsync({
				returnUrl: `${window.location.href}`,
			})
			.then((data) => (tab!.location.href = data.customer_portal_url))
			.catch(() => handleCheckoutError(tab))
	}

	return active ? (
		<div className="flex p-4 items-center justify-between bg-[#FFC83A] mb-6 rounded">
			<div className="flex items-center w-[73%] mr-10">
				<ExclamationIcon className="mr-2" />
				<div className="text-dark-blue-1 font-Lato text-[18px] leading-6">
					Seu pagamento foi recusado. Atualize a forma de pagamento
					até{" "}
					<span className="text-dark-blue-1 font-Lato leading-6 font-bold text-[18px]">
						{user?.subscription?.end_period &&
							format(
								new Date(
									new Date(
										user?.subscription?.end_period,
									).getTime() +
										3 * 24 * 60 * 60 * 1000,
								),
								"dd/MM/yy",
								{
									locale: ptBR,
								},
							)}
					</span>{" "}
					para manter sua assinatura ativa.
				</div>
			</div>
			<button
				className="bg-[#FFFFFF] py-3 px-6 rounded"
				onClick={handleClick}
			>
				Atualizar forma de pagamento
			</button>
		</div>
	) : null
}

export default HeaderAlert

import CloseFilledIcon from "icons/CloseFilledIcon"

interface FilteredChipProps {
	text: string
	onClick: () => void
}
function FilteredChip({ text, onClick }: FilteredChipProps) {
	return (
		<div className="flex gap-2 rounded-[21px] border border-solid border-light-blue-1 bg-semi-white-3 px-4 py-2 text-center text-light-blue-1 h-[36px]">
			<div className="flex items-center">
				<p className="text-cta-2">{text}</p>
				<button
					type="button"
					className="ms-2 mt-[1px] items-center"
					onClick={onClick}
				>
					<CloseFilledIcon className="fill-light-blue-4" />
				</button>
			</div>
		</div>
	)
}

export default FilteredChip

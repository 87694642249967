import { useInfiniteQuery } from "@tanstack/react-query"
import Loading from "components/loading"
import Tooltip from "components/tooltip"
import useAPI from "hooks/useAPI"
import CopyIcon from "icons/CopyIcon"
import JurisprudenceAutocomplete from "pages/JurisprudencesSearch/subcomponents/autocomplete"
import { Fragment, useEffect, useState } from "react"
import Lottie from "react-lottie"
import { useSearchParams } from "react-router-dom"
import { Jurisprudence, JurisprudenceData } from "types/jurisprudence"
import loading from "lotties/dotTheses.json"
import { TooltipPosition } from "components/tooltip/enums"

const LottieComponent = Lottie as any

const lottieOptions = {
	loop: true,
	autoplay: true,
	animationData: loading,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
		className: "cursor-default",
	},
}

interface SearchResultItemProps {
	jurisprudence: Jurisprudence
}
const SearchResultItem = ({ jurisprudence }: SearchResultItemProps) => {
	const [textIsCopied, setTextIsCopied] = useState(false)

	const handleCopyClick = () => {
		navigator.clipboard.writeText(jurisprudence.ementa)
		setTextIsCopied(true)
	}

	useEffect(() => {
		if (textIsCopied) setTimeout(() => setTextIsCopied(false), 3000)
	}, [textIsCopied])

	return (
		<div className="bg-white rounded shadow-outlined w-full p-4  relative">
			<div className="flex items-center flex-wrap w-full max-w-[95%]">
				<p className="text-brand-gray-3">
					<a
						target="blank"
						href={jurisprudence.link}
						className="text-h4 font-semibold text-light-blue-4 hover:text-light-blue-2 cursor-pointer no-underline"
					>
						{jurisprudence.tribunal} -{" "}
						{jurisprudence.classe_processual} -{" "}
						{jurisprudence.assunto} -{" "}
						{jurisprudence.numero_processo}
					</a>
					<span className="mx-4 text-[12px]">•</span>
					<span className="text-small-1">
						{" "}
						{jurisprudence.tipo_decisao}{" "}
					</span>

					<span className="mx-4 text-[12px]">•</span>
					<span className="text-small-1">
						{" "}
						{String(jurisprudence.data_publicacao).replaceAll(
							"-",
							"/",
						)}{" "}
					</span>
				</p>
			</div>

			<div
				className="absolute top-4 right-4 cursor-pointer"
				onClick={handleCopyClick}
			>
				<Tooltip
					position={TooltipPosition.Above}
					text={textIsCopied ? "Copiado!" : "Copiar"}
					bg={textIsCopied ? "bg-[#323232EB]" : ""}
				>
					<CopyIcon />
				</Tooltip>
			</div>

			<p className="text-brand-gray-3 mt-4">{jurisprudence.ementa}</p>
		</div>
	)
}

export default function JurisprudenceResultsPage() {
	const [searchParams, setSearchParams] = useSearchParams()
	const { searchJurisprudences } = useAPI()
	const query = searchParams.get("query") || ""

	const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
		useInfiniteQuery<JurisprudenceData, Error>(
			[query],
			({ pageParam = 0 }) => searchJurisprudences(query, pageParam),
			{
				getNextPageParam: (lastPage) => {
					if (!lastPage.next) return undefined
					const url = new URL(lastPage.next)
					const offset = url.searchParams.get("offset")
					if (
						(offset && parseInt(offset, 10) >= 81) ||
						offset === "None"
					) {
						return undefined
					}
					return offset ? parseInt(offset, 10) : undefined
				},
				refetchOnWindowFocus: false,
			},
		)

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				if (
					entries[0].isIntersecting &&
					hasNextPage &&
					!isFetchingNextPage
				) {
					fetchNextPage()
				}
			},
			{ threshold: 1 },
		)
		const element = document.getElementById("load-more-trigger")
		if (element) observer.observe(element)

		return () => observer.disconnect()
	}, [fetchNextPage, hasNextPage, isFetchingNextPage])

	return (
		<div className=" w-full ">
			<div className="flex items-center justify-between">
				<h1 className=" font-semibold text-dark-blue-1">
					Jurisprudência
				</h1>

				<div className="relative">
					<JurisprudenceAutocomplete
						className="w-[480px]"
						onSubmit={(value) => setSearchParams({ query: value })}
						value={searchParams.get("query") || ""}
						isLoading={isLoading}
						showClearIcon={true}
					/>
				</div>
			</div>
			<div className="flex flex-col gap-4 mt-8">
				{isLoading ? (
					<Loading className="fixed top-[40%] left-[50%]" />
				) : (
					data &&
					data.pages.map((page, i) => (
						<Fragment key={i}>
							{page.results.map((jurisprudence) => (
								<SearchResultItem
									jurisprudence={jurisprudence}
									key={jurisprudence.id}
								/>
							))}
						</Fragment>
					))
				)}
			</div>
			{isFetchingNextPage && (
				<div className="flex items-center justify-center mt-8">
					<LottieComponent
						options={lottieOptions}
						height={15}
						width={45}
						style={{ margin: "3.5px 0 0 0" }}
						isClickToPauseDisabled
					/>
				</div>
			)}

			<div id="load-more-trigger" className="h-2"></div>
		</div>
	)
}

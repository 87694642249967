import React from "react"
import BaseIcon from "./BaseIcon"

const PenalIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
		>
			<path d="M20.574 6.76C18.734 4.91 15.984 4.53 13.754 5.59L12.794 4.63C12.7015 4.5373 12.5916 4.46375 12.4706 4.41357C12.3496 4.36339 12.22 4.33756 12.089 4.33756C11.958 4.33756 11.8283 4.36339 11.7074 4.41357C11.5864 4.46375 11.4765 4.5373 11.384 4.63L11.304 4.72C11.164 3.75 10.334 3 9.33398 3C8.23398 3 7.33398 3.9 7.33398 5C7.33398 5.09 7.33398 5.18 7.36398 5.26C6.75398 5.61 6.33398 6.25 6.33398 7C6.33398 7.46 6.49398 7.87 6.75398 8.21C6.6257 8.29829 6.52057 8.41618 6.44746 8.55368C6.37436 8.69119 6.33543 8.84428 6.33398 9V10.35C4.00398 11.17 2.33398 13.39 2.33398 16C2.33398 19.31 5.02398 22 8.33398 22C11.644 22 14.334 19.31 14.334 16C14.334 14.7601 13.9493 13.5507 13.2328 12.5387C12.5164 11.5267 11.5035 10.7619 10.334 10.35V9C10.3325 8.84428 10.2936 8.69119 10.2205 8.55368C10.1474 8.41618 10.0423 8.29829 9.91398 8.21C10.174 7.87 10.334 7.46 10.334 7C10.334 6.91 10.334 6.82 10.304 6.74C10.484 6.64 10.634 6.5 10.764 6.38C11.694 6.88 12.334 7.86 12.334 9V9.08C12.464 9.15 12.584 9.25 12.714 9.33C12.904 8.91 13.164 8.5 13.504 8.17C14.2553 7.42085 15.273 7.00016 16.334 7.00016C17.395 7.00016 18.4127 7.42085 19.164 8.17C19.9131 8.92131 20.3338 9.93901 20.3338 11C20.3338 12.061 19.9131 13.0787 19.164 13.83C18.364 14.63 17.334 15 16.264 15C16.334 15.32 16.334 15.66 16.334 16C16.334 16.34 16.304 16.67 16.264 17C17.834 17 19.384 16.43 20.574 15.24C21.1326 14.6842 21.5759 14.0235 21.8784 13.2959C22.1809 12.5682 22.3366 11.788 22.3366 11C22.3366 10.212 22.1809 9.43175 21.8784 8.70411C21.5759 7.97647 21.1326 7.31578 20.574 6.76ZM9.33398 4C9.88398 4 10.334 4.45 10.334 5C10.334 5.33 10.164 5.61 9.91398 5.79C9.55398 5.31 8.98398 5 8.33398 5C8.33398 4.45 8.78398 4 9.33398 4ZM7.75398 6.21C8.11398 6.69 8.68398 7 9.33398 7C9.33398 7.55 8.88398 8 8.33398 8C7.78398 8 7.33398 7.55 7.33398 7C7.33398 6.67 7.50398 6.39 7.75398 6.21ZM12.334 16C12.334 18.21 10.544 20 8.33398 20C6.12398 20 4.33398 18.21 4.33398 16C4.33398 13.79 6.12398 12 8.33398 12C10.544 12 12.334 13.79 12.334 16Z" />
		</BaseIcon>
	)
}

export default PenalIcon

import { ToastContainer, ToastContentProps, Zoom } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { ToastType } from "./types"
import { toastClassName } from "./variables"
interface ToastDisplay extends Partial<ToastContentProps> {
	message: string
	type: ToastType
	ctaButton?: {
		text: string
		onClick: () => void
	}
}

export const ToastMessage = ({
	closeToast,
	message,
	type,
	ctaButton,
}: ToastDisplay) => (
	<div className="relative flex justify-between p-6">
		<p
			className={`${toastClassName[type].text} text-[16px] font-medium ${
				ctaButton && "mr-[80px]"
			}`}
		>
			{message}
		</p>

		{ctaButton && (
			<button
				onClick={() => {
					ctaButton.onClick()
					if (closeToast) {
						closeToast()
					}
				}}
				className={`${toastClassName[type].text} font-bold`}
			>
				{ctaButton.text}
			</button>
		)}
		<div
			className={`${toastClassName[type].progress.end} absolute right-[0] top-[63px] h-[5px] w-[100vw] border-4`}
		></div>
	</div>
)

const Toast = () => {
	return (
		<div>
			<ToastContainer
				className="!w-max"
				toastClassName={() => "p-0 "}
				closeButton={false}
				pauseOnHover={false}
				autoClose={3000}
				transition={Zoom}
			/>
		</div>
	)
}

export default Toast

import React from "react"
import BaseIcon from "./BaseIcon"

const TextUnlinkIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.125 1.25H6.875V3.75H8.125V1.25ZM2.23991 3.12622L3.12364 2.24218L5.25859 4.37639L4.37487 5.26043L2.23991 3.12622ZM14.7384 15.6201L15.6224 14.7364L17.7566 16.8713L16.8726 17.7551L14.7384 15.6201ZM1.25 6.875H3.75V8.125H1.25V6.875ZM18.75 11.875H16.25V13.125H18.75V11.875ZM11.875 16.25H13.125V18.75H11.875V16.25ZM10.3625 13.1687L8.04373 15.4938C7.81146 15.726 7.53571 15.9103 7.23222 16.036C6.92874 16.1617 6.60347 16.2264 6.27498 16.2264C5.9465 16.2264 5.62122 16.1617 5.31774 16.036C5.01426 15.9103 4.73851 15.726 4.50623 15.4938C4.03713 15.0246 3.77359 14.3884 3.77359 13.725C3.77359 13.0616 4.03713 12.4254 4.50623 11.9562L6.83123 9.63125L5.94373 8.75L3.62498 11.075C3.26915 11.4213 2.98564 11.8349 2.79092 12.2917C2.59619 12.7485 2.49412 13.2394 2.49063 13.7359C2.48714 14.2325 2.5823 14.7247 2.77059 15.1842C2.95888 15.6437 3.23655 16.0612 3.58748 16.4125C3.93788 16.7601 4.35344 17.035 4.81033 17.2216C5.26722 17.4083 5.75646 17.5029 6.24998 17.5C6.75208 17.5005 7.24929 17.4013 7.71276 17.2082C8.17624 17.0151 8.59678 16.7319 8.94998 16.375L11.25 14.0562L10.3625 13.1687ZM11.9563 4.50631L9.63125 6.83131L8.75 5.94381L11.05 3.62506C11.4032 3.2682 11.8237 2.985 12.2872 2.79189C12.7507 2.59877 13.2479 2.49958 13.75 2.50006C14.2435 2.49721 14.7328 2.5918 15.1897 2.77842C15.6465 2.96504 16.0621 3.24001 16.4125 3.58756C16.7634 3.93887 17.0411 4.35637 17.2294 4.81584C17.4177 5.27532 17.5128 5.76761 17.5094 6.26415C17.5059 6.76069 17.4038 7.2516 17.2091 7.70838C17.0143 8.16516 16.7308 8.57872 16.375 8.92506L14.0563 11.2501L13.1688 10.3688L15.4938 8.04381C15.726 7.81154 15.9103 7.53579 16.036 7.2323C16.1617 6.92882 16.2264 6.60355 16.2264 6.27506C16.2264 5.94658 16.1617 5.62131 16.036 5.31782C15.9103 5.01434 15.726 4.73859 15.4938 4.50631C15.2615 4.27404 14.9857 4.08979 14.6822 3.96408C14.3788 3.83837 14.0535 3.77367 13.725 3.77367C13.3965 3.77367 13.0712 3.83837 12.7678 3.96408C12.4643 4.08979 12.1885 4.27404 11.9563 4.50631Z"
			/>
		</BaseIcon>
	)
}

export default TextUnlinkIcon

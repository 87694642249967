import { LegalDocumentType } from "types/legalDocument"
import { NewDocumentComplete } from "../../NewDocumentComplete"
import { HeaderButtonsCompleteDocument } from "../../HeaderButtonsCompleteDocument"

export function newContractCompleteStep(documentId: string) {
	return {
		subtitle: "Subtitulo  Teses 2",
		Component: (
			<NewDocumentComplete
				documentId={documentId}
				type={LegalDocumentType.Contract}
			/>
		),
		headerButtons: (
			<HeaderButtonsCompleteDocument
				documentId={documentId}
				type={LegalDocumentType.Contract}
			/>
		),
	}
}

import { withRef } from "@udecode/cn"
import {
	useComposedRef,
	useEventEditorSelectors,
	usePlateSelectors,
} from "@udecode/plate-common"
import {
	type FloatingToolbarState,
	flip,
	offset,
	useFloatingToolbar,
	useFloatingToolbarState,
} from "@udecode/plate-floating"

import { Toolbar } from "./toolbar"

export const FloatingToolbar = withRef<
	typeof Toolbar,
	{
		state?: FloatingToolbarState
	}
>(({ children, state, ...props }, componentRef) => {
	const editorId = usePlateSelectors().id()
	const focusedEditorId = useEventEditorSelectors.focus()

	const floatingToolbarState = useFloatingToolbarState({
		editorId,
		focusedEditorId,
		...state,
		floatingOptions: {
			middleware: [
				offset(12),
				flip({
					fallbackPlacements: [
						"top-start",
						"top-end",
						"bottom-start",
						"bottom-end",
					],
					padding: 12,
				}),
			],
			placement: "top-start",
			...state?.floatingOptions,
		},
	})

	const {
		hidden,
		props: rootProps,
		ref: floatingRef,
	} = useFloatingToolbar(floatingToolbarState)

	const ref = useComposedRef<HTMLDivElement>(componentRef, floatingRef)

	if (hidden) return null

	return (
		<div>
			<Toolbar
				className={`absolute z-50 px-2 gap-2 bg-[#FCFCFC] shadow-outlined-hover rounded flex items-center text-justify`}
				ref={ref}
				{...rootProps}
				{...props}
			>
				{children}
			</Toolbar>
		</div>
	)
})

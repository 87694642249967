const ExclamationIcon = ({
	size = "24",
	className,
}: {
	className?: string
	size?: string
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="currentColor"
			className={className}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17316C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8078C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 9.34783 20.9464 6.80429 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM12 20.5714C10.3047 20.5714 8.64754 20.0687 7.23797 19.1269C5.82841 18.185 4.72979 16.8464 4.08104 15.2801C3.43229 13.7139 3.26254 11.9905 3.59327 10.3278C3.924 8.6651 4.74035 7.13782 5.93909 5.93908C7.13782 4.74035 8.66511 3.924 10.3278 3.59327C11.9905 3.26254 13.7139 3.43228 15.2801 4.08103C16.8464 4.72978 18.185 5.8284 19.1269 7.23797C20.0687 8.64753 20.5714 10.3047 20.5714 12C20.5714 14.2733 19.6684 16.4535 18.0609 18.0609C16.4535 19.6684 14.2733 20.5714 12 20.5714ZM12.7143 6.28571H11.2857V14.1429H12.7143V6.28571ZM11.4047 16.4663C11.5809 16.3485 11.7881 16.2857 12 16.2857C12.2842 16.2857 12.5567 16.3986 12.7576 16.5995C12.9585 16.8005 13.0714 17.073 13.0714 17.3571C13.0714 17.569 13.0086 17.7762 12.8909 17.9524C12.7731 18.1286 12.6058 18.2659 12.41 18.347C12.2142 18.4281 11.9988 18.4493 11.791 18.408C11.5831 18.3666 11.3922 18.2646 11.2424 18.1148C11.0925 17.9649 10.9905 17.774 10.9492 17.5662C10.9078 17.3583 10.929 17.1429 11.0101 16.9471C11.0912 16.7513 11.2285 16.584 11.4047 16.4663Z"
			/>
		</svg>
	)
}

export default ExclamationIcon

/// <reference lib="dom" />
/// <reference lib="dom.iterable" />
import React from "react"
import ReactDOM from "react-dom/client"
import "./styles/globals.css"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { Auth0Provider } from "@auth0/auth0-react"
import BasePage from "./pages/BasePage"
import MyDocumentsPage from "./pages/MyDocumentsPage"
import DocumentPage from "./pages/DocumentPage"
import { AUTH0, IS_PROD, IS_STAGING } from "config"
import { ROUTES } from "routes"
import type { CustomFlowbiteTheme } from "flowbite-react"
import { Flowbite } from "flowbite-react"
import RequestAccessDocumentPage from "pages/RequestAccessDocumentPage"
import SettingsPage from "pages/SettingsPage"
import ErrorPage from "pages/ErrorPage"
import AnalizeDocumentPage from "pages/AnalizeDocumentPage"
import ChatPdfPage from "pages/ChatPdfPage"
import VerifyEmailPage from "pages/VerifyEmailPage"
import SidebarPages from "pages/SidebarPages"
import NotificationPage from "pages/NotificationPage"
import ContractPage from "pages/DocumentPage/steps/ContractPage"
import OutOfAreaPage from "pages/OutOfAreaPage"
import CheckoutSuccess from "pages/CheckoutSuccess"
import * as Sentry from "@sentry/react"
import RedirectingPage from "pages/BasePage/subcomponents/RedirectingPage"
import JurisprudencesSearchPage from "pages/JurisprudencesSearch"
import InternalDashboard from "pages/InternalDashboardPage"
import Sidebar from "pages/InternalDashboardPage/subcomponents/Sidebar"
import UserDetailsPage from "pages/InternalDashboardPage/subcomponents/UserDetails"
import posthog from "posthog-js"
import { POSTHOG_CONFIG } from "config"
import ListModelsPage from "pages/ListModelsPage"
import ModelPage from "pages/ModelPage"
import LegalAssistantPage from "pages/LegalAssistantPage"

posthog.init(POSTHOG_CONFIG.apiKey, {
	api_host: POSTHOG_CONFIG.host,
})

if (IS_PROD || IS_STAGING) {
	let environment

	if (IS_PROD) {
		environment = "prod"
	} else {
		environment = "staging"
	}

	Sentry.init({
		dsn: "https://0ed987b9e54aa4b703ad68744f94513c@o4506803010600960.ingest.sentry.io/4506803032948736",
		environment: environment,
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [
			"localhost",
			/^https:\/\/staging\.lawdeck\.ai\/api/,
			/^https:\/\/app\.lawdeck\.ai\/api/,
		],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	})
}

const router = createBrowserRouter([
	{
		element: <BasePage />,
		children: [
			{
				path: "",
				element: <SidebarPages />,
				children: [
					{
						index: true,
						path: ROUTES.myDocuments(),
						element: <MyDocumentsPage />,
					},
					{
						path: ROUTES.documentDetail({ id: ":documentId" }),
						element: <DocumentPage />,
					},
					{
						path: ROUTES.settings(),
						element: <SettingsPage />,
					},
					{
						path: ROUTES.analizeDocument({ id: ":documentId" }),
						element: <AnalizeDocumentPage />,
					},
					{
						path: ROUTES.chatPdf(),
						element: <ChatPdfPage />,
					},
					{
						path: ROUTES.notification(),
						element: <NotificationPage />,
					},
					{
						path: ROUTES.contract(),
						element: <ContractPage />,
					},
					{
						path: ROUTES.jurisprudencesSearch(),
						element: <JurisprudencesSearchPage />,
					},
					{
						path: ROUTES.listModels(),
						element: <ListModelsPage />,
					},
					{
						path: ROUTES.model({ id: ":model" }),
						element: <ModelPage />,
					},
					{
						path: ROUTES.legalAssistant(),
						element: <LegalAssistantPage />,
					}
				],
			},
			{
				path: ROUTES.requestAccessDocument({ id: ":documentId" }),
				element: <RequestAccessDocumentPage />,
			},
			{
				path: ROUTES.verifyEmail(),
				element: <VerifyEmailPage />,
			},
			{
				path: ROUTES.outOfArea(),
				element: <OutOfAreaPage />,
			},
			{ path: ROUTES.redirect(), element: <RedirectingPage /> },
			{
				path: ROUTES.checkoutSuccess(),
				element: <CheckoutSuccess />,
			},
			{
				path: "",
				element: <Sidebar />,
				children: [
					{
						path: ROUTES.admin(),
						element: <InternalDashboard />,
					},
					{
						path: ROUTES.userDetail({ id: ":userId" }),
						element: <UserDetailsPage />,
					},
				],
			},
		],
		errorElement: <ErrorPage />,
	},
])

const queryClient = new QueryClient()

const customTheme: CustomFlowbiteTheme = {
	modal: {
		content: {
			base: "relative h-auto w-full !outline-none ",
			inner: "relative rounded-lg shadow flex flex-col !outline-none",
		},
		root: {
			sizes: {
				full: "max-w-[5000px]",
				"896": "max-w-[896px]",
				"843": "max-w-[843px]",
				"750": "max-w-[750px]",
				"674": "max-w-[674px]",
				"664": "max-w-[664px]",
				"550": "max-w-[550px]",
				"425": "max-w-[425px]",
				"397": "max-w-[397px]",
				"337": "max-w-[337px]",
			},
		},
	},
}

const searchParams = new URLSearchParams(window.location.search)
const screenHint = searchParams.get("screen_hint")

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<QueryClientProvider client={queryClient}>
		<Auth0Provider
			domain={AUTH0.domain}
			clientId={AUTH0.clientId}
			useRefreshTokens={true}
			cacheLocation="localstorage"
			authorizationParams={{
				audience: AUTH0.audience,
				redirect_uri: window.location.origin,
				screen_hint: screenHint ?? undefined,
			}}
		>
			<Flowbite theme={{ theme: customTheme }}>
				<RouterProvider router={router} />
			</Flowbite>
		</Auth0Provider>
	</QueryClientProvider>,
)

const CircleCheckEditorIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
		>
			<path
				d="M7.99984 2.66699C6.945 2.66699 5.91386 2.97979 5.0368 3.56582C4.15974 4.15185 3.47615 4.98481 3.07248 5.95935C2.66881 6.93389 2.5632 8.00624 2.76898 9.04081C2.97477 10.0754 3.48272 11.0257 4.2286 11.7716C4.97448 12.5174 5.92479 13.0254 6.95936 13.2312C7.99392 13.437 9.06628 13.3313 10.0408 12.9277C11.0154 12.524 11.8483 11.8404 12.4343 10.9634C13.0204 10.0863 13.3332 9.05516 13.3332 8.00032C13.3332 6.58584 12.7713 5.22928 11.7711 4.22909C10.7709 3.2289 9.41433 2.66699 7.99984 2.66699ZM7.23793 10.1302L5.33317 8.22539L5.93912 7.61937L7.23793 8.91811L10.0608 6.09556L10.6687 6.69972L7.23793 10.1302Z"
				fill="#3083FF"
			/>
		</svg>
	)
}

export default CircleCheckEditorIcon

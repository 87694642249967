import { HeaderGroup } from "@tanstack/react-table"
import { RetrieveLegalDocumentResponse } from "types/legalDocument"
import { flexRender } from "@tanstack/react-table"

interface LegalDocumentTableFooterProps {
	footerGroup: HeaderGroup<RetrieveLegalDocumentResponse>
}

const LegalDocumentTableFooter = ({
	footerGroup,
}: LegalDocumentTableFooterProps) => {
	return (
		<div key={footerGroup.id} className="text-center align-middle bg-white">
			{footerGroup.headers.map((header) => (
				<div key={header.id}>
					{header.isPlaceholder
						? null
						: flexRender(
								header.column.columnDef.footer,
								header.getContext(),
						  )}
				</div>
			))}
		</div>
	)
}

export default LegalDocumentTableFooter

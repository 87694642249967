import { useQuery } from "@tanstack/react-query"
import image from "assets/verify-email.jpg"
import ExitButton from "components/exit-button"
import { useLongPoll } from "hooks/useLongPoll"
import useUserAPI from "hooks/useUserAPI"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ROUTES } from "routes"

export default function VerifyEmailPage() {
	const { currentUser } = useUserAPI()
	const [isPageVisible, setIsPageVisible] = useState(!document.hidden)
	const navigate = useNavigate()
	const { data: user } = useQuery(["current-user"], currentUser, {
		enabled: isPageVisible,
	})

	useEffect(() => {
		function handleVisibilityChange() {
			setIsPageVisible(!document.hidden)
		}

		document.addEventListener("visibilitychange", handleVisibilityChange)

		return () => {
			document.removeEventListener(
				"visibilitychange",
				handleVisibilityChange,
			)
		}
	}, [])

	useEffect(() => {
		if (user && user.email_verified) {
			navigate(ROUTES.basePage())
		}
	}, [user])

	useLongPoll({
		conditionToStop: user && user.email_verified,
		queryKey: ["current-user"],
		deps: [user && user.email_verified],
		ms: 5000,
	})

	return (
		<div className="w-full h-[100vh] flex items-center justify-center bg-verify-gradient">
			<div className="bg-white p-[64px] rounded-xl shadow-3  mx-auto w-full max-w-[554px] relative">
				<ExitButton className="absolute top-6 left-6" />
				<div className="flex items-center justify-center mb-6 ">
					<div className="w-[269px] h-[202px] bg-green-100 rounded-full flex items-center justify-center">
						<img src={image} width={269} height={202} />
					</div>
				</div>

				<div>
					<p className="text-h0 font-semibold mb-4 font-lato">
						Verifique seu email!
					</p>
					<p className="text-gray-600 text-start  leading-6 font-lato text-[18px]">
						Enviamos um link de confirmação para o seu email,
						lembre-se de checar a caixa de spam.
					</p>
				</div>
			</div>
		</div>
	)
}

import React from "react"
import BaseIcon from "./BaseIcon"

const UserIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.0834 3.63199C13.4667 3.21993 12.7417 3 12 3C11.0054 3 10.0516 3.39509 9.34835 4.09835C8.64509 4.80161 8.25 5.75544 8.25 6.75C8.25 7.49168 8.46994 8.2167 8.88199 8.83339C9.29405 9.45007 9.87972 9.93072 10.5649 10.2145C11.2502 10.4984 12.0042 10.5726 12.7316 10.4279C13.459 10.2833 14.1272 9.9261 14.6517 9.40165C15.1761 8.8772 15.5333 8.20902 15.6779 7.48159C15.8226 6.75416 15.7484 6.00016 15.4646 5.31494C15.1807 4.62971 14.7001 4.04404 14.0834 3.63199ZM9.08326 2.38478C9.94662 1.80791 10.9617 1.5 12 1.5C13.3924 1.5 14.7277 2.05312 15.7123 3.03769C16.6969 4.02226 17.25 5.35761 17.25 6.75C17.25 7.78835 16.9421 8.80339 16.3652 9.66674C15.7883 10.5301 14.9684 11.203 14.0091 11.6004C13.0498 11.9977 11.9942 12.1017 10.9758 11.8991C9.95738 11.6966 9.02192 11.1965 8.28769 10.4623C7.55347 9.72808 7.05345 8.79262 6.85088 7.77422C6.64831 6.75582 6.75227 5.70022 7.14963 4.74091C7.54699 3.7816 8.2199 2.96166 9.08326 2.38478ZM18 22.5H19.5V18.75C19.5 17.3576 18.9469 16.0223 17.9623 15.0377C16.9777 14.0531 15.6424 13.5 14.25 13.5H9.75C8.35761 13.5 7.02226 14.0531 6.03769 15.0377C5.05312 16.0223 4.5 17.3576 4.5 18.75V22.5H6V18.75C6 17.7554 6.39509 16.8016 7.09835 16.0983C7.80161 15.3951 8.75544 15 9.75 15H14.25C14.7425 15 15.2301 15.097 15.6851 15.2855C16.14 15.4739 16.5534 15.7501 16.9017 16.0983C17.2499 16.4466 17.5261 16.86 17.7145 17.3149C17.903 17.7699 18 18.2575 18 18.75V22.5Z"
			/>
		</BaseIcon>
	)
}

export default UserIcon

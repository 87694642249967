import BaseIcon from "./BaseIcon"

const EditIcon = ({ className, ...props }: { className?: string }) => {
	return (
		<BaseIcon
			width="24"
			height="24"
			viewBox="0 0 24 24"
			className={className}
			{...props}
			fill="none"
		>
			<path d="M21.5615 5.06923L18.9308 2.43846C18.3462 1.85385 17.4692 1.85385 16.8846 2.43846L11.0385 8.28448L12.0615 9.30755L14.6923 6.67692L17.3231 9.30769L14.6923 11.9385L15.7154 12.9615L21.5615 7.11538C22.1462 6.53077 22.1462 5.65385 21.5615 5.06923ZM18.3462 8.28462L15.7154 5.65385L17.9077 3.46154L20.5385 6.09231L18.3462 8.28462Z" />
			<path d="M11.0385 8.28448L3 16.3231V21H7.67692L15.7154 12.9615L14.6923 11.9385L7.09231 19.5385H4.46154V16.9077L12.0615 9.30755L11.0385 8.28448Z" />
		</BaseIcon>
	)
}

export default EditIcon

import { MouseEvent, ReactNode } from "react"

interface CardSelectorProps {
	className?: string
	titleClassName?: string
	image?: string
	title: string
	icon?: ReactNode
	onClick: (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void
}

export function CardSelector({
	className,
	titleClassName,
	image,
	title,
	icon,
	onClick,
}: CardSelectorProps) {
	return (
		<div
			className={`flex flex-col w-full p-4 items-center bg-brand-white-1 rounded-md shadow-[0px_0px_4px_0px_rgba(0,0,0,0.08)] text-brand-gray-3  hover:shadow-[0px_0px_10px_0px_rgba(0,0,0,0.14)] hover:text-dark-blue-2 cursor-pointer ${className}`}
			onClick={onClick}
		>
			{image && (
				<img
					className="self-stretch rounded mb-[17px]"
					height={128}
					width={176}
					src={image}
				/>
			)}

			<div
				className={
					titleClassName ??
					"flex text-h4 font-semibold leading-6 font-lato"
				}
			>
				{icon && <div className="mr-2">{icon}</div>}
				{title}
			</div>
		</div>
	)
}

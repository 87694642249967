import { CardSelector } from "./CardSelector"
import { OnboardingStepProps } from "pages/MyDocumentsPage/subcomponents/ModalOnboarding"
import EllipseFooter from "./EllipseFooter"
import { useEffect } from "react"
import { YearsOfExperience } from "types/currentUser/enum"

export function ThirdStep({
	currentStep,
	formData,
	setFormData,
	onStepChanges,
	setSize,
}: OnboardingStepProps) {
	useEffect(() => {
		setSize("750")
	}, [])
	const yearsOfExperience = [
		{
			title: "Menos de 1 ano",
			years_of_experience: YearsOfExperience.LessThan1,
		},
		{
			title: "1 - 3 anos",
			years_of_experience: YearsOfExperience.Between1And3,
		},
		{
			title: "3 - 5 anos",
			years_of_experience: YearsOfExperience.Between3And5,
		},
		{
			title: "5 - 7 anos",
			years_of_experience: YearsOfExperience.Between5And7,
		},
		{
			title: "7 - 10 anos",
			years_of_experience: YearsOfExperience.Between7And10,
		},
		{
			title: "Mais de 10 anos",
			years_of_experience: YearsOfExperience.MoreThan10,
		},
	]
	return (
		<div className="flex justify-center flex-col p-[24px]">
			<div className="flex mb-8 text-[22px] font-lato font-semibold">
				Há quanto tempo você estuda ou atua em Direito?
			</div>
			<div className="flex mb-4">
				{yearsOfExperience
					.slice(0, 2)
					.map(({ title, years_of_experience }, index) => {
						const className = index !== 1 ? "mr-4" : ""
						return (
							<CardSelector
								key={years_of_experience}
								title={title}
								className={className}
								onClick={() => {
									setFormData({
										...formData,
										years_of_experience,
									})
									onStepChanges(currentStep + 1)
								}}
							/>
						)
					})}
			</div>
			<div className="flex mb-4">
				{yearsOfExperience
					.slice(2, 4)
					.map(({ title, years_of_experience }, index) => {
						const className = index !== 1 ? "mr-4" : ""

						return (
							<CardSelector
								key={years_of_experience}
								className={className}
								title={title}
								onClick={() => {
									setFormData({
										...formData,
										years_of_experience,
									})
									onStepChanges(currentStep + 1)
								}}
							/>
						)
					})}
			</div>
			<div className="flex mb-8">
				{yearsOfExperience
					.slice(4, 6)
					.map(({ title, years_of_experience }, index) => {
						const className = index !== 1 ? "mr-4" : ""

						return (
							<CardSelector
								key={years_of_experience}
								className={className}
								title={title}
								onClick={() => {
									setFormData({
										...formData,
										years_of_experience,
									})
									onStepChanges(currentStep + 1)
								}}
							/>
						)
					})}
			</div>

			<EllipseFooter activeIndex={1} />
		</div>
	)
}

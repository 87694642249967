import BaseIcon from "./BaseIcon"

interface CopyIconProps {
	className?: string
	size?: string
}

export default function CopyIcon({ className, size = "24px" }: CopyIconProps) {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 24 24"
			className={className || "fill-current"}
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4 13.1425H5.14288V5.14281H13.143V4H5.14288C4.83977 4 4.54907 4.1204 4.33474 4.33472C4.12041 4.54904 4 4.83972 4 5.14281V13.1425ZM18.8571 18.8572V8.57193H8.5712V18.8572H18.8571ZM8.5712 7.42912H18.8571C19.1602 7.42912 19.4509 7.54953 19.6653 7.76384C19.8796 7.97816 20 8.26884 20 8.57193V18.8572C20 19.1603 19.8796 19.451 19.6653 19.6653C19.4509 19.8796 19.1602 20 18.8571 20H8.5712C8.26809 20 7.9774 19.8796 7.76306 19.6653C7.54873 19.451 7.42832 19.1603 7.42832 18.8572V8.57193C7.42832 8.26884 7.54873 7.97816 7.76306 7.76384C7.9774 7.54953 8.26809 7.42912 8.5712 7.42912Z"
			/>
		</BaseIcon>
	)
}

import Modal from "components/modal"
import downgrade from "assets/downgrade.jpg"
import cancelPlan1 from "assets/cancelPlan1.jpg"
import CloseIcon from "icons/CloseIcon"
import { Button } from "components/button"
import { useEffect, useState } from "react"
import Lottie from "react-lottie"
import check from "lotties/check.json"
import TextareaAutosize from "react-textarea-autosize"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import ExclamationIcon from "icons/ExclamationIcon"
import useCurrentUser from "hooks/useCurrentUser"
import { formatDate } from "utils/formatDate"
import { useJwt } from "hooks/useJwt"
import { showToast } from "components/toast/functions"
import { ButtonVariant } from "components/button/types"
import useProductCatalogAPI from "hooks/useProductCatalogAPI"
import useSubscriptionAPI from "hooks/useSubscriptionAPI"
import { formatPrice } from "utils/formatPrice"
import { useFeatureFlag } from "hooks/useFeatureFlag"
import FEATURE_FLAGS from "feature-flags"

interface CancelPlanProps {
	openModal: boolean
	setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}

export default function CancelPlan({
	openModal,
	setOpenModal,
}: CancelPlanProps) {
	const [selecteds, setSelecteds] = useState<string[]>([])
	const [steps, setSteps] = useState(0)
	const user = useCurrentUser()
	const { subscriptionCancelPlan, subscriptionReactivatePlan } =
		useSubscriptionAPI()
	const { productCatalog, productCatalogV2 } = useProductCatalogAPI()
	const queryClient = useQueryClient()
	const [jwt] = useJwt()

	const subscriptionCancelPlanMutation = useMutation({
		mutationFn: subscriptionCancelPlan,
		onSuccess: () => queryClient.invalidateQueries(["current-user", jwt]),
	})
	const subscriptionReactivatePlanMutation = useMutation({
		mutationFn: subscriptionReactivatePlan,
		onSuccess: handleSuccessReactivePlan,
	})

	const { data: newBussinessModel } = useFeatureFlag(
		FEATURE_FLAGS.newBussinessModel,
	)
	const { data: productCatalogListV1 } = useQuery(
		["product-catalog"],
		() => productCatalog(),
		{
			refetchOnWindowFocus: false,
		},
	)
	const { data: productCatalogListV2 } = useQuery(
		["product-catalog-v2"],
		() => productCatalogV2(),
		{
			refetchOnWindowFocus: false,
		},
	)

	const productCatalogList = newBussinessModel
		? productCatalogListV2
		: productCatalogListV1

	const benefits =
		productCatalogList?.professional_plan.marketing_features.map(
			(benefit) => benefit.description,
		)

	const LottieComponent = Lottie as any

	const lottieOptions = {
		loop: true,
		autoplay: true,
		animationData: check,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
			className: "cursor-default",
		},
	}

	const cancelOptions = [
		{
			title: "Dificuldades técnicas",
			id: "1",
		},
		{
			title: "Caro demais",
			id: "2",
		},
		{
			title: "Dificuldade na usabilidade",
			id: "3",
		},
		{
			title: "Suporte insatisfatório",
			id: "4",
		},
		{
			title: "Recursos limitados",
			id: "5",
		},
		{
			title: "Gostei mais de outra plataforma",
			id: "6",
		},
	]

	// const promotionPlan = {
	// 	containerClassName: "!w-full",
	// 	selected: false,
	// 	isSale: true,
	// 	title: "Profissional",
	// 	titleColor: "text-[#DDB011]",
	// 	monthly: {
	// 		credits: "80",
	// 		price: "119,00",
	// 		discount: "397,00",
	// 		creditsPerMonth: true,
	// 	},
	// 	yearly: {
	// 		credits: "80",
	// 		price: "119,00",
	// 		discount: "397,00",
	// 		creditsPerMonth: true,
	// 	},
	// 	allowed: professionalBenefits,
	// 	noAllowed: [],
	// 	tag: (
	// 		<Tag
	// 			text="70% OFF"
	// 			background="bg-semi-white-3"
	// 			textColor="text-light-blue-4"
	// 			className="px-2 py-2"
	// 		/>
	// 	),
	// }

	function handleCancelPlan() {
		subscriptionCancelPlanMutation
			.mutateAsync()
			.then(() => setSteps(steps + 1))
	}

	function handleKeepPlan() {
		subscriptionReactivatePlanMutation
			.mutateAsync()
			.then(() => setOpenModal(false))
	}
	function handleSuccessReactivePlan() {
		queryClient.invalidateQueries(["current-user", jwt])
		showToast("Sucesso! Sua assinatura será mantida.")
	}

	useEffect(() => {
		if (steps === 3) {
			setTimeout(() => {
				setOpenModal(false)
			}, 1300)
		}
	}, [steps])

	function onSelect(id: string) {
		const isSelected = selecteds.includes(id)
		if (isSelected) {
			const filtered = selecteds.filter((item) => item !== id)
			setSelecteds(filtered)
		} else {
			setSelecteds([...selecteds, id])
		}
	}

	const billingTypeIsMonthly = user?.subscription?.billing_type === "monthly"

	return (
		<Modal
			openModal={openModal}
			setOpenModal={setOpenModal}
			size={steps === 0 ? "xl" : steps === 3 ? "425" : "896"}
			width="w-[100%]"
		>
			<span className="prose max-w-none font-lato">
				{steps === 0 && (
					<div className="relative">
						<div
							className="absolute top-0 right-0 cursor-pointer"
							onClick={() => setOpenModal(false)}
						>
							<CloseIcon
								className=" fill-dark-blue-1"
								size="24"
							/>
						</div>
						<h3 className=" font-semibold mb-8">Gerenciar Plano</h3>
						<p className="text-cta-1  text-brand-gray-3">Plano</p>
						<p className="text-cta-1  text-dark-blue-1 mb-8">
							{user?.subscription?.product_name}:{" "}
							{billingTypeIsMonthly
								? user?.subscription?.credits
								: user?.subscription?.credits &&
								  user?.subscription?.credits / 12}{" "}
							créditos/mês - R${" "}
							{billingTypeIsMonthly
								? formatPrice(user?.subscription?.amount)
								: formatPrice(
										(user?.subscription?.amount as number) /
											12,
								  )}
							/mês
						</p>

						<div
							className={
								user?.subscription?.cancel_at_period_end
									? "w-full"
									: "flex items-center justify-between w-full"
							}
						>
							<div>
								<p className="text-cta-1  text-brand-gray-3 ">
									Seu plano renova em:
								</p>
								{!user?.subscription?.cancel_at_period_end &&
									user?.subscription?.end_period && (
										<p className="text-cta-1  text-dark-blue-1">
											{
												formatDate(
													user?.subscription
														?.end_period,
												).split(" ")[0]
											}
										</p>
									)}
								{user?.subscription?.cancel_at_period_end && (
									<div className="bg-brand-orange-opacity p-4 mt-2 mb-8">
										<div className="flex">
											<ExclamationIcon
												className="fill-brand-orange mr-2 mt-1 flex"
												size="20"
											/>{" "}
											<p className="font-semibold text-brand-orange w-[426px]">
												Voce solicitou o cancelamento do
												seu plano. Seu plano cancela em:{" "}
												<span className="font-bold">
													{
														formatDate(
															user?.subscription
																?.end_period,
														).split(" ")[0]
													}
												</span>
											</p>
										</div>
									</div>
								)}
							</div>
							{!user?.subscription?.cancel_at_period_end && (
								<Button
									variant={ButtonVariant.Text}
									className=" flex items-center justify-center"
									onClick={() => setSteps(steps + 1)}
								>
									Cancelar Assinatura
								</Button>
							)}
						</div>
						{user?.subscription?.cancel_at_period_end && (
							<Button
								variant={ButtonVariant.Contained}
								className="w-full flex items-center justify-center"
								onClick={handleKeepPlan}
							>
								Manter Assinatura
							</Button>
						)}
					</div>
				)}

				{steps === 1 && (
					<div className="flex p-2 w-100">
						<div className="bg-[#FEE] p-8 rounded-lg w-[404px] h-[428px]">
							<div className="font-lato font-semibold text-dark-blue-1 text-[20.998px] mb-8">
								Ao fazer o cancelamento do plano Profissional
								você perderá:
							</div>
							{benefits?.map((benefit) => (
								<div
									className="flex items-center mb-6"
									key={benefit}
								>
									<CloseIcon
										className="fill-[#D01313] mr-2"
										size="24px"
									/>
									<p className="text-cta-1 text-dark-blue-1">
										{benefit}
									</p>
								</div>
							))}
						</div>
						<div className="w-[404px] h-[428px] ml-6">
							<img
								className="mb-6"
								src={cancelPlan1}
								width={404}
								height={180}
							/>

							<h3 className=" font-semibold mb-2">
								Tem certeza que deseja cancelar sua assinatura?
							</h3>
							<h5 className="text-cta-1 mb-[32px] text-brand-gray-3">
								Ficamos tristes com sua partida. É importante
								lembrar alguns benefícios que você perderá antes
								de prosseguir com o cancelamento da sua
								assinatura.
							</h5>
							<div className="flex justify-between">
								<Button
									variant={ButtonVariant.Text}
									className="w-full flex items-center justify-center"
									onClick={() => setSteps(steps + 1)}
								>
									Cancelar assinatura
								</Button>
								<Button
									variant={ButtonVariant.Contained}
									className="w-full flex items-center justify-center"
									onClick={() => setOpenModal(false)}
								>
									Manter assinatura
								</Button>
							</div>
						</div>
					</div>
				)}

				{/* {steps === 2 && (
					<div className="flex p-2 w-100">
						<div className="flex p-4 flex-col bg-white rounded-lg border border-brand-gray-1  border-none outline outline-offset-2 outline-light-blue-4 w-[404px] h-[428px]">
							<div>
								<div className="flex items-center justify-between">
									<h3
										className={`mr-3 font-bold font-lato ${promotionPlan.titleColor}`}
									>
										{promotionPlan.title}
									</h3>

									{promotionPlan.tag && promotionPlan.tag}
								</div>
								<div className="flex items-center">
									<CreditIcon size="32px" />
									<p
										className={
											"text-h1 font-extrabold font-lato ml-2"
										}
									>
										{promotionPlan?.yearly?.credits}
									</p>

									<h3
										className={`font-normal font-lato ml-2 `}
									>
										créditos
									</h3>

									<p
										className={`text-cta-2 text-brand-gray-2 ml-2 mt-[2px] font-lato`}
									>
										/mês
									</p>
								</div>

								<div className="mb-4 flex items-center">
									<p
										className={`text-h3 font-semibold text-transparent bg-clip-text bg-gradient-to-r  border-light-blue-4 from-light-blue-4 to-light-blue-5 `}
									>
										R$
										{promotionPlan?.yearly?.price}
										<span className="text-[16px] font-semibold  ">
											/
										</span>
										<span className="text-[16px] font-semibold   ">
											mês
										</span>
									</p>

									<p className="text-cta-2 text-brand-gray-2 line-through ml-2 mt-[8px] font-lato">
										R$
										{promotionPlan?.monthly?.discount}
										/mês
									</p>
								</div>
								<div>
									{promotionPlan.allowed.map((item) => (
										<PlanBenefits
											key={item}
											item={item}
											allowed
										/>
									))}
									{promotionPlan.noAllowed.map((item) => (
										<PlanBenefits key={item} item={item} />
									))}
								</div>
							</div>
						</div>
						<div className="w-[404px] h-[428px] ml-6">
							<img
								src={cancelPlan2}
								width={486}
								height={180}
								className="h-[180px]"
							/>

							<h3 className="font-semibold mb-2 mt-6 font-lato">
								Estamos oferecendo um desconto especial pra você
								pelos próximos 2 meses!
							</h3>
							<div>
								<h5 className="text-brand-gray-3 font-lato">
									Assine o plano
									<span className="font-bold">
										{" "}
										Profissional{" "}
									</span>
									agora para garantir esse desconto de
									<span className="font-bold"> 70% OFF</span>.
								</h5>
							</div>
							<div className="flex justify-between mt-2">
								<Button
									variant={ButtonVariant.Text}
									className="w-full flex items-center justify-center"
									onClick={() => setSteps(steps + 1)}
								>
									Cancelar assinatura
								</Button>
								<Button
									variant={ButtonVariant.Contained}
									className="w-full flex items-center justify-center"
									onClick={() => setOpenModal(false)}
								>
									Aceitar oferta
								</Button>
							</div>
						</div>
					</div>
				)} */}

				{steps === 2 && (
					<div className="flex p-2">
						<div className="w-[404px] h-[508px] mr-6">
							<img
								className="mb-6"
								src={downgrade}
								width={405}
								height={268}
							/>
							<h3 className=" font-semibold mb-1 font-lato">
								Por que você quer cancelar sua assinatura?
							</h3>
							<p className="text-cta-1 mb-6 text-brand-gray-3 font-lato">
								Sentimos muito vê-lo partir. Ajude-nos a
								melhorar nosso serviço
							</p>
							<p className="text-cta-2 text-brand-gray-3 font-lato">
								Se precisar de alguma assistência adicional,
								entre em contato conosco pelo chat online ou
								pelo e-mail:{" "}
								<a
									className="text-light-blue-1 cursor-pointer"
									href="mailto:suporte@lawdeck.ai"
								>
									suporte@lawdeck.ai.
								</a>
							</p>
						</div>
						<div className="w-[404px] h-[508px] flex flex-col justify-between">
							<div className="p-8 rounded-md border-[2px] border-solid border-brand-white-3">
								<div>
									{cancelOptions.map((item) => (
										<div
											className="flex items-center mb-4"
											key={item.id}
										>
											<input
												type="checkbox"
												className="rounded-sm focus:shadow-none focus:ring-0 focus:outline-none"
												onClick={() =>
													onSelect(item.id)
												}
											/>
											<p className="text-cta-2 ml-2">
												{item.title}
											</p>
										</div>
									))}
								</div>
								<div className="mt-[-16px] flex">
									<TextareaAutosize
										id="textarea"
										className={`border-1 !outline-offset-0 border-1  min-h-[90px] px-4 py-2 mt-[24px] resize-none w-full
							  rounded-md border-gray-100 bg-white shadow-outlined focus:outline-light-blue-4 focus:outline focus:outline-2`}
										placeholder="Deixe um feedback (opcional)"
										lang="pt-br"
										maxRows={4}
									/>
								</div>
							</div>
							<div className="flex justify-between">
								<Button
									variant={ButtonVariant.Text}
									className="w-full flex items-center justify-center"
									onClick={() => setOpenModal(false)}
								>
									Manter assinatura
								</Button>
								<Button
									variant={ButtonVariant.Contained}
									className="w-full flex items-center justify-center"
									disabled={!(selecteds.length > 0)}
									onClick={handleCancelPlan}
								>
									Cancelar assinatura
								</Button>
							</div>
						</div>
					</div>
				)}

				{steps === 3 && (
					<div className="w-full h-full flex items-center justify-center flex-col">
						<LottieComponent
							options={lottieOptions}
							height={140}
							width={140}
							isClickToPauseDisabled
						/>
						<h3 className="font-semibold mb-4 text-center">
							Sua assinatura foi cancelada com sucesso!
						</h3>
					</div>
				)}
			</span>
		</Modal>
	)
}

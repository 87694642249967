import Paper from "components/paper"
import BlueCheckMarkIcon from "icons/BlueCheckMarkIcon"
import { useEffect, useRef, useState } from "react"
import TextareaAutosize from "react-textarea-autosize"
import _ from "lodash"
import { Button } from "components/button"
import PadlockIcon from "icons/PadlockIcon"
import CheckIcon from "icons/CheckIcon"
import DraggableIcon from "icons/DraggableIcon"
import { useAtom } from "jotai"
import Tooltip from "components/tooltip"
import OutlineEditIcon from "icons/OutlineEditIcon"
import ArrowRight from "icons/ArrowRight"
import ArrowLeft from "icons/ArrowLeft"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { Strategy } from "./ChooseStrategiesStep"
import MagicWandIcon from "icons/MagicWandIcon"
import { Dropdown } from "components/dropdown"
import FlyoutMenu from "components/flyout-menu"
import RewriteLabel from "components/rewrite-label"
import Lottie from "react-lottie"
import magicWandLoading from "lotties/varinha_magica_-_loading.json"
import { RetrieveLegalDocumentResponse, ToneEnum } from "types/legalDocument"
import { AxiosError } from "axios"
import { useLongPoll } from "hooks/useLongPoll"
import { getDocumentStatus } from "utils/getDocumentStatus"
import { useClauseContentContext } from "hooks/useClauseContentContext"
import { TooltipPosition } from "components/tooltip/enums"
import { isStrategyAtom } from "pages/SidebarPages/subcomponents/atoms"
import { plansModalAtom } from "pages/BasePage/atoms"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"
import { ButtonVariant } from "components/button/types"
import useClauseAPI from "hooks/useClauseAPI"
import useCheckIfHasCredits from "hooks/useCheckIfHasCredits"

interface ClauseCardProps {
	thesis: any
	isContainerHovered?: boolean
	selected?: boolean
	number?: number
	documentId: string
	isLoading: boolean
	setIsLoading: (value: boolean) => void
	noRomanNumber?: boolean
	legalDocument: RetrieveLegalDocumentResponse
}
function numberToRoman(number: number): string {
	const numbers = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1]
	const romanNumbers = [
		"M",
		"CM",
		"D",
		"CD",
		"C",
		"XC",
		"L",
		"XL",
		"X",
		"IX",
		"V",
		"IV",
		"I",
	]

	let result = ""

	for (let i = 0; i < numbers?.length; i++) {
		while (number >= numbers[i]) {
			result += romanNumbers[i]
			number -= numbers[i]
		}
	}

	return result
}
const LottieComponent = Lottie as any

const lottieOptions = {
	loop: true,
	autoplay: true,
	animationData: magicWandLoading,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
		className: "cursor-default",
	},
}

const ClauseCard = ({
	thesis,
	selected,
	number,
	isContainerHovered,
	documentId,
	isLoading,
	setIsLoading,
	noRomanNumber,
	legalDocument,
	...rest
}: ClauseCardProps) => {
	const [openCard, setOpenCard] = useState(false)
	const [isEdit, setIsEdit] = useState(false)
	const [cardText, setCardText] = useState(thesis.text?.text)
	const [version, setVersion] = useState(thesis?.text?.version || 1)
	const isProcessing = getDocumentStatus(legalDocument) === "processing"

	const [isHovered, setIsHovered] = useState(false)
	const [showRewriteSection, setShowRewriteSection] = useState(false)
	const [open, setOpen] = useState(false)
	const [isHoveredTonality, setIsHoveredTonality] = useState(false)
	const [, setIsStrategy] = useAtom(isStrategyAtom)
	const ref = useRef(false)
	const textAreaRef = useRef<HTMLTextAreaElement>(null)
	const [, setOpenPlansModal] = useAtom(plansModalAtom)
	const hasCredits = useCheckIfHasCredits()
	const {
		createNewClauseRevision,
		selectClauseVersion,
		correctSpellingClause,
		improveClause,
		increaseClause,
		rewriteClause,
		summarizeClause,
		clauseAIEditor,
	} = useClauseAPI()

	const queryClient = useQueryClient()
	const { clausesContent, setClausesContent } = useClauseContentContext()

	const updateTheseMutation = useMutation({
		mutationFn: createNewClauseRevision,
	})

	const clauseAIEditorMutation = useMutation({
		mutationFn: clauseAIEditor,
		onSuccess: handleSuccessMutation,
		onError: (error: AxiosError<any>) => {
			if (error.response?.status === 403) handleErrorMutation()
		},
	})

	const correctSpellingThesisMutation = useMutation({
		mutationFn: correctSpellingClause,
		onSuccess: handleSuccessMutation,
		onError: (error: AxiosError<any>) => {
			if (error.response?.status === 403) handleErrorMutation()
		},
	})

	const improveThesisMutation = useMutation({
		mutationFn: improveClause,
		onSuccess: handleSuccessMutation,
		onError: (error: AxiosError<any>) => {
			if (error.response?.status === 403) handleErrorMutation()
		},
	})

	const increaseThesisMutation = useMutation({
		mutationFn: increaseClause,
		onSuccess: handleSuccessMutation,
		onError: (error: AxiosError<any>) => {
			if (error.response?.status === 403) handleErrorMutation()
		},
	})

	const rewriteThesisMutation = useMutation({
		mutationFn: rewriteClause,
		onSuccess: handleSuccessMutation,
		onError: (error: AxiosError<any>) => {
			if (error.response?.status === 403) handleErrorMutation()
		},
	})

	const summarizeThesisMutation = useMutation({
		mutationFn: summarizeClause,
		onSuccess: handleSuccessMutation,
		onError: (error: AxiosError<any>) => {
			if (error.response?.status === 403) handleErrorMutation()
		},
	})

	const { data } = useQuery(
		[`version-${thesis.id}`, version],
		() => selectClauseVersion(thesis.id, version),
		{
			enabled: thesis?.text?.version > 1,
		},
	)

	const handleClick = () => {
		if (selected || isLoading || ref.current || isProcessing) return

		const addedClause = _.find(
			clausesContent,
			(clause) => clause.id === thesis.id,
		)
		if (addedClause) {
			setClausesContent(
				clausesContent.filter((clause) => clause.id !== thesis.id),
			)
		} else {
			setClausesContent([...clausesContent, { id: thesis.id, version }])
		}
	}
	const active =
		selected ||
		_.find(
			clausesContent,
			(clauseContent) => clauseContent.id === thesis.id,
		)

	const baseClassName = `shadow-1 mb-6  rounded-md py-6 px-5 text-[#020812] ${
		!isLoading &&
		!isProcessing &&
		"hover:cursor-pointer hover:shadow-outlined-hover hover:text-dark-blue-2"
	} `

	const className = active
		? `${baseClassName} bg-slate-200 border-2 border-light-blue-1`
		: `${baseClassName} border-2 border-transparent`

	const isOpenCard = openCard ? "rounded-md" : "max-h-[64px] rounded-t-md"

	const flyoutSections = [
		{
			label: "Assertiva",
			onClick: () => {
				if (!hasCredits) {
					handleNoBalance()
					return
				}
				handleBeforeMutate()

				handleAIEditor("change_writing_tone", ToneEnum.Assertive)
			},
			className:
				"p-2 w-[170px] hover:bg-[#f6f6f6] hover:text-dark-blue-2",
		},
		{
			label: "Persuasiva",
			onClick: () => {
				if (!hasCredits) {
					handleNoBalance()
					return
				}
				handleBeforeMutate()
				handleAIEditor("change_writing_tone", ToneEnum.Persuasive)
			},
			className:
				"p-2 w-[170px] hover:bg-[#f6f6f6] hover:text-dark-blue-2",
		},
		{
			label: "Empática",
			onClick: () => {
				if (!hasCredits) {
					handleNoBalance()
					return
				}
				handleBeforeMutate()
				handleAIEditor("change_writing_tone", ToneEnum.Empathetic)
			},
			className:
				"p-2 w-[170px] hover:bg-[#f6f6f6] hover:text-dark-blue-2",
		},
		{
			label: "Jornalista",
			onClick: () => {
				if (!hasCredits) {
					handleNoBalance()
					return
				}
				handleBeforeMutate()
				handleAIEditor("change_writing_tone", ToneEnum.Journalistic)
			},
			className:
				"p-2 w-[170px] hover:bg-[#f6f6f6] hover:text-dark-blue-2",
		},
		{
			label: "Entusiástica",
			onClick: () => {
				if (!hasCredits) {
					handleNoBalance()
					return
				}
				handleBeforeMutate()
				handleAIEditor("change_writing_tone", ToneEnum.Enthusiastic)
			},
			className:
				"p-2 w-[170px] hover:bg-[#f6f6f6] hover:text-dark-blue-2",
		},
		{
			label: "Narrativa",
			onClick: () => {
				if (!hasCredits) {
					handleNoBalance()
					return
				}
				handleBeforeMutate()
				handleAIEditor("change_writing_tone", ToneEnum.Narrative)
			},
			className:
				"p-2 w-[170px] hover:bg-[#f6f6f6] hover:text-dark-blue-2",
		},
	]

	const sectionItems = [
		{
			label: "Reescrever",
			type: "rewrite",
			className:
				"flex items-center w-[186px] p-2 hover:bg-[#f6f6f6] hover:text-dark-blue-2",
			onClick: handleRewrite,
			onMouseEnter: handleMouseLeave,
			icon: <MagicWandIcon className="fill-[#AE30FC]" size="16px" />,
		},
		{
			label: "Reescrever Como..",
			type: "rewrite_as",
			className:
				"flex items-center w-[186px] p-2 hover:bg-[#f6f6f6] hover:text-dark-blue-2",
			onClick: () => setShowRewriteSection(true),
			onMouseEnter: handleMouseLeave,
			icon: <MagicWandIcon className="fill-[#AE30FC]" size="16px" />,
		},
		{
			label: "Corrigir ortografia",
			className:
				"flex items-center w-[186px] p-2 hover:bg-[#f6f6f6] hover:text-dark-blue-2",
			type: "correct_spelling ",
			onClick: handleCorrectSpelling,
			onMouseEnter: handleMouseLeave,
			icon: <MagicWandIcon className="fill-[#AE30FC]" size="16px" />,
		},
		{
			label: "Melhorar",
			type: "improve",
			className:
				"flex items-center w-[186px] p-2 hover:bg-[#f6f6f6] hover:text-dark-blue-2",
			onClick: handleImprove,
			onMouseEnter: handleMouseLeave,
			icon: <MagicWandIcon className="fill-[#AE30FC]" size="16px" />,
		},
		{
			label: "Acrescentar",
			type: "increase",
			className:
				"flex items-center w-[186px] p-2 hover:bg-[#f6f6f6] hover:text-dark-blue-2",
			onClick: handleIncrease,
			onMouseEnter: handleMouseLeave,
			icon: <MagicWandIcon className="fill-[#AE30FC]" size="16px" />,
		},
		{
			label: "Reduzir",
			type: "summarize",
			className:
				"flex items-center w-[186px] p-2 hover:bg-[#f6f6f6] hover:text-dark-blue-2",
			onClick: handleSummarize,
			onMouseEnter: handleMouseLeave,
			icon: <MagicWandIcon className="fill-[#AE30FC]" size="16px" />,
		},
		{
			label: (
				<FlyoutMenu
					isOpen={isHoveredTonality}
					setIsOpen={setIsHoveredTonality}
					sections={flyoutSections}
				>
					Tonalidade
				</FlyoutMenu>
			),
			type: "change_writing_tone",
			className: `flex items-center w-[186px] p-2 hover:bg-[#f6f6f6] hover:text-dark-blue-2 ${
				isHoveredTonality ? "bg-[#f6f6f6] text-dark-blue-2" : ""
			}`,

			onMouseEnter: handleMouseEnter,
			icon: <MagicWandIcon className="fill-[#AE30FC]" size="16px" />,
			rightIcon: (
				<div className="ml-[64px]">
					<ArrowRight />
				</div>
			),
		},
	]

	const sections = [
		{
			title: "Pedir IA para:",
			titleClassName:
				"!text-[10px] font-[500px] text-[#ACACAC] w-[186px]",
			items: sectionItems.map((item) => {
				if (item.type === "rewrite_as") {
					return {
						...item,
						onClick: () => setShowRewriteSection(true),
					}
				}
				if (item.type === "change_writing_tone") {
					return {
						...item,
						onClick: null,
					}
				}
				return {
					...item,
					onClick: () => handleAIEditor(item.type),
				}
			}),
		},
	]

	const rewriteSection = [
		{
			title: (
				<div className="flex justify-between pl-2 pt-2 pr-2">
					<div>Dê instruções pra IA:</div>
					<div>Enter</div>
				</div>
			),
			titleClassName: "!text-[12px] text-[#ACACAC] !cursor-auto",
			items: [
				{
					label: (
						<RewriteLabel
							setShowRewriteSection={setShowRewriteSection}
							setOpen={setOpen}
							onMutate={(event) =>
								handleAIEditor("rewrite_as", event)
							}
						/>
					),
					className: "flex items-center p-4 pt-1 !cursor-auto",
					labelClassName: "w-full",
					onMouseEnter: handleMouseLeave,
				},
			],
		},
	]

	function handleMouseEnter() {
		setIsHoveredTonality(true)
	}

	function handleMouseLeave() {
		setIsHoveredTonality(false)
	}

	function onChangeVersion(type: "next" | "previous") {
		const newVersion = type === "next" ? version + 1 : version - 1

		setVersion(newVersion)
	}

	function handleBeforeMutate() {
		setCardText("")
		setIsLoading(true)
		setIsEdit(false)
		setOpen(false)
	}

	function handleRewrite() {
		if (!hasCredits) return handleNoBalance()
		handleBeforeMutate()
		rewriteThesisMutation.mutateAsync(thesis.id)
	}

	function handleCorrectSpelling() {
		if (!hasCredits) return handleNoBalance()
		handleBeforeMutate()
		correctSpellingThesisMutation.mutateAsync(thesis.id)
	}

	function handleImprove() {
		if (!hasCredits) return handleNoBalance()
		handleBeforeMutate()
		improveThesisMutation.mutateAsync(thesis.id)
	}

	function handleIncrease() {
		if (!hasCredits) return handleNoBalance()
		handleBeforeMutate()
		increaseThesisMutation.mutateAsync(thesis.id)
	}

	function handleSummarize() {
		if (!hasCredits) return handleNoBalance()
		handleBeforeMutate()
		summarizeThesisMutation.mutateAsync(thesis.id)
	}

	async function handleAIEditor(type: string, userInput?: string) {
		handleBeforeMutate()
		let payload = {}

		if (type === "rewrite_as" && userInput) {
			payload = {
				rewrite_as: userInput,
			}
		}

		if (type === "change_writing_tone" && userInput) {
			payload = {
				tone: userInput,
			}
		}

		await clauseAIEditorMutation.mutateAsync({
			id: thesis.id,
			payload: {
				...payload,
				action: type,
			},
		})
	}

	function handleSuccessMutation() {
		setTimeout(() => {
			queryClient.invalidateQueries({
				queryKey: ["listClauses", documentId],
			})

			queryClient.invalidateQueries({
				queryKey: ["legalDocument", documentId],
			})
		}, 500)
	}

	function handleNoBalance() {
		setOpenPlansModal(true)
		showToast(
			"Você não tem créditos para concluir essa ação.",
			ToastType.Error,
		)
	}

	function handleErrorMutation() {
		setIsLoading(false)
		setCardText(thesis.text?.text)
		setShowRewriteSection(false)
		handleNoBalance()
	}

	async function handeSaveEdit() {
		setIsEdit(false)
		await updateTheseMutation
			.mutateAsync({
				id: thesis.id,
				payload: {
					text: cardText,
				},
			})
			.then(() =>
				queryClient.setQueryData(
					["listClauses", documentId],
					(oldDocuments: Strategy[] | undefined) =>
						oldDocuments?.map((doc) => {
							if (doc?.id === thesis?.id) {
								return {
									...doc,
									text: {
										...doc?.text,
										text: cardText,
										version:
											parseInt(thesis.text?.version) + 1,
									},
								}
							}
							return doc
						}),
				),
			)
			.catch(() => {
				setIsEdit(true)
			})
	}

	useEffect(() => {
		setIsStrategy(true)
		return () => setIsStrategy(false)
	}, [])

	useEffect(() => {
		if (thesis.is_object_clause)
			setClausesContent([{ id: thesis.id, version }])
	}, [thesis.id])

	useEffect(() => {
		const clauseIndex = clausesContent.findIndex(
			(clauseContent) => clauseContent.id === thesis.id,
		)
		if (clauseIndex !== -1) {
			const newClausesContent = [...clausesContent]
			newClausesContent[clauseIndex].version = version
			setClausesContent(newClausesContent)
		}
	}, [version])

	useEffect(() => {
		ref.current = false
	}, [open])

	useEffect(() => {
		if (isEdit) {
			setOpenCard(true)
		}
		if (textAreaRef.current) {
			const length = cardText?.length || 0
			textAreaRef.current.focus()
			textAreaRef.current.setSelectionRange(length, length)
		}
	}, [isEdit])

	useEffect(() => {
		if (data && data.revision) {
			setCardText(data.revision.text)
			if (data.revision.version > 1 && data.revision.text === "")
				setIsLoading(true)
			if (data.revision.text !== "") {
				if (isLoading) {
					queryClient.invalidateQueries({
						queryKey: ["legalDocument", documentId],
					})
				}

				setIsLoading(false)
			}
		}
	}, [data])

	useLongPoll({
		conditionToStop: !!data?.revision.text,
		queryKey: [`version-${thesis.id}`, version],
		deps: [data?.revision.text],
		ms: 3000,
	})

	return (
		<div className="flex box-content  ml-[-24px]">
			<div className="flex justify-center items-center my-auto w-[24px]">
				<div
					className={
						isContainerHovered && !selected && !isLoading
							? ""
							: "hidden"
					}
				>
					<DraggableIcon />
				</div>
			</div>
			<Paper
				className={`w-full ${className}`}
				onClick={handleClick}
				{...rest}
			>
				<div className={`flex items-center justify-between mb-5`}>
					<div
						className={`flex items-center gap-2 ${
							isLoading && "opacity-40"
						} ${isProcessing && "opacity-40"}`}
					>
						{active && !selected ? (
							<BlueCheckMarkIcon size="16" viewBox="4 4 16 16" />
						) : !selected ? (
							<div className="border w-[16px] h-[16px] rounded-full border-current flex items-center justify-center">
								{isContainerHovered && !isLoading && (
									<CheckIcon
										size="14"
										className="fill-current mt-[1px]"
									/>
								)}
							</div>
						) : null}
						{selected && (
							<PadlockIcon className="fill-light-blue-1" />
						)}
						<h4
							className={`font-semibold text-current text-[16px] ${
								active && "text-light-blue-1"
							}`}
						>
							{!noRomanNumber && number && number > 0
								? `${numberToRoman(number)} - `
								: ""}{" "}
							{thesis.title}
						</h4>
					</div>
					{!isEdit && (
						<div className="flex items-center">
							{thesis.text?.version > 1 && (
								<div className="flex items-center mr-4">
									<div
										onClick={(event) => {
											event.stopPropagation()
											if (
												version === 1 ||
												isLoading ||
												isProcessing
											)
												return
											onChangeVersion("previous")
										}}
										className="w-6 h-6 flex items-center justify-center "
									>
										<ArrowLeft
											className={`${
												version === 1 ||
												isLoading ||
												isProcessing
													? "fill-[#ACACAC]"
													: "fill-[#646464]"
											} ${
												version !== 1 &&
												!isLoading &&
												!isProcessing &&
												"hover:fill-[#003659]"
											}`}
										/>
									</div>
									<p
										className={
											"text-brand-gray-3 select-none"
										}
									>
										<span
											className={`${
												version === 1 ||
												isLoading ||
												isProcessing
													? "text-brand-gray-2"
													: "text-brand-gray-3"
											}`}
										>
											{version}
										</span>
										<span
											className={`${
												isLoading || isProcessing
													? "text-brand-gray-2"
													: "text-brand-gray-3"
											}`}
										>
											/
										</span>
										<span
											className={`${
												version ===
													thesis.text?.version ||
												isLoading ||
												isProcessing
													? "text-brand-gray-2"
													: "text-brand-gray-3"
											}`}
										>
											{thesis.text?.version}
										</span>
									</p>

									<div
										onClick={(event) => {
											event.stopPropagation()
											if (
												version ===
													thesis.text?.version ||
												isLoading ||
												isProcessing
											)
												return
											onChangeVersion("next")
										}}
										className="w-6 h-6 flex items-center justify-center"
									>
										<ArrowRight
											className={`${
												version ===
													thesis.text?.version ||
												isLoading ||
												isProcessing
													? "fill-[#ACACAC]"
													: "fill-[#646464]"
											} ${
												version !==
													thesis.text?.version &&
												!isLoading &&
												!isProcessing &&
												"hover:fill-[#003659]"
											}`}
										/>
									</div>
								</div>
							)}
							<div className="flex items-center gap-4">
								<div
									className={
										isLoading || isProcessing
											? "opacity-40"
											: ""
									}
									onClick={(event) => {
										event.stopPropagation()
										if (!isLoading && !isProcessing)
											setIsEdit(true)
									}}
								>
									{isLoading || isProcessing ? (
										<OutlineEditIcon fill={"#020812"} />
									) : (
										<Tooltip
											text="Editar"
											position={TooltipPosition.Above}
										>
											<div
												className="text-dark-blue-1 hover:text-dark-blue-2 cursor-pointer"
												onMouseEnter={() =>
													setIsHovered(true)
												}
												onMouseLeave={() =>
													setIsHovered(false)
												}
											>
												<OutlineEditIcon
													fill={
														isHovered
															? "#003659"
															: "#020812"
													}
												/>
											</div>
										</Tooltip>
									)}
								</div>

								{/* <Tooltip
									text="Usar IA"
									position={TooltipPosition.Above}
								>
									<div className="bg-clip-text bg-gradient-to-r from-light-purple-gradient-from to-light-purple-gradient-to">
								<MagicWandIcon className="fill-[#AE30FC] hover:fill-[#D289FF]" />
								</div>
							</Tooltip> */}
								{isLoading ? (
									<LottieComponent
										options={lottieOptions}
										height={38}
										width={24}
										isClickToPauseDisabled
									/>
								) : (
									<div
										onClick={() => {
											ref.current = true
										}}
									>
										{isProcessing ? (
											<MagicWandIcon className="fill-brand-gray-2 " />
										) : (
											<Dropdown
												sections={
													showRewriteSection
														? rewriteSection
														: sections
												}
												className={`right-0 ${
													showRewriteSection
														? "w-[280px]"
														: ""
												}`}
												open={open}
												setOpen={setOpen}
											>
												<Tooltip
													text="Usar IA"
													position={
														TooltipPosition.Above
													}
												>
													<div className="bg-clip-text bg-gradient-to-r from-light-purple-gradient-from to-light-purple-gradient-to">
														<MagicWandIcon className="fill-[#AE30FC] hover:fill-[#D289FF]" />
													</div>
												</Tooltip>
											</Dropdown>
										)}
									</div>
								)}
							</div>
						</div>
					)}
				</div>
				<div
					className={` overflow-hidden ${isOpenCard} ${
						openCard && isEdit && "border-[1px] border-light-blue-1"
					} bg-ice-white p-4 rounded-t-md relative`}
				>
					<TextareaAutosize
						ref={textAreaRef}
						id="textarea"
						className={`!ring-0 border-none !outline-offset-0 shadow-none  w-full overflow-hidden bg-transparent outline-none p-0 m-0 resize-none ${
							isLoading && "h-[8px]"
						}`}
						lang="pt-br"
						value={cardText}
						data-cy="user-input-textarea"
						onChange={(event) => {
							setCardText(event.target.value)
						}}
						onClick={(event) => {
							event.stopPropagation()
						}}
						disabled={!isEdit}
					/>
					{/* The div below is used to overlay the textarea to make clicking possible, when editing it, logic must be implemented to hide it */}
					{!isEdit ? (
						<div className="absolute top-0 right-0 w-full h-full"></div>
					) : (
						<div className="flex items-center justify-end gap-4">
							<Button
								variant={ButtonVariant.Text}
								onClick={(event) => {
									event.stopPropagation()
									setCardText(thesis.text?.text)
									setOpenCard(false)
									setIsEdit(false)
								}}
							>
								Cancelar
							</Button>
							<Button
								variant={ButtonVariant.Contained}
								onClick={(event) => {
									event.stopPropagation()
									setOpenCard(false)
									handeSaveEdit()
								}}
								disabled={
									thesis.text?.text === cardText || !cardText
								}
							>
								Salvar
							</Button>
						</div>
					)}
				</div>
				<div
					className={`transition-all  delay-150 w-full ${
						openCard ? "h-0 " : "h-4"
					}  bg-ice-white rounded-b-md`}
				/>

				<div className="flex justify-end">
					{!isEdit && (
						<div className="flex  justify-center ">
							<Button
								variant={ButtonVariant.Text}
								onClick={(event) => {
									event.stopPropagation()
									setOpenCard(!openCard)
								}}
								disabled={isLoading}
							>
								{openCard ? "Esconder" : "Expandir"}
							</Button>
						</div>
					)}
				</div>
			</Paper>
		</div>
	)
}

export default ClauseCard

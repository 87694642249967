import React from "react"
import BaseIcon from "./BaseIcon"

const TributarioIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
		>
			<path d="M12.667 2C7.14408 2 2.66699 6.47709 2.66699 12C2.66699 17.5229 7.14408 22 12.667 22C18.1899 22 22.667 17.5229 22.667 12C22.667 6.47709 18.1899 2 12.667 2ZM13.5305 17.4218V18.8302H12.1833V17.4376C9.97262 17.1349 9.00455 15.3184 9.00455 15.3184L10.3806 14.1673C10.3806 14.1673 11.2593 15.6968 12.8486 15.6968C13.7266 15.6968 14.3926 15.2269 14.3926 14.424C14.3926 12.547 9.33549 12.7747 9.33549 9.29255C9.33549 7.77886 10.5327 6.68832 12.1826 6.4303V5.02326H13.5298V6.4303C14.6802 6.58167 16.0425 7.18715 16.0425 8.48961V9.48865H14.2564V9.00427C14.2564 8.50475 13.6199 8.17174 12.9078 8.17174C11.9996 8.17174 11.3343 8.62584 11.3343 9.26159C11.3343 11.184 16.3914 10.7147 16.3914 14.3634C16.3914 15.8634 15.2712 17.1638 13.5305 17.4218Z" />
		</BaseIcon>
	)
}

export default TributarioIcon

import {
	CreateLegalDocumentResponse,
	CreatePetitionRequestBody,
	GenerateLegalDocumentContentRequestBody,
	GenerateLegalDocumentContentResponse,
	RetrieveLegalDocumentResponse,
	UpdateLegalDocumentRequestBody,
	UpdateLegalDocumentResponse,
	GenerateThesesRequestBody,
	GenerateLegalDocumentContentV2RequestBody,
	GenerateLegalDocumentContentV2Response,
	GenerateContentV3Body,
} from "types/legalDocument"
import { GenerateThesesResponse, ThesesData } from "types/theses"
import { AxiosResponse } from "axios"
import axiosInstance from "axiosInstance"

const usePetitionAPI = () => {
	const generateTheses = async (
		id: string,
		payload: GenerateThesesRequestBody,
	) => {
		const response: AxiosResponse<GenerateThesesResponse> =
			await axiosInstance.post(
				`/petition/${id}/generate_theses/`,
				payload,
			)

		return response.data
	}

	const createPetition = async (payload: CreatePetitionRequestBody) => {
		const response: AxiosResponse<CreateLegalDocumentResponse> =
			await axiosInstance.post("/petition/", payload)

		return response.data
	}

	const updatePetition = async ({
		id,
		payload,
	}: {
		id: string
		payload: UpdateLegalDocumentRequestBody
	}) => {
		const response: AxiosResponse<UpdateLegalDocumentResponse> =
			await axiosInstance.patch(`/petition/${id}/`, payload)

		return response.data
	}

	const retrievePetition = async (id: string) => {
		if (!id) throw new Error("No id provided")

		const response: AxiosResponse<RetrieveLegalDocumentResponse> =
			await axiosInstance.get(`/petition/${id}/`)

		return response.data
	}

	const listTheses = async (id: string) => {
		if (!id) throw new Error("No id provided")

		const response: AxiosResponse<ThesesData[]> = await axiosInstance.get(
			`/petition/${id}/list_theses_v2/`,
		)

		return response.data
	}

	const generatePetitionContent = async (
		id: string,
		payload: GenerateLegalDocumentContentRequestBody,
	) => {
		const response: AxiosResponse<GenerateLegalDocumentContentResponse> =
			await axiosInstance.post(
				`/petition/${id}/generate_content/`,
				payload,
			)

		return response.data
	}

	const generatePetitionContentV2 = async (
		id: string,
		payload: GenerateLegalDocumentContentV2RequestBody,
	) => {
		const response: AxiosResponse<GenerateLegalDocumentContentV2Response> =
			await axiosInstance.post(
				`/v2/petition/${id}/generate_content/`,
				payload,
			)

		return response.data
	}
	const generatePetitionContentV3 = async (
		id: string,
		payload: GenerateContentV3Body,
	) => {
		const response: AxiosResponse<GenerateLegalDocumentContentV2Response> =
			await axiosInstance.post(
				`/v3/petition/${id}/generate_content/`,
				payload,
			)

		return response.data
	}

	const generateObjection = async (id: string) => {
		const response: AxiosResponse<GenerateLegalDocumentContentResponse> =
			await axiosInstance.post(`/petition/${id}/generate_objection/`)
		return response.data
	}

	const copyPetition = async (id: string) => {
		const response: AxiosResponse<RetrieveLegalDocumentResponse> =
			await axiosInstance.post(`/petition/${id}/create_copy/`)

		return response.data
	}

	const destroyTheses = async (id: string) => {
		await axiosInstance.delete(`/petition/${id}/destroy_theses/`)
	}

	const createStrategy = async ({
		id,
		payload,
	}: {
		id: string
		payload: {
			instructions: string
		}
	}) => {
		const response: AxiosResponse<any> = await axiosInstance.post(
			`/petition/${id}/create_strategy/`,
			payload,
		)

		return response.data
	}

	const createNewThesis = async (id: string) => {
		const response: AxiosResponse<GenerateLegalDocumentContentResponse> =
			await axiosInstance.post(`/petition/${id}/create_new_thesis/`)

		return response.data
	}

	const updatePetitionContent = async ({
		id,
		payload,
	}: {
		id: string
		payload: UpdateLegalDocumentRequestBody
	}) => {
		const response: AxiosResponse<UpdateLegalDocumentResponse> =
			await axiosInstance.patch(
				`/legal-document/${id}/update_document/`,
				payload,
			)

		return response.data
	}

	return {
		generateTheses,
		createPetition,
		updatePetition,
		retrievePetition,
		generatePetitionContent,
		generatePetitionContentV2,
		generatePetitionContentV3,
		generateObjection,
		copyPetition,
		listTheses,
		destroyTheses,
		createStrategy,
		createNewThesis,
		updatePetitionContent,
	}
}

export default usePetitionAPI

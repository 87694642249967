import BaseIcon from "./BaseIcon"

const FilterArrowUpIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
		>
			<path
				d="M8 2L3 7L3.705 7.705L7.5 3.915V14H8.5V3.915L12.295 7.705L13 7L8 2Z"
				fill="#020812"
			/>
		</BaseIcon>
	)
}

export default FilterArrowUpIcon

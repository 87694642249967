const PdfDocICon = () => {
	return (
		<svg
			width="56"
			height="56"
			viewBox="0 0 56 56"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0 4C0 1.79086 1.79086 0 4 0H28H40.3431C41.404 0 42.4214 0.421427 43.1716 1.17157L49 7L54.8284 12.8284C55.5786 13.5786 56 14.596 56 15.6569V28V52C56 54.2091 54.2091 56 52 56H4C1.79086 56 0 54.2091 0 52V4Z"
				className="fill-current"
			/>
			<g clipPath="url(#clip0_86_2758)">
				<path
					d="M43.4638 32.49C42.5158 31.48 40.5788 30.896 37.8338 30.896C36.3648 30.896 34.6668 31.042 32.8178 31.386C31.6878 30.2714 30.6594 29.0583 29.7448 27.761C29.0368 26.777 28.4168 25.735 27.8488 24.709C28.9318 21.324 29.4578 18.563 29.4578 16.574C29.4578 14.345 28.6508 12.022 26.3378 12.022C25.6238 12.022 24.9158 12.454 24.5358 13.09C23.4938 14.965 23.9628 19.08 25.7598 23.142C25.0744 25.2141 24.3187 27.2623 23.4938 29.283C22.713 31.1367 21.8562 32.9576 20.9258 34.741C15.7228 36.856 12.3528 39.319 12.0408 41.257C11.8998 41.986 12.1398 42.653 12.6498 43.189C12.8268 43.335 13.4938 43.913 14.6238 43.913C18.0768 43.913 21.7128 38.21 23.5668 34.767C24.9838 34.288 26.4058 33.85 27.8218 33.413C29.3182 32.9998 30.8341 32.6607 32.3638 32.397C36.0098 35.736 39.2228 36.262 40.8378 36.262C42.8278 36.262 43.5358 35.439 43.7758 34.767C44.1508 33.897 43.8698 32.939 43.4428 32.444L43.4688 32.496L43.4638 32.49ZM41.6198 33.896C41.4788 34.62 40.7658 35.104 39.7708 35.104C39.4898 35.104 39.2498 35.052 38.9638 35.01C37.1508 34.572 35.4538 33.651 33.7608 32.197C35.074 31.9765 36.4033 31.8651 37.7348 31.864C38.7188 31.864 39.5738 31.911 40.1458 32.057C40.8018 32.198 41.8438 32.64 41.6098 33.901H41.6358L41.6198 33.896ZM31.5888 31.62C30.2711 31.889 28.9616 32.1964 27.6618 32.542C26.5313 32.8491 25.411 33.1929 24.3028 33.573C24.8782 32.4335 25.4149 31.2748 25.9118 30.099C26.4848 28.74 26.9538 27.339 27.4278 26.036C27.8918 26.854 28.4118 27.677 28.9328 28.411C29.7868 29.572 30.6778 30.677 31.5738 31.64V31.614L31.5888 31.62ZM25.3848 13.641C25.4682 13.4704 25.5968 13.3258 25.7565 13.223C25.9162 13.1203 26.101 13.0632 26.2908 13.058C27.2858 13.058 27.4728 14.214 27.4728 15.141C27.4728 16.698 26.9988 19.063 26.1918 21.761C24.7748 18.001 24.6818 14.855 25.3898 13.641H25.3848ZM20.1818 36.172C17.7708 40.229 15.4528 42.745 14.0308 42.745C13.7548 42.745 13.5148 42.646 13.3278 42.505C13.0418 42.213 12.9008 41.875 12.9948 41.489C13.2808 40.036 15.9788 38.005 20.1878 36.171L20.1818 36.172Z"
					fill="white"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_86_2758"
					x1="28"
					y1="0"
					x2="28"
					y2="56"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#020812" />
					<stop offset="1" stopColor="#212C3A" />
				</linearGradient>
				<clipPath id="clip0_86_2758">
					<rect
						width="32"
						height="32"
						fill="white"
						transform="translate(12 12)"
					/>
				</clipPath>
			</defs>
		</svg>
	)
}

export default PdfDocICon

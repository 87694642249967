import BaseIcon from "./BaseIcon"

const LargeDocIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M37.55 12.95L27.05 2.45C26.75 2.15 26.45 2 26 2H11C9.35 2 8 3.35 8 5V41C8 42.65 9.35 44 11 44H35C36.65 44 38 42.65 38 41V14C38 13.55 37.85 13.25 37.55 12.95ZM26 5.6L34.4 14H26V5.6ZM11 41V5H23V14C23 15.65 24.35 17 26 17H35V41H11ZM14 32V35H32V32H14ZM14 26V23H32V26H14Z"
				fill="#646464"
			/>
		</BaseIcon>
	)
}

export default LargeDocIcon

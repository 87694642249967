import BaseIcon from "./BaseIcon"

const ContractIcon = ({
	className,
	size,
}: {
	className?: string
	size?: number
}) => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width={size || "24"}
			height={size || "24"}
			viewBox="0 0 24 24"
			fill="none"
			className={className || "fill-current"}
		>
			<g clipPath="url(#clip0_2688_31041)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M3 4C3 3.46957 3.20666 2.96086 3.57452 2.58579C3.94238 2.21071 4.44131 2 4.96154 2H15.6938L20 6.39067V20C20 20.5304 19.7933 21.0391 19.4255 21.4142C19.0576 21.7893 18.5587 22 18.0385 22H4.96154C4.44131 22 3.94238 21.7893 3.57452 21.4142C3.20666 21.0391 3 20.5304 3 20V4ZM10.8462 7.33333H6.92308V8.66667H10.8462V7.33333ZM16.0769 11.3333H6.92308V12.6667H16.0769V11.3333ZM9.93731 16.544C9.409 16.688 8.99577 17.1 8.85192 17.544L7.60962 17.1227C7.90123 16.2333 8.66492 15.512 9.59731 15.256C10.5218 15.0027 11.5772 15.216 12.4899 16.0707C12.7548 15.9914 13.0277 15.9436 13.3033 15.928C14.0095 15.8893 14.6411 16.0707 15.1838 16.3013C15.5826 16.4707 15.9684 16.684 16.2953 16.864C16.3973 16.9227 16.4954 16.976 16.5856 17.024C17.008 17.2507 17.2395 17.3333 17.3846 17.3333V18.6667C16.8746 18.6667 16.3725 18.416 15.9775 18.2053C15.8585 18.1413 15.74 18.0764 15.6218 18.0107C15.3164 17.8345 15.0021 17.6747 14.6803 17.532C14.2435 17.3453 13.8133 17.236 13.3739 17.26C13.3083 17.2632 13.2428 17.2695 13.1778 17.2787L13.1791 17.2973C13.2144 17.7293 13.0392 18.0853 12.8129 18.3413C12.3971 18.8133 11.7184 19.0613 11.2084 19.1547C10.9441 19.2087 10.6732 19.22 10.4055 19.188C10.2536 19.1682 10.107 19.1184 9.97392 19.0413C9.87041 18.9806 9.78126 18.8974 9.71279 18.7976C9.64432 18.6979 9.5982 18.584 9.57769 18.464C9.54553 18.2397 9.59735 18.0112 9.72285 17.824C9.81781 17.6818 9.93332 17.555 10.0655 17.448C10.3139 17.2387 10.6735 17.012 11.1561 16.764L11.1914 16.7373C11.0059 16.6199 10.799 16.5423 10.5832 16.509C10.3674 16.4758 10.1485 16.4877 9.93731 16.544Z"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2688_31041">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</BaseIcon>
	)
}

export default ContractIcon

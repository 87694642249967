import React, { ButtonHTMLAttributes } from "react"
import "styles/globals.css"
import { ButtonColor, ButtonIconPlacement, ButtonVariant } from "./types"

interface BaseButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	isLoading?: boolean
	icon?: React.ReactNode
	iconPlacement?: ButtonIconPlacement
	variant?: ButtonVariant
	iconStyle?: string
}

const BaseButton = ({
	children,
	className,
	icon,
	iconPlacement,
	disabled,
	variant,
	iconStyle,
	...props
}: BaseButtonProps) => {
	const baseClassName =
		variant === "text"
			? `h-[48px] text-base rounded-md flex items-center gap-2 cursor-pointer ${className} !outline-none`
			: `h-[48px] text-base px-6 py-3 rounded-md flex items-center gap-2 cursor-pointer ${className} !outline-none`

	return (
		<button disabled={disabled} {...props} className={baseClassName}>
			{icon && iconPlacement === "start" && icon && (
				<div className={iconStyle}>{icon}</div>
			)}
			{children}
			{icon && iconPlacement === "end" && icon && (
				<div className={iconStyle}>{icon}</div>
			)}
		</button>
	)
}

const styles = {
	primary: {
		contained: {
			default:
				"text-white bg-gradient-to-r from-light-blue-4 to-light-blue-5 hover:bg-current hover:bg-gradient-to-r hover:from-light-blue-4 hover:to-light-blue-4 hover:shadow-custom hover:shadow-light-blue-4",
			loading:
				"!cursor-default text-white bg-gradient-to-r from-light-blue-5 to-light-blue-5",
			disabled:
				"!cursor-default text-brand-gray-2 bg-gradient-to-r from-brand-gray-1 to-brand-gray-1",
			icon: "default",
		},
		outlined: {
			default:
				"shadow-outlined border text-transparent bg-clip-text bg-gradient-to-r font-medium border-light-blue-4 from-light-blue-4 to-light-blue-5 hover:bg-current hover:bg-gradient-to-r hover:from-light-blue-4 hover:to-light-blue-4 hover:shadow-outlined-hover",
			loading:
				"!cursor-default shadow border text-transparent bg-clip-text bg-gradient-to-r font-medium border-light-blue-5 from-light-blue-5 to-light-blue-5",
			disabled:
				"!cursor-default border text-brand-gray-2 bg-clip-text bg-gradient-to-r font-medium border-brand-gray-2 from-brand-gray-2 to-brand-gray-2",
			icon: "text-light-blue-4",
		},
		text: {
			default:
				"text-transparent bg-clip-text bg-gradient-to-r font-medium border-light-blue-4 from-light-blue-4 to-light-blue-5 hover:bg-current hover:bg-gradient-to-r hover:from-light-blue-4 hover:to-light-blue-4",
			loading:
				"!cursor-default text-transparent bg-clip-text bg-gradient-to-r font-medium border-light-blue-5 from-light-blue-5 to-light-blue-5",
			disabled:
				"!cursor-default text-brand-gray-2 bg-clip-text bg-gradient-to-r font-medium border-brand-gray-2 from-brand-gray-2 to-brand-gray-2",
			icon: "text-light-blue-4",
		},
	},
	secondary: {
		contained: {
			default:
				"text-white bg-gradient-to-r from-brand-purple-3 to-brand-purple-4 hover:bg-current hover:bg-gradient-to-r hover:from-brand-purple-3 hover:to-brand-purple-3 hover:shadow-custom hover:shadow-brand-purple-3",
			loading:
				"!cursor-default text-white bg-gradient-to-r from-brand-purple-4 to-brand-purple-4",
			disabled:
				"!cursor-default text-brand-gray-2 bg-gradient-to-r from-brand-gray-1 to-brand-gray-1",
			icon: "default",
		},
		outlined: {
			default:
				"shadow-outlined border text-transparent bg-clip-text bg-gradient-to-r font-medium border-brand-purple-3 from-brand-purple-3 to-brand-purple-4 hover:bg-current hover:bg-gradient-to-r hover:from-brand-purple-3 hover:to-brand-purple-3 hover:shadow-outlined-hover",
			loading:
				"shadow border text-transparent bg-clip-text bg-gradient-to-r font-medium border-brand-purple-4 from-brand-purple-4 to-brand-purple-4",
			disabled:
				"!cursor-default border text-brand-gray-2 bg-clip-text bg-gradient-to-r font-medium border-brand-gray-2 from-brand-gray-2 to-brand-gray-2",
			icon: "text-brand-purple-3",
		},
		text: {
			default:
				"text-transparent bg-clip-text bg-gradient-to-r font-medium border-brand-purple-3 from-brand-purple-3 to-brand-purple-4 hover:bg-current hover:bg-gradient-to-r hover:from-brand-purple-3 hover:to-brand-purple-3",
			loading:
				"!cursor-default text-transparent bg-clip-text bg-gradient-to-r font-medium border-brand-purple-4 from-brand-purple-4 to-brand-purple-4",
			disabled:
				"!cursor-default text-brand-gray-2 bg-clip-text bg-gradient-to-r font-medium border-brand-gray-2-4 from-brand-gray-2 to-brand-gray-2",
			icon: "text-brand-purple-3",
		},
	},
}

interface ButtonProps extends BaseButtonProps {
	variant: ButtonVariant
	color?: ButtonColor
	iconPlacement?: ButtonIconPlacement
}
export const Button = ({
	children,
	className,
	isLoading = false,
	variant,
	color = ButtonColor.Primary,
	icon,
	disabled,
	iconPlacement = ButtonIconPlacement.Start,
	...props
}: ButtonProps) => {
	const buttonStyles = styles[color]

	let buttonStyle = buttonStyles[variant].default
	let iconStyle = buttonStyles[variant].icon

	if (disabled) {
		buttonStyle = buttonStyles[variant].disabled
		iconStyle = ""
	} else if (isLoading) {
		buttonStyle = buttonStyles[variant].loading
	}

	const disableButton = isLoading || disabled

	return (
		<BaseButton
			icon={icon}
			// isLoading={isLoading}
			className={`${buttonStyle} ${className || ""}`}
			iconPlacement={iconPlacement}
			disabled={disableButton}
			variant={variant}
			iconStyle={iconStyle}
			{...props}
		>
			{children}
		</BaseButton>
	)
}

import Tooltip from "components/tooltip"
import AddCreditsIcon from "icons/AddCreditsIcon"
import BlockUserIcon from "icons/BlockUserIcon"
import ChangePasswordKeyIcon from "icons/ChangePasswordKeyIcon"
import BlockUserModal from "./BlockUserModal"
import { useState } from "react"
import AddCreditsModal from "./AddCreditsModal"
import ChangePasswordModal from "./ChangePasswordModal"
import { TooltipPosition } from "components/tooltip/enums"

export default function UserTableActions() {
	const [open, setOpen] = useState(false)
	const [openAddCredits, setOpenAddCredits] = useState(false)
	const [openChangePassword, setOpenChangePassword] = useState(false)

	return (
		<div className="flex justify-end gap-4">
			<Tooltip text="Adicionar créditos" position={TooltipPosition.Above}>
				<div
					className="text-dark-blue-1 hover:text-dark-blue-2 cursor-pointer"
					onClick={() => {
						setOpenAddCredits(true)
					}}
				>
					<AddCreditsIcon />
				</div>
			</Tooltip>

			<Tooltip
				text="Forçar troca de senha"
				position={TooltipPosition.Above}
			>
				<div
					className={`text-dark-blue-1 hover:text-dark-blue-2 cursor-pointer`}
					onClick={() => setOpenChangePassword(true)}
				>
					<ChangePasswordKeyIcon />
				</div>
			</Tooltip>

			<Tooltip text="Bloquear" position={TooltipPosition.Above}>
				<div
					className="text-dark-blue-1 hover:text-dark-blue-2 cursor-pointer"
					onClick={() => {
						setOpen(true)
					}}
				>
					<BlockUserIcon />
				</div>
			</Tooltip>
			<BlockUserModal open={open} setOpen={setOpen} />
			<ChangePasswordModal
				open={openChangePassword}
				setOpen={setOpenChangePassword}
			/>
			<AddCreditsModal
				open={openAddCredits}
				setOpen={setOpenAddCredits}
			/>
		</div>
	)
}

import BaseIcon from "./BaseIcon"

interface SendIconProps {
	className?: string
}

const Send = ({ className }: SendIconProps) => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill=""
			className={className}
		>
			<path d="M20.5861 11.3326L4.08607 3.08263C3.95675 3.01795 3.81149 2.99204 3.66778 3.00801C3.52407 3.02397 3.38804 3.08114 3.27607 3.17263C3.16914 3.26225 3.08933 3.37992 3.0456 3.51241C3.00188 3.6449 2.99598 3.78697 3.02857 3.92263L5.24857 12.0001L2.99857 20.0551C2.96799 20.1684 2.96442 20.2873 2.98815 20.4022C3.01188 20.5171 3.06224 20.6249 3.13519 20.7168C3.20814 20.8087 3.30164 20.8822 3.40817 20.9315C3.5147 20.9807 3.63129 21.0042 3.74857 21.0001C3.86598 20.9994 3.98158 20.9712 4.08607 20.9176L20.5861 12.6676C20.7089 12.6047 20.812 12.5091 20.884 12.3913C20.956 12.2735 20.9941 12.1382 20.9941 12.0001C20.9941 11.8621 20.956 11.7267 20.884 11.609C20.812 11.4912 20.7089 11.3956 20.5861 11.3326ZM4.91107 18.8326L6.56857 12.7501H13.4986V11.2501H6.56857L4.91107 5.16763L18.5686 12.0001L4.91107 18.8326Z" />
		</BaseIcon>
	)
}

export default Send

import BaseIcon from "./BaseIcon"

const CurrencyIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.0001 4.51677C15.5201 3.52784 13.78 3 12 3C9.6152 3.00698 7.33008 3.95744 5.64376 5.64375C3.95744 7.33007 3.00699 9.6152 3 12C3 13.78 3.52785 15.5201 4.51678 17.0001C5.50571 18.4802 6.91132 19.6337 8.55585 20.3149C10.2004 20.9961 12.01 21.1743 13.7558 20.8271C15.5016 20.4798 17.1053 19.6226 18.364 18.364C19.6226 17.1053 20.4798 15.5016 20.8271 13.7558C21.1743 12.01 20.9961 10.2004 20.3149 8.55585C19.6337 6.91131 18.4802 5.50571 17.0001 4.51677ZM6.16652 3.26957C7.89323 2.11581 9.9233 1.5 12 1.5C14.7823 1.50815 17.4483 2.61702 19.4156 4.58438C21.383 6.55175 22.4919 9.21773 22.5 12C22.5 14.0767 21.8842 16.1068 20.7304 17.8335C19.5767 19.5602 17.9368 20.906 16.0182 21.7007C14.0996 22.4955 11.9884 22.7034 9.95156 22.2982C7.91476 21.8931 6.04383 20.8931 4.57538 19.4246C3.10693 17.9562 2.1069 16.0852 1.70176 14.0484C1.29661 12.0116 1.50455 9.90045 2.29927 7.98182C3.09399 6.0632 4.4398 4.42332 6.16652 3.26957ZM15.75 7.5V9H9.75V11.25H14.25C14.6477 11.2504 15.029 11.4086 15.3102 11.6898C15.5914 11.971 15.7496 12.3523 15.75 12.75V15C15.7495 15.3977 15.5914 15.779 15.3102 16.0602C15.029 16.3414 14.6477 16.4995 14.25 16.5H12.75V18.75H11.25V16.5H8.25V15H14.25V12.75H9.75C9.3523 12.7496 8.971 12.5914 8.68978 12.3102C8.40856 12.029 8.2504 11.6477 8.25 11.25V9C8.2504 8.6023 8.40856 8.221 8.68978 7.93978C8.971 7.65856 9.3523 7.5004 9.75 7.5H11.25V5.25H12.75V7.5H15.75Z"
			/>
		</BaseIcon>
	)
}

export default CurrencyIcon

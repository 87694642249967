const NotFoundIcon = ({
	height = "223",
	width = "241",
}: {
	height?: string
	width?: string
}) => {
	return (
		<svg
			width={height}
			height={width}
			viewBox="0 0 241 223"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M151.892 207.699C120.54 208.574 92.1959 208.788 62.8704 206.302C59.6952 206.035 57.359 205.748 55.8617 205.44C55.1647 205.3 53.9966 205.36 53.3319 204.625C53.2706 204.557 53.2278 204.473 53.2079 204.383C53.188 204.292 53.1917 204.197 53.2186 204.108C53.2455 204.019 53.2946 203.939 53.361 203.876C53.4273 203.814 53.5086 203.77 53.5965 203.75C62.3993 201.893 71.9314 201.612 80.9019 201.231C113.415 199.875 146.587 199.728 179.036 202.514C181.807 202.75 184.561 203.18 187.297 203.804C187.4 203.827 187.493 203.884 187.561 203.967C187.63 204.05 187.67 204.153 187.677 204.262C187.684 204.371 187.656 204.479 187.598 204.57C187.54 204.661 187.454 204.729 187.355 204.766C184.767 205.748 182.244 205.868 179.488 206.149C172.51 206.866 163.311 207.382 151.892 207.699Z"
				fill="#E6E6E6"
			/>
			<g clipPath="url(#clip0_4367_46545)">
				<path
					d="M152.552 38.0537C154.769 34.2136 159.679 32.8979 163.52 35.115L198.285 55.187L215.668 65.223C217.857 66.4866 219.323 68.7069 219.625 71.2158L223.627 104.429C223.834 106.154 223.477 107.899 222.609 109.404L207.889 134.899L179.788 183.571C177.571 187.411 172.661 188.727 168.821 186.51L99.2891 146.366C95.4489 144.148 94.1332 139.238 96.3503 135.398L152.552 38.0537Z"
					fill="#FAFAFA"
					stroke="#E6E6E6"
					strokeWidth="3.01081"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeDasharray="4.01 10.04"
				/>
				<path
					d="M194.602 71.6012L174.503 59.9971M210.04 99.0567L175.274 78.9847M174.197 96.9049L202.01 112.963M175.634 116.406L193.982 126.869M185.953 140.775L175.523 134.754M177.924 154.682L153.588 140.631M142.082 152.53L169.895 168.588"
					stroke="#E6E6E6"
					strokeWidth="3.01081"
					strokeLinecap="round"
				/>
				<path
					d="M4.01636 83.2159C1.79926 79.3757 3.11499 74.4654 6.95511 72.2483L41.7209 52.1763L59.1038 42.1402C61.2923 40.8767 63.9481 40.7173 66.2721 41.71L97.0363 54.8511C98.6339 55.5335 99.967 56.7157 100.836 58.2202L115.555 83.7151L143.656 132.387C145.873 136.227 144.557 141.138 140.717 143.355L71.1856 183.499C67.3455 185.716 62.4352 184.4 60.2181 180.56L4.01636 83.2159Z"
					fill="#FAFAFA"
					stroke="#E6E6E6"
					strokeWidth="3.01081"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeDasharray="4.01 10.04"
				/>
				<path
					d="M21.9395 82.1404L54.0978 63.5737M29.9683 96.0467L57.7809 79.9891L64.7341 75.9747M37.997 109.953L65.8097 93.8953M46.0259 123.859L59.9322 115.831L66.8854 111.816M54.0547 137.766L67.961 129.737M62.0835 151.672L75.9898 143.643L82.943 139.629L86.4196 137.622M70.1123 165.578L118.784 137.477"
					stroke="#E6E6E6"
					strokeWidth="3.01081"
					strokeLinecap="round"
				/>
				<path
					d="M72.2617 11.0288C72.2617 6.59462 75.8563 3 80.2905 3L120.435 3L140.507 3C143.034 3 145.413 4.18982 146.93 6.21153L167.002 32.9742C168.044 34.364 168.607 36.0543 168.607 37.7915V67.2305V123.432C168.607 127.866 165.013 131.461 160.579 131.461H80.2905C75.8564 131.461 72.2617 127.866 72.2617 123.432V11.0288Z"
					fill="#FAFAFA"
					stroke="#E6E6E6"
					strokeWidth="3.01081"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeDasharray="4.01 10.04"
				/>
				<line
					x1="89.8257"
					y1="17.5522"
					x2="134.988"
					y2="17.5522"
					stroke="#E6E6E6"
					strokeWidth="3.01081"
					strokeLinecap="round"
				/>
				<line
					x1="89.8257"
					y1="33.6098"
					x2="151.045"
					y2="33.6098"
					stroke="#E6E6E6"
					strokeWidth="3.01081"
					strokeLinecap="round"
				/>
				<line
					x1="89.8257"
					y1="49.6674"
					x2="151.045"
					y2="49.6674"
					stroke="#E6E6E6"
					strokeWidth="3.01081"
					strokeLinecap="round"
				/>
				<line
					x1="89.8257"
					y1="65.7251"
					x2="151.045"
					y2="65.7251"
					stroke="#E6E6E6"
					strokeWidth="3.01081"
					strokeLinecap="round"
				/>
				<line
					x1="89.8257"
					y1="81.7827"
					x2="151.045"
					y2="81.7827"
					stroke="#E6E6E6"
					strokeWidth="3.01081"
					strokeLinecap="round"
				/>
				<line
					x1="89.8257"
					y1="97.8403"
					x2="151.045"
					y2="97.8403"
					stroke="#E6E6E6"
					strokeWidth="3.01081"
					strokeLinecap="round"
				/>
				<line
					x1="89.8257"
					y1="113.898"
					x2="151.045"
					y2="113.898"
					stroke="#E6E6E6"
					strokeWidth="3.01081"
					strokeLinecap="round"
				/>
			</g>
			<g clipPath="url(#clip1_4367_46545)">
				<ellipse
					cx="147.948"
					cy="86.8149"
					rx="53.186"
					ry="53.0729"
					fill="#FAFAFA"
				/>
				<path
					d="M130.182 68.2048L165.308 103.13M130.182 104.334L165.308 69.4092"
					stroke="#E6E6E6"
					strokeWidth="3.01081"
					strokeLinecap="round"
				/>
				<rect
					x="176.916"
					y="130.904"
					width="9.45528"
					height="15.3648"
					transform="rotate(-45 176.916 130.904)"
					fill="#E6E6E6"
				/>
				<rect
					x="176.916"
					y="130.904"
					width="9.45528"
					height="8.02968"
					transform="rotate(-45 176.916 130.904)"
					fill="#B2B2B2"
				/>
				<path
					d="M201.134 86.8149C201.134 116.126 177.321 139.888 147.948 139.888C118.574 139.888 94.7617 116.126 94.7617 86.8149C94.7617 57.5035 118.574 33.7419 147.948 33.7419C177.321 33.7419 201.134 57.5035 201.134 86.8149ZM105.399 86.8149C105.399 110.264 124.449 129.273 147.948 129.273C171.447 129.273 190.496 110.264 190.496 86.8149C190.496 63.3658 171.447 44.3565 147.948 44.3565C124.449 44.3565 105.399 63.3658 105.399 86.8149Z"
					fill="#E6E6E6"
				/>
				<path
					d="M116.774 49.5497C116.423 49.1291 116.478 48.5023 116.906 48.1601C124.73 41.9037 134.277 38.153 144.291 37.4149C154.305 36.6769 164.3 38.9871 172.96 44.0283C173.434 44.3043 173.582 44.9165 173.296 45.3842C173.01 45.8505 172.402 45.9966 171.929 45.7218C163.622 40.8947 154.039 38.6833 144.437 39.3909C134.836 40.0986 125.682 43.6911 118.176 49.6837C117.749 50.0247 117.125 49.9692 116.774 49.5497Z"
					fill="white"
				/>
				<path
					d="M105.632 62.8687C105.155 62.5987 104.987 61.9929 105.267 61.5224C107.002 58.606 109.033 55.8751 111.328 53.3729C111.698 52.9697 112.326 52.9555 112.723 53.3327C113.121 53.7102 113.135 54.3368 112.766 54.7406C110.582 57.1252 108.648 59.7257 106.993 62.5016C106.713 62.9713 106.109 63.1385 105.632 62.8687Z"
					fill="white"
				/>
				<rect
					x="182.826"
					y="144.008"
					width="18.9106"
					height="61.4593"
					rx="2.0072"
					transform="rotate(-45 182.826 144.008)"
					fill="#B2B2B2"
				/>
				<rect
					x="195.127"
					y="136.436"
					width="2.36382"
					height="36.7059"
					rx="1.18191"
					transform="rotate(-45 195.127 136.436)"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_4367_46545">
					<rect
						width="240.864"
						height="189.681"
						fill="white"
						transform="translate(0.00195312 0.992676)"
					/>
				</clipPath>
				<clipPath id="clip1_4367_46545">
					<rect
						width="159.656"
						height="168.455"
						fill="white"
						transform="translate(80 19.0112)"
					/>
				</clipPath>
			</defs>
		</svg>
	)
}
export default NotFoundIcon

export default function ColoredLawdeck({ size = 48 }: { size?: number }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 48 48"
			fill="none"
		>
			<path
				d="M24 3C19.8466 3 15.7865 4.23163 12.333 6.53914C8.8796 8.84665 6.18798 12.1264 4.59854 15.9636C3.0091 19.8009 2.59323 24.0233 3.40352 28.0969C4.21381 32.1705 6.21386 35.9123 9.15077 38.8492C12.0877 41.7861 15.8295 43.7862 19.9031 44.5965C23.9767 45.4068 28.1991 44.9909 32.0364 43.4015C35.8736 41.812 39.1534 39.1204 41.4609 35.667C43.7684 32.2135 45 28.1534 45 24C45 18.4305 42.7875 13.089 38.8493 9.15076C34.911 5.21249 29.5696 3 24 3Z"
				fill="url(#paint0_linear_8491_6017)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18 29.9431C19.51 31.2216 21.5097 32 23.703 32C24.1581 32 24.6049 31.9665 25.0407 31.902V29.302C24.6111 29.3958 24.1632 29.4454 23.703 29.4454C22.4745 29.4454 21.3346 29.0922 20.3938 28.4888V19.5112V18.1513V16.6259C19.5099 16.9762 18.702 17.4624 18 18.0569V18.1513V22.8064V25.1936V29.3109V29.9431ZM25.0407 27.7203V27.0252V18.6979C27.6234 19.2616 29.5468 21.4215 29.5468 24C29.5468 26.2884 28.0319 28.2471 25.8856 29.0529V31.735C29.5332 30.8298 32.2222 27.7098 32.2222 24C32.2222 19.5817 28.408 16 23.703 16C23.3454 16 22.9929 16.0207 22.6469 16.0609V18.1683V18.6433V27.8149C22.9847 27.9138 23.3386 27.9664 23.703 27.9664C24.1701 27.9664 24.6197 27.88 25.0407 27.7203Z"
				fill="#3083FF"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_8491_6017"
					x1="24"
					y1="3"
					x2="24"
					y2="45"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#020812" />
					<stop offset="1" stopColor="#212C3A" />
				</linearGradient>
			</defs>
		</svg>
	)
}

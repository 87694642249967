import { Button } from "components/button"
import { ButtonColor, ButtonVariant } from "components/button/types"
import { useAtom } from "jotai"
import { modalAtom } from "pages/SidebarPages/subcomponents/atoms"
import MagicWandIcon from "icons/MagicWandIcon"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"
import {
	LDProcess,
	LegalDocumentType,
	ProcessType,
	RetrieveLegalDocumentResponse,
} from "types/legalDocument"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useState } from "react"

import { contractStepAtom } from "../ContractDisplay/atoms"
import NewFactsStep from "../../NewFactsStep"
import {
	contractTypeAtom,
	openAtom,
	toFavorAtom,
	userInputContractAtom,
} from "../../atoms"
import useCurrentUser from "hooks/useCurrentUser"
import useContractAPI from "hooks/useContractAPI"

export function newContractFactsStep(documentId: string) {
	function PrimaryButton({ documentId }: { documentId: string }) {
		const [userInput] = useAtom(userInputContractAtom)
		const [toFavor] = useAtom(toFavorAtom)
		const [contractType] = useAtom(contractTypeAtom)
		const queryClient = useQueryClient()
		const { generateClauses } = useContractAPI()
		const user = useCurrentUser()
		const [, setOpen] = useAtom(openAtom)
		const [, setCurrentStep] = useAtom(contractStepAtom)
		const [loadingClauses, setLoadingClauses] = useState(false)

		const generateClausesMutation = useMutation({
			mutationFn: () =>
				generateClauses(documentId, {
					user_input: userInput,
					to_favor: toFavor,
					contract_type: contractType,
				}),
			onSettled: () => {
				queryClient.setQueryData(
					["legalDocument", documentId],
					(
						oldDocument: RetrieveLegalDocumentResponse | undefined,
					) => {
						if (oldDocument) {
							const process = {
								process_type: ProcessType.GenerateClauses,
								max_processing_time: 360,
								start_time: new Date().toISOString(),
							} as LDProcess
							return {
								...oldDocument,
								processes: [process],
							}
						}

						return oldDocument
					},
				)
			},
		})
		const checkGenerateClausesButtonIsDisabled = () => {
			if (!userInput || !contractType) return true

			if (userInput.length < 20) return true

			if (userInput.length > 10000) return true

			if (loadingClauses) return true

			return false
		}
		const onClickGenerateClauses = () => {
			if (!user) return

			if (user.balance <= 0) {
				showToast(
					"Você não tem créditos para concluir essa ação.",
					ToastType.Error,
				)
				setOpen(true)
			} else {
				setLoadingClauses(true)
				generateClausesMutation.mutateAsync().then(() => {
					setCurrentStep(2)
					queryClient.invalidateQueries([
						"retrieveLegalDocument",
						documentId,
					])
				})
			}
		}
		return (
			<Button
				className="ml-4"
				variant={ButtonVariant.Contained}
				color={ButtonColor.Secondary}
				icon={<MagicWandIcon />}
				disabled={checkGenerateClausesButtonIsDisabled()}
				data-cy="generate-clauses"
				onClick={onClickGenerateClauses}
			>
				Gerar estratégias
			</Button>
		)
	}
	function SecondaryButton() {
		// const navigate = useNavigate()
		const [, setOpenModalSaveDocument] = useAtom(modalAtom)
		// const { deleteLegalDocument } = useAPI()

		// const deleteDocumentMutation = useMutation({
		// 	mutationFn: () => deleteLegalDocument(documentId),
		// 	onSuccess: () => {
		// 		navigate(ROUTES.basePage())
		// 	},
		// })
		const onFactsStepCancel = () => {
			// const localStorageCheck = localStorage.getItem("saveDocumentModal")

			// if (localStorageCheck === "true") {
			// 	navigate("/")
			// } else if (localStorageCheck === "false") {
			// 	await deleteDocumentMutation.mutateAsync()
			// } else {
			// 	setOpenModalSaveDocument(true)
			// }
			setOpenModalSaveDocument({
				open: true,
				isCancel: true,
			})
		}
		return (
			<Button
				variant={ButtonVariant.Outlined}
				onClick={() => onFactsStepCancel()}
			>
				Cancelar
			</Button>
		)
	}
	return {
		subtitle: "Subtitulo Fatos",
		Component: (
			<NewFactsStep
				documentId={documentId}
				type={LegalDocumentType.Contract}
			/>
		),
		primaryButton: <PrimaryButton documentId={documentId} />,
		secondaryButton: <SecondaryButton />,
		footerText: "Informações",
	}
}

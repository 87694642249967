import { useQuery } from "@tanstack/react-query"
import { useJwt } from "./useJwt"
import useUserAPI from "./useUserAPI"

export default function useCheckIfHasCredits() {
	const { currentUser } = useUserAPI()
	const [jwt] = useJwt()
	const { data: user } = useQuery(["current-user", jwt], currentUser)
	const userExtraCredits = user?.extra_credits || 0
	const userCredits = user?.credits_remaining || 0

	if (user && (userExtraCredits > 0 || userCredits > 0)) {
		return true
	}
	return false
}

import { AutoformatRule } from "@udecode/plate-autoformat"
import { ELEMENT_BLOCKQUOTE } from "@udecode/plate-block-quote"
import { ELEMENT_H1, ELEMENT_H2 } from "@udecode/plate-heading"

import { ELEMENT_OL, ELEMENT_UL, unwrapList } from "@udecode/plate-list"
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph"

const preFormat = (editor: any) => unwrapList(editor)
export const autoformatBlocks: AutoformatRule[] = [
	{
		mode: "block",
		type: ELEMENT_H1,
		match: "# ",
		preFormat,
	},
	{
		mode: "block",
		type: ELEMENT_H2,
		match: "## ",
		preFormat,
	},
	{
		mode: "block",
		type: ELEMENT_BLOCKQUOTE,
		match: "> ",
		preFormat,
	},
    {
		mode: "block",
		type: ELEMENT_OL,
		match: "1. ",
		preFormat,
	},
    {
		mode: "block",
		type: ELEMENT_UL,
		match: "* ",
		preFormat,
	},
	{
		mode: "block",
		type: ELEMENT_PARAGRAPH,
		match: " ",
		preFormat,
	},
]

export const autoformatMarks: AutoformatRule[] = [
	{
		mode: "mark",
		type: ["bold", "italic"],
		match: "***",
	},
	{
		mode: "mark",
		type: "bold",
		match: "**",
	},
	{
		mode: "mark",
		type: "italic",
		match: "_",
	},

	{
		mode: "mark",
		type: "strikethrough",
		match: "~~",
	},
    {
		mode: "mark",
		type: "link",
		match: "[",
	},
]

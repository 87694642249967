import { Outlet, useNavigate } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import { useState } from "react"
import { Dropdown } from "components/dropdown"
import ConfigIcon from "icons/ConfigIcon"
import ExitIcon from "icons/ExitIcon"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import Toast from "components/toast"
import { ROUTES } from "routes"
import { useJwt } from "hooks/useJwt"

import ExclamationIcon from "icons/ExclamationIcon"
import { formatDate } from "utils/formatDate"
import ModalOnboarding from "pages/MyDocumentsPage/subcomponents/ModalOnboarding"
import LogoContainer from "pages/SidebarPages/subcomponents/LogoContainer"
import MenuItem from "pages/SidebarPages/subcomponents/MenuItem"
import Profile from "pages/SidebarPages/subcomponents/Profile"
import GroupIcon from "icons/GroupIcon"
import DashboardIcon from "icons/DashboardIcon"
import ArrowChangeIcon from "icons/ArrowChangeIcon"
import { showToast } from "components/toast/functions"
import useSubscriptionAPI from "hooks/useSubscriptionAPI"
import useCurrentUser from "hooks/useCurrentUser"

export default function Sidebar() {
	const navigate = useNavigate()

	const { logout } = useAuth0()
	const [jwt] = useJwt()
	const [openOnboardingModal, setOpenOnboardingModal] = useState(false)

	const queryClient = useQueryClient()

	const handleLogout = async () => {
		logout({
			logoutParams: {
				returnTo: window.location.origin,
			},
		})
	}

	const supportEnabled = [
		{
			label: "Painel usuário",
			className:
				"text-[#AE30FC] flex cursor-pointer items-center px-[8px] py-[8px] duration-200 ease-out hover:bg-[#f6f6f6] hover:text-dark-blue-2",
			onClick: () => navigate(ROUTES.basePage()),
			icon: <ArrowChangeIcon />,
		},
		{
			label: "Configurações",
			onClick: () => navigate(ROUTES.settings()),
			icon: <ConfigIcon />,
		},
		{
			label: "Sair",
			onClick: () => handleLogout(),
			icon: <ExitIcon />,
		},
	]

	const sections = [
		{
			items: supportEnabled,
		},
	]

	const { subscriptionReactivatePlan } = useSubscriptionAPI()

	const subscriptionReactivatePlanMutation = useMutation({
		mutationFn: subscriptionReactivatePlan,
	})

	const user = useCurrentUser()

	const menuItems = [
		{
			Icon: DashboardIcon,
			name: "Dashboard",
			path: ROUTES.myDocuments(),
		},
		{
			Icon: GroupIcon,
			name: "Usuários",
			path: ROUTES.admin(),
		},
	]

	function handleKeepPlan() {
		subscriptionReactivatePlanMutation.mutateAsync().then(() => {
			queryClient.invalidateQueries(["current-user", jwt])
			showToast("Sucesso! Sua assinatura será mantida.")
		})
	}

	return (
		<main className="absolute inset-0 flex bg-ice-white font-lato">
			<div className={`flex h-[100vh] w-[220px] flex-col bg-white`}>
				<div
					className={
						"z-50 col-start-1 col-end-3 row-span-full flex h-screen w-[220px] flex-col text-white"
					}
				>
					<LogoContainer />
					<div className={`mt-5 w-[100%] `}>
						{menuItems.map((item) => {
							return (
								<MenuItem
									Icon={item.Icon}
									name={item.name}
									path={item.path}
									key={item.name}
									setRouteToNavigate={() => {}}
								/>
							)
						})}
					</div>
				</div>
				<div className="p-4">
					{user?.subscription?.cancel_at_period_end && (
						<div className="bg-brand-orange-opacity p-4 mt-2 mb-8">
							<div className="flex items-center">
								<ExclamationIcon
									className="fill-brand-orange mr-2 mt-1 flex"
									size="20"
								/>{" "}
								<p className="text-[14px] text-brand-orange w-[128px]">
									Seu plano cancela em:{" "}
									{
										formatDate(
											user?.subscription?.end_period,
										).split(" ")[0]
									}
									<p
										className="font-bold cursor-pointer"
										onClick={handleKeepPlan}
									>
										Manter assinatura
									</p>
								</p>
							</div>
						</div>
					)}

					<div className={`mt-4 w-full `}>
						<Dropdown
							className={`right-[5px] bottom-[58px] min-w-[176px]`}
							sections={sections}
							shouldCloseAfterClick
						>
							<div className="align-center flex cursor-pointer">
								{user && <Profile user={user} />}
							</div>
						</Dropdown>
					</div>
				</div>
			</div>

			<div
				className={`col-span-full col-start-3 row-span-full row-start-3 w-full overflow-y-scroll px-6 pt-6 pb-[80px] `}
				id="document-content"
			>
				<Outlet />
			</div>
			<Toast />

			<ModalOnboarding
				open={openOnboardingModal}
				setOpen={setOpenOnboardingModal}
			/>
		</main>
	)
}

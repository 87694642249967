interface DropdownSectionTitleProps {
	title: React.ReactNode
	className?: string
}

const DropdownSectionTitle = ({
	title,
	className,
}: DropdownSectionTitleProps) => {
	return (
		<div
			className={`p-[8px] text-cta-2 font-[600] text-[#020812] ${className}`}
		>
			{title}
		</div>
	)
}

export default DropdownSectionTitle

import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Button } from "components/button"
import { ButtonVariant } from "components/button/types"
import InputField from "components/input-field"
import { showToast } from "components/toast/functions"
import useCurrentUser from "hooks/useCurrentUser"
import useUserAPI from "hooks/useUserAPI"
import { useEffect, useState } from "react"
import { formatOabNumber, formatPhoneNumber } from "utils/format"

export default function Profile() {
	const user = useCurrentUser()
	const { updateCurrentUser } = useUserAPI()
	const [errors, setErrors] = useState({} as Record<string, string>)
	const [changedField, setChangedField] = useState(false)
	const [formData, setFormData] = useState({
		first_name: "",
		last_name: "",
		phone_number: "",
		oab_number: "",
	})

	const queryClient = useQueryClient()
	const updateCurrentUserMutation = useMutation({
		mutationFn: updateCurrentUser,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["current-user"] })
			showToast("Informações alteradas com sucesso!")
		},
	})

	function handleFormDataChange(e: React.ChangeEvent<HTMLInputElement>) {
		setChangedField(true)
		const newValue = e.target.value
		if (e.target.name === "oab_number") {
			setFormData({
				...formData,
				[e.target.name]: formatOabNumber(newValue),
			})
		} else if (e.target.name === "phone_number") {
			setFormData({
				...formData,
				[e.target.name]: formatPhoneNumber(newValue),
			})
		} else if (
			e.target.name === "first_name" ||
			e.target.name === "last_name"
		) {
			const regex = /^[a-zA-ZÀ-ÖØ-öø-ÿ\s]*$/

			if (regex.test(newValue)) {
				setFormData({
					...formData,
					[e.target.name]: newValue,
				})
			}
		} else {
			setFormData({
				...formData,
				[e.target.name]: newValue,
			})
		}
	}

	function handleUpdateUser() {
		if (user) {
			updateCurrentUserMutation.mutate(formData)
			setChangedField(false)
		}
	}

	useEffect(() => {
		if (user) setFormData({ ...user })
	}, [user])

	return (
		<div className="flex w-auto">
			<div className="mr-6 w-[431px]">
				<div className=" text-h3 text-dark-blue-1 font-semibold">
					Informações Pessoais
				</div>
				<div className="text-brand-gray-2 text-h4">
					Altere e atualize suas informações na plataforma
				</div>
			</div>
			<div className="w-[669px]">
				<div className="flex items-center mb-8 w-auto">
					<div className="mr-6">
						<img
							className="rounded-[89px]"
							src={user?.picture}
							alt="Avatar"
							width={89}
							height={89}
						/>
					</div>
					<div>
						<div className="font-medium text-dark-blue-1">
							{user?.first_name} {user?.last_name}
						</div>
						<div className="font-semibold text-brand-gray-3">
							{user?.oab_number}
						</div>
					</div>
				</div>
				<div className="mb-8 w-auto">
					<div className="flex gap-4 pb-4">
						<InputField
							className="w-[321px]"
							name="first_name"
							value={formData.first_name || ""}
							onChange={(event) => {
								handleFormDataChange(event)
								if (
									formData.first_name ||
									formData.first_name.length > 1
								) {
									setErrors((prev) => ({
										...prev,
										first_name: "",
									}))
								}
							}}
							label="Nome"
							maxLength={30}
							error={errors.first_name}
							onBlur={() => {
								if (
									!formData.first_name ||
									formData.first_name.length <= 1
								) {
									setErrors((prev) => ({
										...prev,
										first_name: "Campo obrigatório",
									}))
								} else {
									setErrors((prev) => ({
										...prev,
										first_name: "",
									}))
								}
							}}
						/>
						<InputField
							className="w-[321px]"
							name="last_name"
							value={formData.last_name || ""}
							onChange={handleFormDataChange}
							label="Sobrenome"
							maxLength={30}
						/>
					</div>
					<div className="flex gap-4">
						<InputField
							className="w-[321px]"
							name="phone_number"
							value={formData.phone_number || ""}
							onChange={(event) => {
								handleFormDataChange(event)
							}}
							placeholder="(99) 9 9999-9999"
							label="Telefone"
							error={errors.phone_number}
							onBlur={() => {
								if (
									formData.phone_number &&
									formData.phone_number.length < 14
								) {
									setErrors((prev) => ({
										...prev,
										phone_number:
											"Telefone deve ser um campo valido",
									}))
								} else {
									setErrors((prev) => ({
										...prev,
										phone_number: "",
									}))
								}
							}}
						/>
						<InputField
							className="w-[321px]"
							name="oab_number"
							value={formData.oab_number || ""}
							onChange={handleFormDataChange}
							placeholder="UF 123456"
							label="OAB"
						/>
					</div>
				</div>
				<div>
					<Button
						variant={ButtonVariant.Contained}
						disabled={
							!changedField ||
							!!errors.first_name ||
							!formData.first_name ||
							!!errors.phone_number ||
							(formData.phone_number &&
								formData.phone_number.length < 14) ||
							!formData.first_name ||
							formData.first_name.length <= 1
						}
						onClick={handleUpdateUser}
					>
						Salvar
					</Button>
				</div>
			</div>
		</div>
	)
}

import BaseIcon from "./BaseIcon"

const UploadIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.1681 14.5431L10.375 13.75L16 8.125L21.625 13.75L20.8319 14.5431L16.5625 10.2794L16.5625 20.5L15.4375 20.5L15.4375 10.2794L11.1681 14.5431ZM21.625 22.7503V20.5003H22.75V22.7503C22.75 23.0486 22.6315 23.3348 22.4205 23.5458C22.2095 23.7567 21.9234 23.8753 21.625 23.8753H10.375C10.0766 23.8753 9.79048 23.7567 9.5795 23.5458C9.36853 23.3348 9.25 23.0486 9.25 22.7503V20.5003H10.375V22.7503H21.625Z"
				fill="#020812"
			/>
		</BaseIcon>
	)
}

export default UploadIcon

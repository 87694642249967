import { ReactNode, createContext, useState } from "react"

export interface ThesisContent {
	id: string
	version: number
}

interface ThesisContextProps {
	thesesContent: ThesisContent[]
	setThesesContent: (params: ThesisContent[]) => void
}

interface ThesisProviderProps {
	children: ReactNode
}

export const ThesisContentContext = createContext<ThesisContextProps>({
	thesesContent: [],
	setThesesContent: () => {},
})

export default function ThesisContentProvider({
	children,
}: ThesisProviderProps) {
	const [thesesContent, setThesesContent] = useState<ThesisContent[]>([])

	return (
		<ThesisContentContext.Provider
			value={{ thesesContent, setThesesContent }}
		>
			{children}
		</ThesisContentContext.Provider>
	)
}

import { AxiosResponse } from "axios"
import axiosInstance from "axiosInstance"
import {
	CreateLegalDocumentResponse,
	GenerateLegalDocumentContentResponse,
	RetrieveLegalDocumentResponse,
	UpdateLegalDocumentRequestBody,
	UpdateLegalDocumentResponse,
	CreateContractRequestBody,
	GenerateClausesRequestBody,
	GenerateContractContentRequestBody,
	GenerateContractContentResponse,
	GenerateContractContentV2RequestBody,
	GenerateContractContentV2Response,
} from "types/legalDocument"

import { ClausesData, GenerateClausesResponse } from "types/theses"
const useContractAPI = () => {
	const generateClauses = async (
		id: string,
		payload: GenerateClausesRequestBody,
	) => {
		const response: AxiosResponse<GenerateClausesResponse> =
			await axiosInstance.post(
				`/contract/${id}/generate_clauses/`,
				payload,
			)

		return response.data
	}

	const createContract = async (payload: CreateContractRequestBody) => {
		const response: AxiosResponse<CreateLegalDocumentResponse> =
			await axiosInstance.post("/contract/", payload)

		return response.data
	}

	const updateContract = async ({
		id,
		payload,
	}: {
		id: string
		payload: UpdateLegalDocumentRequestBody
	}) => {
		const response: AxiosResponse<UpdateLegalDocumentResponse> =
			await axiosInstance.patch(`/contract/${id}/`, payload)

		return response.data
	}

	const retrieveContract = async (id: string) => {
		if (!id) throw new Error("No id provided")

		const response: AxiosResponse<RetrieveLegalDocumentResponse> =
			await axiosInstance.get(`/contract/${id}/`)

		return response.data
	}

	const listClauses = async (id: string) => {
		if (!id) throw new Error("No id provided")

		const response: AxiosResponse<ClausesData[]> = await axiosInstance.get(
			`/contract/${id}/list_clauses/`,
		)

		return response.data
	}

	const generateContractContent = async (
		id: string,
		payload: GenerateContractContentRequestBody,
	) => {
		const response: AxiosResponse<GenerateContractContentResponse> =
			await axiosInstance.post(
				`/contract/${id}/generate_content/`,
				payload,
			)

		return response.data
	}

	const generateContractContentV2 = async (
		id: string,
		payload: GenerateContractContentV2RequestBody,
	) => {
		const response: AxiosResponse<GenerateContractContentV2Response> =
			await axiosInstance.post(
				`/v2/contract/${id}/generate_content/`,
				payload,
			)

		return response.data
	}

	const copyContract = async (id: string) => {
		const response: AxiosResponse<RetrieveLegalDocumentResponse> =
			await axiosInstance.post(`/contract/${id}/create_copy/`)

		return response.data
	}

	const destroyClauses = async (id: string) => {
		await axiosInstance.delete(`/contract/${id}/destroy_clauses/`)
	}

	const createManualClause = async ({
		id,
		payload,
	}: {
		id: string
		payload: {
			instructions: string
		}
	}) => {
		const response: AxiosResponse<any> = await axiosInstance.post(
			`/contract/${id}/create_manual_clause/`,
			payload,
		)

		return response.data
	}

	const createNewClause = async (id: string) => {
		const response: AxiosResponse<GenerateLegalDocumentContentResponse> =
			await axiosInstance.post(`/contract/${id}/create_new_clause/`)

		return response.data
	}
	const updateContractContent = async ({
		id,
		payload,
	}: {
		id: string
		payload: UpdateLegalDocumentRequestBody
	}) => {
		const response: AxiosResponse<UpdateLegalDocumentResponse> =
			await axiosInstance.patch(
				`/contract/${id}/update_document/`,
				payload,
			)

		return response.data
	}

	return {
		generateClauses,
		createContract,
		updateContract,
		retrieveContract,
		generateContractContent,
		generateContractContentV2,
		copyContract,
		listClauses,
		destroyClauses,
		createManualClause,
		createNewClause,
		updateContractContent,
	}
}

export default useContractAPI

import { useQuery } from "@tanstack/react-query"
import { Button } from "components/button"
import { useJwt } from "hooks/useJwt"
import MagicWandIcon from "icons/MagicWandIcon"
import NoDocumentsIcon from "icons/NoDocumentsIcon"
import React from "react"
import FilteredChip from "./FilteredChip"
import FilterClearIcon from "icons/FilterClearIcon"
import { LegalDocumentTable } from "../legal-document-table"
import Lottie from "react-lottie"
import loading from "lotties/loading.json"
import { LegalDocumentStatus, LegalDocumentType } from "types/legalDocument"
import {
	ColumnFilter,
	RowSelectionState,
	SortingState,
} from "@tanstack/react-table"
import ImagePrefetch from "components/image-prefetch"
import WelcomeImage from "assets/welcome-image.jpg"
import { ButtonVariant } from "components/button/types"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"


const LottieComponent = Lottie as any

const lottieOptions = {
	loop: true,
	autoplay: true,
	animationData: loading,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
		className: "cursor-default",
	},
}
const translate = {
	completed: "Completo",
	draft: "Rascunho",
	processing: "Processando",
	canceled: "Cancelado",
	error: "Erro",
	// filed: "Arquivado",
	strategy: "Estratégias",
	petition: "Petição",
	contract: "Contrato",
	power_of_attorney: "Procuração",
	uploaded_document: "Importado",
	others: "Outros",
}

interface DashboardDocumentDisplayProps {
	columnFilterValue: ColumnFilter[] | undefined
	filterValue: string
	sorting: SortingState
	rowSelection: RowSelectionState
	setColumnFilterValue: (value: ColumnFilter[]) => void
	setFilterValue: React.Dispatch<React.SetStateAction<string>>
	setSorting: React.Dispatch<React.SetStateAction<SortingState>>
	setRowSelection: (value: object) => void
	setIdsToDelete: React.Dispatch<React.SetStateAction<string[] | undefined>>
	onCreateNewDocument: () => void
}

const DashboardDocumentDisplay = ({
	columnFilterValue,
	setColumnFilterValue,
	filterValue,
	sorting,
	setSorting,
	rowSelection,
	setFilterValue,
	setRowSelection,
	setIdsToDelete,
	onCreateNewDocument
}: DashboardDocumentDisplayProps) => {
	const [jwt] = useJwt()
	const { listLegalDocuments } = useLegalDocumentAPI()
	const { data, isLoading } = useQuery(["listLegalDocuments", jwt], () =>
		listLegalDocuments(),
	)


	function handleChangeStatus(statusFilterValue: string[], status: string) {
		if (columnFilterValue) {
			const value = statusFilterValue.filter((value) => value !== status)
			const columnFilters = []
			if (
				columnFilterValue.filter((filter) => filter.id !== "status")
					.length > 0
			)
				columnFilters.push(
					...columnFilterValue.filter(
						(filter) => filter.id !== "status",
					),
				)

			if (value.length > 0)
				columnFilters.push({
					id: "status",
					value,
				})
			setColumnFilterValue(columnFilters)
		}
		// if (notFilteredValue) return onChange([notFilteredValue])
		// 			return onChange([])
	}

	return data?.length === 0 && !isLoading ? (
		<div className="flex items-center justify-center h-full flex-col mt-[-30px]">
			<NoDocumentsIcon />

			<h3 className=" text-brand-gray-3 font-semibold mt-[24px]">
				Crie seu primeiro documento com IA!
			</h3>
			<p className="text-cta-1 text-brand-gray-2 mt-[16px]">
				Descubra uma forma mais inteligente e eficiente de advogar.
			</p>

			<Button
				onClick={() => onCreateNewDocument()}
				icon={<MagicWandIcon />}
				variant={ButtonVariant.Contained}
				className="mt-6"
			>
				Criar documento
			</Button>
		</div>
	) : (
		<div className=" mb-[8px] flex flex-col h-full">
			<div className="flex mt-8 flex-wrap gap-4">
				{columnFilterValue?.map((filterValue) => {
					if (filterValue.id === "status") {
						const statusFilterValue =
							columnFilterValue?.find(
								(filter) => filter.id === "status",
							) &&
								Array.isArray(
									columnFilterValue?.find(
										(filter) => filter.id === "status",
									)!.value,
								)
								? (columnFilterValue.find(
									(filter) => filter.id === "status",
								)!.value as string[])
								: []
						return statusFilterValue.map((status, index) => {
							return (
								<div key={index}>
									<FilteredChip
										text={
											translate[
											status as LegalDocumentStatus
											]
										}
										onClick={() =>
											handleChangeStatus(
												statusFilterValue,
												status,
											)
										}
									/>
								</div>
							)
						})
					}
					if (filterValue.id === "type") {
						const typeFilterValue =
							columnFilterValue?.find(
								(filter) => filter.id === "type",
							) &&
								Array.isArray(
									columnFilterValue?.find(
										(filter) => filter.id === "type",
									)!.value,
								)
								? (columnFilterValue.find(
									(filter) => filter.id === "type",
								)!.value as string[])
								: []
						return typeFilterValue.map((type, index) => {
							return (
								<div key={index}>
									<FilteredChip
										text={
											translate[type as LegalDocumentType]
										}
										onClick={() =>
											setColumnFilterValue([
												...columnFilterValue.filter(
													(filter) =>
														filter.id !== "type",
												),
												{
													id: "type",
													value: typeFilterValue.filter(
														(value) =>
															value !== type,
													),
												},
											])
										}
									/>
								</div>
							)
						})
					}
					return null
				})}
				{columnFilterValue && columnFilterValue.length > 0 ? (
					<div className="flex items-center h-[42px]">
						<Button
							variant={ButtonVariant.Text}
							icon={<FilterClearIcon />}
							className="px-0 py-0"
							onClick={() => setColumnFilterValue([])}
						>
							Limpar
						</Button>
					</div>
				) : null}
			</div>

			{isLoading ? (
				<div className="fixed top-[40%] left-[50%]">
					<LottieComponent
						options={lottieOptions}
						height={87}
						width={95}
						isClickToPauseDisabled
					/>
				</div>
			) : (
				<LegalDocumentTable
					data={data}
					columnFilterValue={columnFilterValue}
					filterValue={filterValue}
					rowSelection={rowSelection}
					sorting={sorting}
					onRowSelection={setRowSelection}
					onFilterValueChange={setFilterValue}
					onSelectRowsToDelete={setIdsToDelete}
					onSortingChange={setSorting}
				/>
			)}
			<ImagePrefetch imageUrl={WelcomeImage} />
		</div>
	)
}

export default DashboardDocumentDisplay

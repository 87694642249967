const BlueCheckMarkIcon = ({
	className,
	size = "14",
	viewBox = "0 0 24 24",
}: {
	className?: string
	size?: string
	viewBox?: string
}) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox={viewBox}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M12 4C10.4178 4 8.87103 4.46919 7.55544 5.34824C6.23985 6.22729 5.21447 7.47672 4.60897 8.93853C4.00347 10.4003 3.84504 12.0089 4.15372 13.5607C4.4624 15.1126 5.22433 16.538 6.34315 17.6569C7.46197 18.7757 8.88743 19.5376 10.4393 19.8463C11.9911 20.155 13.5997 19.9965 15.0615 19.391C16.5233 18.7855 17.7727 17.7602 18.6518 16.4446C19.5308 15.129 20 13.5822 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4ZM10.8571 15.1947L8 12.3376L8.90892 11.4286L10.8571 13.3767L15.0914 9.14286L16.0033 10.0491L10.8571 15.1947Z"
				fill={className ? "currentColor" : "#3083FF"}
			/>
		</svg>
	)
}

export default BlueCheckMarkIcon

import useCurrentUser from "hooks/useCurrentUser"
import GoogleIcon from "icons/GoogleIcon"

function Google() {
	const user = useCurrentUser()
	return (
		<div>
			<div className="flex justify-between">
				<div className="w-1/2">
					<h3 className="mb-2 font-semibold">Contas sincronizadas</h3>
					<h4 className=" text-brand-gray-2 font-normal">
						Gerencie seus meios de acesso a Lawdeck.
					</h4>
				</div>
				<div className=" flex flex-col w-1/2">
					<div className="flex w-[385px] p-4 justify-between items-center rounded-md border border-brand-gray-1">
						<div className="flex justify-center items-center flex-shrink-0 gap-4">
							<GoogleIcon />
							<div className="flex flex-col items-start">
								<span className="mb-1 text-dark-blue-1 font-semibold leading-[20px] text-cta-2">
									Google
								</span>
								<span className="text-brand-gray-3 font-semibold leading-[20px] text-cta-2">
									{user?.email}
								</span>
							</div>
						</div>
						<div className="flex py-1 px-2 items-start border-[1.5px] rounded-3xl border-solid border-light-blue-1">
							<span className="text-[10px] text-light-blue-1 font-bold leading-[16px]">
								Usando
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Google

import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useJwt } from "hooks/useJwt"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"
import ExclamationIcon from "icons/ExclamationIcon"
import TrashIcon from "icons/TrashIcon"
import UploadIcon from "icons/UploadIcon"
import { useAtom } from "jotai"
import { openLexAtom } from "pages/SidebarPages/subcomponents/atoms"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

export default function UploadPdf() {
	const [file, setFile] = useState<File | null>(null)
	// const [url, setUrl] = useState<string>("")
	const inputRef = useRef<HTMLInputElement>(null)
	const { uploadDocument, deleteLegalDocument } = useLegalDocumentAPI()
	const [startUpload, setStartUpload] = useState(false)
	const [progressBarWidth, setProgressBarWidth] = useState(0)
	const [progressBarColor, setProgressBarColor] = useState("bg-light-blue-4")
	const [uploadedFileId, setUploadedFileId] = useState<string | null>(null)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)
	const queryClient = useQueryClient()
	const [jwt] = useJwt()
	const [, setOpenLex] = useAtom(openLexAtom)
	const navigate = useNavigate()


	const deleteDocumentMutation = useMutation({
		mutationFn: deleteLegalDocument,
		onSuccess: () => {
			setUploadedFileId(null)
			setErrorMessage(null)
			setProgressBarWidth(0)
			setProgressBarColor("bg-light-blue-4")
			setStartUpload(false)
			queryClient.invalidateQueries({
				queryKey: ["listLegalDocuments", jwt],
			})
		},
	})

	const uploadDocumentMutation = useMutation({
		mutationFn: uploadDocument,

		onSuccess: (data) => {
			setProgressBarWidth(4)
			setProgressBarColor("bg-light-green")
			setUploadedFileId(data.id)
			queryClient.invalidateQueries({
				queryKey: ["listLegalDocuments", jwt],
			})
			setOpenLex(true)

			navigate(`/document/${data.id}`)
		},
		onError: (error) => {
			setErrorMessage(
				"Tivemos um erro ao fazer upload do seu documento, por favor tente novamente.",
			)
			setFile(null)
			setStartUpload(false)
			setProgressBarWidth(0)
			setProgressBarColor("bg-light-blue-4")
			throw new Error(`${error}`)
		},
	})

	useEffect(() => {
		setTimeout(() => {
			if (progressBarWidth <= 3) {
				setProgressBarWidth(progressBarWidth + 1)
			}
		}, 1000)
	}, [progressBarWidth])

	const onFileChange = async (event: any) => {
		const fileData = event.target.files[0]
		if (fileData && fileData.size > 15728640) {
			setErrorMessage(
				"Seu arquivo é muito grande, faça upload de um arquivo menor que 15 MB",
			)
			return
		}

		setFile(fileData)
		setStartUpload(true)
		const formData = new FormData()
		formData.append("file_path", fileData)

		await uploadDocumentMutation.mutateAsync(formData)
	}

	function handleDeleteDocument() {
		if (uploadedFileId) {
			deleteDocumentMutation.mutate(uploadedFileId)
		} else {
			setFile(null)
			setStartUpload(false)
			setErrorMessage(null)
			setProgressBarWidth(0)
			setProgressBarColor("bg-light-blue-4")
		}
	}

	const onDrop = (event: any) => {
		event.preventDefault()

		const files = event.dataTransfer.files
		if (files && files.length > 0) {
			const fileData = files[0]
			if (fileData && fileData.size > 15728640) {
				setErrorMessage(
					"Seu arquivo é muito grande, faça upload de um arquivo menor que 15 MB",
				)
				return
			}
			setFile(fileData)
			setStartUpload(true)
			const formData = new FormData()
			formData.append("file_path", fileData)

			uploadDocumentMutation.mutateAsync(formData)
		}
	}

	function onDragOver(event: React.DragEvent<HTMLDivElement>) {
		event.preventDefault()
	}

	function onDragEnter(event: React.DragEvent<HTMLDivElement>) {
		event.preventDefault()
	}

	function onDragLeave(event: React.DragEvent<HTMLDivElement>) {
		event.preventDefault()
	}

	function handleDivClick() {

		if (inputRef.current) {
			inputRef.current.click()
		}
	}

	const containerClassName = startUpload
		? "border-solid"
		: " shadow-outlined  border-dashed cursor-pointer hover:bg-semi-white-4"
	const progressBarConfig: { [key: number]: string } = {
		0: "w-0",
		1: "w-1/4",
		2: "w-2/4",
		3: "w-3/4",
		4: "w-4/4",
	}

	return (
		<div className="w-full">
			<h1 className="mb-8 font-semibold">Chat PDF</h1>

			<h3 className="font-semibold mb-6 ">Upload de documentos</h3>

			<div className="w-full">
				<div
					className={` mb-6 p-6 flex items-center flex-col bg-white border-2 rounded text-center ${containerClassName}`}
					onClick={handleDivClick}
					onDragOver={onDragOver}
					onDragEnter={onDragEnter}
					onDragLeave={onDragLeave}
					onDrop={onDrop}
				>
					{startUpload ? (
						<div className="py-5 w-full ">
							<div className="flex justify-between w-full mb-2 items-center ">
								<div className="flex items-center">
									<h4 className="font-semi-bold mr-2 text-left">
										{file?.name}
									</h4>
									<p className="text-cta-2 text-brand-gray-3">
										(
										{(
											file?.size && file?.size / 1048576
										)?.toFixed(2)}
										mb)
									</p>
								</div>
								<div
									className="cursor-pointer"
									onClick={() => handleDeleteDocument()}
								>
									<TrashIcon />
								</div>
							</div>
							<div className="w-full flex items-center justify-between">
								<div className="h-2 w-full rounded-full  bg-semi-white-4 max-w-[90%]">
									<div
										className={`${progressBarConfig[progressBarWidth]} h-full ${progressBarColor} rounded-full `}
									/>
								</div>

								<p className="text-cta-1 text-brand-gray-3">
									{progressBarWidth * 25}%
								</p>
							</div>
						</div>
					) : (
						<>
							<div className="bg-semi-white-4 w-[32px] h-[32px] rounded-full mb-6">
								<UploadIcon />
							</div>

							<input
								type="file"
								className="hidden"
								onChange={onFileChange}
								ref={inputRef}
								accept=".pdf, .txt, .docx"
							></input>
							<p className="text-brand-gray-2 text-cta-2">
								<span className="text-dark-blue-1 text-cta-2">
									Clique para fazer upload
								</span>{" "}
								ou arraste e solte seu arquivo
							</p>
							{errorMessage ? (
								<div className="flex items-center justify-center">
									<div>
										<ExclamationIcon
											size="16"
											className="text-light-red"
										/>
									</div>

									<p className="text-light-red text-cta-2 ml-2">
										{errorMessage}
									</p>
								</div>
							) : (
								<p className="text-brand-gray-2 text-cta-2">
									Carregue arquivos de até 15 MB em PDF, TXT,
									DOCX
								</p>
							)}
						</>
					)}
				</div>
				{/* {!startUpload && (
					<>
						<p className="mb-2">Ou faça upload pela URL</p>

						<InputField
							type="text"
							placeholder="Insira a URL do site aqui"
							value={url}
							onChange={onUrlChange}
							className=" p-2 w-full rounded mb-12"
						/>
					</>
				)} */}
			</div>
		</div>
	)
}

import {
	ColumnFilter,
	FilterFn,
	OnChangeFn,
	SortingState,
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table"
import { rankItem } from "@tanstack/match-sorter-utils"
import NotFoundIcon from "icons/NotFoundIcon"
import UserTableHeader from "./UserTableHeader"
import UserTableRow from "./UserTableRow"
import UserTableActions from "./UserTableActions"
import ArrowLeft from "icons/ArrowLeft"
import ArrowRight from "icons/ArrowRight"

export interface UserTableProps {
	data?: RetrieveUserResponse[]
	filterValue: string
	columnFilterValue: ColumnFilter[] | undefined
	sorting: SortingState
	onFilterValueChange: (filterValue: string) => void
	onSortingChange: OnChangeFn<SortingState>
}

export interface RetrieveUserResponse {
	user: { name: string; email: string; avatar: string }
	lastLogin: { date: string; hour: string }
	type: string
	createdDocs: number
	Ltv: number
	credits: number
}

export const UserTable = ({
	data,
	filterValue,
	columnFilterValue,
	sorting,
	onFilterValueChange,
	onSortingChange,
}: UserTableProps) => {
	const columnHelper = createColumnHelper<RetrieveUserResponse>()
	const fuzzyFilter: FilterFn<RetrieveUserResponse> = (
		row,
		columnId,
		value,
		addMeta,
	) => {
		const itemRank = rankItem(row.getValue(columnId), filterValue)

		addMeta({
			itemRank,
		})

		return itemRank.passed
	}

	function sortingFn(a: any, b: any, columnId: string) {
		const ordem =
			'0123456789 !"#$%&()*+,-./:;?@[\\]^_`{|}~AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz'

		return ordem.indexOf(a.getValue(columnId)[0]) <
			ordem.indexOf(b.getValue(columnId)[0])
			? 1
			: -1
	}

	function sortingTypefn(a: any, b: any, columnId: string) {
		const ordem = ["uploaded_document", "contract", "petition"]

		return ordem.indexOf(a.getValue(columnId)) <
			ordem.indexOf(b.getValue(columnId))
			? 1
			: -1
	}

	function HeaderTable({
		text,
		className,
		onClick,
	}: {
		text: string
		className?: string
		onClick?: (event: unknown) => void
	}) {
		return (
			<div className={className} onClick={onClick}>
				<span className="text-cta-1 leading-6 whitespace-nowrap font-semibold select-none">
					{text}
				</span>
			</div>
		)
	}

	const columns = [
		columnHelper.accessor("user", {
			header: ({ header }) => {
				return (
					<div className="flex items-center relative">
						<HeaderTable
							text="Usuário"
							onClick={header.column.getToggleSortingHandler()}
						/>
					</div>
				)
			},
			footer: () => (
				<div className="flex p-6 justify-between">
					<div>
						<span className="text-brand-gray-3">Mostrando</span>{" "}
						<span className="font-bold">1 - 50</span>{" "}
						<span className="text-brand-gray-3">de</span>{" "}
						<span className="font-bold">48.251</span>{" "}
						<span className="text-brand-gray-3">resultados</span>
					</div>
					<div className="flex text-center items-center">
						<div className="flex items-center mr-4">
							<div className="w-6 h-6 flex items-center justify-center">
								<ArrowLeft />
							</div>
							<p className={"text-brand-gray-3 select-none"}>
								<span
								// className={`${
								// 	page
								// 		? "text-brand-gray-2"
								// 		: "text-brand-gray-3"
								// }`}
								>
									1
								</span>
								<span>/</span>
								<span
								// className={`${
								// 	page
								// 		? "text-brand-gray-2"
								// 		: "text-brand-gray-3"
								// }`}
								>
									965
								</span>
							</p>

							<div className="w-6 h-6 flex items-center justify-center cursor-pointer">
								<ArrowRight />
							</div>
						</div>
					</div>
				</div>
			),
			filterFn: fuzzyFilter,
			sortingFn: sortingFn,
			sortDescFirst: true,
			enableColumnFilter: true,
			cell: (info) => (
				<div className="flex w-full h-full">
					<div className={`w-[32px] mr-4 items-center flex`}>
						<img
							src={info.getValue().avatar}
							height={32}
							width={32}
							className="rounded-full border border-sky-500 bg-cover "
							referrerPolicy="no-referrer"
						/>
					</div>
					<div>
						<p className="max-w-[120px] overflow-hidden text-ellipsis whitespace-nowrap text-cta-2 font-semibold">
							{info.getValue().name}
						</p>
						<p className="text-brand-gray-3">
							{info.getValue().email}
						</p>
					</div>
				</div>
			),
		}),

		columnHelper.accessor("lastLogin", {
			sortDescFirst: true,
			sortingFn: sortingTypefn,
			header: ({ header }) => (
				<HeaderTable
					text="Último login"
					onClick={header.column.getToggleSortingHandler()}
				/>
			),
			cell: (info) => (
				<div className="flex-col text-brand-gray-3 text-[14px]">
					<div>{info.getValue().date}</div>
					<div>{info.getValue().hour}</div>
				</div>
			),
		}),

		columnHelper.accessor("type", {
			sortDescFirst: true,
			header: ({ header }) => (
				<HeaderTable
					text="Tipo de conta"
					onClick={header.column.getToggleSortingHandler()}
				/>
			),
			cell: (info) => (
				<div className="text-brand-gray-3 text-[14px]">
					{info.getValue()}
				</div>
			),
		}),

		columnHelper.accessor("createdDocs", {
			header: ({ header }) => (
				<HeaderTable
					text="Docs criados"
					onClick={header.column.getToggleSortingHandler()}
				/>
			),
			sortDescFirst: true,
			cell: (info) => (
				<div className="text-brand-gray-3 text-[14px]">
					{info.getValue()}
				</div>
			),
		}),

		columnHelper.accessor("Ltv", {
			header: ({ header }) => (
				<HeaderTable
					text="LTV"
					onClick={header.column.getToggleSortingHandler()}
				/>
			),
			sortDescFirst: true,
			cell: (info) => (
				<div className="text-brand-gray-3 text-[14px]">
					{info.getValue()}
				</div>
			),
		}),

		columnHelper.accessor("credits", {
			header: ({ header }) => (
				<HeaderTable
					text="Créditos"
					onClick={header.column.getToggleSortingHandler()}
				/>
			),

			sortDescFirst: true,
			cell: (info) => (
				<div className="text-brand-gray-3 text-[14px]">
					{info.getValue()}
				</div>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: "Actions",
			header: () => "",
			enableSorting: false,
			cell: () => {
				return <UserTableActions />
			},
		}),
	]

	const table = useReactTable({
		data: data ?? [],
		columns,
		state: {
			sorting,
			globalFilter: filterValue,
			columnFilters: columnFilterValue,
		},
		enableSortingRemoval: false,
		globalFilterFn: fuzzyFilter,
		enableColumnFilters: true,
		onSortingChange: onSortingChange,
		onGlobalFilterChange: onFilterValueChange,
		getFilteredRowModel: getFilteredRowModel(),
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	})

	function getWidth(type: string) {
		if (type === "user") {
			return "basis-[30%] grow"
		}
		if (type === "type") {
			return "basis-[10%] shrink"
		}
		if (type === "Ltv") {
			return "basis-[10%] shrink"
		}

		if (type === "createdDocs") {
			return "basis-[10%] shrink"
		}
		if (type === "credits") {
			return "basis-[10%] shrink"
		}

		if (type === "lastLogin") {
			return "basis-[10%] shrink"
		}

		if (type === "Actions") {
			return "basis-[20%] shrink text-end"
		}
	}

	if (
		(filterValue || columnFilterValue) &&
		table.getRowModel().rows.length === 0
	) {
		return (
			<div className="flex items-center justify-center h-full flex-col mb-[150px]">
				<NotFoundIcon />
				<div className="flex items-center justify-center flex-col mt-6">
					<h3 className="text-brand-gray-3 mb-4">
						Nenhum resultado encontrado
					</h3>
					<p className="text-brand-gray-2 text-cta-1">
						Por favor, tente fazer uma nova busca ou ajustar seus
						filtros.
					</p>
				</div>
			</div>
		)
	}
	return (
		<div>
			<div className="w-full bg-white mt-6 h-[calc(100vh-270px)]">
				<div>
					{table.getHeaderGroups().map((headerGroup) => (
						<div
							className=" p-4 flex gap-[20px] border-b border-solid border-[#F0F0F0]"
							key={headerGroup.id}
						>
							{headerGroup.headers.map((header) => {
								const width = getWidth(header.column.id)
								return (
									<div className={width} key={header.id}>
										<UserTableHeader header={header} />
									</div>
								)
							})}
						</div>
					))}
				</div>
				<div>
					{table.getRowModel().rows.map((row) => {
						return (
							<div
								className=" text-dark-blue-1 hover:text-dark-blue-2"
								key={row.id}
							>
								<UserTableRow row={row} />
							</div>
						)
					})}
				</div>
			</div>
			{table.getFooterGroups().map((footerGroup) => (
				<div
					key={footerGroup.id}
					className="text-center align-middle bg-white border-t border-solid border-[#F0F0F0]"
				>
					{footerGroup.headers.map((header) => (
						<div key={header.id}>
							{header.isPlaceholder
								? null
								: flexRender(
										header.column.columnDef.footer,
										header.getContext(),
								  )}
						</div>
					))}
				</div>
			))}
			<div className="h-6"> </div>
		</div>
	)
}

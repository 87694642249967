export function isMobile() {
	if (
		/Mobi|Android/i.test(navigator.userAgent) ||
		/Mobi|iPhone|iPad|iPod/i.test(navigator.userAgent) ||
		/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(
			navigator.userAgent,
		)
	) {
		return true
	}
	return false
}

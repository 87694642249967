import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useAtom } from "jotai"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { getDocumentStatus } from "utils/getDocumentStatus"
import { useEffect } from "react"
import { modalAtom } from "./atoms"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"

interface MenuItemProps {
	Icon: any
	name: string
	path: string
	setRouteToNavigate: (value: string) => void
}

const MenuItem = ({ Icon, name, path, setRouteToNavigate }: MenuItemProps) => {
	const { pathname } = useLocation()
	const active = path === pathname.split("/")[1] || ""
	const { documentId } = useParams()
	const navigate = useNavigate()
	const { retrieveLegalDocument } = useLegalDocumentAPI()
	const [, setOpenModalSaveDocument] = useAtom(modalAtom)

	const queryClient = useQueryClient()

	// const deleteDocumentMutation = useMutation({
	// 	mutationFn: () => deleteLegalDocument(documentId || ""),
	// 	onSuccess: () => {
	// 		navigate(ROUTES.basePage())
	// 	},
	// })

	const { data } = useQuery(
		["legalDocument", documentId],
		() => retrieveLegalDocument(documentId!),
		{
			enabled: !!documentId,
		},
	)

	const localStorageNotifications = localStorage.getItem("notificationsData")
		? JSON.parse(localStorage.getItem("notificationsData") || "{}")
		: null

	const className = active
		? "flex gap-2 py-3 text-light-blue-1 border-l-4 border-blue-500 w-[100%] bg-gradient-to-r from-[#F1F4FF] to-[#FEFEFF]"
		: "flex gap-2 py-3 text-black border-l-4 border-transparent hover:text-dark-blue-2"

	const onClickMenuItem = () => {
		const splittedPathName = pathname.split("/")[1]
		if (!documentId) {
			navigate(path)
			return
		}

		if (splittedPathName === "document") {
			const status = getDocumentStatus(data)

			if (status !== "draft") {
				navigate(path)
				return
			}

			const localStorageSaveDocument = localStorage.getItem(
				"documentAlreadyCheck",
			)
				? JSON.parse(localStorage.getItem("documentAlreadyCheck")!)
				: []

			if (
				!localStorageSaveDocument ||
				!localStorageSaveDocument.includes(documentId)
			) {
				setRouteToNavigate(path)
				setOpenModalSaveDocument({
					open: true,
					isCancel: false,
				})
				return
			}

			if (localStorageSaveDocument.includes(documentId)) {
				navigate(path)
				return
			}

			// if (
			// 	localStorageSaveDocument &&
			// 	localStorageSaveDocument.save === null
			// ) {
			// 	const documentAlredyCheck =
			// 		localStorageSaveDocument.alreadShowDocuments.includes(
			// 			documentId || "",
			// 		)
			// 	if (!documentAlredyCheck) {
			// 		setOpenModalSaveDocument(true)
			// 		return
			// 	}
			// }
			// if (
			// 	localStorageSaveDocument &&
			// 	localStorageSaveDocument.save === false
			// ) {
			// 	deleteDocumentMutation.mutateAsync()
			// 	return
			// }
		}
	}

	function getNewNotifications() {
		if (!localStorageNotifications) return
		const notificationsLastSeen = localStorage.getItem(
			"notificationsLastSeen",
		)
		const notificationsLastSeenDate = notificationsLastSeen
			? new Date(notificationsLastSeen)
			: null

		const newNotifications = localStorageNotifications.filter(
			(item: any) => {
				if (item.visualized) {
					return false
				}
				if (notificationsLastSeenDate) {
					return (
						new Date(item._created_at) > notificationsLastSeenDate
					)
				}

				return true
			},
		)

		return newNotifications.length
	}

	useEffect(() => {
		queryClient.invalidateQueries(["retrieveLegalDocument", documentId])
	}, [documentId, pathname])

	return (
		<div
			className={`relative cursor-pointer font-medium h-[52px] ${className}`}
			onClick={onClickMenuItem}
		>
			<div className={`pl-3`}>
				<Icon />
			</div>
			<div>
				<span className="whitespace-nowrap">{name}</span>
			</div>
			{name === "Notificações" &&
				getNewNotifications() > 0 &&
				pathname !== "/notification" && (
					<div className="bg-[#e8edfd] px-2.5 flex items-center justify-center rounded-md ml-6">
						<p className="text-light-blue-1">
							{getNewNotifications()}
						</p>
					</div>
				)}
		</div>
	)
}

export default MenuItem

import BaseIcon from "./BaseIcon"

const Sumarize = ({
	size = "24px",
	className,
}: {
	size?: string
	className?: string
}) => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			className={className}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.5 5H21V6.5H7.5V5ZM7.5 18.5H21V20H7.5V18.5ZM21 11.75H7.5V13.25H21V11.75ZM3 11.75H4.5V13.25H3V11.75ZM4.5 5H3V6.5H4.5V5ZM3 18.5H4.5V20H3V18.5Z"
				fill=""
			/>
		</BaseIcon>
	)
}

export default Sumarize

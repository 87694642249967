const LogIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.5 22.5H10.5V21H4.5V3H16.5V10.5H18V3C17.9996 2.6023 17.8414 2.22101 17.5602 1.9398C17.279 1.65859 16.8977 1.50042 16.5 1.5H4.5C4.1023 1.50042 3.72101 1.65859 3.4398 1.9398C3.15859 2.22101 3.00042 2.6023 3 3V21C3.00042 21.3977 3.15859 21.779 3.4398 22.0602C3.72101 22.3414 4.1023 22.4996 4.5 22.5ZM6 4.5H15V6H6V4.5ZM6 10.5H10.5V12H6V10.5ZM15 7.5H6V9H15V7.5ZM6 18H10.5V19.5H6V18ZM22.5 16.5H20.9242C20.8275 16.0305 20.6411 15.5842 20.3752 15.1853L21.4928 14.0678L20.4323 13.0073L19.3148 14.1248C18.9159 13.859 18.4695 13.6725 18 13.5758V12H16.5V13.5758C16.0305 13.6725 15.5842 13.8589 15.1853 14.1248L14.0678 13.0072L13.0073 14.0677L14.1248 15.1852C13.859 15.5841 13.6725 16.0305 13.5758 16.5H12V18H13.5758C13.6725 18.4695 13.8589 18.9158 14.1248 19.3147L13.0072 20.4322L14.0677 21.4927L15.1852 20.3752C15.5841 20.641 16.0305 20.8275 16.5 20.9242V22.5H18V20.9242C18.4695 20.8275 18.9158 20.6411 19.3147 20.3752L20.4322 21.4928L21.4927 20.4323L20.3752 19.3148C20.641 18.9159 20.8275 18.4695 20.9242 18H22.5V16.5ZM17.25 19.5C16.805 19.5 16.37 19.368 16 19.1208C15.63 18.8736 15.3416 18.5222 15.1713 18.111C15.001 17.6999 14.9564 17.2475 15.0432 16.811C15.13 16.3746 15.3443 15.9737 15.659 15.659C15.9737 15.3443 16.3746 15.13 16.811 15.0432C17.2475 14.9564 17.6999 15.001 18.111 15.1713C18.5222 15.3416 18.8736 15.63 19.1208 16C19.368 16.37 19.5 16.805 19.5 17.25C19.4993 17.8465 19.2621 18.4185 18.8403 18.8403C18.4185 19.2621 17.8465 19.4993 17.25 19.5Z"
				fill="#ACACAC"
			/>
		</svg>
	)
}

export default LogIcon

import React from "react"
import BaseIcon from "./BaseIcon"

interface LinkOutIconProps {
	className?: string
	noStroke?: boolean
}

const LinkOutIcon = ({ className, noStroke }: LinkOutIconProps) => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			className={className}
			stroke=""
		>
			<path
				d="M8.88888 7.11107L13.3333 2.66663M13.3333 2.66663H10.3704M13.3333 2.66663V5.62959M13.3333 9.18514V12.1481C13.3333 12.4624 13.2085 12.7639 12.9862 12.9862C12.7639 13.2084 12.4625 13.3333 12.1481 13.3333H3.85184C3.53751 13.3333 3.23605 13.2084 3.01379 12.9862C2.79152 12.7639 2.66666 12.4624 2.66666 12.1481V3.85181C2.66666 3.53748 2.79152 3.23602 3.01379 3.01376C3.23605 2.79149 3.53751 2.66663 3.85184 2.66663H6.8148"
				stroke={noStroke ? "" : "#020812"}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</BaseIcon>
	)
}

export default LinkOutIcon

import BaseIcon from "./BaseIcon"

const DraggableIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="17"
			viewBox="0 0 16 17"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5 3.98145H7V5.98145H5V3.98145ZM9 3.98145H11V5.98145H9V3.98145ZM7 7.98145H5V9.98145H7V7.98145ZM9 7.98145H11V9.98145H9V7.98145ZM7 11.9814H5V13.9814H7V11.9814ZM9 11.9814H11V13.9814H9V11.9814Z"
				fill="#003659"
			/>
		</BaseIcon>
	)
}

export default DraggableIcon

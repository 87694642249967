import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Button } from "components/button"
import { Dropdown } from "components/dropdown"
import { differenceInHours, differenceInMinutes, format } from "date-fns"
import { ptBR } from "date-fns/locale"
import CircleKeyIcon from "icons/CircleKeyIcon"
import ColoredLawdeck from "icons/ColoredLawdeck"
import CreditIcon from "icons/CreditIcon"
import DotsIcon from "icons/DotsIcon"
import ErrorIcon from "icons/ErrorIcon"
import NotificationPlanIcon from "icons/NotificationPlanIcon"
import SuccessIcon from "icons/SuccessIcon"
import { useAtom } from "jotai"
import { useNavigate } from "react-router-dom"
import { ROUTES } from "routes"
import { Notification } from "types/notification"
import { firstLoginOpenModalAtom } from "./atoms"
import { ButtonVariant } from "components/button/types"
import useNotificationAPI from "hooks/useNotificationAPI"

type NotificationCardProps = Notification & {
	isToday?: boolean
}

const translate = {
	petition: "A petição",
	contract: "O contrato",
	power_of_attorney: "A rocuração",
	uploaded_document: "O documento importado",
}

export default function NotificationCard({
	details,
	type,
	_created_at,
	isToday,
	visualized,
	id,
}: NotificationCardProps) {
	const { deleteNotification, markAsReadNotification } = useNotificationAPI()
	const queryClient = useQueryClient()
	const deleteDocumentMutation = useMutation({
		mutationFn: () => deleteNotification(id),
		onSuccess: () => {
			queryClient.invalidateQueries(["notifications"])
		},
	})
	const navigate = useNavigate()

	const [firstLoginOpenModal, setFirstLoginOpenModal] = useAtom(
		firstLoginOpenModalAtom,
	)

	const markAsReadNotificationMutation = useMutation({
		mutationFn: () => markAsReadNotification(id),
		onSuccess: () => {
			queryClient.invalidateQueries(["notifications"])
		},
	})

	async function handleDeleteNotification() {
		await deleteDocumentMutation.mutateAsync()
	}

	async function handleMarkAsReadNotification() {
		await markAsReadNotificationMutation.mutateAsync()
	}

	const sections = [
		{
			items: [
				{
					label: "Marcar como lida",
					onClick: () => handleMarkAsReadNotification(),
				},
				{
					label: "Excluir",
					deleteLabel: true,
					onClick: () => handleDeleteNotification(),
				},
			],
		},
	]

	function getTextByType() {
		switch (type) {
			case "error_generate_document":
				return (
					<p className="text-cta-1">
						Houve um erro ao gerar o documento{" "}
						<span className="font-semibold">
							“{details?.legal_document?.title}”
						</span>
						. Por favor, verifique ou tente novamente.
					</p>
				)
			case "success_generate_document":
				return (
					<p className="text-cta-1">
						{details?.legal_document?.type &&
							translate[details?.legal_document?.type]}{" "}
						<span className="font-semibold">
							“{details?.legal_document?.title}”
						</span>{" "}
						está completa! Clique aqui para verificar.
					</p>
				)
			case "invite_access_document":
				return (
					<p className="text-cta-1">
						<span className="font-semibold">
							{details.sender?.first_name}
						</span>{" "}
						convidou você para acessar o documento{" "}
						<span className="font-semibold">
							“{details?.legal_document?.title}”
						</span>
					</p>
				)
			case "request_access_document":
				return (
					<p className="text-cta-1">
						<span className="font-semibold">
							{details.sender?.first_name}
						</span>{" "}
						solicitou acesso ao documento{" "}
						<span className="font-semibold">
							“{details?.legal_document?.title}”
						</span>
					</p>
				)
			case "password_reset":
				return <p className="text-cta-1">Sua senha foi alterada!</p>
			case "inform_document_comment":
				return (
					<p className="text-cta-1">
						<span className="font-semibold">
							{details.sender?.first_name}
						</span>{" "}
						fez um comentário no documento{" "}
						<span className="font-semibold">
							“{details?.legal_document?.title}”
						</span>
					</p>
				)
			case "inform_first_login":
				return (
					<p className="text-cta-1">
						Seja bem-vindo(a) à Lawdeck! Você está prestes a
						otimizar 80% da sua rotina jurídica. Crie seu primeiro
						documento agora mesmo! 🚀
					</p>
				)
			case "succeed_payment":
				return (
					<div>
						<p className="font-semibold">
							Você adicionou {details.credits} créditos à sua
							conta!
						</p>
						<p className="mt-2 mb-4 text-brand-gray-3 text-cta-2">
							Utilize seus créditos para criar petições, contratos
							e outros documentos com IA.
						</p>
					</div>
				)
			case "succeeded_subscription":
				return details.renew ? (
					<div>
						<p className="font-semibold">
							Seu plano &quot;
							{details.subscription?.product_name}
							&quot; foi renovado!
						</p>
						<p className="mt-2 mb-4 text-brand-gray-3 text-cta-2">
							Seus créditos foram renovados. Continue inovando seu
							escritório com Inteligência Artificial!
						</p>
					</div>
				) : (
					<div>
						<p className="font-semibold">
							Seu plano &quot;
							{details.subscription?.product_name}
							&quot; está ativo!
						</p>
						<p className="mt-2 mb-4 text-brand-gray-3 text-cta-2">
							O futuro do seu escritório começa agora! Aproveite
							todos os benefícios do seu plano!
						</p>
					</div>
				)
			case "canceled_subscription":
				return (
					<div>
						<p className="font-semibold">Seu plano foi cancelado</p>
						<p className="mt-2 mb-4 text-brand-gray-3 text-cta-2">
							Renove seu plano para continuar aproveitando todos
							os benefícios da Lawdeck.
						</p>
					</div>
				)
			case "failed_subscription_payment":
				return (
					<div>
						<p className="font-semibold">
							Seu pagamento foi recusado
						</p>
						<p className="mt-2 mb-4 text-brand-gray-3 text-cta-2">
							O pagamento no cartão{" "}
							<span className="font-semibold">
								final {details?.card_number}
							</span>{" "}
							foi recusado. Atualize sua forma de pagamento até{" "}
							<span className="font-semibold">
								{details?.pay_until &&
									format(
										new Date(details?.pay_until),
										"dd/MM/yyyy",
									)}
							</span>{" "}
							para manter seu plano ativo.
						</p>
					</div>
				)
			default:
				return ""
		}
	}

	function getIconByType() {
		switch (type) {
			case "error_generate_document":
				return <ErrorIcon />
			case "success_generate_document":
				return <SuccessIcon />
			case "password_reset":
				return <CircleKeyIcon />
			case "inform_first_login":
				return <ColoredLawdeck />
			case "succeed_payment":
				return <CreditIcon size="48px" />
			case "succeeded_subscription":
				return <NotificationPlanIcon />
			case "canceled_subscription":
				return <NotificationPlanIcon />
			case "failed_subscription_payment":
				return <NotificationPlanIcon />
			default:
				return (
					<img
						className="rounded-full bg-cover ml-1"
						height={42}
						width={42}
						referrerPolicy="no-referrer"
						src={details.sender?.picture}
					/>
				)
		}
	}

	function formatTodayDate() {
		const hours = differenceInHours(new Date(), new Date(_created_at))

		if (hours <= 0) {
			const minutes = differenceInMinutes(
				new Date(),
				new Date(_created_at),
			)

			if (minutes <= 0) {
				return "Agora mesmo"
			} else {
				return `${minutes} min atrás`
			}
		}

		return `${hours} horas atrás`
	}

	function formatDate() {
		return format(new Date(_created_at), "dd 'de' MMMM - HH'h'mm", {
			locale: ptBR,
		})
	}

	async function handleClickCard() {
		if (
			type === "invite_access_document" ||
			type === "request_access_document" ||
			type === "success_generate_document" ||
			type === "error_generate_document"
		) {
			await handleMarkAsReadNotification()
			navigate(ROUTES.documentDetail({ id: details.legal_document?.id }))
		}

		if (type === "inform_first_login") {
			if (!firstLoginOpenModal) {
				setFirstLoginOpenModal(true)
			}

			navigate("/")
		}
	}

	return (
		<div className="cursor-pointer flex  justify-between border-b-[1px] border-brand-white-3 hover:bg-brand-white-2">
			<div className="flex p-8 w-full" onClick={handleClickCard}>
				<div className="mr-4 h-[48px] w-[48px] flex items-center relative">
					{getIconByType()}
					{!visualized && (
						<div className="w-2 h-2 bg-[#D01313] rounded-full absolute top-[4px] right-[5px]" />
					)}
				</div>
				<div className="flex flex-col">
					<div className="text-cta-1">{getTextByType()}</div>
					{isToday ? (
						<p className=" text-brand-gray-2 text-cta-2">
							{formatTodayDate()}
						</p>
					) : (
						<p className=" text-brand-gray-2 text-cta-2">
							{" "}
							{formatDate()}
						</p>
					)}
				</div>
			</div>

			<div className="flex gap-1 items-center p-8">
				{(type === "invite" || type === "request") && (
					<div className="flex gap-4">
						<Button variant={ButtonVariant.Outlined}>
							Rejeitar
						</Button>
						<Button variant={ButtonVariant.Contained}>
							Aceitar
						</Button>
					</div>
				)}
				<Dropdown
					sections={sections}
					className="right-0 w-[165px]"
					shouldCloseAfterClick={true}
				>
					<div className="text-dark-blue-1 hover:text-dark-blue-2 cursor-pointer">
						<DotsIcon />
					</div>
				</Dropdown>
			</div>
		</div>
	)
}

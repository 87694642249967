import { useEffect } from "react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { RetrieveLegalDocumentResponse } from "types/legalDocument"
import Footer from "components/footer"
import Header from "pages/DocumentPage/header"
import { getDocumentStatus } from "utils/getDocumentStatus"
import { useAtom } from "jotai"
import Loading from "components/loading"
import { NavigationModel } from "./types"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"
import { contractStepAtom } from "./atoms"
import { openLexAtom } from "pages/SidebarPages/subcomponents/atoms"
import useContractAPI from "hooks/useContractAPI"
const getStep = (documentData: RetrieveLegalDocumentResponse) => {
	// 0 = 'user input',
	// 1 = 'gerando clauses',
	// 2 = 'clauses gerado',
	// 3 = 'gerando conteúdo do documento',
	// 4 = 'conteúdo do documento gerado'

	const { clauses, content, processes } = documentData
	const status = getDocumentStatus(documentData)

	let generateNewTheses = false
	const lastProcess = processes?.at(-1)

	if (
		lastProcess?.process_type === "generate_new_clause" ||
		lastProcess?.process_type === "generate_ai_editor_text"
	) {
		generateNewTheses = true
	}

	switch (status) {
		case "draft":
			return 0
		case "strategy":
			return 2
		case "processing":
			if (content && !generateNewTheses) {
				return 3
			} else {
				return 2
			}
		case "error":
			if (clauses && clauses?.length >= 2) {
				return 2
			}

			if (clauses && clauses?.length < 2) {
				showToast(
					"Tivemos um erro ao gerar o contrato, por favor tente novamente.",
					ToastType.Error,
					{ text: "", onClick: () => {} },
					{
						toastId: "error-generate-clauses",
					},
				)

				return 0
			}

			return 0

		case "completed":
			if (content && !generateNewTheses) {
				return 3
			} else {
				return 2
			}
		default:
			throw new Error(`Invalid combination: ${status} and ${clauses}`)
	}
}

export const ContractDisplay = ({
	navigationModel,
	documentId,
}: {
	navigationModel: NavigationModel
	documentId: string
}) => {
	const { retrieveContract, updateContract } = useContractAPI()
	const { data } = useQuery(["legalDocument", documentId], () =>
		retrieveContract(documentId),
	)

	const { steps } = navigationModel
	const [currentStep, setCurrentStep] = useAtom(contractStepAtom)
	const status = getDocumentStatus(data)
	const [openLex] = useAtom(openLexAtom)
	const queryClient = useQueryClient()

	const updateContractMutation = useMutation({
		mutationFn: updateContract,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["retrieveLegalDocument", documentId],
			})
		},
	})

	useEffect(() => {
		if (data) {
			setCurrentStep(getStep(data))
		}
	}, [data?.id, status])

	if (currentStep === undefined || !data) {
		return <Loading />
	}

	const HeaderButtons = steps[currentStep]?.headerButtons
	const Step = steps[currentStep]?.Component
	const PrimaryButton = steps[currentStep]?.primaryButton
	const SecondaryButton = steps[currentStep]?.secondaryButton
	const footerText = steps[currentStep]?.footerText

	return (
		<div className="w-full relative">
			<div
				className={`flex items-center mb-[32px] justify-between ${
					currentStep === 2 ? "" : ""
				}
				${
					!openLex && (currentStep === 4 || currentStep === 3)
						? "w-full"
						: "w-[calc(100%-324px)]"
				}`}
			>
				{data && (
					<Header
						documentId={documentId}
						data={data}
						updateMutation={updateContractMutation}
					/>
				)}
				{HeaderButtons && HeaderButtons}
			</div>
			<div>{Step}</div>
			{(PrimaryButton || SecondaryButton) && (
				<Footer stepText={footerText}>
					<div className="flex items-center">
						{SecondaryButton && SecondaryButton}
						{PrimaryButton && PrimaryButton}
					</div>
				</Footer>
			)}
		</div>
	)
}

import axiosInstance from "axiosInstance"
import { FeatureModal } from "types/featureModal"


const useFeatureModalAPI = () => {

  const getFeatureModal = async () => {
    const response = await axiosInstance.get<FeatureModal>(`/feature_modal/`)
    return response.data

  }

  return {
    getFeatureModal
  }

}

export default useFeatureModalAPI
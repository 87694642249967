import ExitButton from "components/exit-button"
import React, { useEffect, useState } from "react"
import image from "assets/verify-email.jpg"
import Modal from "components/modal"
import { useLongPoll } from "hooks/useLongPoll"
import { ROUTES } from "routes"
import { useNavigate } from "react-router-dom"
import { useMutation } from "@tanstack/react-query"
import useAPI from "hooks/useAPI"
import useCurrentUser from "hooks/useCurrentUser"

interface Props {
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ConfirmEmailOverlay = ({ isOpen, setIsOpen }: Props) => {
	const { resendVerifyEmail } = useAPI()
	const navigate = useNavigate()
	const user = useCurrentUser()
	const [counter, setCounter] = useState(59)

	const confirmEmailRequestCodeMutation = useMutation({
		mutationFn: resendVerifyEmail,
	})

	useEffect(() => {
		if (counter === 0) return
		const interval = setInterval(() => {
			setCounter((prev) => prev - 1)
		}, 1000)
		return () => clearInterval(interval)
	}, [counter])

	useEffect(() => {
		if (user && user.email_verified && isOpen) {
			navigate(ROUTES.basePage())
			setIsOpen(false)
		}
	}, [user])

	useLongPoll({
		conditionToStop: user && user.email_verified,
		queryKey: ["current-user"],
		deps: [user && user.email_verified],
		ms: 5000,
	})
	return (
		isOpen && (
			<Modal
				size="full"
				width="w-full !p-0"
				openModal={isOpen}
				setOpenModal={setIsOpen}
				whitoutCloseButton
				dismissible={false}
				className="!overflow-hidden z-[1000] !p-0"
			>
				<div className="w-full h-[100vh] flex items-center justify-center bg-verify-gradient">
					<div className="bg-white p-[64px] rounded-xl shadow-3  mx-auto w-full max-w-[554px] relative">
						<ExitButton className="absolute top-6 left-6" />
						<div className="flex items-center justify-center mb-6 ">
							<div className="w-[269px] h-[202px] bg-green-100 rounded-full flex items-center justify-center">
								<img src={image} width={269} height={202} />
							</div>
						</div>

						<div>
							<p className="text-h0 font-semibold mb-4 font-lato">
								Verifique seu email!
							</p>
							<p className="text-gray-600 text-start  leading-6 font-lato text-[18px]">
								Enviamos um link de confirmação para o seu
								email, lembre-se de checar a caixa de spam.
							</p>
						</div>
						<p className="text-cta-1 text-brand-gray-3 mt-4">
							Não recebeu?{" "}
							<span
								onClick={() => {
									if (counter === 0) {
										confirmEmailRequestCodeMutation.mutate()
										setCounter(59)
									}
								}}
								className={`${
									counter === 0
										? "text-light-blue-4 cursor-pointer"
										: "text-brand-gray-2"
								} `}
							>
								{counter === 0
									? "Envie novamente"
									: `Envie novamente em ${counter}s`}
							</span>
						</p>
					</div>
				</div>
			</Modal>
		)
	)
}

export default ConfirmEmailOverlay

import { AxiosResponse } from "axios"
import axiosInstance from "axiosInstance"
import { ProductCatalog, ProductCatalogCredit } from "types/productCatalog"

const useProductCatalogAPI = () => {
	const productCatalogCredits = async () => {
		const response: AxiosResponse<ProductCatalogCredit> =
			await axiosInstance.get(`/product-catalog/credits`)
		return response.data
	}

	const productCatalog = async () => {
		const response: AxiosResponse<ProductCatalog> = await axiosInstance.get(
			`/product-catalog/plans`,
		)
		return response.data
	}

	const productCatalogV2 = async () => {
		const response: AxiosResponse<ProductCatalog> = await axiosInstance.get(
			`/product-catalog/v2/plans`,
		)
		return response.data
	}

	return { productCatalog, productCatalogCredits, productCatalogV2 }
}
export default useProductCatalogAPI

import BaseIcon from "./BaseIcon"

const FilterIcon = ({ className }: { className?: string }) => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			className={className}
		>
			<path d="M13.3333 20H10.6667C10.313 20 9.97391 19.8595 9.72386 19.6095C9.47381 19.3594 9.33333 19.0203 9.33333 18.6667V13.6067L4.39333 8.66667C4.14294 8.41777 4.00149 8.07972 4 7.72667V5.33333C4 4.97971 4.14048 4.64057 4.39052 4.39052C4.64057 4.14048 4.97971 4 5.33333 4H18.6667C19.0203 4 19.3594 4.14048 19.6095 4.39052C19.8595 4.64057 20 4.97971 20 5.33333V7.72667C19.9985 8.07972 19.8571 8.41777 19.6067 8.66667L14.6667 13.6067V18.6667C14.6667 19.0203 14.5262 19.3594 14.2761 19.6095C14.0261 19.8595 13.687 20 13.3333 20ZM5.33333 5.33333V7.72667L10.6667 13.06V18.6667H13.3333V13.06L18.6667 7.72667V5.33333H5.33333Z" />
		</BaseIcon>
	)
}

export default FilterIcon

import { Button } from "components/button"
import Tag from "components/tag"
import LinkOutIcon from "icons/LinkOutIcon"
import { useState } from "react"
import VisaImage from "assets/visa.png"
import MastercardImage from "assets/mastercard.jpg"
import DowngradePlan from "./DowngradePlan"
import CancelPlan from "./CancelPlan"
import { useMutation, useQuery } from "@tanstack/react-query"
import useCurrentUser from "hooks/useCurrentUser"
import { formatDate } from "utils/formatDate"
import convertAmountToReal from "utils/convertAmountToReal"
import useBasicPlan from "hooks/useBasicPlan"
import handleCheckoutError from "utils/handleCheckoutError"
import { ProductCatalogItem } from "types/productCatalog"
import PostLauchPlanCard from "pages/BasePage/plan-card/post-lauch-plan-card"
import { businessPlan } from "utils/businessPlan"
import { sanitizePlan } from "utils/sanitizePlan"
import { ButtonIconPlacement, ButtonVariant } from "components/button/types"
import useSubscriptionAPI from "hooks/useSubscriptionAPI"
import useProductCatalogAPI from "hooks/useProductCatalogAPI"
import { useFeatureFlag } from "hooks/useFeatureFlag"
import FEATURE_FLAGS from "feature-flags"

export default function PlansAndPayment() {
	const user = useCurrentUser()
	const [isMonthlyPlan, setIsMonthlyPlan] = useState(
		user?.subscription?.billing_type === "monthly" ? true : false,
	)
	const [openDowngradeModal, setOpenDowngradeModal] = useState(false)
	const [openCancelPlanModal, setOpenCancelPlanModal] = useState(false)
	const {
		subscriptionCheckout,
		subscriptionPaymentsHistory,
		subscriptionCustomerPortal,
	} = useSubscriptionAPI()
	const { productCatalog, productCatalogV2 } = useProductCatalogAPI()
	const [limit, setLimit] = useState(10)
	const { data: paymentHistory } = useQuery(
		["paymentHistory"],
		subscriptionPaymentsHistory,
	)

	const { data: newBussinessModel } = useFeatureFlag(
		FEATURE_FLAGS.newBussinessModel,
	)
	const { data: productCatalogListV1 } = useQuery(
		["product-catalog"],
		() => productCatalog(),
		{
			refetchOnWindowFocus: false,
		},
	)
	const { data: productCatalogListV2 } = useQuery(
		["product-catalog-v2"],
		() => productCatalogV2(),
		{
			refetchOnWindowFocus: false,
		},
	)

	const productCatalogList = newBussinessModel
		? productCatalogListV2
		: productCatalogListV1

	const isBasicPlan = useBasicPlan()

	const isProfessionalSelected =
		user?.subscription?.active && isMonthlyPlan
			? user?.subscription.product_name === "Profissional" &&
			  user?.subscription.billing_type === "monthly"
			: user?.subscription &&
			  user?.subscription.product_name === "Profissional" &&
			  user?.subscription.billing_type === "annual"
	const professionalText = isProfessionalSelected
		? "Gerenciar"
		: isBasicPlan
		? "Assinar agora"
		: isMonthlyPlan
		? "Trocar para plano mensal"
		: "Trocar para plano anual"

	const subscriptionCheckoutMutation = useMutation({
		mutationFn: subscriptionCheckout,
	})

	const subscriptionCustomerPortalMutation = useMutation({
		mutationFn: subscriptionCustomerPortal,
	})

	function handleFreePlanClick() {
		return isBasicPlan
			? setOpenCancelPlanModal(true)
			: setOpenDowngradeModal(true)
	}

	function handleCheckout() {
		const tab = window.open(`${window.location.origin}/redirect`)

		let lookupKey = isMonthlyPlan
			? "professional_monthly"
			: "professional_annual"

		if (productCatalogList) {
			lookupKey = isMonthlyPlan
				? productCatalogList.professional_plan.prices.monthly
						.lookup_key || "professional_monthly"
				: productCatalogList.professional_plan.prices.annual
						.lookup_key || "professional_annual"
		}

		subscriptionCheckoutMutation
			.mutateAsync({
				successUrl: `${window.location.origin}/checkout-success`,
				lookupKey: lookupKey,
			})
			.then((data) => {
				tab!.location.href = data.checkout_url
			})
			.catch(() => handleCheckoutError(tab))
	}

	function handleProfessionalPlanClick() {
		return isProfessionalSelected
			? setOpenCancelPlanModal(true)
			: handleCheckout()
	}

	function handleManageCards() {
		const tab = window.open(`${window.location.origin}/redirect`)
		subscriptionCustomerPortalMutation
			.mutateAsync({
				returnUrl: `${window.location.href}`,
			})
			.then((data) => (tab!.location.href = data.customer_portal_url))
			.catch(() => handleCheckoutError(tab))
	}

	const cardBrand: { [key: string]: any } = {
		visa: VisaImage,
		mastercard: MastercardImage,
	}

	const isCancelPlan =
		user?.subscription?.end_period &&
		user?.subscription?.cancel_at_period_end

	return (
		<div>
			<div className="mb-8">
				<div className="flex justify-center flex-col">
					<h3 className={"font-semibold"}>Planos</h3>
					<div className=" flex items-center justify-between w-full mb-6">
						<h4 className="text-brand-gray-2 font-normal">
							Escolha o plano que mais combina com sua rotina:
						</h4>

						{isBasicPlan && (
							<div className="flex items-center justify-between">
								<p
									className={`text-cta-1 font-semibold ${
										!isMonthlyPlan && "text-brand-gray-2"
									}`}
								>
									Mensal
								</p>
								<label className="relative inline-flex items-center cursor-pointer mx-4">
									<input
										type="checkbox"
										value=""
										className="sr-only peer"
										checked={!isMonthlyPlan}
										onChange={() =>
											setIsMonthlyPlan(!isMonthlyPlan)
										}
									/>
									<div className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-light peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
								</label>
								<p
									className={`text-cta-1 font-semibold  mr-[10px] ${
										isMonthlyPlan && "text-brand-gray-2"
									}`}
								>
									Anual
								</p>
								<Tag
									text="Economize 20%"
									background="bg-semi-white-3"
									textColor="text-light-blue-4"
								/>
							</div>
						)}
					</div>

					<div className="flex  justify-center">
						{productCatalogList &&
							Object.entries(productCatalogList).map(
								([planKey, planDetails]: [
									string,
									ProductCatalogItem,
								]) => {
									const professionalButton = {
										buttonVariant: isBasicPlan
											? ButtonVariant.Contained
											: ButtonVariant.Outlined,

										buttonText: professionalText,
									}
									const freeButton = {
										buttonVariant: ButtonVariant.Text,
										buttonText: "Escolher plano",
									}

									const buttonProps =
										planKey === "professional_plan"
											? professionalButton
											: freeButton

									return (
										<PostLauchPlanCard
											buttonClick={
												planKey === "free_plan"
													? handleFreePlanClick
													: handleProfessionalPlanClick
											}
											key={planKey}
											plan={sanitizePlan(
												planKey,
												planDetails,
												isBasicPlan,
												isProfessionalSelected || false,

												isMonthlyPlan,
												user,
												buttonProps,
											)}
											isMonthlyPlan={isMonthlyPlan}
											withoutButton={
												planKey === "free_plan" &&
												(isBasicPlan || isCancelPlan)
											}
											dividePlan={
												planKey === "professional_plan"
											}
										/>
									)
								},
							)}
						<PostLauchPlanCard
							plan={businessPlan}
							isMonthlyPlan={isMonthlyPlan}
							withoutButton
						/>
					</div>
				</div>
			</div>

			{paymentHistory && paymentHistory.length > 0 && (
				<>
					<div className="h-[1px] w-full bg-[#F0F0F0]" />
					<div className="mt-8">
						<div className="flex items-center justify-between">
							<div>
								<h3 className="font-semibold mb-2">
									Método de pagamento
								</h3>
								<h4 className="text-brand-gray-2 font-normal max-w-[430px]">
									Escolha um método de pagamento ideal para
									atender às suas necessidades
								</h4>
							</div>
							<Button
								variant={ButtonVariant.Text}
								icon={
									<LinkOutIcon
										className="stroke-current"
										noStroke
									/>
								}
								iconPlacement={ButtonIconPlacement.End}
								onClick={handleManageCards}
							>
								Gerenciar Cartões
							</Button>
						</div>

						<div className="h-[1px] w-full bg-[#F0F0F0] my-8" />

						<div className="flex w-full">
							<div className="w-[40%]">
								<h3 className="font-semibold mb-2">
									Histórico de Pagamento
								</h3>
								<h4 className="text-brand-gray-2 font-normal max-w-[430px]">
									Acompanhe o histórico dos seus pagamentos
									anteriores
								</h4>
							</div>

							<div className="flex flex-col w-[60%] gap-[20px]">
								<div className="font-bold w-full flex justify-between p-2">
									<div className="w-[20%]">Data</div>
									<div className="w-[20%] mr-4">
										Descrição
									</div>
									<div className="w-[30%]">
										Forma de Pagamento
									</div>
									<div className="w-[15%]">Valor</div>
									<div className="flex w-[15%] justify-end">
										Fatura
									</div>
								</div>

								<div>
									{paymentHistory
										?.slice(0, limit)
										.map((payment, idx) => (
											<div
												key={idx}
												className="flex mb-2 text-brand-gray-3 w-full p-2"
											>
												<div className="w-[20%] flex items-center">
													{
														formatDate(
															payment.created_at,
														).split(" ")[0]
													}
												</div>
												<div className="w-[20%] mr-4">
													{payment.description}
												</div>
												<div className="w-[30%] flex items-center">
													<img
														src={
															cardBrand[
																payment.card
																	.brand
															]
														}
														className="mr-2"
													/>
													**** {payment.card.last4}
												</div>
												<div className="w-[15%] flex items-center">
													{convertAmountToReal(
														payment.amount,
													)}
												</div>
												<div className="flex items-center justify-end p-2 w-[15%]">
													<a
														className="cursor-pointer h-5"
														href={
															payment.invoice_url ??
															payment.receipt_url
														}
														target="_blank"
														rel="noopener noreferrer"
													>
														<LinkOutIcon className=" w-5 h-5" />{" "}
													</a>
												</div>
											</div>
										))}
									{limit < 11 &&
										paymentHistory.length > 10 && (
											<div className="flex w-full p-2">
												<div className="w-[20%]">
													{""}
												</div>
												<div className="w-[20%]">
													{""}
												</div>
												<div className="w-[30%]">
													<Button
														variant={
															ButtonVariant.Text
														}
														onClick={() => {
															setLimit(20)
														}}
													>
														Carregar mais
													</Button>
												</div>
											</div>
										)}
								</div>
							</div>
						</div>
					</div>
				</>
			)}
			{openDowngradeModal && (
				<DowngradePlan
					openModal={openDowngradeModal}
					setOpenModal={setOpenDowngradeModal}
				/>
			)}

			{openCancelPlanModal && (
				<CancelPlan
					openModal={openCancelPlanModal}
					setOpenModal={setOpenCancelPlanModal}
				/>
			)}
		</div>
	)
}

import { ROUTES } from "routes"
import LegalDocumentTableBody from "./LegalDocumentTableBody"
import { useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { Row, Table } from "@tanstack/react-table"
import { RetrieveLegalDocumentResponse } from "types/legalDocument"
import { useFeatureFlag } from "hooks/useFeatureFlag"
import FEATURE_FLAGS from "feature-flags"

interface TableRowProps {
	row: Row<RetrieveLegalDocumentResponse>
	table: Table<RetrieveLegalDocumentResponse>
	shiftKeyPressed: boolean
}

const TableRow = ({ row, table, shiftKeyPressed }: TableRowProps) => {
	const { data: legalDocumentsRework } = useFeatureFlag(
		FEATURE_FLAGS.legalDocumentsRework,
	)
	const checkboxRef = useRef<HTMLInputElement>(null)
	const navigate = useNavigate()

	function getWidth(type: string) {
		if (type === "title") {
			return "basis-[40%] grow"
		}
		if (type === "type") {
			return "basis-[15%] shrink"
		}
		if (type === "status") {
			return "basis-[15%] shrink"
		}
		if (type === "_updated_at") {
			return "basis-[20%] shrink"
		}
		if (type === "Actions") {
			return "basis-[10%] shrink"
		}
	}
	useEffect(() => {
		if (shiftKeyPressed) {
			const { rows } = table.getRowModel()
			const selectedRows = Object.fromEntries(
				Object.keys(table.getSelectedRowModel().rowsById).map((key) => [
					key,
					true,
				]),
			)
			if (Object.keys(selectedRows).length > 1) {
				const firstSelectedRowIndex = rows.findIndex(
					(row) => row.id === Object.keys(selectedRows)[0],
				)
				const lastSelectedRowIndex = rows.findIndex(
					(row) => row.id === Object.keys(selectedRows).at(-1),
				)
				const newSelectedRows = rows
					.slice(firstSelectedRowIndex, lastSelectedRowIndex + 1)
					.map((row) => row.id)

				table.setRowSelection(() => {
					return Object.fromEntries(
						newSelectedRows.map((key) => [key, true]),
					)
				})
			}
		}
	}, [row.getIsSelected()])

	return (
		<div
			key={row.id}
			className={`relative gap-[20px] bg-white mb-4 flex items-stretch  hover:cursor-pointer hover:shadow-[0px_0px_10px_0px_rgba(0,0,0,0.14)] hover:text-dark-blue-2 ${
				row.getIsSelected()
					? "border-[3px] border-light-blue-1 rounded-[4px]"
					: "border-[3px] border-transparent rounded-[4px]"
			}`}
		>
			{row.getVisibleCells().map((cell: any) => {
				if (cell.column.id === "type" && legalDocumentsRework)
					return null
				const width = getWidth(cell.column.id)

				const handleMouseEnter = () => {
					if (checkboxRef.current) {
						checkboxRef.current.style.opacity = "1"
					}
				}

				const handleMouseLeave = () => {
					if (checkboxRef.current && !row.getIsSelected()) {
						checkboxRef.current.style.opacity = "0"
					}
				}
				useEffect(() => {
					if (checkboxRef.current && row.getIsSelected()) {
						checkboxRef.current.style.opacity = "1"
					}
					if (checkboxRef.current && !row.getIsSelected()) {
						checkboxRef.current.style.opacity = "0"
					}
				}, [row.getIsSelected()])

				return (
					<div
						className={`${width} flex items-center h-[104px]`}
						key={cell.id}
						onClick={() => {
							if (
								cell.column.id === "Actions" ||
								cell.column.id === "select"
							)
								return
							navigate(
								ROUTES.documentDetail({
									id: row.original.id,
								}),
							)
						}}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					>
						{cell.column.id === "title" && (
							<>
								<input
									ref={checkboxRef}
									type="checkbox"
									className={`mx-1 cursor-pointer rounded-sm focus:shadow-none focus:ring-0 focus:outline-none checked:!bg-[3.55px] ${
										row.getIsSelected()
											? "opacity-100"
											: "opacity-0"
									}`}
									id={row.original.id}
									{...{
										checked: row.getIsSelected(),
										disabled: !row.getCanSelect(),
										onChange: (e) => {
											e.stopPropagation()
											row.toggleSelected()
										},
										onClick: (e) => {
											e.stopPropagation()
											row.toggleSelected()
										},
									}}
								/>
							</>
						)}
						<LegalDocumentTableBody cell={cell} />
					</div>
				)
			})}
		</div>
	)
}

export default TableRow

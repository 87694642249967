const CreditIcon2 = ({ size }: { size?: string }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g filter="url(#filter0_d_13863_3499)">
				<g filter="url(#filter1_i_13863_3499)">
					<path
						d="M22 12C22 17.5228 17.618 22 12 22C6.49438 22 2 18.0674 2 12C2 6.1573 6.83146 2 12 2C17.8427 2 22 6.47715 22 12Z"
						fill="url(#paint0_linear_13863_3499)"
					/>
					<path
						d="M22 12C22 17.5228 17.618 22 12 22C6.49438 22 2 18.0674 2 12C2 6.1573 6.83146 2 12 2C17.8427 2 22 6.47715 22 12Z"
						fill="url(#pattern0_13863_3499)"
						fillOpacity="0.1"
					/>
				</g>
				<g filter="url(#filter2_ii_13863_3499)">
					<ellipse
						cx="12.4495"
						cy="12"
						rx="9.55056"
						ry="10"
						fill="url(#paint1_linear_13863_3499)"
					/>
				</g>
				<g filter="url(#filter3_i_13863_3499)">
					<path
						d="M20.5271 12.0003C20.5271 16.4686 16.9539 20.078 12.5618 20.078C8.16969 20.078 4.59642 16.4686 4.59642 12.0003C4.59642 7.53202 8.16969 3.9226 12.5618 3.9226C16.9539 3.9226 20.5271 7.53202 20.5271 12.0003Z"
						stroke="url(#paint2_linear_13863_3499)"
						strokeWidth="1.14793"
					/>
				</g>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8.7417 15.9228C9.69605 16.7667 10.9599 17.2805 12.3461 17.2805C12.6338 17.2805 12.9161 17.2584 13.1916 17.2158V15.4996C12.9201 15.5615 12.637 15.5942 12.3461 15.5942C11.5697 15.5942 10.8493 15.3611 10.2547 14.9628V7.13193C9.69598 7.36312 9.18539 7.68412 8.7417 8.07651V15.9228ZM13.1916 14.4555V8.4997C14.8239 8.87177 16.0395 10.2975 16.0395 11.9996C16.0395 13.5103 15.0821 14.8032 13.7256 15.3351V17.1056C16.0309 16.5081 17.7305 14.4485 17.7305 11.9996C17.7305 9.08308 15.3198 6.71875 12.3461 6.71875C12.1201 6.71875 11.8973 6.73241 11.6786 6.75893V14.5179C11.8922 14.5832 12.1158 14.6179 12.3461 14.6179C12.6413 14.6179 12.9255 14.5609 13.1916 14.4555Z"
					fill="white"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d_13863_3499"
					x="0.46943"
					y="0.46943"
					width="23.0611"
					height="23.0611"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation="0.765285" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_13863_3499"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_13863_3499"
						result="shape"
					/>
				</filter>
				<filter
					id="filter1_i_13863_3499"
					x="2"
					y="2"
					width="20.3826"
					height="20.3826"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dx="0.382642" dy="0.382642" />
					<feGaussianBlur stdDeviation="0.191321" />
					<feComposite
						in2="hardAlpha"
						operator="arithmetic"
						k2="-1"
						k3="1"
					/>
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.366667 0 0 0 0 0.366667 0 0 0 0 0.366667 0 0 0 0.2 0"
					/>
					<feBlend
						mode="normal"
						in2="shape"
						result="effect1_innerShadow_13863_3499"
					/>
				</filter>
				<pattern
					id="pattern0_13863_3499"
					patternContentUnits="objectBoundingBox"
					width="1"
					height="1"
				>
					<use
						href="#image0_13863_3499"
						transform="translate(-0.00819672) scale(0.00819672)"
					/>
				</pattern>
				<filter
					id="filter2_ii_13863_3499"
					x="2.89893"
					y="1.76257"
					width="19.5759"
					height="20.2374"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dx="0.47486" dy="-0.23743" />
					<feGaussianBlur stdDeviation="0.356145" />
					<feComposite
						in2="hardAlpha"
						operator="arithmetic"
						k2="-1"
						k3="1"
					/>
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.560784 0 0 0 0 0.196078 0 0 0 0 0.870588 0 0 0 0.2 0"
					/>
					<feBlend
						mode="normal"
						in2="shape"
						result="effect1_innerShadow_13863_3499"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dx="0.118715" />
					<feGaussianBlur stdDeviation="0.23743" />
					<feComposite
						in2="hardAlpha"
						operator="arithmetic"
						k2="-1"
						k3="1"
					/>
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.531354 0 0 0 0 0.208906 0 0 0 0 0.795833 0 0 0 1 0"
					/>
					<feBlend
						mode="normal"
						in2="effect1_innerShadow_13863_3499"
						result="effect2_innerShadow_13863_3499"
					/>
				</filter>
				<filter
					id="filter3_i_13863_3499"
					x="4.02246"
					y="3.34863"
					width="17.0786"
					height="17.3037"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feMorphology
						radius="0.382642"
						operator="dilate"
						in="SourceAlpha"
						result="effect1_innerShadow_13863_3499"
					/>
					<feOffset dx="0.382642" dy="0.765285" />
					<feComposite
						in2="hardAlpha"
						operator="arithmetic"
						k2="-1"
						k3="1"
					/>
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.537255 0 0 0 0 0.203922 0 0 0 0 0.815686 0 0 0 0.15 0"
					/>
					<feBlend
						mode="normal"
						in2="shape"
						result="effect1_innerShadow_13863_3499"
					/>
				</filter>
				<linearGradient
					id="paint0_linear_13863_3499"
					x1="4.58427"
					y1="6.49438"
					x2="19.5281"
					y2="34.5843"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#942FE8" />
					<stop offset="0.997885" stopColor="#611C9C" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_13863_3499"
					x1="1.32589"
					y1="11.7753"
					x2="15.9326"
					y2="5.82023"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#AE30FC" />
					<stop offset="1" stopColor="#B174FF" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_13863_3499"
					x1="14.9168"
					y1="21.5843"
					x2="7.55751"
					y2="-2.49802"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#45126A" stopOpacity="0.3" />
					<stop offset="1" stopColor="#283394" stopOpacity="0" />
					<stop offset="1" stopColor="#B174FF" stopOpacity="0.2" />
				</linearGradient>
				<image
					id="image0_13863_3499"
					width="124"
					height="122"
					href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHwAAAB6CAYAAAB9RzejAAAAAXNSR0IArs4c6QAAAJ1JREFUeJzt1zERxAAQxLBL+HP+R5HZwhICt37u7ndkvOsAAACAtud8eIoPBwAAmPLhMT4cAABgyofH+HAAAIApHx7jwwEAAKZ8eIwPBwAAmPLhMT4cAABgyofH+HAAAIApHx7jwwEAAKZ8eIwPBwAAmPLhMT4cAABgyofH+HAAAIApHx7jwwEAAKZ8eIwPBwAAmPLhMT4cAAAA4CN/6lMPH9CQcTwAAAAASUVORK5CYII="
				/>
			</defs>
		</svg>
	)
}
export default CreditIcon2

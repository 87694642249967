import { Button } from "components/button"
import CreditIcon from "icons/CreditIcon"

import working from "assets/working.png"
import PlanBenefits from "./subcomponents/PlanBenefits"

import { ProductCatalogItem } from "types/productCatalog"
import { formatPrice } from "utils/formatPrice"
import { ButtonVariant } from "components/button/types"

interface PlanCardProps {
	plan: ProductCatalogItem
	isMonthlyPlan: boolean
	withoutButton?: boolean | "" | undefined
	smallCard?: boolean
	smallerCard?: boolean
	buttonClick?: () => void
	dividePlan?: boolean
	cardHeader?: React.ReactNode
}

const PostLauchPlanCard = ({
	plan,
	isMonthlyPlan,
	withoutButton,
	smallCard,
	smallerCard,
	buttonClick,
	dividePlan,
	cardHeader,
}: PlanCardProps) => {
	const classNameContainer = smallCard ? `p-6 w-[223px]` : `  w-[348px] p-8 `

	return (
		<div
			className={`relative w-full bg-white rounded-lg border border-brand-gray-1 ${
				plan.containerClassName
			} ${
				plan.selected &&
				"border-transparent outline outline-offset-2 outline-light-blue-4"
			} ${classNameContainer}`}
		>
			<div className="flex flex-col h-full justify-between">
				<div>
					{plan.commingSoon ? (
						<>
							<div className="flex items-center justify-between">
								<h3 className={`font-bold font-lato `}>
									{plan.display_name}
								</h3>
								{plan.tag && plan.tag}
							</div>
							<div className="w-[full] h-[1px] border border-brand-white-3 my-6" />
							<div className="mt-[60px] flex flex-col items-center">
								<img
									src={working}
									width={236}
									height={189.41}
								/>
								<p
									className={`text-cta-1  font-lato text-brand-gray-3 text-center`}
								>
									Estamos trabalhando nisso!
								</p>
							</div>
						</>
					) : (
						<>
							{cardHeader ? (
								<div>{cardHeader}</div>
							) : (
								<div className="flex items-center justify-between">
									{smallCard ? (
										<p
											className={`text-cta-2 font-semibold font-lato ${plan.titleColor}`}
										>
											{plan.display_name}
										</p>
									) : (
										<h3
											className={`font-bold font-lato ${plan.titleColor}`}
										>
											{plan.display_name}
										</h3>
									)}
									{plan.tag && plan.tag}
								</div>
							)}
							{!smallCard && (
								<div className="w-[full] h-[1px] border border-brand-white-3 my-6" />
							)}

							{cardHeader && (
								<h3
									className={`font-semibold font-lato ${plan.titleColor}`}
								>
									{plan.display_name}
								</h3>
							)}
							<div className="">
								<div
									className={`flex items-center mt-2 ${
										smallerCard && "h-4"
									}`}
								>
									<CreditIcon
										size={smallerCard ? "16px" : "32px"}
									/>
									<p
										className={
											smallCard
												? "text-h4 font-extrabold  ml-2"
												: "text-h1 font-extrabold font-lato ml-2"
										}
									>
										{isMonthlyPlan
											? plan?.prices?.monthly
													.credits_to_add
											: plan?.prices?.annual
													.credits_to_add &&
											  dividePlan
											? plan?.prices?.annual
													.credits_to_add / 12
											: plan?.prices?.annual
													.credits_to_add}
									</p>

									<h3
										className={`font-normal font-lato ml-2 ${
											smallCard && "text-small-1"
										}`}
									>
										créditos
									</h3>

									{plan?.creditsPerMonth && (
										<p
											className={`text-cta-2 text-brand-gray-2 ml-2 mt-[6px] font-lato ${
												smallCard &&
												"!text-small-2 !ml-1"
											}`}
										>
											/mês
										</p>
									)}
								</div>

								<div
									className={`mb-4 mt-2 flex items-center ${
										smallerCard && "h-6"
									}`}
								>
									<p
										className={`text-h3 font-semibold text-transparent bg-clip-text bg-gradient-to-r  border-light-blue-4 from-light-blue-4 to-light-blue-5 ${
											smallCard && "!text-cta-1 "
										}`}
									>
										<span className="mr-1">R$</span>
										{isMonthlyPlan
											? formatPrice(
													plan?.prices?.monthly
														.monthly_price,
											  )
											: formatPrice(
													plan?.prices?.annual
														.monthly_price,
											  )}
										<span className="text-[16px] font-semibold   leading-6">
											/
										</span>
										<span className="text-[16px] font-semibold   leading-6 mt-[2px]">
											mês
										</span>
									</p>
								</div>

								{plan.marketing_features_headline && (
									<p className="font-semibold text-dark-blue-1 mb-[24px]">
										Tudo que tem no Gratuito mais:
									</p>
								)}

								<div className="flex flex-col gap-4">
									{plan.marketing_features.map((item) => (
										<PlanBenefits
											key={item.description}
											item={item.description}
											allowed
											smallCard={smallCard}
										/>
									))}
								</div>
							</div>
						</>
					)}
				</div>
				{!withoutButton && (
					<Button
						className="w-[100%] flex items-center justify-center mt-6"
						variant={
							plan?.button?.buttonVariant
								? plan?.button?.buttonVariant
								: ButtonVariant.Contained
						}
						icon={plan?.button?.buttonIcon}
						isLoading={plan?.button?.buttonIsLoading}
						onClick={() => buttonClick && buttonClick()}
					>
						{plan?.button?.buttonText || "Escolher"}
					</Button>
				)}
			</div>
			{plan.warning && plan.warning}
		</div>
	)
}

export default PostLauchPlanCard

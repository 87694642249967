import BaseIcon from "./BaseIcon"

const JusticeIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<g clipPath="url(#clip0_2688_17297)">
				<path
					d="M12 1C14.7625 1 17 3.1697 17 5.84848C17 8.52727 14.7625 10.697 12 10.697C9.2375 10.697 7 8.52727 7 5.84848C7 3.1697 9.2375 1 12 1ZM17 13.7758C17 15.0606 16.65 18.0545 14.2625 21.4L13.25 15.5455L14.425 13.2667C13.65 13.1818 12.8375 13.1212 12 13.1212C11.1625 13.1212 10.35 13.1818 9.575 13.2667L10.75 15.5455L9.7375 21.4C7.35 18.0545 7 15.0606 7 13.7758C4.0125 14.6242 2 16.1515 2 17.9697V22.8182H22V17.9697C22 16.1515 20 14.6242 17 13.7758Z"
					fill="#646464"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2688_17297">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</BaseIcon>
	)
}

export default JusticeIcon

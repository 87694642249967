import { useAtom } from "jotai"
import React, { useEffect, useRef, useState } from "react"
import {
	contractTypeAtom,
	openAtom,
	petitionTypeAtom,
	toFavorAtom,
	userInputContractAtom,
} from "./atoms"
import Loading from "components/loading"
import InputField from "components/input-field"
import ReactTextareaAutosize from "react-textarea-autosize"
import ExclamationIcon from "icons/ExclamationIcon"
import CreditsModal from "pages/SidebarPages/subcomponents/CreditsModal"
import _ from "lodash"
import { useLegalDocument } from "hooks/useLegalDocument"
import { LegalDocumentType } from "types/legalDocument"
import { useLegalDocumentMutation } from "hooks/useLegalDocumentMutation"
import { userInputAtom } from "../steps/PetitionPageV3/atoms"
import PetitionAutoComplete from "./AutoComplete"
import ModelIcon from "icons/ModelIcon"
import CloseIcon from "icons/CloseIcon"
import { useLocation } from "react-router-dom"
import FEATURE_FLAGS from "feature-flags"
import { useFeatureFlag } from "hooks/useFeatureFlag"
import FileDropzone from "./DocumentFacts/subcomponents/FileDropzone"

function NewFactsStep({
	documentId,
	type,
}: {
	documentId: string
	type: LegalDocumentType
}) {
	const textAreaData = {
		[LegalDocumentType.Petition]: {
			label: "Descreva os fatos que o levaram a entrar com essa petição:",
			placeholder:
				"O autor comprou uma TV nova e após um ano apareceu um vício oculto, a fabricante não quer se responsabilizar pelo problema e não irá consertar o produto...",
		},
		[LegalDocumentType.Contract]: {
			label: "Descreva todas as informações que são relevantes para o seu contrato:",
			placeholder:
				"O contratante será João de Souza Silva e a contratada Maria Aparecida Santos. A transação envolve uma fiança no valor de três alugueis, sendo o valor do aluguel R$ 1.000,00.",
		},
	}
	const translate = {
		petition: "petição",
		contract: "contrato",
		power_of_attorney: "procuração",
		uploaded_document: "documento",
	}
	const [showError, setShowError] = useState(false)
	const [activeCounter, setActiveCounter] = useState(false)
	const [errorFields, setErrorFields] = useState<string[]>([])
	const [userInput, setUserInput] = useAtom(
		type === LegalDocumentType.Contract
			? userInputContractAtom
			: userInputAtom,
	)
	const [toFavor, setToFavor] = useAtom(toFavorAtom)
	const [specificType, setSpecificType] = useAtom(
		type === LegalDocumentType.Contract
			? contractTypeAtom
			: petitionTypeAtom,
	)
	const location = useLocation()
	const { data: documentAsModelFeatureFlag } = useFeatureFlag(
		FEATURE_FLAGS.useDocumentAsModel,
	)
	const [isDocumentAsModel, setIsDocumentAsModel] = useState(
		location.state ? location.state.documentAsModel : false,
	)
	const [open, setOpen] = useAtom(openAtom)

	const isInitialMount = useRef(true)

	const { data, isFetchedAfterMount, isFetched } = useLegalDocument(
		documentId,
		type,
	)
	const updateDocumentMutation = useLegalDocumentMutation(documentId, type)
	const inputRef = useRef<HTMLInputElement>(null)
	const textAreaRef = useRef<HTMLTextAreaElement>(null)
	const debouncedOnChange = _.debounce((value, typeInput, favor) => {
		const payload =
			type === LegalDocumentType.Contract
				? {
						user_input: value,
						contract_type: typeInput,
						to_favor: favor,
				  }
				: {
						user_input: value,
						petition_type: typeInput || "Petição Inicial",
				  }
		updateDocumentMutation.mutate({
			id: documentId,
			payload,
		})
	}, 500)

	const getErrorText = () => {
		if (userInput && userInput.length > 10000) {
			return "Você ultrapassou o limite de caracteres"
		}

		return "Escreva pelo menos 20 caracteres"
	}

	function handleFocus(e: React.FocusEvent<any, Element>) {
		setErrorFields(errorFields.filter((field) => field !== e.target.name))
	}

	function handleBlur(e: React.FocusEvent<any, Element>) {
		if (!e.target.value) setErrorFields([...errorFields, e.target.name])
	}

	useEffect(() => {
		if (data?.user_input && isFetchedAfterMount) {
			setActiveCounter(true)
		}
	}, [isFetchedAfterMount])

	useEffect(() => {
		if (activeCounter) {
			if (
				userInput &&
				userInput.length >= 20 &&
				userInput.length <= 10000
			) {
				setShowError(false)
			} else {
				setShowError(true)
			}
		}
	}, [userInput, isFetchedAfterMount, activeCounter])

	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false
		} else {
			debouncedOnChange(userInput, specificType, toFavor)
		}

		return () => {
			debouncedOnChange.cancel()
		}
	}, [userInput, specificType, toFavor])

	useEffect(() => {
		if (isFetched && type === LegalDocumentType.Petition) {
			setSpecificType(data?.petition_type || "")
		}
	}, [isFetched])

	useEffect(() => {
		if (data && type === LegalDocumentType.Contract) {
			setUserInput(data?.user_input)
			setToFavor(data?.to_favor)
			setSpecificType(data?.contract_type)
		}
	}, [])

	useEffect(() => {
		if (inputRef.current && type === LegalDocumentType.Contract) {
			inputRef.current?.focus()
		}
		if (textAreaRef.current && type === LegalDocumentType.Petition) {
			textAreaRef.current.focus()
		}
	}, [inputRef.current, textAreaRef.current])

	if (documentId !== data?.id) return <Loading />
	return (
		<>
			<div className="w-full">
				<div
					className={` w-[420px] ${
						documentAsModelFeatureFlag && isDocumentAsModel
							? "mb-2"
							: "mb-6"
					}`}
				>
					<p className="text-brand-gray-3">
						Tipo de {translate[type]}:
					</p>
					{documentAsModelFeatureFlag && isDocumentAsModel ? (
						<InputField
							className={`border-1 mt-2 border border-solid border-light-blue-1 p-2 shadow-outlined rounded w-full max-w-[420px]  ${
								errorFields.find(
									(field) => field === "specificType",
								)
									? "border-red-500"
									: ""
							} bg-[#F2F6FB] text-light-blue-1 text-ellipsis overflow-hidden h-12`}
							readOnly
							value="Petição Inicial nome grande lorem ipsum dolor amet"
							leftIcon={
								<div className="mt-2">
									<ModelIcon
										className={"fill-light-blue-1"}
									/>
								</div>
							}
							rightIcon={
								isDocumentAsModel && (
									<div className="pt-[10px]">
										{" "}
										<div
											className="cursor-pointer"
											onClick={() =>
												setIsDocumentAsModel(false)
											}
										>
											<CloseIcon />
										</div>
									</div>
								)
							}
							name="specificType"
							onChange={(e) => setSpecificType(e.target.value)}
							onFocus={handleFocus}
							onBlur={handleBlur}
						/>
					) : type === LegalDocumentType.Petition ? (
						<div className="w-full max-w-[420px]">
							<PetitionAutoComplete
								onSubmit={(event) => setSpecificType(event)}
								value={data?.petition_type || ""}
								placeholder="Petição Inicial"
								removeIcons
								className="w-full max-w-[420px] mt-2"
								onChange={(event) => setSpecificType(event)}
								maxLength={40}
							/>
						</div>
					) : (
						<InputField
							className={`border-1 mt-2 border border-gray-200 p-2 shadow-outlined rounded w-full max-w-[420px]  ${
								errorFields.find(
									(field) => field === "specificType",
								)
									? "border-red-500"
									: ""
							}`}
							placeholder="Compra e Venda de Casa"
							name="specificType"
							ref={inputRef}
							value={specificType || ""}
							onChange={(e) => setSpecificType(e.target.value)}
							onFocus={handleFocus}
							onBlur={handleBlur}
						/>
					)}
				</div>
				{documentAsModelFeatureFlag && isDocumentAsModel && (
					<div className="mb-6 text-brand-gray-2 text-[14px]">
						Seu documento será criado a partir deste modelo.
					</div>
				)}
				<div className="mb-5 w-full">
					<p className="text-brand-gray-3">
						{textAreaData[type as "contract" | "petition"].label}
					</p>
					<ReactTextareaAutosize
						id="textarea"
						className={`placeholder:text-[#ACACAC] border-none resize-none !outline-offset-0 border-1 mb-1 mt-2 min-h-[229px] w-full overflow-hidden rounded-md border border-gray-100 bg-white p-4 shadow-outlined focus:outline-light-blue-4 focus:outline focus:outline-1 ${
							errorFields.find(
								(field) => field === "userInput",
							) || showError
								? "!outline-red-500 !outline !outline-1"
								: ""
						}`}
						placeholder={
							textAreaData[type as "contract" | "petition"]
								.placeholder
						}
						lang="pt-br"
						name="userInput"
						value={userInput || ""}
						ref={textAreaRef}
						onFocus={handleFocus}
						onBlur={handleBlur}
						onChange={(e) => setUserInput(e.target.value)}
						onSelect={() => {
							if (userInput && userInput?.length <= 20) {
								setActiveCounter(true)
							}
						}}
						data-cy="user-input-textarea"
					/>
					<div className="flex items-center justify-between w-full">
						{showError ? (
							<div className="flex items-center">
								<ExclamationIcon className="mr-2 text-light-red" />
								<p className="text-light-red text-cta-2">
									{getErrorText()}
								</p>
							</div>
						) : (
							<div />
						)}

						<div className="mr-[0.5px]">
							<p
								className={`${
									showError
										? "text-light-red"
										: "text-brand-gray-2"
								} text-cta-2`}
							>
								{userInput?.length ?? 0}/10000
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="mb-2">
				<p className="text-brand-gray-3">
					Anexe peças, contratos ou outros documentos aos fatos:{" "}
					<span className="text-[#B0B0B0]">(opcional)</span>
				</p>
			</div>
			<FileDropzone documentId={documentId} data={data} />
			<CreditsModal open={open} setOpen={setOpen} />
		</>
	)
}

export default NewFactsStep

import Image from "assets/mobile-dash.png"
import ExitButton from "components/exit-button"
import Modal from "components/modal"
import LawdeckIcon from "icons/LawdeckIcon"

interface MobileModalProps {
	isOpen: boolean
}

export default function MobileModal({ isOpen }: MobileModalProps) {
	return (
		isOpen && (
			<Modal
				size="full"
				width="w-full"
				openModal={isOpen}
				setOpenModal={() => {}}
				whitoutCloseButton
				dismissible={false}
				className="!overflow-hidden z-[9999]"
			>
				<div className=" bg-white">
					<div className="flex flex-col relative items-center justify-center w-full h-[100vh]">
						<div className="flex items-center justify-center gap-2 mb-8">
							<LawdeckIcon />
							<h3>Lawdeck</h3>
						</div>
						<img src={Image} width={248} height={199} />

						<h3 className="text-2xl mt-8 mb-4">
							Você já está cadastrado!
						</h3>
						<p className="text-cta-1 text-brand-gray-2 text-center px-6">
							Para uma experiência completa, acesse nossa
							plataforma pelo computador ou notebook.
						</p>
						<ExitButton
							className="absolute"
							style={{ top: window.innerHeight - 48 + "px" }}
						/>
					</div>
				</div>
			</Modal>
		)
	)
}

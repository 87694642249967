import React from "react"
import BaseIcon from "./BaseIcon"

const CivilIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
		>
			<path d="M16.167 11C17.827 11 19.157 9.66 19.157 8C19.157 6.34 17.827 5 16.167 5C14.507 5 13.167 6.34 13.167 8C13.167 9.66 14.507 11 16.167 11ZM8.16699 11C9.82699 11 11.157 9.66 11.157 8C11.157 6.34 9.82699 5 8.16699 5C6.50699 5 5.16699 6.34 5.16699 8C5.16699 9.66 6.50699 11 8.16699 11ZM8.16699 13C5.83699 13 1.16699 14.17 1.16699 16.5V19H15.167V16.5C15.167 14.17 10.497 13 8.16699 13ZM16.167 13C15.877 13 15.547 13.02 15.197 13.05C16.357 13.89 17.167 15.02 17.167 16.5V19H23.167V16.5C23.167 14.17 18.497 13 16.167 13Z" />
		</BaseIcon>
	)
}

export default CivilIcon

import React from "react"

interface ImagePrefetchProps {
	imageUrl: string
}

const ImagePrefetch = ({ imageUrl }: ImagePrefetchProps) => {
	React.useEffect(() => {
		const link = document.createElement("link")
		link.rel = "prefetch"
		link.href = imageUrl

		document.head.appendChild(link)

		return () => {
			document.head.removeChild(link)
		}
	}, [imageUrl])

	return <></>
}

export default ImagePrefetch

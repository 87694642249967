import { DropdownItemProps } from ".."

interface DropdownSectionItemProps {
	item: DropdownItemProps
	index: number
	itemClassName?: string
	shouldCloseAfterClick?: boolean
	setIsOpen: (value: boolean) => void
	closeOnClickOption?: boolean
}

const DropdownSectionItem = ({
	item,
	index,
	itemClassName,
	shouldCloseAfterClick,
	setIsOpen,
	closeOnClickOption,
}: DropdownSectionItemProps) => {
	function handleClick(onClick: (() => void) | undefined) {
		onClick && onClick()
		shouldCloseAfterClick && setIsOpen(false)
	}

	return (
		<div
			key={index}
			className={`${
				item.className ??
				`flex cursor-pointer items-center px-[8px] py-[8px] duration-200 ease-out ${
					!item.disabled &&
					"hover:bg-[#f6f6f6] hover:text-dark-blue-2"
				} `
			}  ${
				item.deleteLabel ? "text-brand-red" : "text-brand-gray-3"
			} ${itemClassName}`}
			onMouseEnter={item.onMouseEnter}
			onMouseLeave={item.onMouseLeave}
			onClick={(event) => {
				if (item.disabled) return
				handleClick(item.onClick)
				if (closeOnClickOption) {
					setIsOpen(false)
				}
				event.stopPropagation()
			}}
		>
			{item.icon && item.icon}
			<span
				className={`${
					item.labelClassName
						? item.labelClassName
						: "ml-[8px] text-cta-2"
				} ${item.disabled && "text-brand-gray-2"}`}
			>
				{item.label}
			</span>
			{item.rightIcon && item.rightIcon}
		</div>
	)
}

export default DropdownSectionItem

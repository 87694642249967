import { Button } from "components/button"
import { ButtonVariant } from "components/button/types"
import InputField from "components/input-field"
import Modal from "components/modal"
import { showToast } from "components/toast/functions"
import React, { useState } from "react"

interface AddCreditsModalProps {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	userId?: string
}

const AddCreditsModal = ({ open, setOpen }: AddCreditsModalProps) => {
	const [reason, setReason] = useState("")
	const [credits, setCredits] = useState("")
	return (
		<Modal
			size="550"
			width="w-[550px]"
			openModal={open}
			setOpenModal={setOpen}
			dismissible
			onlyCloseButton
		>
			<div className="flex  justify-center flex-col p-2">
				<p className={"text-[22px] font-semibold mb-2 font-lato"}>
					Adicionar créditos
				</p>
				<div className="text-brand-gray-3 mb-8 text-[16px] font-lato"></div>
				<span className="mb-2 text-brand-gray-2 font-lato">
					Créditos:
				</span>
				<InputField
					className="w-full mb-8"
					placeholder="ex 10"
					value={credits}
					onChange={(e) => setCredits(e.target.value)}
				/>
				<span className="mb-2 text-brand-gray-2 font-lato">
					Motivo:
				</span>
				<textarea
					className="resize-none h-[120px] p-3 pr-10 w-full focus:!outline-none !ring-0 border-brand-gray-1 rounded-[4px] !outline-offset-0 border-2 mb-8"
					placeholder="ex Usuário ganhou bônus em um sorteio"
					value={reason}
					onChange={(e) => {
						setReason(e.target.value)
					}}
				/>
				<div className="flex items-center justify-between">
					<Button
						variant={ButtonVariant.Outlined}
						className="mr-4 w-[235px] justify-center"
						onClick={() => {
							setOpen(false)
						}}
					>
						Cancelar
					</Button>
					<Button
						variant={ButtonVariant.Contained}
						className="flex w-[235px] justify-center"
						disabled={reason === "" || credits === ""}
						onClick={() => {
							showToast(
								"Os créditos de “Devon Lane” foram atualizados com sucesso!",
							)
							setOpen(false)
							setReason("")
							setCredits("")
						}}
					>
						Adicionar
					</Button>
				</div>
			</div>
		</Modal>
	)
}

export default AddCreditsModal

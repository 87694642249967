export default function LawdeckDocIcon() {
	return (
		<svg
			width="56"
			height="56"
			viewBox="0 0 56 56"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0 4C0 1.79086 1.79086 0 4 0H28H40.3431C41.404 0 42.4214 0.421427 43.1716 1.17157L49 7L54.8284 12.8284C55.5786 13.5786 56 14.596 56 15.6569V28V52C56 54.2091 54.2091 56 52 56H4C1.79086 56 0 54.2091 0 52V4Z"
				className="fill-current"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15 39.1434C17.7605 41.5406 21.4162 43 25.4257 43C26.2578 43 27.0746 42.9372 27.8713 42.8162V37.9413C27.0859 38.1171 26.2672 38.2101 25.4257 38.2101C23.18 38.2101 21.0961 37.5478 19.3762 36.4165V19.5835V17.0336V14.1736C17.7602 14.8303 16.2834 15.7421 15 16.8566V17.0336V25.7621V30.2379V37.958V39.1434ZM27.8713 34.9756V33.6723V18.0587C32.5929 19.1155 36.1089 23.1653 36.1089 28C36.1089 32.2908 33.3395 35.9633 29.4158 37.4741V42.5031C36.0841 40.806 41 34.9559 41 28C41 19.7157 34.0272 13 25.4257 13C24.772 13 24.1276 13.0388 23.4951 13.1141V17.0656V17.9562V35.1529C24.1127 35.3384 24.7596 35.437 25.4257 35.437C26.2796 35.437 27.1017 35.275 27.8713 34.9756Z"
				fill="white"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_1892_264717"
					x1="28"
					y1="0"
					x2="28"
					y2="56"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#020812" />
					<stop offset="1" stopColor="#212C3A" />
				</linearGradient>
			</defs>
		</svg>
	)
}

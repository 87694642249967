import BaseIcon from "./BaseIcon"

const BellIcon = ({ size = "24px" }: { size?: string }) => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
		>
			<path d="M19.8198 13.7235L18.1538 12.1366V10.0345C18.1519 8.58184 17.5845 7.18148 16.5615 6.1042C15.5385 5.02692 14.1325 4.34923 12.6154 4.20219V3H11.3846V4.20219C9.86749 4.34923 8.46149 5.02692 7.43848 6.1042C6.41547 7.18148 5.84813 8.58184 5.84615 10.0345V12.1366L4.18025 13.7235C4.06485 13.8334 4.00002 13.9825 4 14.1379V15.8966C4 16.052 4.06483 16.2011 4.18024 16.3111C4.29565 16.421 4.45217 16.4828 4.61538 16.4828H8.92308V16.9381C8.90974 17.6818 9.18493 18.404 9.69593 18.9663C10.2069 19.5287 10.9178 19.8916 11.6923 19.9857C12.1201 20.0261 12.552 19.9808 12.9603 19.8527C13.3686 19.7245 13.7442 19.5164 14.063 19.2417C14.3818 18.967 14.6367 18.6317 14.8114 18.2575C14.986 17.8834 15.0764 17.4785 15.0769 17.069V16.4828H19.3846C19.5478 16.4828 19.7044 16.421 19.8198 16.3111C19.9352 16.2011 20 16.052 20 15.8966V14.1379C20 13.9825 19.9351 13.8334 19.8198 13.7235ZM13.8462 17.069C13.8462 17.5354 13.6516 17.9827 13.3054 18.3125C12.9592 18.6423 12.4896 18.8276 12 18.8276C11.5104 18.8276 11.0408 18.6423 10.6946 18.3125C10.3484 17.9827 10.1538 17.5354 10.1538 17.069V16.4828H13.8462V17.069ZM18.7692 15.3104H5.23077V14.3807L6.89662 12.7938C7.01203 12.6839 7.07689 12.5348 7.07692 12.3793V10.0345C7.07692 8.79072 7.5956 7.59788 8.51886 6.7184C9.44211 5.83892 10.6943 5.34483 12 5.34483C13.3057 5.34483 14.5579 5.83892 15.4811 6.7184C16.4044 7.59788 16.9231 8.79072 16.9231 10.0345V12.3793C16.9231 12.5348 16.988 12.6839 17.1034 12.7938L18.7692 14.3807V15.3104Z" />
		</BaseIcon>
	)
}

export default BellIcon

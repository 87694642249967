import { Button } from "components/button"
import InputField from "components/input-field"
import Modal from "components/modal"
import { useEffect, useRef, useState } from "react"
import deleteAccount from "assets/deleteAccount.jpg"
import ExclamationIcon from "icons/ExclamationIcon"
import check from "lotties/check.json"

import TextareaAutosize from "react-textarea-autosize"
import { addDays, format } from "date-fns"
import Lottie from "react-lottie"
import { useAuth0 } from "@auth0/auth0-react"
import useAPI from "hooks/useAPI"
import { useMutation } from "@tanstack/react-query"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"
import { ButtonVariant } from "components/button/types"
import useCurrentUser from "hooks/useCurrentUser"

const cancelOptions = [
	{
		title: "Dificuldades técnicas",
		id: "1",
	},
	{
		title: "Caro demais",
		id: "2",
	},
	{
		title: "Dificuldade na usabilidade",
		id: "3",
	},
	{
		title: "Suporte insatisfatório",
		id: "4",
	},
	{
		title: "Recursos limitados",
		id: "5",
	},
	{
		title: "Gostei mais de outra plataforma",
		id: "6",
	},
]

const sizeStep: { [key: number]: string } = {
	1: "lg",
	2: "5xl",
	3: "lg",
	4: "md",
}

export default function DeleteAccount() {
	const [openModal, setOpenModal] = useState(false)
	const [codes, setCodes] = useState(Array(5).fill(""))
	const inputRefs = useRef<(HTMLInputElement | null)[]>([])
	const [steps, setSteps] = useState(1)
	const [selecteds, setSelecteds] = useState<string[]>([])
	const [counter, setCounter] = useState(59)
	const LottieComponent = Lottie as any
	const { logout } = useAuth0()
	const { deleteAccountRequestCode, deleteAccountConfirmCode } = useAPI()
	const user = useCurrentUser()
	const deleteAccountRequestCodeMutation = useMutation({
		mutationFn: deleteAccountRequestCode,
		onSuccess: () => {
			setSteps(3)
		},
	})
	const deleteAccountConfirmCodeMutation = useMutation({
		mutationFn: deleteAccountConfirmCode,
		onSuccess: () => {
			setSteps(4)
		},
		onError: () => {
			showToast(
				"Código de verificação inválido tente novamente.",
				ToastType.Error,
			)
		},
	})

	const lottieOptions = {
		loop: true,
		autoplay: true,
		animationData: check,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
			className: "cursor-default",
		},
	}

	const focusNextInput = (index: number) => {
		if (index < 5 - 1) {
			const nextInput = inputRefs.current[index + 1]
			if (nextInput) {
				nextInput.focus()
			}
		}
	}

	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		index: number,
	) => {
		const value = event.target.value
		if (value === "" || /^[0-9]$/.test(value)) {
			setCodes(codes.map((code, i) => (i === index ? value : code)))
			if (value) {
				focusNextInput(index)
			}
		}
	}

	const handleKeyDown = (
		event: React.KeyboardEvent<HTMLInputElement>,
		index: number,
	) => {
		if (event.key === "Backspace" && codes[index] === "" && index > 0) {
			const prevInput = inputRefs.current[index - 1]
			if (prevInput) {
				prevInput.focus()
			}
		}
	}

	useEffect(() => {
		inputRefs.current = inputRefs.current.slice(0, 5).fill(null)
	}, [])

	useEffect(() => {
		if (openModal && steps === 3) {
			if (counter === 0) return
			const interval = setInterval(() => {
				setCounter((prev) => prev - 1)
			}, 1000)
			return () => clearInterval(interval)
		} else {
			if (steps !== 4) {
				setCounter(59)
				setSteps(1)
				setSelecteds([])
				setCodes(Array(5).fill(""))
			}
		}
		if (
			parseInt(localStorage.getItem("excludeAccountTime")!) + 60000 >
			new Date().valueOf()
		) {
			const date = (
				(parseInt(localStorage.getItem("excludeAccountTime")!) +
					60000 -
					new Date().valueOf()) /
				1000
			).toFixed(0)
			setCounter(parseInt(date))
		}
	}, [counter, openModal, steps === 3])

	useEffect(() => {
		if (steps === 4) {
			setTimeout(() => {
				setOpenModal(false)
				logout({
					logoutParams: {
						returnTo: window.location.origin,
					},
				})
			}, 1300)
		}
	}, [steps])

	const isEveryCodeFilled = codes.every(
		(code) => code.trim() !== "" && !isNaN(Number(code)),
	)

	function onSelect(id: string) {
		const isSelected = selecteds.includes(id)
		if (isSelected) {
			const filtered = selecteds.filter((item) => item !== id)
			setSelecteds(filtered)
		} else {
			setSelecteds([...selecteds, id])
		}
	}
	return (
		<div>
			<div className="flex justify-between">
				<div className="w-1/2 ">
					<h3 className="mb-2 font-semibold">
						Excluir sua conta da Lawdeck
					</h3>
					<h4 className=" text-brand-gray-2 font-normal">
						Você pode excluir permanentemente sua conta da Lawdeck e
						todos os seus documentos e dados.
					</h4>
				</div>

				<div className=" flex flex-col justify-center w-1/2">
					<p
						className="text-cta-2 text-brand-red mb-2 font-semibold cursor-pointer"
						onClick={() => {
							if (
								localStorage.getItem("excludeAccountTime") &&
								parseInt(
									localStorage.getItem("excludeAccountTime")!,
								) +
									60000 >
									new Date().valueOf()
							) {
								setSteps(3)
							}
							setOpenModal(true)
						}}
					>
						Excluir conta permanentemente
					</p>
				</div>
			</div>

			<Modal
				openModal={openModal}
				setOpenModal={setOpenModal}
				size={sizeStep[steps]}
			>
				<span className="prose max-w-none">
					{steps === 1 && (
						<>
							<div>
								<img
									src={deleteAccount}
									width={464}
									height={308}
									className="rounded-lg"
								/>
							</div>
							<h3 className="mb-4 font-semibold mt-6">
								Tem certeza que deseja excluir sua conta?
							</h3>
							<div className="flex items-center gap-2 p-[12px] bg-red-opacity">
								<div>
									<ExclamationIcon className="fill-brand-red" />
								</div>
								<p className="text-cta-1 text-brand-red font-semibold">
									Atenção! Essa ação é irreversível e voce
									perderá todos os documentos e dados salvos
									na plataforma.
								</p>
							</div>

							<div className="flex items-center justify-between mt-6">
								<Button
									variant={ButtonVariant.Contained}
									className="w-[230px]  flex items-center justify-center"
									onClick={() => {
										setOpenModal(false)
										setSteps(1)
									}}
								>
									Fechar
								</Button>

								<Button
									variant={ButtonVariant.Text}
									className="w-[230px]  flex items-center justify-center"
									onClick={() => {
										setSteps(2)
									}}
								>
									Continuar
								</Button>
							</div>
						</>
					)}

					{steps === 2 && (
						<div className="flex w-ful justify-between ">
							<div className="mr-6">
								<img
									src={deleteAccount}
									width={464}
									height={308}
									className="rounded-lg"
								/>
								<h3 className="font-semibold mt-6">
									Por que você quer cancelar sua assinatura?
								</h3>

								<p className="text-cta-1 text-brand-gray-3 mt-2">
									Sentimos muito vê-lo partir. Ajude-nos a
									melhorar nosso serviço
								</p>

								<p className="text-cta-2 text-brand-gray-3 mt-2">
									Se precisar de alguma assistência adicional,
									entre em contato conosco pelo chat online ou
									pelo e-mail:{" "}
									<a
										className="underline text-light-blue-4 cursor-pointer"
										href="mailto:suporte@lawdeck.ai"
									>
										suporte@lawdeck.ai
									</a>
								</p>
							</div>

							<div className="flex flex-col">
								<div className="  p-6 rounded-md border-[2px] border-solid border-brand-white-3">
									{cancelOptions.map((item) => (
										<div
											className="flex items-center mb-4"
											key={item.id}
										>
											<input
												type="checkbox"
												className="rounded-sm focus:shadow-none focus:ring-0 focus:outline-none"
												onClick={() =>
													onSelect(item.id)
												}
											/>
											<p className="text-cta-2 ml-2">
												{item.title}
											</p>
										</div>
									))}

									<div className="mt-[-16px]">
										<TextareaAutosize
											id="textarea"
											className={`border-1 !outline-offset-0 border-1  min-h-[90px]  px-4 py-2 mt-[24px]  resize-none w-full
							  rounded-md border-gray-100 bg-white shadow-outlined focus:outline-light-blue-4 focus:outline focus:outline-2`}
											placeholder="Deixe um feedback (opcional)"
											lang="pt-br"
											maxRows={4}
										/>
									</div>
								</div>

								<div className="flex items-center justify-between mt-8">
									<Button
										variant={ButtonVariant.Contained}
										className="w-[230px]  flex items-center justify-center"
										onClick={() => {
											setOpenModal(false)
											setSteps(1)
										}}
									>
										Fechar
									</Button>

									<Button
										variant={ButtonVariant.Text}
										className="w-[230px]  flex items-center justify-center"
										disabled={!(selecteds.length > 0)}
										onClick={() => {
											deleteAccountRequestCodeMutation.mutate()
											localStorage.setItem(
												"excludeAccountTime",
												new Date().valueOf().toString(),
											)
										}}
									>
										Continuar
									</Button>
								</div>
							</div>
						</div>
					)}

					{steps === 3 && (
						<div className="flex flex-col justify-center">
							<h3 className="mb-4 font-semibold">
								Verifique seu email
							</h3>

							<p className="text-cta-1 text-brand-gray-3 mb-6">
								Por favor, verifique o código enviado para{" "}
								<span className="font-bold">{user?.email}</span>{" "}
								e insira abaixo:
							</p>

							<p className="text-cta-1 text-brand-gray-3 mb-2">
								Digite o código
							</p>

							<div className="flex items-center justify-between">
								{codes.map((code, index) => (
									<InputField
										ref={(el) =>
											(inputRefs.current[index] = el)
										}
										key={index}
										maxLength={1}
										value={code}
										className="w-[84px] text-center"
										onChange={(event) =>
											handleInputChange(event, index)
										}
										onKeyDown={(event) =>
											handleKeyDown(event, index)
										}
									/>
								))}
							</div>

							<p className="text-cta-1 text-brand-gray-3 mt-4">
								Não recebeu?{" "}
								<span
									onClick={() => {
										if (counter === 0) {
											deleteAccountRequestCodeMutation.mutate()
											setCounter(59)
										}
									}}
									className={`${
										counter === 0
											? "text-light-blue-4 cursor-pointer"
											: "text-brand-gray-2"
									} `}
								>
									{counter === 0
										? "Envie novamente"
										: `Envie novamente em ${counter}s`}
								</span>
							</p>

							<p className="text-cta-2 text-brand-gray-3 mt-4">
								Sua conta ficará inativa por 7 dias e após o dia{" "}
								<span className="text-dark-blue-1">
									{format(
										addDays(new Date(), 7),
										"dd/MM/yyyy",
									)}
								</span>{" "}
								será excluida permanentemente. Acaso queira
								reverter a ação antes do prazo da exclusão,
								tente entrar na conta novamente.
							</p>

							<div className="flex items-center justify-between mt-4">
								<Button
									variant={ButtonVariant.Contained}
									className="w-[230px]  flex items-center justify-center"
									onClick={() => {
										setOpenModal(false)
										setSteps(1)
									}}
								>
									Fechar
								</Button>

								<Button
									variant={ButtonVariant.Text}
									className="w-[230px]  flex items-center justify-center"
									disabled={!isEveryCodeFilled}
									onClick={() => {
										deleteAccountConfirmCodeMutation.mutate(
											codes.join(""),
										)
									}}
								>
									Concluir exclusão
								</Button>
							</div>
						</div>
					)}

					{steps === 4 && (
						<div className="w-full h-full flex items-center justify-center flex-col">
							<LottieComponent
								options={lottieOptions}
								height={140}
								width={140}
								isClickToPauseDisabled
							/>
							<h3 className="font-semibold mb-4 text-center">
								Conta excluída com sucesso!
							</h3>
						</div>
					)}
				</span>
			</Modal>
		</div>
	)
}

import Check2Icon from "icons/Check2Icon"

interface PlanBenefitsProps {
	allowed?: boolean
	item: string
	smallCard?: boolean
}

const PlanBenefits = ({ allowed, item }: PlanBenefitsProps) => {
	const paragraphClassName = allowed
		? "text-cta-1 text-dark-blue-1"
		: "text-cta-1 ml-2 text-brand-gray-2"

	return (
		<div key={item} className="flex items-center my-[16px]">
			<div>
				<Check2Icon className="fill-light-green mr-2 w-[20px] h-[20px]" />
			</div>

			<p className={` ${paragraphClassName} text-cta-2`}>{item}</p>
		</div>
	)
}

export default PlanBenefits

import { AxiosResponse } from "axios"
import axiosInstance from "axiosInstance"
import { TransactionsData } from "types/legalDocument"
import { PreviewPlan } from "types/subscription"

const useSubscriptionAPI = () => {
	const subscriptionCheckout = async ({
		successUrl,
		lookupKey,
	}: {
		successUrl: string
		lookupKey: string
	}) => {
		const response: AxiosResponse<any> = await axiosInstance.post(
			`/subscriptions/checkout/`,
			{
				success_url: successUrl,
				lookup_key: lookupKey,
			},
		)

		return response.data
	}

	const subscriptionBuyCredits = async ({
		successUrl,
		lookupKey,
	}: {
		successUrl: string
		lookupKey: string
	}) => {
		const response: AxiosResponse<any> = await axiosInstance.post(
			`/subscriptions/buy_credits/`,
			{
				success_url: successUrl,
				lookup_key: lookupKey,
			},
		)

		return response.data
	}

	const subscriptionCustomerPortal = async ({
		returnUrl,
	}: {
		returnUrl: string
	}) => {
		const response: AxiosResponse<any> = await axiosInstance.post(
			`/subscriptions/customer_portal/`,
			{
				return_url: returnUrl,
			},
		)

		return response.data
	}
	const subscriptionJoinBasicPlan = async () => {
		const response: AxiosResponse<any> = await axiosInstance.post(
			`/subscriptions/join_basic_plan/`,
		)

		return response.data
	}

	const subscriptionCancelPlan = async () => {
		const response: AxiosResponse<any> = await axiosInstance.post(
			`/subscriptions/cancel_plan/`,
		)

		return response.data
	}

	const subscriptionReactivatePlan = async () => {
		const response: AxiosResponse<any> = await axiosInstance.post(
			`/subscriptions/reactivate_plan/`,
		)

		return response.data
	}

	const subscriptionPaymentsHistory = async () => {
		const response: AxiosResponse<TransactionsData> =
			await axiosInstance.get("/subscriptions/payments_history/")

		return response.data.transactions
	}

	const upgradePlan = async (lookup_key: string) => {
		await axiosInstance.patch(
			`/subscriptions/upgrade/`, {
			lookup_key,
		}
		)
	}

	const downgradePlan = async (lookup_key: string) => {
		await axiosInstance.patch(
			`/subscriptions/downgrade/`, {
			lookup_key,
		}
		)
	}

	const previewPlan = async (lookup_key: string) => {
		const response: AxiosResponse<PreviewPlan> = await axiosInstance.get(
			`/subscriptions/preview/?lookup_key=${lookup_key}`,
		)
		return response.data
	}

	return {
		subscriptionCheckout,
		subscriptionBuyCredits,
		subscriptionCustomerPortal,
		subscriptionJoinBasicPlan,
		subscriptionCancelPlan,
		subscriptionReactivatePlan,
		subscriptionPaymentsHistory,
		upgradePlan,
		downgradePlan,
		previewPlan
	}
}

export default useSubscriptionAPI

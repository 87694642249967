import { Button } from "components/button"
import ArrowDownIcon from "icons/ArrowDownIcon"
import TextareaAutosize from "react-textarea-autosize"
import { formatUserName } from "utils/profile"
import ManWithDocuments from "assets/man_with_documents.jpg"
import SuccessGif from "assets/success.gif"
import { Dropdown } from "components/dropdown"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import LogoContainer from "pages/SidebarPages/subcomponents/LogoContainer"
import { ButtonVariant } from "components/button/types"
import useCurrentUser from "hooks/useCurrentUser"

const RequestAccessDocumentPage = () => {
	const user = useCurrentUser()
	const [pressedButton, setPressedButton] = useState(false)
	const navigate = useNavigate()

	const sections = [
		{
			items: [
				{
					label: (
						<div className="flex">
							<div className="w-[34px]">
								<img
									src={user?.picture}
									height={32}
									width={32}
									className="rounded-full border border-sky-500 bg-cover mt-1"
									referrerPolicy="no-referrer"
								/>
							</div>
							<div className="ml-2">
								<p className="max-w-[120px] overflow-hidden text-ellipsis whitespace-nowrap text-cta-2 font-semibold">
									{user && formatUserName(user)}
								</p>
								<p className="text-brand-gray-3">
									{user?.email}
								</p>
							</div>
						</div>
					),
					onClick: () => console.log("Item 1 clicado"),
				},
				{
					label: (
						<Button variant={ButtonVariant.Text}>
							Adicionar uma conta
						</Button>
					),
					className:
						"flex cursor-pointer items-center duration-200 ease-out hover:bg-[#f6f6f6] justify-center",
					onClick: () => console.log("Item 2 clicado"),
				},
			],
		},
	]

	function handleRequestAccess() {
		setPressedButton(true)
		setTimeout(() => {
			navigate("/")
		}, 1000)
	}
	return (
		<div className="w-full relative bg-brand-white-1 font-lato h-screen flex items-center justify-between">
			<div
				className={`relative left-[120px] flex flex-col justify-between h-full w-[432px] items-start gap-[${
					pressedButton ? "325" : "160"
				}px]`}
			>
				<LogoContainer className="mt-[60px]" />
				{pressedButton ? (
					<div>
						<img src={SuccessGif} />
						<div className="text-center">
							<div className="mt-8 mb-4 text-[28px]">
								Acesso solicitado!
							</div>
							<div className="text-brand-gray-3">
								Estamos redirecionando você pra plataforma.
							</div>
						</div>
					</div>
				) : (
					<div>
						<div className="text-4xl font-semibold mb-4 ">
							Acesso negado!
						</div>
						<div className="text-brand-gray-3">
							Voce precisa de permissão do proprietário para
							visualizar este documento. Solicite seu acesso:
						</div>
						<TextareaAutosize
							id="textarea"
							className={`border-1 !outline-offset-0 border-1 min-h-[120px]  px-4 py-2 my-[40px]  resize-none w-full
							  rounded-md border-gray-100 bg-white shadow-outlined focus:outline-light-blue-4 focus:outline focus:outline-2`}
							placeholder="Deixe uma mensagem (opcional)"
							lang="pt-br"
							maxRows={4}
						/>
						<Button
							variant={ButtonVariant.Contained}
							className="w-[432px] justify-center mb-[40px]"
							onClick={handleRequestAccess}
						>
							Solicitar acesso
						</Button>
						<div className="text-brand-gray-3 pb-2">
							Você está logado com:
						</div>
						<Dropdown className="w-[432px]" sections={sections}>
							<div className="flex h-[50px]  w-[432px] items-center rounded px-[8px] py-[4px] hover:shadow-custom hover:shadow-[#00000024] justify-between cursor-pointer">
								<div className="flex items-center ">
									<div className="w-[34px]">
										<img
											src={user?.picture}
											height={32}
											width={32}
											className="rounded-full border border-sky-500 bg-cover mt-1"
											referrerPolicy="no-referrer"
										/>
									</div>
									<div className="ml-2 leading-4">
										<p className="max-w-[120px] overflow-hidden text-ellipsis whitespace-nowrap text-cta-2 font-semibold">
											{user && formatUserName(user)}
										</p>
										<p className="text-brand-gray-3">
											{user?.email}
										</p>
									</div>
								</div>
								<div>
									<ArrowDownIcon />
								</div>
							</div>
						</Dropdown>
					</div>
				)}
				<div className=" text-brand-gray-2 left-[193px] top-[983px] mb-[24px] ">
					© 2023 Lawdeck. Todos os direitos reservados
				</div>
			</div>
			<div className="mr-[60px]">
				<img width={617} height={617} src={ManWithDocuments} />
			</div>
		</div>
	)
}

export default RequestAccessDocumentPage

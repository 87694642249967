import { useMutation, useQueryClient } from "@tanstack/react-query"
import { LegalDocumentType } from "types/legalDocument"
import usePetitionAPI from "./usePetitionAPI"
import useContractAPI from "./useContractAPI"

export function useLegalDocumentMutation(
	documentId: string,
	type: LegalDocumentType,
) {
	const { updateContract } = useContractAPI()
	const { updatePetition } = usePetitionAPI()
	const mutationFunctions = {
		[LegalDocumentType.Contract]: updateContract,
		[LegalDocumentType.Petition]: updatePetition,
		[LegalDocumentType.PowerOfAttorney]: undefined,
		[LegalDocumentType.Uploaded]: undefined,
	}
	const queryClient = useQueryClient()
	const updateContractMutation = useMutation({
		mutationFn: mutationFunctions[type],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["legalDocument"] })
			queryClient.invalidateQueries({
				queryKey: ["legalDocument", documentId],
			})
		},
	})
	return updateContractMutation
}

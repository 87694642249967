import { useParams } from "react-router-dom"

import { ContractDisplay } from "pages/DocumentPage/subcomponents/contract/ContractDisplay"
import Loading from "components/loading"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useEffect } from "react"
import { RetrieveLegalDocumentResponse } from "types/legalDocument"
import { useJwt } from "hooks/useJwt"
import { useAtom } from "jotai"
import DiscardClausesModal from "../subcomponents/contract/DiscardClausesModal"
import ClauseContentProvider from "../context/ClausesContentContext"
import { newContractStrategiesStep } from "../subcomponents/contract/newContractStrategiesStep"
import { discardClausesAtom } from "./atoms"
import { NavigationModel } from "../subcomponents/contract/ContractDisplay/types"
import { newContractFactsStep } from "../subcomponents/contract/contractFactsStep/newContractFactsStep"
import { newContractCompleteStep } from "../subcomponents/contract/contractCompleteStep/newContractCompleteStep"
import useContractAPI from "hooks/useContractAPI"

const LoadingComponent = () => {
	const { documentId } = useParams()

	if (!documentId) return null
}

const ContractPage = () => {
	const { documentId } = useParams()
	const { retrieveContract, listClauses } = useContractAPI()
	const [jwt] = useJwt()

	const queryClient = useQueryClient()
	const [openDiscardThesesModal, setOpenDiscardThesesModal] =
		useAtom(discardClausesAtom)
	const { data: listClausesData } = useQuery(
		["listClauses", documentId],
		() => listClauses(documentId!),
	)

	const { data } = useQuery(
		["legalDocument", documentId],
		() => retrieveContract(documentId!),
		{
			staleTime: Infinity,
		},
	)

	const navigationModel: NavigationModel = {
		steps: [
			newContractFactsStep(documentId!),
			{
				subtitle: "Subtitulo  Teses",
				Component: <LoadingComponent />,
			},
			newContractStrategiesStep(documentId!),

			newContractCompleteStep(documentId!),
		],
	}

	useEffect(() => {
		queryClient.setQueryData(
			["listLegalDocuments", jwt],
			(oldDocuments: RetrieveLegalDocumentResponse[] | undefined) => {
				if (oldDocuments && data) {
					const newDocument = data
					const oldDocumentsFiltered = oldDocuments.filter(
						(doc) => doc.id !== documentId,
					)
					return [newDocument, ...oldDocumentsFiltered]
				}

				return oldDocuments
			},
		)
	}, [documentId, data])

	if (!data || !listClausesData) return <Loading />

	return (
		<ClauseContentProvider>
			<ContractDisplay
				navigationModel={navigationModel}
				documentId={documentId!}
			/>
			<DiscardClausesModal
				open={openDiscardThesesModal}
				setOpen={setOpenDiscardThesesModal}
				documentId={documentId!}
			/>
		</ClauseContentProvider>
	)
}

export default ContractPage

import BaseIcon from "./BaseIcon"

const ArrowLeft = ({ size = "16px", className, ...rest }: { size?: string, className?: string }) => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="currentColor"
			className={className}
			{...rest}
		>
			<path
				d="M5.33301 8L10.0114 3L10.6663 3.7L6.64295 8L10.6663 12.3L10.0114 13L5.33301 8Z"
				fill="fillCurrent"
			/>
		</BaseIcon>
	)
}

export default ArrowLeft

const OutlineCheckIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
		>
			<path
				d="M7.99984 2.66663C6.945 2.66663 5.91386 2.97942 5.0368 3.56545C4.15974 4.15149 3.47615 4.98444 3.07248 5.95898C2.66881 6.93352 2.5632 8.00587 2.76898 9.04044C2.97477 10.075 3.48272 11.0253 4.2286 11.7712C4.97448 12.5171 5.92479 13.025 6.95936 13.2308C7.99392 13.4366 9.06628 13.331 10.0408 12.9273C11.0154 12.5236 11.8483 11.8401 12.4343 10.963C13.0204 10.0859 13.3332 9.05479 13.3332 7.99996C13.3332 6.58547 12.7713 5.22892 11.7711 4.22872C10.7709 3.22853 9.41433 2.66663 7.99984 2.66663ZM7.23793 10.1298L5.33317 8.22502L5.93912 7.61901L7.23793 8.91775L10.0608 6.0952L10.6687 6.69935L7.23793 10.1298Z"
				fill="#3083FF"
			/>
		</svg>
	)
}

export default OutlineCheckIcon

import { atom } from "jotai"

export interface ModalAtomType {
  open: boolean
  isCancel: boolean
}

export const creditsAtom = atom(0)
export const modalAtom = atom<ModalAtomType>({
  open: false,
  isCancel: false,
})
export const openLexAtom = atom<boolean>(false)
export const isStrategyAtom = atom<boolean>(false)
export const showLexAtom = atom<boolean>(false)
export const legalDocumentTypeAtom = atom<string>("")

import { OnboardingStepProps } from "pages/MyDocumentsPage/subcomponents/ModalOnboarding"
import { useMutation } from "@tanstack/react-query"
import { CardSelector } from "./CardSelector"
import CivilIcon from "icons/CivilIcon"
import ConsumerIcon from "icons/ConsumerIcon"
import PenalIcon from "icons/PenalIcon"
import EmpresarialIcon from "icons/EmpresarialIcon"
import TrabalhistaIcon from "icons/TrabalhistaIcon"
import TributarioIcon from "icons/TributarioIcon"
import TextBoxIcon from "icons/TextBoxIcon"
import EllipseFooter from "./EllipseFooter"
import { useEffect } from "react"
import AdministrativeIcon from "icons/AdministrativeIcon"
import InternationalIcon from "icons/InternationalIcon"
import { AreaOfLaw } from "types/currentUser/enum"
import useUserAPI from "hooks/useUserAPI"

export function FourthStep({
	formData,
	setFormData,
	setSize,
	setOpen,
}: OnboardingStepProps) {
	const { onboardUser } = useUserAPI()
	const onboardUserMutation = useMutation({
		mutationFn: () => onboardUser(formData),
	})

	const areaOfLaw = [
		{ icon: <CivilIcon />, title: "Civil", area_of_law: AreaOfLaw.Civil },
		{
			icon: <TrabalhistaIcon />,
			title: "Trabalhista",
			area_of_law: AreaOfLaw.Labor,
		},
		{
			icon: <PenalIcon />,
			title: "Penal",
			area_of_law: AreaOfLaw.Criminal,
		},
		{
			icon: <ConsumerIcon />,
			title: "Consumidor",
			area_of_law: AreaOfLaw.Consumer,
		},
		{
			icon: <EmpresarialIcon />,
			title: "Empresarial",
			area_of_law: AreaOfLaw.Business,
		},
		{
			icon: <AdministrativeIcon />,
			title: "Administrativo",
			area_of_law: AreaOfLaw.Administrative,
		},

		{
			icon: <TributarioIcon />,
			title: "Tributário",
			area_of_law: AreaOfLaw.Tax,
		},
		{
			icon: <InternationalIcon />,
			title: "Internacional",
			area_of_law: AreaOfLaw.International,
		},
		{
			icon: <TextBoxIcon />,
			title: "Outros",
			area_of_law: AreaOfLaw.Other,
		},
	]

	useEffect(() => {
		setSize("750")
	}, [])
	return (
		<div className="flex justify-center flex-col p-[24px]">
			<div className="flex items-center mb-8 text-[22px] font-lato font-semibold">
				Qual sua principal área de interesse ou especialidade?
			</div>
			<div className="flex mb-4">
				{areaOfLaw
					.slice(0, 3)
					.map(({ icon, title, area_of_law }, index) => {
						const className = index !== 2 ? "mr-4" : ""
						return (
							<CardSelector
								key={area_of_law}
								className={className}
								icon={icon}
								title={title}
								onClick={() => {
									setFormData({
										...formData,
										area_of_law,
									})
									onboardUserMutation
										.mutateAsync()
										.then(() => setOpen(false))
								}}
							/>
						)
					})}
			</div>
			<div className="flex mb-4">
				{areaOfLaw
					.slice(3, 6)
					.map(({ icon, title, area_of_law }, index) => {
						const className = index !== 2 ? "mr-4" : ""
						return (
							<CardSelector
								key={area_of_law}
								className={className}
								icon={icon}
								title={title}
								onClick={() => {
									setFormData({
										...formData,
										area_of_law,
									})
									onboardUserMutation
										.mutateAsync()
										.then(() => setOpen(false))
								}}
							/>
						)
					})}
			</div>
			<div className="flex mb-8">
				{areaOfLaw
					.slice(6, 9)
					.map(({ icon, title, area_of_law }, index) => {
						const className = index !== 2 ? "mr-4" : ""

						return (
							<CardSelector
								key={area_of_law}
								icon={icon}
								className={className}
								title={title}
								onClick={() => {
									setFormData({
										...formData,
										area_of_law,
									})
									onboardUserMutation
										.mutateAsync()
										.then(() => setOpen(false))
								}}
							/>
						)
					})}
			</div>

			<EllipseFooter activeIndex={2} />
		</div>
	)
}

import BaseIcon from "./BaseIcon"

const EyeIcon = ({
	className = "fill-[#020812]",
	size = "24",
}: {
	className?: string
	size?: string
}) => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 24 24"
			className={className}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.4402 6.8553C19.0334 7.99205 20.2585 9.57072 20.9643 11.3962C21.0119 11.528 21.0119 11.6724 20.9643 11.8042C20.2585 13.6297 19.0334 15.2083 17.4402 16.3451C15.8471 17.4818 13.9557 18.1268 12 18.2004C10.0443 18.1268 8.15292 17.4818 6.55977 16.3451C4.96661 15.2083 3.74146 13.6297 3.03575 11.8042C2.98808 11.6724 2.98808 11.528 3.03575 11.3962C3.74146 9.57072 4.96661 7.99205 6.55977 6.8553C8.15292 5.71855 10.0443 5.07355 12 5C13.9557 5.07355 15.8471 5.71855 17.4402 6.8553ZM4.24178 11.6002C5.45981 14.6423 8.81991 17.0003 12 17.0003C15.1801 17.0003 18.5402 14.6423 19.7582 11.6002C18.5402 8.5581 15.1801 6.20003 12 6.20003C8.81991 6.20003 5.45981 8.5581 4.24178 11.6002ZM9.99985 8.60681C10.5919 8.21123 11.2879 8.00009 12 8.00009C12.9548 8.00009 13.8705 8.37938 14.5456 9.05453C15.2208 9.72968 15.6001 10.6454 15.6001 11.6002C15.6001 12.3122 15.3889 13.0083 14.9933 13.6003C14.5978 14.1923 14.0355 14.6538 13.3777 14.9262C12.7198 15.1987 11.996 15.27 11.2976 15.1311C10.5993 14.9922 9.95779 14.6493 9.45431 14.1458C8.95082 13.6424 8.60795 13.0009 8.46904 12.3025C8.33013 11.6042 8.40142 10.8803 8.6739 10.2225C8.94639 9.56465 9.40782 9.0024 9.99985 8.60681ZM10.6666 13.5958C11.0612 13.8595 11.5253 14.0003 12 14.0003C12.6365 14.0003 13.247 13.7474 13.6971 13.2973C14.1472 12.8472 14.4 12.2367 14.4 11.6002C14.4 11.1255 14.2593 10.6615 13.9955 10.2668C13.7318 9.87209 13.357 9.56447 12.9184 9.38281C12.4799 9.20116 11.9973 9.15363 11.5317 9.24624C11.0662 9.33884 10.6385 9.56743 10.3029 9.90308C9.9672 10.2387 9.73862 10.6664 9.64601 11.132C9.5534 11.5975 9.60093 12.0801 9.78259 12.5187C9.96425 12.9572 10.2719 13.332 10.6666 13.5958Z"
				fill=""
			/>
		</BaseIcon>
	)
}

export default EyeIcon

import { Header } from "@tanstack/react-table"
import FilterArrowUpIcon from "icons/FilterArrowUpIcon"
import { flexRender } from "@tanstack/react-table"
import { RetrieveLegalDocumentResponse } from "types/legalDocument"
import FilterArrowDownIcon from "icons/FilterArrowDownIcon"

interface LegalDocumentTableHeaderProps {
	header: Header<RetrieveLegalDocumentResponse, unknown>
}

const LegalDocumentTableHeader = ({
	header,
}: LegalDocumentTableHeaderProps) => {
	return (
		<div key={header.id}>
			{header.isPlaceholder ? null : (
				<div
					{...{
						className: header.column.getCanSort() ? "flex" : "",
					}}
				>
					<div
						className={`flex cursor-pointer items-center pl-2 ${
							header.id === "select" ? "pl-[18px]" : ""
						}`}
					>
						{flexRender(
							header.column.columnDef.header,
							header.getContext(),
						)}
						{header.column.getCanSort() &&
							{
								desc: (
									<div
										className="ms-2 mt-1"
										onClick={header.column.getToggleSortingHandler()}
									>
										<FilterArrowDownIcon />
									</div>
								),
								asc: (
									<div
										className="ms-2 mt-1"
										onClick={header.column.getToggleSortingHandler()}
									>
										<FilterArrowUpIcon />
									</div>
								),
							}[header.column.getIsSorted() as string]}
					</div>
				</div>
			)}
		</div>
	)
}

export default LegalDocumentTableHeader

import { useMutation, useQueryClient } from "@tanstack/react-query"
import { LegalDocumentType } from "types/legalDocument"
import usePetitionAPI from "./usePetitionAPI"
import useContractAPI from "./useContractAPI"
import useUploadedDocumentAPI from "./useUploadedDocumentAPI"

export function useLegalDocumentContentMutation(
	documentId: string,
	type: LegalDocumentType,
) {
	const { updateContractContent } = useContractAPI()
	const { updatePetitionContent } = usePetitionAPI()
	const { createNewRevisionUploadedDocument } = useUploadedDocumentAPI()

	const mutationFunctions = {
		[LegalDocumentType.Contract]: updateContractContent,
		[LegalDocumentType.Petition]: updatePetitionContent,
		[LegalDocumentType.PowerOfAttorney]: undefined,
		[LegalDocumentType.Uploaded]: createNewRevisionUploadedDocument,
	}
	const queryClient = useQueryClient()
	const updateContractMutation = useMutation({
		mutationFn: mutationFunctions[type],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["legalDocument"] })
			queryClient.invalidateQueries({
				queryKey: ["legalDocument", documentId],
			})
		},
	})
	return updateContractMutation
}

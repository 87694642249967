import Modal from "components/modal"
import DisplayCredits from "./DisplayCredits"
import { Button } from "components/button"
import { useAtom } from "jotai"
import { creditsAtom } from "./atoms"
import { ButtonVariant } from "components/button/types"
import OutlineCircleCheckIcon from "icons/OutlineCircleCheckIcon"

export interface PlansModalProps {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const SuccessCreditsModal = ({ open, setOpen }: PlansModalProps) => {
	const [credits] = useAtom(creditsAtom)
	return (
		<Modal
			size="337"
			width="w-[100%] p-[32px]"
			openModal={open}
			setOpenModal={setOpen}
			dismissible={true}
			whitoutCloseButton
		>
			<div className="prose max-w-none font-lato flex flex-col items-center">
				<OutlineCircleCheckIcon />
				<DisplayCredits credits={credits} isSuccess />
				<div className="mt-4 mb-6">
					<h3 className="text-center font-lato">Foram adicionados</h3>
					<h3 className="text-center font-lato">a sua conta!</h3>
				</div>
				<Button
					variant={ButtonVariant.Contained}
					className="w-[273px] flex justify-center"
					onClick={() => setOpen(false)}
				>
					Ok
				</Button>
			</div>
		</Modal>
	)
}

export default SuccessCreditsModal

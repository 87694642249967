import BaseIcon from "./BaseIcon"

const ArrowRightIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M13.6484 4.50928L12.5759 5.55403L18.2609 11.2593H3.14844V12.7593H18.2609L12.5759 18.439L13.6484 19.5093L21.1484 12.0093L13.6484 4.50928Z"
				fill="#3083FF"
			/>
		</BaseIcon>
	)
}

export default ArrowRightIcon

import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react"
import { useEffect, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { useJwt } from "hooks/useJwt"
import { isMobile } from "utils/isMobile"
import MobileDashboard from "pages/MobileDashboard"

import Loading from "components/loading"
import PlansModal from "./subcomponents/PlansModal"
import { useAtom } from "jotai"
import EmailAlreadyExistsErrorPage from "pages/EmailAlreadyExistsErrorPage"
import ConfirmEmailOverlay from "pages/ConfirmEmailOverlay"
import CreditsModal from "pages/SidebarPages/subcomponents/CreditsModal"
import { CurrentUser } from "types/currentUser"
import { AxiosError } from "axios"
import { ErrorSchema } from "types"
import { IS_PROD } from "config"
import { Helmet } from "react-helmet"
import { usePostHog } from "posthog-js/react"
import { creditsModalAtom, plansModalAtom } from "./atoms"
import useUserAPI from "hooks/useUserAPI"

const BasePage = () => {
	const posthog = usePostHog()
	const location = useLocation()
	const navigate = useNavigate()
	const { getAccessTokenSilently, logout, isAuthenticated } = useAuth0()
	const { currentUser } = useUserAPI()
	const [jwt, setJwt] = useJwt()
	const [overlayOpen, setOverlayOpen] = useState(false)
	const [isOpen, setIsOpen] = useAtom(plansModalAtom)
	const [isOpenCreditsModal, setIsOpenCreditsModal] =
		useAtom(creditsModalAtom)
	const [isVerifyEmailOpen, setIsVerifyEmailOpen] = useState(false)

	const {
		data: user,
		isLoading,
		error,
	} = useQuery<CurrentUser, AxiosError<ErrorSchema>>(
		["current-user", jwt],
		currentUser,
		{
			retry: 1,
			enabled: isAuthenticated,
		},
	)
	useEffect(() => {
		if (isMobile()) {
			setOverlayOpen(true)
		}

		getAccessTokenSilently()
			.then((token) => {
				if (!token) {
					logout({
						logoutParams: {
							returnTo: window.location.origin,
						},
					})
					return
				}
				setJwt(token)
			})
			.catch(() => {
				logout({
					logoutParams: {
						returnTo: window.location.origin,
					},
				})
			})
	}, [])

	useEffect(() => {
		if (user) {
			posthog?.identify(user.id, {
				email: user.email,
			})
		}
	}, [user])

	useEffect(() => {
		if (
			import.meta.env.DEV &&
			window.location.pathname !== location.pathname
		) {
			navigate(window.location.pathname)
		}
	}, [location.pathname, window.location.pathname])

	useEffect(() => {
		if (user && !user.email_verified) {
			setIsVerifyEmailOpen(true)
		}
	}, [location.pathname, window.location.pathname, user])

	if (error?.response?.data?.error?.code === "accounts-0001") {
		return <EmailAlreadyExistsErrorPage />
	} else if (error?.response?.status === 401) {
		logout({
			logoutParams: {
				returnTo: window.location.origin,
			},
		})
	}

	if (isLoading) {
		return null
	}

	return (
		<span className="prose max-w-none">
			{IS_PROD && (
				<Helmet>
					<script>
						{`
						(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
						new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
						j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
						'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
						})(window,document,'script','dataLayer','GTM-P4FQDLXW');
						`}
					</script>
				</Helmet>
			)}
			<div className="fade-in">
				<Outlet />
			</div>
			<MobileDashboard isOpen={overlayOpen} />
			<ConfirmEmailOverlay
				isOpen={isVerifyEmailOpen}
				setIsOpen={setIsVerifyEmailOpen}
			/>
			<PlansModal open={isOpen} setOpen={setIsOpen} />
			<CreditsModal
				open={isOpenCreditsModal}
				setOpen={setIsOpenCreditsModal}
			/>
		</span>
	)
}

const AuthenticatedBasePage = withAuthenticationRequired(BasePage, {
	onRedirecting: () => <Loading />,
})

export default AuthenticatedBasePage

import { Button } from "components/button"
import CreditIcon from "icons/CreditIcon"
import { useEffect, useRef, useState } from "react"
import CreditsModal from "./CreditsModal"
import SuccessCreditsModal from "./SuccessCreditsModal"
import useCurrentUser from "hooks/useCurrentUser"
import { ButtonVariant } from "components/button/types"

const Credit = ({ balance }: { balance?: number }) => {
	const [openSuccessCreditsModal, setOpenSuccessCreditsModal] =
		useState(false)
	const [openCreditsModal, setOpenCreditsModal] = useState(false)
	const [hasBuyCredits, setHasBuyCredits] = useState(false)
	const user = useCurrentUser()

	const firstUpdate = useRef(true)
	const isClosedByButton = useRef(false)

	useEffect(() => {
		setOpenCreditsModal(false)
	}, [user?.balance])

	const balanceValue = balance
		? balance < 1 && balance > 0
			? 1
			: balance
		: 0

	useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false
			return
		}
		if (isClosedByButton?.current) return
		if (!hasBuyCredits) return
		if (!openCreditsModal && hasBuyCredits) {
			setOpenSuccessCreditsModal(true)
			setHasBuyCredits(false)
		}
	}, [openCreditsModal, user?.balance])

	return (
		<>
			<div
				className={`flex flex-col items-center justify-center rounded-lg bg-gray-100 p-[16px] w-full`}
			>
				<div className={`flex flex-row items-center justify-center`}>
					<CreditIcon />
					<p className={`font-bold text-[32px] ml-1`}>
						{balanceValue ? Math.floor(Number(balanceValue)) : 0}
					</p>
				</div>

				<div className="block w-full px-[16px]">
					<Button
						variant={ButtonVariant.Contained}
						className="flex !h-[38px] w-full justify-center"
						onClick={() => setOpenCreditsModal(true)}
					>
						Comprar
					</Button>
				</div>
			</div>
			<CreditsModal
				open={openCreditsModal}
				setOpen={setOpenCreditsModal}
				isClosedByButton={isClosedByButton}
				setHasBuyCredits={setHasBuyCredits}
			/>
			<SuccessCreditsModal
				open={openSuccessCreditsModal}
				setOpen={setOpenSuccessCreditsModal}
			/>
		</>
	)
}

export default Credit

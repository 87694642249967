import { Row, flexRender } from "@tanstack/react-table"
import { RetrieveUserResponse } from "./UserTable"
import { ROUTES } from "routes"
import { useNavigate } from "react-router-dom"

interface TableRowProps {
	row: Row<RetrieveUserResponse>
}

const UserTableRow = ({ row }: TableRowProps) => {
	const navigate = useNavigate()

	function getWidth(type: string) {
		if (type === "user") {
			return "basis-[30%] grow"
		}
		if (type === "type") {
			return "basis-[10%] shrink"
		}
		if (type === "Ltv") {
			return "basis-[10%] shrink"
		}
		if (type === "createdDocs") {
			return "basis-[10%] shrink"
		}
		if (type === "credits") {
			return "basis-[10%] shrink"
		}

		if (type === "lastLogin") {
			return "basis-[10%] shrink"
		}

		if (type === "Actions") {
			return "basis-[20%] shrink justify-end"
		}
	}

	return (
		<div
			key={row.id}
			className={`relative gap-[20px] bg-white flex items-stretch p-4 hover:cursor-pointer hover:shadow-[0px_0px_10px_0px_rgba(0,0,0,0.14)] hover:text-dark-blue-2 ${
				row.getIsSelected()
					? "border-[3px] border-light-blue-1 rounded-[4px]"
					: "border-[3px] border-transparent rounded-[4px]"
			}`}
		>
			{row.getVisibleCells().map((cell: any) => {
				const width = getWidth(cell.column.id)

				return (
					<div
						key={cell.id}
						className={`${width} flex items-center`}
						onClick={() => {
							if (cell.column.id === "Actions") return
							navigate(
								ROUTES.userDetail({
									id: 1,
								}),
							)
						}}
					>
						<div>
							<div key={cell.id}>
								{flexRender(
									cell.column.columnDef.cell,
									cell.getContext(),
								)}
							</div>
						</div>
					</div>
				)
			})}
		</div>
	)
}

export default UserTableRow

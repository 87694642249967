import { ClauseContent } from "pages/DocumentPage/context/ClausesContentContext"
import { ThesisContent } from "pages/DocumentPage/context/ThesesContentContext"
import { ThesesData } from "types/theses"

export enum LegalDocumentType {
	Petition = "petition",
	Contract = "contract",
	PowerOfAttorney = "power_of_attorney",
	Uploaded = "uploaded_document",
}

export enum LegalDocumentStatus {
	Processing = "processing",
	Draft = "draft",
	Error = "error",
	Completed = "completed",
	Canceled = "canceled",
	Strategy = "strategy",
}

export enum ProcessType {
	GenerateTheses = "generate_theses",
	GenerateContent = "generate_content",
	GenerateObjection = "generate_objection",
	GenerateClauses = "generate_clauses",
	GenerateContract = "generate_contract",
	GenerateManualClause = "generate_manual_clause",
	GenerateManualThesis = "generate_manual_thesis",
	GenerateNewTheses = "generate_new_thesis",
	GenerateNewClause = "generate_new_clause",
	GenerateManualThesisRewrite = "generate_new_thesis_rewrite",
	GenerateManualThesisRewriteHow = "generate_new_thesis_rewrite_how",
	GenerateNewThesisCorrectSpelling = "generate_new_thesis_correct_spelling",
	GenerateNewThesisImprove = "generate_new_thesis_improve",
	GenerateNewThesisIncrease = "generate_new_thesis_increase",
	GenerateNewThesisSummarize = "generate_new_thesis_summarize",
	GenerateNewThesisChangeWritingTone = "generate_new_thesis_change_writing_tone",

	GenerateNewClauseRewrite = "generate_new_clause_rewrite",
	GenerateNewClauseRewriteHow = "generate_new_clause_rewrite_how",
	GenerateNewClauseCorrectSpelling = "generate_new_clause_correct_spelling",
	GenerateNewClauseImprove = "generate_new_clause_improve",
	GenerateNewClauseIncrease = "generate_new_clause_increase",
	GenerateNewClauseSummarize = "generate_new_clause_summarize",
	GenerateNewClauseChangeWritingTone = "generate_new_clause_change_writing_tone",

	GenerateAiEditorText = "generate_ai_editor_text",
}

export enum ToneEnum {
	Assertive = "assertive",
	Persuasive = "persuasive",
	Empathetic = "empathetic",
	Journalistic = "journalistic",
	Enthusiastic = "enthusiastic",
	Narrative = "narrative",
}

interface UserInfo {
	email: string
	first_name: string
	last_name: string
	phone_number?: string
	oab_number?: string
	picture: string
}

interface LDProcess {
	id: string
	document_id: string
	start_time: string
	end_time: string
	process_type: ProcessType
	json_serialized_result: string
	error: string
	max_processing_time: number
}
interface LegalDocumentContent {
	id: string
	text?: string
	version: number
	status: LegalDocumentStatus
}
interface CreatePetitionRequestBody {
	title?: string
	legal_document_type?: string
	user_input?: string
	petition_type?: string
	type?: string
	template_id?: string | null | undefined
	template_type?: string | null | undefined
}

interface CreateContractRequestBody {
	title?: string
	contract_type?: string
	user_input?: string
	to_favor?: string
}

interface CreateLegalDocumentResponse {
	title?: string
	type: LegalDocumentType
	id: string
}

interface UpdateLegalDocumentRequestBody {
	title?: string
	text?: {
		text?: string
	}
	user_input?: string
	contract_type?: string
	to_favor?: string
	petition_type?: string
	legal_document_type?: string
	template_id?: string | null
	template_type?: string | null
}

interface UpdateLegalDocumentResponse {
	id: string
	title: string
	user_input: string
}

interface VersionDocumentResponse {
	id: string
	revision: {
		id: string
		text: string
		version: number
		title: string
	}
	legal_ground: {
		id: string
		title: string
	}
}

interface LegalDocumentGenerateContent {
	user_input?: string,
	legal_document_type: string
	template_id?: string | null
	template_type?: string | null
}

interface GenerateThesesRequestBody {
	user_input: string
}
interface GenerateContentV3Body {
	user_input: string
	petition_type: string
}

interface GenerateClausesRequestBody {
	user_input: string
	to_favor: string
	contract_type: string
}
interface GenerateLegalDocumentContentRequestBody {
	theses_ids: string[]
}

interface GenerateLegalDocumentContentV2RequestBody {
	theses_contents: ThesisContent[]
}

interface GenerateContractContentV2RequestBody {
	clauses_contents: ClauseContent[]
}

interface GenerateContractContentRequestBody {
	clauses_ids: string[]
}

interface GenerateLegalDocumentContentResponse {
	id: string
	type: LegalDocumentType
	title: string
}
interface GenerateLegalDocumentContentV2Response {
	petition_id: string
}

interface GenerateContractContentV2Response {
	contract_id: string
}

interface GenerateContractContentResponse {
	contract_id: string
}

interface RetrieveLegalDocumentResponse {
	id: string
	type: LegalDocumentType
	title?: string
	text?: {
		id: string
		text?: string
		version: number
		_created_at: string
		_updated_at: string
	}
	version: number
	created_by: UserInfo
	extension?: string | null
	petition_type?: string
	legal_document_type: string
	updated_by: UserInfo
	owner: UserInfo
	_created_at: string
	_updated_at: string
	user_input: string
	objection: {
		id: string
		text: string
		version: number
		status: LegalDocumentStatus
	}
	content: LegalDocumentContent
	theses?: ThesesData[]
	processes?: LDProcess[]
	to_favor: string
	contract_type: string
	clauses?: string[]
	attached_documents?: AttachedDocument[]
	last_revision?: {
		id: string
		text?: string
		version: number
		title: string
	}
	template_id?: string | null
	template_type?: string | null
}

interface AttachedDocument {
	ext: string
	file_size: number
	file_type: string
	file_name: string
	id: string
	user: string
	_created_at: string
}

interface PaginatedResponse<T> {
	count: number
	next: string | null
	previous: string | null
	results: T[]
}

interface DefaultQueryParams {
	ordering?: string
	limit?: number
	offset?: number
	status?: LegalDocumentStatus
}

interface DocStreamWebsocketResponse {
	status: "PROCESSING" | "COMPLETED" | "ERROR" | "PENDING"
	text?: string
}

interface Transaction {
	created_at: string
	amount: number
	description: string
	card: {
		brand: string
		last4: string
	}
	invoice_pdf: string
	invoice_url: string
	receipt_url: string
}

interface TransactionsData {
	transactions: Transaction[]
}

interface RewriteThesisResponse {
	text?: string
}



interface LegalDocumentPropmts {
	id: string;
	document_name: string;
	placeholder: string;
}




export type {
	CreatePetitionRequestBody,
	CreateContractRequestBody,
	GenerateClausesRequestBody,
	CreateLegalDocumentResponse,
	GenerateThesesRequestBody,
	UpdateLegalDocumentRequestBody,
	UpdateLegalDocumentResponse,
	GenerateLegalDocumentContentRequestBody,
	GenerateLegalDocumentContentV2RequestBody,
	GenerateContractContentRequestBody,
	GenerateContractContentV2RequestBody,
	GenerateLegalDocumentContentResponse,
	GenerateLegalDocumentContentV2Response,
	GenerateContractContentResponse,
	GenerateContractContentV2Response,
	RetrieveLegalDocumentResponse,
	PaginatedResponse,
	DefaultQueryParams,
	LDProcess,
	LegalDocumentContent,
	DocStreamWebsocketResponse,
	VersionDocumentResponse,
	Transaction,
	TransactionsData,
	GenerateContentV3Body,
	RewriteThesisResponse,
	LegalDocumentGenerateContent,
	LegalDocumentPropmts
}

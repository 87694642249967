import { useMutation, useQueryClient } from "@tanstack/react-query"
import { CellContext } from "@tanstack/react-table"
import TitleTooltip from "components/title-tooltip"
import { useJwt } from "hooks/useJwt"
import LawdeckDocIcon from "icons/LawdeckDocIcon"
import PdfDocICon from "icons/PdfDocIcon"
import TxtDocIcon from "icons/TxtDocIcon"
import WordIcon from "icons/WordIcon"
import { useAtom } from "jotai"
import { useState, RefObject, useRef, useEffect } from "react"
import TextareaAutosize, {
	TextareaAutosizeProps,
} from "react-textarea-autosize"
import {
	LegalDocumentType,
	RetrieveLegalDocumentResponse,
} from "types/legalDocument"
import { isEditTextAtom } from "./atoms"
import { showToast } from "components/toast/functions"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"

interface ColumnTitleProps {
	info: CellContext<RetrieveLegalDocumentResponse, string | undefined>
}

export default function ColumnTitle({ info }: ColumnTitleProps) {
	const [inputTitle, setInputTitle] = useState(
		info.row.original.title || "Documento sem título",
	)
	const [isEditText, setIsEditText] = useAtom(isEditTextAtom)

	const { updateLegalDocument } = useLegalDocumentAPI()

	const [jwt] = useJwt()

	const queryClient = useQueryClient()

	const updateUploadedDocumentMutation = useMutation({
		mutationFn: updateLegalDocument,
		onSuccess: ({ id }) => {
			onSuccessUpdateDocument(id)
		},
	})

	function onSuccessUpdateDocument(id: string) {
		queryClient.invalidateQueries(["listLegalDocuments", jwt])
		queryClient.refetchQueries({
			queryKey: ["legalDocument", id],
		})
	}

	const inputRef: RefObject<TextareaAutosizeProps> &
		(string | RefObject<HTMLInputElement>) = useRef(null)

	const onSaveTitle = (
		id: string,
		title: string,
		type: LegalDocumentType,
	) => {
		if (title !== info.row.original.title) {
			updateUploadedDocumentMutation.mutate({
				id: id,
				payload: {
					title: title,
				},
			})

			if (type === "uploaded_document") {
				updateUploadedDocumentMutation.mutate({
					id: id,
					payload: {
						title: title,
					},
				})
			}

			showToast("O título do documento foi alterado com sucesso.")
		}
		setIsEditText("")
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (event.key === "Enter") {
			event.preventDefault()
			onSaveTitle(
				info.row.original.id,
				inputTitle,
				info.row.original.type,
			)
		}
	}

	useEffect(() => {
		if (isEditText && inputRef.current) {
			;(inputRef.current as HTMLTextAreaElement).select()
		}
	}, [isEditText])

	function noSpacesClassName(text?: string) {
		if (!text) return ""

		const hasSpaces = /\s/.test(text)
		if (!hasSpaces) {
			return "break-all"
		}
		return ""
	}

	function getIcon() {
		switch (info.row.original.extension) {
			case "docx":
				return <WordIcon />
			case "pdf":
				return <PdfDocICon />
			case "lawdeck":
				return <LawdeckDocIcon />
			case "txt":
				return <TxtDocIcon />
			default:
				return <LawdeckDocIcon />
		}
	}

	return (
		<>
			{/* <div className="absolute bottom-[39px] left-[-6px]">
                <IndeterminateCheckbox
                    className={`${className} mx-2 hover:opacity-100`}

                />
            </div> */}
			<div className="flex group-hover:text-blue-500 items-center w-[100%] relative gap-2 mr-5">
				<div className="">{getIcon()}</div>
				<div className="flex flex-col ">
					<div className="font-semibold text-md">
						{info.row.original.id === isEditText ? (
							<TextareaAutosize
								maxRows={2}
								maxLength={50}
								className={` leading-[27.5px] w-full overflow-hidden resize-none text-dark-blue-1 p-0 text-[16px] xl:text-h4 font-semibold border-none bg-transparent mr-[16px]  focus-visible:!outline-none focus:!shadow-none focus:!ring-0`}
								value={inputTitle}
								onClick={(event) => event.stopPropagation()}
								onChange={(event) =>
									setInputTitle(event.target.value)
								}
								onBlur={() =>
									onSaveTitle(
										info.row.original.id,
										inputTitle,
										info.row.original.type,
									)
								}
								autoFocus
								ref={inputRef}
								onKeyDown={(event) => handleKeyDown(event)}
							/>
						) : (
							<TitleTooltip
								text={inputTitle || "Documento sem título"}
								disable={inputTitle.length < 74}
							>
								<p
									className={`${noSpacesClassName(
										inputTitle,
									)} text-[16px] line-clamp-2 xl:text-h4`}
								>
									{inputTitle || "Documento sem título"}
								</p>
							</TitleTooltip>
						)}
					</div>
					<div className="text-brand-gray-2">
						Criado por: {info.row.original.created_by.first_name}
					</div>
				</div>
			</div>
		</>
	)
}

import { useAtom } from "jotai"
import { atomWithStorage } from "jotai/utils"

const initialValue =
	localStorage.getItem("jwt") !== null ? localStorage.getItem("jwt") : ""

const tokenAtom = atomWithStorage<string>("jwt", initialValue!)

export function useJwt(): [string, (value: string) => void] {
	const [token, setToken] = useAtom(tokenAtom)

	return [token, setToken]
}

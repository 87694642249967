import { withRef } from "@udecode/cn"
import {
	useMarkToolbarButton,
	useMarkToolbarButtonState,
} from "@udecode/plate-common"

import { ToolbarButton } from "./toolbar"

export const MarkToolbarButton = withRef<
	typeof ToolbarButton,
	{
		clear?: string | string[]
		nodeType: string
	}
>(({ clear, nodeType, ...rest }, ref) => {
	const state = useMarkToolbarButtonState({ clear, nodeType })
	const { props } = useMarkToolbarButton(state)

	return (
		<ToolbarButton ref={ref} {...props} {...rest} className="p-0">
			<div
				className={`text-[#646464] p-0.5 ${
					state.pressed
						? "text-light-blue-1 bg-semi-white-3"
						: "hover:text-dark-blue-2"
				}`}
			>
				{rest.children}
			</div>
		</ToolbarButton>
	)
})

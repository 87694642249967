import BaseIcon from "./BaseIcon"

interface DownloadIconProps {
	className?: string
}

const DownloadIcon = ({ className }: DownloadIconProps) => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			className={className}
		>
			<path d="M17.625 16.5V18.75H6.375V16.5H5.25V18.75C5.25 19.0484 5.36853 19.3345 5.5795 19.5455C5.79048 19.7565 6.07663 19.875 6.375 19.875H17.625C17.9234 19.875 18.2095 19.7565 18.4205 19.5455C18.6315 19.3345 18.75 19.0484 18.75 18.75V16.5H17.625Z" />
			<path d="M17.625 10.875L16.8319 10.0819L12.5625 14.3456V4.125H11.4375V14.3456L7.16812 10.0819L6.375 10.875L12 16.5L17.625 10.875Z" />
		</BaseIcon>
	)
}

export default DownloadIcon

import smallLex from "assets/small-lex.png"
import CloseIcon from "icons/CloseIcon"
import ChatPlusIcon from "icons/ChatPlusIcon"
import largeLex from "assets/large-lex.png"
import Send from "icons/Send"
import { useAtom } from "jotai"
import { useEffect, useRef, useState } from "react"
import ReactTextareaAutosize from "react-textarea-autosize"
import Lottie from "react-lottie"
import loading from "lotties/varinha_magica_-_loading.json"
import { useParams } from "react-router-dom"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useLongPoll } from "hooks/useLongPoll"
import QuickActionIcon from "icons/QuickActionIcon"
import useCurrentUser from "hooks/useCurrentUser"
import { AxiosError } from "axios"
import NewLexChatModal from "../NewLexChatModal"
import { haveFooterAtom } from "./atoms"
import {
	openLexAtom,
	showLexAtom,
} from "pages/SidebarPages/subcomponents/atoms"
import { plansModalAtom } from "pages/BasePage/atoms"
import { ToastType } from "components/toast/types"
import { showToast } from "components/toast/functions"
import { Button } from "components/button"
import { ButtonVariant } from "components/button/types"
import MessageItem from "../MessageItem"
import useLexChatAPI from "hooks/useLexChatAPI"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"
import { LegalDocumentType } from "types/legalDocument"
import Sumarize from "icons/Sumarize"
import useCheckIfHasCredits from "hooks/useCheckIfHasCredits"

const LottieComponent = Lottie as any

const lottieOptions = {
	autoplay: true,
	animationData: loading,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
		className: "cursor-default",
	},
}

interface ILexAssistant {
	isDocumentPage?: boolean
}
const LexAssistant = ({ isDocumentPage }: ILexAssistant) => {
	const queryClient = useQueryClient()
	const [haveFooter] = useAtom(haveFooterAtom)
	const [lexOpen, setLexOpen] = useAtom(openLexAtom)
	const [, setShowLex] = useAtom(showLexAtom)
	const [messageToSend, setMessageToSend] = useState<string>()
	const [lexId, setLexId] = useState<string>()
	const [, setOpenPlansModal] = useAtom(plansModalAtom)
	const hasCredits = useCheckIfHasCredits()
	const [shiftKeyPressed, setShiftKeyPressed] = useState(false)
	const [hasScroll, setHasScroll] = useState(false)
	const [openNewChatModal, setOpenNewChatModal] = useState(false)
	const scrollDivRef = useRef<HTMLDivElement>(null)
	const bottomDivRef = useRef<HTMLDivElement>(null)
	const user = useCurrentUser()

	const scrollToBottom = () => {
		bottomDivRef.current?.scrollIntoView({
			behavior: "smooth",
			block: "end",
		})
	}

	const inputRef = useRef<HTMLTextAreaElement>(null)
	const barScrolled = useRef(false)

	const { documentId } = useParams()

	const { createLexChat, listLexChat, sendMessageLexChat, retrieveLexChat } =
		useLexChatAPI()

	const { retrieveLegalDocument } = useLegalDocumentAPI()
	const { data } = useQuery(
		["listLexChat", documentId],
		() => listLexChat(documentId),
		{ enabled: !!documentId },
	)

	const {
		data: lexChat,
		refetch,
		isError,
	} = useQuery(["lexChat", lexId], () => retrieveLexChat(lexId!), {
		enabled: !!lexId,
	})

	const { data: legalDocument } = useQuery(
		["legalDocument", documentId],
		() => retrieveLegalDocument(documentId!),
		{ enabled: !!documentId },
	)

	const createLexChatMutation = useMutation({
		mutationFn: createLexChat,
		onSuccess: () => queryClient.invalidateQueries(["lexChat", lexId]),
	})

	const sendMessageLexChatMutation = useMutation({
		mutationFn: sendMessageLexChat,
		onSuccess: () => queryClient.invalidateQueries(["lexChat", lexId]),
		onError: (error: AxiosError<any>) => {
			if (error.response?.status === 403) handleNoBalance()
		},
	})

	const messages =
		lexChat && lexChat.messages.length > 0
			? lexChat.messages.slice().reverse()
			: []

	const options = [
		legalDocument?.type === "petition"
			? {
				label: "Sugira jurisprudência",
				icon: <QuickActionIcon />,
				value: "suggestJurisprudence",
			}
			: {
				label: "Pontos-chave",
				icon: <QuickActionIcon />,
				value: "documentKeyPoints",
			},
		legalDocument?.type === "petition"
			? {
				label: "Preveja uma impugnação",
				icon: <QuickActionIcon />,
				value: "previewOpposition",
			}
			: {
				label: "Sugira dicas de melhorias",
				icon: <QuickActionIcon />,
				value: "similarCases",
			},
		legalDocument?.type !== LegalDocumentType.Uploaded
			? {
				label: "Sugira dicas de melhorias",
				icon: <QuickActionIcon />,
				value: "suggestImprovement",
			}
			: {
				label: "Sumarize o documento",
				icon: <Sumarize className="fill-current" />,
				value: "sumarize",
			},
	]

	const isLoading = messages?.at(-1)?.sender === "USER"

	function handleKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement>) {
		if (event.key === "Enter" && messageToSend) {
			if (shiftKeyPressed) return
			event.preventDefault()
			if (lexChat) {
				{
					if (!hasCredits) return handleNoBalance()
					sendMessageLexChatMutation
						.mutateAsync({
							id: lexChat.id,
							payload: {
								message: messageToSend,
							},
						})
						.then(() => setMessageToSend(""))
				}
			} else {
				if (documentId)
					createLexChatMutation
						.mutateAsync({
							document_id: documentId,
						})
						.then((data) => {
							if (!hasCredits) return handleNoBalance()
							setLexId(data.id)
							sendMessageLexChatMutation
								.mutateAsync({
									id: data.id,
									payload: {
										message: messageToSend,
									},
								})
								.then(() => setMessageToSend(""))
						})
			}
		}
		if (event.key === "Enter" && !messageToSend) {
			event.preventDefault()
		}
	}

	function getContainerClassName() {
		const bottom = haveFooter ? "bottom-[90px]" : "bottom-6"
		if (lexOpen) {
			if (isDocumentPage) {
				return "bottom-0 right-[7px]"
			}
			return `${bottom} right-[24px]`
		} else {
			return `right-5 ${bottom}`
		}
	}

	function handleNoBalance() {
		setOpenPlansModal(true)
		showToast(
			"Você não tem créditos para concluir essa ação.",
			ToastType.Error,
		)
	}
	function handleNewChat() {
		if (messages && messages.length > 0)
			createLexChatMutation
				.mutateAsync({
					document_id: documentId!,
				})
				.then((data) => {
					setLexId(data.id)
					setMessageToSend("")
					setOpenNewChatModal(false)
					refetch()
				})
	}

	useLongPoll({
		queryKey: ["lexChat", lexId],
		conditionToStop: !isLoading,
		deps: [documentId, messages?.length, lexId],
		ms: 5000,
	})

	useEffect(() => {
		return () => {
			setLexOpen(false)
			setShowLex(false)
		}
	}, [])
	useEffect(() => {
		const handleShiftDown = (event: KeyboardEvent) => {
			if (event.key === "Shift") {
				setShiftKeyPressed(true)
			}
		}

		const handleKeyUp = (event: KeyboardEvent) => {
			if (event.key === "Shift") {
				setShiftKeyPressed(false)
			}
		}

		window.addEventListener("keydown", handleShiftDown)
		window.addEventListener("keyup", handleKeyUp)

		return () => {
			window.removeEventListener("keyup", handleKeyUp)
			window.removeEventListener("keydown", handleShiftDown)
		}
	}, [])

	useEffect(() => {
		const div = scrollDivRef.current
		if (div) {
			if (div.scrollHeight > div.clientHeight) {
				setHasScroll(true)
			} else {
				setHasScroll(false)
			}
		}
	}, [scrollDivRef, messages])

	useEffect(() => {
		if (data && data?.length) setLexId(data[0].id)
	}, [data?.length])

	useEffect(() => {
		if (isLoading) scrollToBottom()
	}, [isLoading])

	useEffect(() => {
		if (messages?.length > 0 && barScrolled.current === false) {
			scrollToBottom()
			barScrolled.current = true
		}
	}, [messages?.length > 0])

	useEffect(() => {
		queryClient.invalidateQueries(["lexChat", lexId])
	}, [documentId, lexId])

	useEffect(() => {
		if (inputRef.current && lexOpen) {
			setTimeout(() => {
				inputRef.current?.focus()
			}, 100)
		}
	}, [lexOpen])

	if (!documentId) return null

	return (
		<div className={` fixed ${getContainerClassName()} `}>
			<div className={lexOpen ? "" : "hidden"}>
				<div
					className={` bg-white shadow-md w-[330px]  h-[100vh] 
					 flex flex-col justify-between`}
				>
					<div className="shadow-outlined p-6">
						<div className="flex justify-between items-center">
							<div className="flex items-center ">
								<img src={smallLex} />
								<h4 className="font-semibold text-xl ml-2">
									Lex
								</h4>
							</div>

							<div className="flex items-center ">
								<div className="mr-4">
									<div
										className={
											messages && messages.length > 0
												? "cursor-pointer"
												: ""
										}
										onClick={() => {
											if (messages && messages.length < 1)
												return
											setOpenNewChatModal(true)
										}}
									>
										<ChatPlusIcon
											fill={
												messages && messages.length > 0
													? "#3083FF"
													: "#ACACAC"
											}
										/>
									</div>
								</div>

								<div
									className="cursor-pointer"
									onClick={() => setLexOpen(false)}
								>
									<CloseIcon size={"24px"} />
								</div>
							</div>
						</div>
					</div>

					{messages && messages.length > 0 ? (
						<div
							ref={scrollDivRef}
							className={`overflow-hidden h-full hover:overflow-auto  pl-6 pr-[32px]  ${hasScroll && "hover:pr-6"
								}`}
						>
							<div>
								{messages.map((message, index) => {
									return (
										<MessageItem
											key={index}
											message={message}
											user={user}
											index={index}
										/>
									)
								})}
							</div>

							{isLoading && (
								<div className="mb-6 ">
									<div className="flex items-center gap-2 mb-2 ">
										<img
											src={smallLex}
											className="w-[20px] h-[20px] rounded-full "
										/>
										<p className="text-small-1 font-semibold">
											Lex
										</p>
									</div>
									<div
										role="status"
										className="animate-pulse flex flex-col gap-2"
									>
										<div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-full mb-0 mt-0"></div>
										<div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-full mb-0 mt-0"></div>
										<div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-[80%] mb-0 mt-0"></div>
									</div>
								</div>
							)}

							{isError && (
								<div className="flex w-full justify-center">
									<Button
										variant={ButtonVariant.Contained}
										className="mb-8"
										onClick={() =>
											queryClient.invalidateQueries([
												"lexChat",
												lexId,
											])
										}
									>
										Gerar novamente
									</Button>
								</div>
							)}
							<div ref={bottomDivRef} />
						</div>
					) : (
						<div className="flex flex-col justify-center p-6 h-full overflow-auto no-scrollbar ">
							<div className="text-center flex justify-center flex-col items-center mb-[52px]">
								<div>
									<img src={largeLex} />
								</div>
								<h1 className="font-semibold my-4">
									Oi, sou Lex!
								</h1>
								<p className="text-brand-gray-3">
									Sou seu assistente jurídico e aqui estão
									algumas ações rápidas pra te ajudar:
								</p>
							</div>

							<div
								className={`text-center ${isDocumentPage && "mb-6"
									} flex justify-center flex-col items-center`}
							>
								{options.map((option, index) => (
									<button
										key={option.value}
										onClick={() => {
											createLexChatMutation
												.mutateAsync({
													document_id: documentId!,
												})
												.then((data) => {
													if (!hasCredits) {
														return handleNoBalance()
													}
													setLexId(data.id)
													sendMessageLexChatMutation.mutate(
														{
															id: data.id,
															payload: {
																message:
																	option.label,
															},
														},
													)
												})
										}}
										className={`flex items-center justify-start ${index !== options.length - 1 &&
											"mb-4"
											} w-full p-4 rounded-lg shadow-outlined hover:text-dark-blue-2 `}
									>
										{option.icon}
										<span className="ml-2 text-cta-1 text-brand-gray-3 text-current">
											{option.label}
										</span>
									</button>
								))}
							</div>
						</div>
					)}

					<div className="p-6 pt-0">
						<div className="relative flex items-center">
							<div className="shadow-4">
								<ReactTextareaAutosize
									className={`!outline-offset-0 block rounded-[4px] border !border-1 w-[272px] max-h-[112px] pr-[50px] resize-none focus:!outline-none border-brand-gray-1 py-3 focus:border focus:border-light-blue-4  ${isError &&
										"border-brand-gray-1 bg-brand-white-3 placeholder:text-brand-gray-2 "
										}`}
									placeholder={
										isLoading
											? "Estou escrevendo..."
											: "Converse com o Lex"
									}
									name="lex"
									ref={inputRef}
									value={messageToSend}
									onChange={(e) =>
										setMessageToSend(e.target.value)
									}
									onKeyDown={handleKeyDown}
									disabled={isLoading || isError}
								/>
							</div>
							<div className="flex items-center absolute right-[16px] bottom-[12px] gap-[8px]">
								{isLoading ? (
									<>
										<div>
											<LottieComponent
												options={lottieOptions}
												height={24}
												width={24}
												isClickToPauseDisabled
											/>
										</div>
									</>
								) : (
									<div
										className={`cursor-pointer ${isError && "cursor-not-allowed"
											}`}
										onClick={() => {
											if (isError) return
											if (messageToSend) {
												if (lexChat) {
													if (!hasCredits)
														return handleNoBalance()
													sendMessageLexChatMutation
														.mutateAsync({
															id: lexChat.id,
															payload: {
																message:
																	messageToSend,
															},
														})
														.then(() => {
															setMessageToSend("")
														})
												} else {
													createLexChatMutation
														.mutateAsync({
															document_id:
																documentId!,
														})
														.then((data) => {
															if (!hasCredits)
																return handleNoBalance()
															setLexId(data.id)
															sendMessageLexChatMutation.mutate(
																{
																	id: data.id,
																	payload: {
																		message:
																			messageToSend,
																	},
																},
															)
														})
												}
											}
										}}
									>
										<Send
											className={`text-dark-blue-1 hover:fill-dark-blue-2 ${isError &&
												"fill-brand-gray-2 hover:fill-brand-gray-2"
												}`}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className={lexOpen ? "hidden" : "show"}>
				<img
					src={largeLex}
					onClick={() => setLexOpen(true)}
					className="cursor-pointer w-[80px] h-[81px] hover:w-[96px]  hover:h-[97px] transition-all"
				/>
			</div>
			<NewLexChatModal
				open={openNewChatModal}
				setOpen={setOpenNewChatModal}
				onConfirm={handleNewChat}
			/>
		</div>
	)
}

export default LexAssistant

import Modal from "components/modal"
import { Button } from "components/button"
import { useNavigate } from "react-router-dom"
import { ROUTES } from "routes"
import { useMutation } from "@tanstack/react-query"
import { ButtonVariant } from "components/button/types"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"
import { ModalAtomType } from "./atoms"

interface SaveDocumentModalProps {
	open: ModalAtomType
	setOpen: React.Dispatch<React.SetStateAction<ModalAtomType>>
	documentId: string
	routeToNavigate: string
}

export interface NewLocalSaveDocumentModal {
	checkedAt: string
	save: boolean | null
	alreadShowDocuments: string[]
}

const SaveDocumentModal = ({
	open,
	setOpen,
	documentId,
	routeToNavigate,
}: SaveDocumentModalProps) => {
	const navigate = useNavigate()
	const { deleteLegalDocument } = useLegalDocumentAPI()

	const deleteDocumentMutation = useMutation({
		mutationFn: () => deleteLegalDocument(documentId),
		onSuccess: () => {
			navigate(ROUTES.basePage())
		},
	})

	const onSave = () => {
		const copyDocumentId = getDocumentCopy()

		const localStorageSaveDocument: string[] = localStorage.getItem(
			"documentAlreadyCheck",
		)
			? JSON.parse(localStorage.getItem("documentAlreadyCheck")!)
			: []
		localStorage.setItem(
			"documentAlreadyCheck",
			JSON.stringify([...localStorageSaveDocument, documentId]),
		)
		if (copyDocumentId && open.isCancel) {
			navigate(ROUTES.documentDetail({ id: copyDocumentId }))
		} else {
			navigate(routeToNavigate)
		}
		localStorage.removeItem("copiedDocument")
		setOpen({
			open: false,
			isCancel: false,
		})
	}

	const onDiscard = async () => {
		const copyDocumentId = getDocumentCopy()

		await deleteDocumentMutation.mutateAsync()

		if (copyDocumentId && open.isCancel) {
			navigate(ROUTES.documentDetail({ id: copyDocumentId }))
		} else {
			navigate(routeToNavigate)
		}
		localStorage.removeItem("copiedDocument")
		setOpen({
			open: false,
			isCancel: false,
		})
	}

	const getDocumentCopy = () => {
		const localStorageCopiedDocument: string | null =
			localStorage.getItem("copiedDocument")

		return localStorageCopiedDocument
	}

	return (
		<Modal
			size="md"
			width="w-[550px]"
			openModal={open.open}
			setOpenModal={() =>
				setOpen({
					open: false,
					isCancel: open.isCancel,
				})
			}
			dismissible
			onlyCloseButton
		>
			<div className="flex  justify-center flex-col p-2">
				<h3 className={"font-semibold mb-2"}>
					Salvar documento como rascunho?
				</h3>
				<p className="text-brand-gray-3 mb-8 text-cta-1">
					Se você optar por não salvar, seu documento será descartado.
				</p>

				<div className="flex items-center justify-between">
					<Button
						variant={ButtonVariant.Outlined}
						className="mr-4 w-[235px] justify-center"
						onClick={() => onDiscard()}
					>
						Descartar
					</Button>
					<Button
						variant={ButtonVariant.Contained}
						className="flex w-[235px] justify-center"
						onClick={() => onSave()}
					>
						Salvar
					</Button>
				</div>
			</div>
		</Modal>
	)
}

export default SaveDocumentModal

import { CellContext } from "@tanstack/react-table"
import ContractIcon from "icons/ContractIcon"
import HammerIcon from "icons/HammerIcon"
import ImportDocIcon from "icons/ImportDocIcon"
import JusticeIcon from "icons/JusticeIcon"
import {
	LegalDocumentType,
	RetrieveLegalDocumentResponse,
} from "types/legalDocument"

export interface TypeIconProps {
	type: LegalDocumentType
}

function TypeIcon({ type }: TypeIconProps) {
	const icon = {
		petition: <HammerIcon size="24" />,
		contract: <ContractIcon />,
		power_of_attorney: <JusticeIcon />,
		uploaded_document: <ImportDocIcon />,
	}

	return icon[type]
}

export default function ColumnType({
	info,
}: {
	info: CellContext<RetrieveLegalDocumentResponse, string | undefined>
}) {
	const translate = {
		petition: "Petição",
		contract: "Contrato",
		power_of_attorney: "Procuração",
		uploaded_document: "Importado",
	}

	return (
		<div className="flex items-center min-w-[110px] max-w-[244px] text-brand-gray-3 mr-5">
			<div className="me-2">
				<TypeIcon type={info.getValue() as LegalDocumentType} />
			</div>
			{translate[info.getValue() as LegalDocumentType]}
		</div>
	)
}

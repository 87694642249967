import { Button } from "components/button"
import { ButtonVariant } from "components/button/types"
import Tooltip from "components/tooltip"
import { TooltipPosition } from "components/tooltip/enums"
import CopyIcon from "icons/CopyIcon"
import { useRef, useState } from "react"
import { Jurisprudence } from "types/jurisprudence"

export default function JurisprudenceCard({
	jurisprudence,
	isBig
}: {
	jurisprudence: Jurisprudence
	isBig?: boolean
}) {
	const [openText, setOpenText] = useState(false)
	const [textIsCopied, setTextIsCopied] = useState(false)
	const textRef = useRef<HTMLDivElement>(null)
	const linkRef = useRef<HTMLDivElement>(null)

	const handleCopyClick = async () => {
		const textElement = textRef.current
		const linkElement = linkRef.current
		if (!textElement || !linkElement) return

		const originalText = textElement.innerText
		const innerHtmlText = textElement.innerHTML
		const htmlText = `<blockquote>${innerHtmlText} - <a target="blank" href=${jurisprudence?.link}>Fonte: ${jurisprudence.tribunal} - ${jurisprudence.numero_processo}</a></blockquote>`

		if (typeof ClipboardItem !== "undefined") {
			// Shiny new Clipboard API, not fully supported in Firefox.
			// https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API#browser_compatibility
			const blobHtml = new Blob([htmlText], {
				type: "text/html",
			})
			const blobText = new Blob([originalText], { type: "text/plain" })
			const data = [
				new ClipboardItem({
					["text/plain"]: blobText,
					["text/html"]: blobHtml,
				}),
			]
			try {
				await navigator.clipboard.write(data)
			} catch (err) {
				console.error("Erro ao copiar texto: ", err)
			}
		} else {
			// Fallback using the deprecated `document.execCommand`.
			// https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand#browser_compatibility
			const cb = (e: any) => {
				e.clipboardData.setData("text/html", htmlText)
				e.clipboardData.setData("text/plain", originalText)
				e.preventDefault()
			}
			document.addEventListener("copy", cb)
			document.execCommand("copy")
			document.removeEventListener("copy", cb)
		}
		setTextIsCopied(true)
	}

	return (
		<div className="rounded shadow-outlined ">
			<div className="flex items-center justify-between px-4 py-2 bg-semi-white-4">
				<p className="text-cta-2">Jurisprudência</p>
				<div
					className="cursor-pointer hover:text-light-blue-4"
					onClick={handleCopyClick}
				>
					<Tooltip
						position={TooltipPosition.Above}
						text={textIsCopied ? "Copiado!" : "Copiar"}
						bg={textIsCopied ? "bg-[#323232EB]" : ""}
					>
						<CopyIcon className="fill-current" size="20px" />
					</Tooltip>
				</div>
			</div>

			<div className="p-4 relative bg-white">
				<div ref={linkRef}>
					<a
						target="blank"
						href={jurisprudence?.link}
						className={`no-underline  font-semibold text-light-blue-4 ${isBig ? "text-h4" : "text-cta-2"} line-clamp-2 hover:text-light-blue-2 cursor-pointer`}
					>
						{jurisprudence.tribunal} -{" "}
						{jurisprudence.classe_processual} -{" "}
						{jurisprudence.assunto} -{" "}
						{jurisprudence.numero_processo}
					</a>
				</div>
				<span className="text-small-1">
					{jurisprudence.tipo_decisao}
				</span>
				<span className="mx-4 text-small-1">•</span>
				<span className="text-small-1">
					{String(jurisprudence.data_publicacao).replaceAll("-", "/")}
				</span>
				<div
					className={`${openText ? "max-h-[100%]" : "max-h-[262px]"
						} overflow-hidden `}
				>
					<p className="text-cta-2 mt-4" ref={textRef}>
						{jurisprudence.ementa}
					</p>
				</div>
				<div
					className={`shadow-5 absolute  right-[16px]  bg-white cursor-pointer ${openText
						? "relative flex items-center justify-end"
						: "bottom-[18px]"
						}`}
				>
					<Button
						variant={ButtonVariant.Text}
						className="p-0 m-0 h-fit text-cta-2 font-semibold"
						onClick={() => setOpenText(!openText)}
					>
						{openText ? "Mostrar menos" : "Mostrar mais"}
					</Button>
				</div>
			</div>
		</div>
	)
}

import { HTMLProps, useEffect, useRef } from "react"

export function IndeterminateCheckbox({
	indeterminate,
	className = "",
	...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
	const ref = useRef<HTMLInputElement>(null!)

	useEffect(() => {
		if (typeof indeterminate === "boolean") {
			ref.current.indeterminate = !rest.checked && indeterminate
		}
	}, [ref, indeterminate])

	return (
		<input
			type="checkbox"
			ref={ref}
			className={
				className +
				" cursor-pointer rounded-sm focus:shadow-none focus:ring-0 focus:outline-none checked:!bg-[3.55px]"
			}
			{...rest}
		/>
	)
}

const UpgradeIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
		>
			<path
				d="M7.99998 10.26L5.49331 11.7733L6.15331 8.92L3.93998 7L6.85998 6.75333L7.99998 4.06L9.13998 6.75333L12.06 7L9.84665 8.92L10.5066 11.7733M14.6666 6.16L9.87331 5.75333L7.99998 1.33333L6.12665 5.75333L1.33331 6.16L4.96665 9.31333L3.87998 14L7.99998 11.5133L12.12 14L11.0266 9.31333L14.6666 6.16Z"
				fill="white"
			/>
		</svg>
	)
}

export default UpgradeIcon

export function formatDate(date: string) {
	const newDate = new Date(date)
	const formattedDate = newDate.toLocaleString("pt-BR", {
		day: "2-digit",
		month: "2-digit",
		year: "numeric",
		hour: "2-digit",
		minute: "2-digit",
	})
	const newFormattedDate = formattedDate.split(",").join(" - ")
	return newFormattedDate
}

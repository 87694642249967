import BaseIcon from "./BaseIcon"

interface CloudIconProps {
	className?: string
}

const CloudIcon = ({ className }: CloudIconProps) => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="18"
			viewBox="0 0 21 15"
			fill="none"
			className={className}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.5905 5.4428C13.6516 5.50376 13.7001 5.57618 13.7331 5.6559C13.7662 5.73563 13.7833 5.8211 13.7833 5.90742C13.7833 5.99374 13.7662 6.07921 13.7331 6.15894C13.7001 6.23867 13.6516 6.31109 13.5905 6.37205L9.65295 10.3095C9.59199 10.3707 9.51958 10.4191 9.43985 10.4522C9.36012 10.4853 9.27465 10.5023 9.18833 10.5023C9.10201 10.5023 9.01654 10.4853 8.93681 10.4522C8.85708 10.4191 8.78466 10.3707 8.7237 10.3095L6.75495 8.3408C6.69394 8.27978 6.64554 8.20735 6.61252 8.12763C6.5795 8.04791 6.5625 7.96246 6.5625 7.87617C6.5625 7.78988 6.5795 7.70444 6.61252 7.62472C6.64554 7.545 6.69394 7.47256 6.75495 7.41155C6.81597 7.35053 6.88841 7.30213 6.96813 7.26911C7.04785 7.23609 7.13329 7.21909 7.21958 7.21909C7.30587 7.21909 7.39131 7.23609 7.47103 7.26911C7.55075 7.30213 7.62319 7.35053 7.6842 7.41155L9.18833 8.91699L12.6612 5.4428C12.7222 5.38168 12.7946 5.3332 12.8743 5.30011C12.954 5.26703 13.0395 5.25 13.1258 5.25C13.2121 5.25 13.2976 5.26703 13.3773 5.30011C13.4571 5.3332 13.5295 5.38168 13.5905 5.4428Z"
			/>
			<path d="M5.78288 1.76137C7.09469 0.630177 8.76782 0.0054318 10.5 0C14.0306 0 16.9614 2.625 17.2804 6.00994C19.3699 6.30525 21 8.05481 21 10.2021C21 12.5593 19.0339 14.4375 16.6517 14.4375H4.96256C2.24175 14.4375 0 12.2929 0 9.60487C0 7.29094 1.66162 5.37469 3.86137 4.88906C4.04906 3.75637 4.7775 2.62762 5.78288 1.76137ZM6.63994 2.75494C5.64638 3.612 5.12663 4.64494 5.12663 5.45344V6.04144L4.54256 6.10575C2.709 6.30656 1.3125 7.812 1.3125 9.60487C1.3125 11.5303 2.92687 13.125 4.96256 13.125H16.6517C18.3488 13.125 19.6875 11.7968 19.6875 10.2021C19.6875 8.60606 18.3488 7.27781 16.6517 7.27781H15.9954V6.62156C15.9968 3.70781 13.5555 1.3125 10.5 1.3125C9.08234 1.31816 7.71335 1.82888 6.63994 2.75494Z" />
		</BaseIcon>
	)
}

export default CloudIcon

import { AxiosResponse } from "axios"
import axiosInstance from "axiosInstance"
import { NotificationsResponse } from "types/notification"

const useNotificationAPI = () => {
	const listNotifications = async () => {
		const response: AxiosResponse<NotificationsResponse> =
			await axiosInstance.get("/notifications/?ordering=-_created_at")

		return response.data
	}

	const deleteNotification = async (id: string) => {
		await axiosInstance.delete(`/notifications/${id}/`)
	}

	const markAsReadNotification = async (id: string) => {
		await axiosInstance.patch(`/notifications/${id}/mark_as_read/`, {
			visualized: true,
		})
	}

	const markAllAsReadNotification = async (ids: string[]) => {
		await Promise.all(
			ids.map(async (id) => {
				await axiosInstance.patch(
					`/notifications/${id}/mark_as_read/`,
					{
						visualized: true,
					},
				)
			}),
		)
	}
	return {
		listNotifications,
		deleteNotification,
		markAsReadNotification,
		markAllAsReadNotification,
	}
}
export default useNotificationAPI

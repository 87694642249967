import React from "react"
import BaseIcon from "./BaseIcon"

const TextFontIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.25 15H10L8.94194 11.875H4.8085L3.75 15H2.5L6.25 4.375H7.5L11.25 15ZM6.79231 6.01806L5.23194 10.625H8.51875L6.95906 6.01838L6.79231 6.01806ZM12.5 7.5H15.625C16.1221 7.50055 16.5987 7.69827 16.9502 8.04978C17.3017 8.40129 17.4995 8.87789 17.5 9.375V15H13.75C13.2529 14.9995 12.7763 14.8017 12.4248 14.4502C12.0733 14.0987 11.8755 13.6221 11.875 13.125V12.5C11.8755 12.0029 12.0733 11.5263 12.4248 11.1748C12.7763 10.8233 13.2529 10.6255 13.75 10.625H16.25V9.375C16.2499 9.20928 16.184 9.05039 16.0668 8.9332C15.9496 8.81602 15.7907 8.75013 15.625 8.75H12.5V7.5ZM13.3082 13.5668C13.4254 13.684 13.5843 13.7499 13.75 13.75H16.25V11.875H13.75C13.5843 11.8751 13.4254 11.941 13.3082 12.0582C13.191 12.1754 13.1251 12.3343 13.125 12.5V13.125C13.1251 13.2907 13.191 13.4496 13.3082 13.5668Z"
			/>
		</BaseIcon>
	)
}

export default TextFontIcon

import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Button } from "components/button"
import Modal from "components/modal"
import React from "react"
import { useAtom } from "jotai"
import { contractStepAtom } from "./ContractDisplay/atoms"
import { ButtonVariant } from "components/button/types"
import useContractAPI from "hooks/useContractAPI"

interface DiscardClausesModalProps {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	documentId: string
}

const DiscardClausesModal = ({
	open,
	setOpen,
	documentId,
}: DiscardClausesModalProps) => {
	const { destroyClauses } = useContractAPI()
	const queryClient = useQueryClient()
	const [, setCurrentStep] = useAtom(contractStepAtom)

	function handleCancel() {
		setOpen(false)
	}

	const destroyClausesMutation = useMutation({
		mutationFn: destroyClauses,
		onSuccess: () => {
			queryClient.invalidateQueries(["legalDocument", documentId])
			setCurrentStep(0)
			setOpen(false)
		},
	})
	async function handleBack() {
		destroyClausesMutation.mutateAsync(documentId)
	}
	return (
		<Modal
			size="550"
			width="w-[550px]"
			openModal={open}
			setOpenModal={setOpen}
			dismissible
			onlyCloseButton
		>
			<div className="flex  justify-center flex-col p-2">
				<p className={"text-[22px] font-semibold mb-2 font-lato"}>
					Tem certeza que deseja voltar?
				</p>
				<div className="text-brand-gray-3 mb-8 text-[16px] font-lato">
					Se você voltar agora, perderá todas as estratégias que foram
					geradas.
				</div>
				<div className="flex items-center justify-between">
					<Button
						variant={ButtonVariant.Outlined}
						className="mr-4 w-[235px] justify-center"
						onClick={handleCancel}
					>
						Cancelar
					</Button>
					<Button
						variant={ButtonVariant.Contained}
						className="flex w-[235px] justify-center"
						onClick={handleBack}
					>
						Sim, voltar
					</Button>
				</div>
			</div>
		</Modal>
	)
}

export default DiscardClausesModal

import BaseIcon from "./BaseIcon"

const CloseIcon = ({
	size = "16px",
	className,
}: {
	size?: string
	className?: string
}) => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			className={className}
		>
			<path
				d="M20 5.4L18.6 4L12 10.6L5.4 4L4 5.4L10.6 12L4 18.6L5.4 20L12 13.4L18.6 20L20 18.6L13.4 12L20 5.4Z"
				fill={!className ? "#020812" : ""}
			/>
		</BaseIcon>
	)
}

export default CloseIcon

interface BaseIconProps extends React.SVGProps<SVGSVGElement> {}

const BaseIcon = ({ children, ...props }: BaseIconProps) => {
	return (
		<svg className="fill-current" {...props}>
			{children}
		</svg>
	)
}

export default BaseIcon

import { useMutation } from "@tanstack/react-query"
import { Button } from "components/button"
import { ButtonVariant } from "components/button/types"
import InputField from "components/input-field"
import Modal from "components/modal"
import useAPI from "hooks/useAPI"
import useCurrentUser from "hooks/useCurrentUser"

import PadlockIcon from "icons/PadlockIcon"
import { useEffect, useState } from "react"

export default function Password() {
	const [openModal, setOpenModal] = useState(false)
	const [counter, setCounter] = useState(59)
	const user = useCurrentUser()
	const { updatePassword } = useAPI()

	const updatePasswordMutation = useMutation({
		mutationFn: updatePassword,
		onSuccess: () => {
			setOpenModal(true)
		},
	})

	useEffect(() => {
		if (openModal) {
			if (counter === 0) return
			const interval = setInterval(() => {
				setCounter((prev) => prev - 1)
			}, 1000)
			return () => clearInterval(interval)
		} else {
			setCounter(59)
		}
	}, [counter, openModal])

	return (
		<div>
			<div className="flex justify-between">
				<div className="w-1/2">
					<h3 className="mb-2 font-semibold">Senha</h3>
					<h4 className=" text-brand-gray-2 font-normal">
						Altere a senha de acesso da plataforma
					</h4>
				</div>
				<div className=" flex flex-col w-1/2">
					<p className="text-cta-1 text-brand-gray-3 mb-2">Senha</p>
					<InputField
						value={"●●●●●●●●●"}
						className="w-full text-brand-gray-2"
						disabled
						rightIcon={
							<PadlockIcon className="fill-brand-gray-2" />
						}
					/>
					<Button
						variant={ButtonVariant.Contained}
						className="mt-6 w-fit"
						onClick={() => updatePasswordMutation.mutate()}
					>
						Alterar senha
					</Button>

					<Modal
						openModal={openModal}
						setOpenModal={setOpenModal}
						size={"lg"}
					>
						<span className="prose ">
							<div className="flex flex-col justify-center">
								<h3 className="mb-4 font-semibold">
									Verifique seu email
								</h3>

								<p className="text-cta-1 text-brand-gray-3 mb-6">
									Para alterar sua senha siga as instruções
									que enviamos para{" "}
									<span className="font-semibold">
										{user?.email}
									</span>
									, lembre-se de checar a caixa de spam.
								</p>

								<p className="text-cta-1 text-brand-gray-3">
									Não recebeu?{" "}
									<span
										onClick={() => {
											if (counter === 0) {
												updatePasswordMutation.mutate()
												setCounter(59)
											}
										}}
										className={`${
											counter === 0
												? "text-light-blue-4 cursor-pointer"
												: "text-brand-gray-2"
										} `}
									>
										{counter === 0
											? "Envie novamente"
											: `Envie novamente em ${counter}s`}
									</span>
								</p>

								<div className="flex items-center justify-between mt-6">
									<Button
										variant={ButtonVariant.Contained}
										className="w-full flex items-center justify-center"
										onClick={() => {
											setOpenModal(false)
										}}
									>
										Fechar
									</Button>
								</div>
							</div>
						</span>
					</Modal>
				</div>
			</div>
		</div>
	)
}

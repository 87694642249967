import DeleteAccount from "./DeleteAccount"
import Email from "./Email"
import Password from "./Password"
import Google from "./Google"
import useCurrentUser from "hooks/useCurrentUser"

export default function Account() {
	const user = useCurrentUser()
	const authMethod = user?.id.split("|")[0]
	return (
		<div>
			{authMethod === "google-oauth2" ? (
				<Google />
			) : (
				<>
					<Email />
					<div className="h-[1px] bg-brand-white-4 my-8" />
					<Password />
				</>
			)}
			<div className="h-[1px] bg-brand-white-4 my-8" />
			<DeleteAccount />
		</div>
	)
}

import { Button } from "components/button";
import { ButtonVariant } from "components/button/types";
import Modal from "components/modal";
import LoadingSpinner from "pages/MyDocumentsPage/subcomponents/LoadingSpinner";
import { cardBrand } from "utils/cardBrand";
import dotsGift from "assets/dotsGif.gif"
import { formatPrice } from "utils/formatPrice";
import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import PlanName from "components/plan-name";
import useSubscriptionAPI from "hooks/useSubscriptionAPI";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useJwt } from "hooks/useJwt";
import { showToast } from "components/toast/functions";
import { ToastType } from "components/toast/types";
import Lottie from "react-lottie"
import check from "lotties/check.json"
import useCurrentUser from "hooks/useCurrentUser";

const LottieComponent = Lottie as any

const lottieOptions = {
  autoplay: true,
  animationData: check,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
    className: "cursor-default",
  },
}

const billignTypes = {
  "month": "mensalmente",
  "year": "anualmente"
}


function formatDate(date: string) {
  const parsedDate = parseISO(date)
  const day = format(parsedDate, 'dd')
  const month = format(parsedDate, 'LLLL', {
    locale: ptBR
  })
  const year = format(parsedDate, 'yyyy')
  return `${day} de ${month} de ${year}`
}

interface ModalConfirmPlanProps {
  lookupKey: string | null
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}


export default function ModalConfirmPlan({
  lookupKey,
  open,
  setOpen
}: ModalConfirmPlanProps) {

  const {
    upgradePlan,
    previewPlan
  } = useSubscriptionAPI()
  const queryClient = useQueryClient()
  const [jwt] = useJwt()
  const user = useCurrentUser()
  const productName = user?.subscription?.product_name
  const billingType = user?.subscription?.billing_type

  const { data, isRefetching, isLoading } = useQuery(["preview-plan", lookupKey, productName, billingType], () =>
    previewPlan(lookupKey || ""),
    {
      refetchOnWindowFocus: true,
      refetchOnMount: false,
    }
  )


  const upgradePlanMutation = useMutation({
    mutationFn: upgradePlan,
    onSuccess: () => {
      queryClient.invalidateQueries(["current-user", jwt])
      queryClient.invalidateQueries(["preview-plan", lookupKey, productName, billingType])
      setTimeout(() => {
        queryClient.invalidateQueries(["current-user", jwt])
        setOpen(false)
      }, 3000)
    },
    onError: () => {
      showToast(
        'Seu cartão de crédito foi recusado. Tente alterar a forma de pagamento.',
        ToastType.Error,
      )
    }
  })


  return (
    <Modal openModal={open} setOpenModal={setOpen} size='lg' whitoutCloseButton dismissible>
      {(isRefetching || isLoading) && (
        <div className="absolute top-0 right-0 w-full h-full z-50 flex items-center justify-center bg-white opacity-50">
          <LoadingSpinner />
        </div>
      )}
      <span className="prose max-w-none">
        {upgradePlanMutation.isSuccess ? (
          <div className="flex flex-col items-center justify-center">
            <LottieComponent
              options={lottieOptions}
              height={240}
              width={240}
              isClickToPauseDisabled
            />
            <h3 className="mt-2">Seu plano foi alterada com sucesso!</h3>
          </div>
        ) : (
          <div className="p-2">
            <h3>Confirmar pagamento</h3>

            <div className="my-8">
              <div className="mb-2">
                <PlanName name={data?.name || ""} />
              </div>
              <div className="text-brand-gray-3 flex justify-between">
                <p className="max-w-[300px]">O que você pagará {billignTypes[data?.billing_type as keyof typeof billignTypes]} a partir de {data?.start_date && formatDate(data?.start_date)}</p>
                <p>R$ {formatPrice(data?.amount)}</p>
              </div>

            </div>

            <div className="w-full h-[1px] bg-brand-white-4 mb-8" />

            <div>
              <div className="flex justify-between">
                <p className="text-brand-gray-3 ">Valor devido hoje</p>
                <p className="font-semibold ">R$ {formatPrice(data?.prorate_amount)}</p>
              </div>

              <div className="flex justify-between mt-2 items-center">
                <Button
                  variant={ButtonVariant.Text}
                  className="h-[22px]"
                  onClick={() => {
                    if (data?.customer_portal) {
                      window.open(data?.customer_portal, '_blank')
                    }
                  }}
                >
                  Gerenciar Cartões
                </Button>
                <div className="flex justify-between items-center">
                  <img
                    src={
                      cardBrand[data?.credit_card.type as keyof typeof cardBrand]
                    }
                    className="mr-2 h-[24px]"
                  />

                  <p className=" text-brand-gray-3">**** {data?.credit_card.number}</p>
                </div>
              </div>
            </div>
            {upgradePlanMutation.isError ? (
              <Button variant={ButtonVariant.Contained} className="w-full mt-8 justify-center" onClick={() => {
                if (data?.customer_portal) {
                  window.open(data?.customer_portal, '_blank')

                }
              }}>
                Alterar forma de pagamento
              </Button>
            ) : (
              <Button onClick={() => upgradePlanMutation.mutate(lookupKey || "")} variant={ButtonVariant.Contained} className="w-full mt-8 justify-center" isLoading={upgradePlanMutation.isLoading} icon={upgradePlanMutation.isLoading ? (
                <div className="mr-[-15px] w-[50px] h-[50px]">
                  <img src={dotsGift} />
                </div>
              ) : null}>
                {upgradePlanMutation.isLoading ? "Confirmando" : "Confirmar"}
              </Button>
            )}

          </div>
        )}
      </span>
    </Modal>
  )
}
import { Button } from "components/button"
import { ButtonVariant } from "components/button/types"
import ExclusionButton from "components/exclusion-button"
import Modal from "components/modal"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"
import React, { useState } from "react"

interface BlockUserModalProps {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	userId?: string
}

const BlockUserModal = ({ open, setOpen }: BlockUserModalProps) => {
	const [reason, setReason] = useState("")
	return (
		<Modal
			size="550"
			width="w-[550px]"
			openModal={open}
			setOpenModal={setOpen}
			dismissible
			onlyCloseButton
		>
			<div className="flex  justify-center flex-col p-2">
				<p className={"text-[22px] font-semibold mb-2 font-lato"}>
					Bloquear conta do usuário
				</p>
				<div className="text-brand-gray-3 mb-8 text-[16px] font-lato">
					Essa ação vai bloquear a conta desse usuário e vai remover o
					acesso a plataforma. Tem certeza de que deseja continuar?
				</div>
				<textarea
					className="resize-none h-[120px] p-3 pr-10 w-full focus:!outline-none !ring-0 rounded-[4px] !outline-offset-0 border-2 border-gray-300 mb-8"
					placeholder="ex Comportamento inapropriado"
					value={reason}
					onChange={(e) => {
						setReason(e.target.value)
					}}
				/>
				<div className="flex items-center justify-between">
					<Button
						variant={ButtonVariant.Outlined}
						className="mr-4 w-[235px] justify-center"
						onClick={() => {
							setOpen(false)
						}}
					>
						Cancelar
					</Button>
					<ExclusionButton
						className="flex w-[235px] justify-center"
						disabled={reason === ""}
						onClick={() => {
							setReason("")
							showToast("Usuário foi bloqueado", ToastType.Error)
							setOpen(false)
						}}
					>
						Bloquear
					</ExclusionButton>
				</div>
			</div>
		</Modal>
	)
}

export default BlockUserModal

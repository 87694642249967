const API = {
	baseUrl: import.meta.env.VITE_API_BASE_URL,
}

const WS_SERVER = {
	baseUrl: import.meta.env.VITE_WS_SERVER_BASE_URL,
}

const AUTH0 = {
	domain: import.meta.env.VITE_AUTH0_DOMAIN,
	clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
	audience: import.meta.env.VITE_AUTH0_AUDIENCE,
}

const APP_CONFIG = {
	useMock: false,
}

const POSTHOG_CONFIG = {
	apiKey: import.meta.env.VITE_POSTHOG_KEY,
	host: import.meta.env.VITE_POSTHOG_HOST,
}

const IS_PROD = window.location.hostname === "app.lawdeck.ai"
const IS_STAGING = window.location.hostname === "staging.app.lawdeck.ai"

export {
	API,
	AUTH0,
	APP_CONFIG,
	IS_PROD,
	IS_STAGING,
	WS_SERVER,
	POSTHOG_CONFIG,
}

import { AxiosResponse } from "axios"
import axiosInstance from "axiosInstance"
import {
	CurrentUser,
	OnboardUserRequestBody,
	UpdateCurrentUserRequestBody,
} from "types/currentUser"

const useUserAPI = () => {
	const currentUser = async () => {
		const response: AxiosResponse<CurrentUser> =
			await axiosInstance.get("/current-user")

		return response.data
	}

	const updateCurrentUser = async (payload: UpdateCurrentUserRequestBody) => {
		const response: AxiosResponse<CurrentUser> = await axiosInstance.patch(
			"/current-user",
			payload,
		)

		return response.data
	}
	const onboardUser = async (payload: OnboardUserRequestBody) => {
		const response: AxiosResponse<CurrentUser> = await axiosInstance.post(
			"/onboard-user",
			payload,
		)

		return response.data
	}
	const updateUserState = async () => {
		const response = await axiosInstance.patch(
			"/user-state",
		)

		return response.data
	}

	return {
		currentUser,
		onboardUser,
		updateCurrentUser,
		updateUserState
	}
}

export default useUserAPI

import BaseIcon from "./BaseIcon"

interface CloseFilledIconProps {
	className?: string
}

const CloseFilledIcon = ({ className }: CloseFilledIconProps) => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="17"
			viewBox="0 0 16 17"
			fill="none"
			className={className}
		>
			<path d="M8 1.5C4.1 1.5 1 4.6 1 8.5C1 12.4 4.1 15.5 8 15.5C11.9 15.5 15 12.4 15 8.5C15 4.6 11.9 1.5 8 1.5ZM10.7 12L8 9.3L5.3 12L4.5 11.2L7.2 8.5L4.5 5.8L5.3 5L8 7.7L10.7 5L11.5 5.8L8.8 8.5L11.5 11.2L10.7 12Z" />
		</BaseIcon>
	)
}

export default CloseFilledIcon

import Modal from "components/modal"
import downgrade from "assets/downgrade.jpg"
import CloseIcon from "icons/CloseIcon"
import { Button } from "components/button"
import { useEffect, useState } from "react"
import ArrowRightIcon from "icons/ArrowRightIcon"
import Lottie from "react-lottie"
import check from "lotties/check.json"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useJwt } from "hooks/useJwt"
import useCurrentUser from "hooks/useCurrentUser"
import PostLauchPlanCard from "pages/BasePage/plan-card/post-lauch-plan-card"
import { sanitizePlan } from "utils/sanitizePlan"
import { ButtonVariant } from "components/button/types"
import useProductCatalogAPI from "hooks/useProductCatalogAPI"
import useSubscriptionAPI from "hooks/useSubscriptionAPI"
import { useFeatureFlag } from "hooks/useFeatureFlag"
import FEATURE_FLAGS from "feature-flags"

interface DowngradePlanProps {
	openModal: boolean
	setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}

export default function DowngradePlan({
	openModal,
	setOpenModal,
}: DowngradePlanProps) {
	const [isCheck, setIsCheck] = useState(false)
	const [steps, setSteps] = useState(0)
	const { productCatalog, productCatalogV2 } = useProductCatalogAPI()
	const { subscriptionJoinBasicPlan } = useSubscriptionAPI()
	const [jwt] = useJwt()
	const user = useCurrentUser()

	const subscriptionJoinBasicPlanMutation = useMutation({
		mutationFn: subscriptionJoinBasicPlan,
	})

	const { data: newBussinessModel } = useFeatureFlag(
		FEATURE_FLAGS.newBussinessModel,
	)
	const { data: productCatalogListV1 } = useQuery(
		["product-catalog"],
		() => productCatalog(),
		{
			refetchOnWindowFocus: false,
		},
	)
	const { data: productCatalogListV2 } = useQuery(
		["product-catalog-v2"],
		() => productCatalogV2(),
		{
			refetchOnWindowFocus: false,
		},
	)

	const productCatalogList = newBussinessModel
		? productCatalogListV2
		: productCatalogListV1

	const benefits =
		productCatalogList?.professional_plan.marketing_features.map(
			(benefit) => benefit.description,
		)

	const LottieComponent = Lottie as any

	const lottieOptions = {
		loop: true,
		autoplay: true,
		animationData: check,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
			className: "cursor-default",
		},
	}

	const queryClient = useQueryClient()

	function handleChangePlan() {
		subscriptionJoinBasicPlanMutation.mutateAsync().then(() => {
			queryClient.invalidateQueries(["current-user", jwt])
			setSteps(steps + 1)
		})
	}

	function getSize() {
		const size =
			steps === 0
				? "896"
				: steps === 1
				? "843"
				: steps === 1
				? "664"
				: "sm"
		return size
	}

	useEffect(() => {
		if (steps === 2) {
			setTimeout(() => {
				setOpenModal(false)
			}, 1300)
		}
	}, [steps])

	return (
		<Modal
			openModal={openModal}
			setOpenModal={setOpenModal}
			size={getSize()}
			width="w-[100%]"
			dismissible={steps === 1}
			onlyCloseButton={steps === 1}
		>
			<span className="prose max-w-none font-lato">
				{steps === 0 && (
					<div className="flex w-[100%] p-2 gap-6">
						<div className="bg-[#FEE] p-8 rounded-lg w-[404px] h-[460px]">
							<div className="font-lato font-semibold text-dark-blue-1 text-[20.998px] mb-8">
								Ao fazer o downgrade do plano Profissional você
								perderá:
							</div>
							{benefits?.map((benefit) => (
								<div
									className="flex items-center mb-6"
									key={benefit}
								>
									<CloseIcon
										className="fill-[#D01313] mr-2"
										size="24px"
									/>
									<p className="text-cta-1 text-dark-blue-1">
										{benefit}
									</p>
								</div>
							))}
						</div>
						<div className="w-[414px] h-[428px]">
							<img
								className="mb-6"
								src={downgrade}
								width={405}
								height={268}
							/>

							<h3 className=" font-semibold">
								Tem certeza que deseja alterar seu plano?
							</h3>
							<div className="flex items-center my-6 leading-4">
								<input
									type="checkbox"
									id="terms"
									className="mr-2 rounded-sm focus:shadow-none focus:ring-0 focus:outline-none"
									onChange={() => setIsCheck(!isCheck)}
									checked={isCheck}
								/>
								<p className="text-cta-2 text-dark-blue-1">
									Estou ciente dos{" "}
									<span
										className="text-light-blue-4 cursor-pointer rounded-sm focus:shadow-none focus:ring-0 focus:outline-none"
										onClick={() =>
											window.open(
												"https://www.lawdeck.ai/termos-de-uso",
												"_blank",
											)
										}
									>
										Termos de Uso
									</span>{" "}
									e{" "}
									<span
										className="text-light-blue-4 cursor-pointer"
										onClick={() =>
											window.open(
												"https://www.lawdeck.ai/politica-de-privacidade",
												"_blank",
											)
										}
									>
										Políticas de Privacidade
									</span>{" "}
									a respeito do downgrade.
								</p>
							</div>
							<div className="flex justify-between">
								<Button
									variant={ButtonVariant.Contained}
									className="w-full flex items-center justify-center"
									onClick={() => setOpenModal(false)}
								>
									Cancelar
								</Button>
								<Button
									variant={ButtonVariant.Text}
									className="w-full flex items-center justify-center"
									onClick={() => setSteps(steps + 1)}
									disabled={!isCheck}
								>
									Continuar
								</Button>
							</div>
						</div>
					</div>
				)}

				{steps === 1 && (
					<div>
						<h3 className=" font-semibold mb-6">Alterar Plano</h3>
						<div className="flex items-center justify-between w-full px-2">
							{productCatalogList && (
								<PostLauchPlanCard
									plan={{
										...sanitizePlan(
											"professional_plan",
											productCatalogList?.professional_plan,
											false,
											true,
											user?.subscription?.billing_type ===
												"monthly",
											null,
										),
										containerClassName: "h-[590px]",
										button: {
											buttonText: "Manter plano",
											buttonVariant:
												ButtonVariant.Contained,
										},
									}}
									isMonthlyPlan={
										user?.subscription?.billing_type ===
										"monthly"
									}
									buttonClick={() => setOpenModal(false)}
									dividePlan
								/>
							)}
							<div className="ml-[11px] mr-2">
								<ArrowRightIcon />
							</div>
							{productCatalogList && (
								<PostLauchPlanCard
									plan={{
										...sanitizePlan(
											"free_plan",
											productCatalogList?.free_plan,
											false,
											false,
											false,
											null,
										),
										button: {
											buttonText: "Escolher plano",
											buttonVariant: ButtonVariant.Text,
										},
									}}
									isMonthlyPlan={false}
									buttonClick={handleChangePlan}
								/>
							)}
						</div>
					</div>
				)}

				{steps === 2 && (
					<div className="w-full h-full flex items-center justify-center flex-col">
						<LottieComponent
							options={lottieOptions}
							height={140}
							width={140}
							isClickToPauseDisabled
						/>
						<h3 className=" font-semibold mb-4">
							Plano alterado com sucesso!
						</h3>
					</div>
				)}
			</span>
		</Modal>
	)
}

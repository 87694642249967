import { useMutation } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { Button } from "components/button"
import { ButtonVariant } from "components/button/types"
import InputField from "components/input-field"
import Modal from "components/modal"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"
import useAPI from "hooks/useAPI"
import useCurrentUser from "hooks/useCurrentUser"
import PadlockIcon from "icons/PadlockIcon"
import { useEffect, useRef, useState } from "react"

export default function Email() {
	const [editEmail, setEditEmail] = useState(false)
	const [newEmail, setNewEmail] = useState("")
	const { emailRequestCode, emailConfirmCode } = useAPI()
	const user = useCurrentUser()
	const [openModal, setOpenModal] = useState(false)
	const [codes, setCodes] = useState(Array(5).fill(""))
	const [counter, setCounter] = useState(59)
	const inputRefs = useRef<(HTMLInputElement | null)[]>([])

	const emailRequestCodeMutation = useMutation({
		mutationFn: emailRequestCode,
		onSuccess: () => {
			setOpenModal(true)
		},
		onError: (data: AxiosError<any>) => {
			const newDate = parseInt(data.response?.data.timestamp)
			const currentDate = parseInt(
				(new Date().getTime() / 1000).toFixed(0),
			)
			setCounter(newDate - currentDate)
			setOpenModal(true)
		},
	})

	const emailConfirmCodeMutation = useMutation({
		mutationFn: emailConfirmCode,
		onSuccess: () => {
			setOpenModal(false)
			setEditEmail(false)
			showToast("Email alterado com sucesso!", ToastType.Success)
			setTimeout(() => {
				window.location.reload()
			}, 1500)
		},
		onError: () => {
			showToast(
				"Código de verificação inválido tente novamente.",
				ToastType.Error,
			)
		},
	})

	const validateEmail = (): boolean => {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		return regex.test(newEmail)
	}

	const focusNextInput = (index: number) => {
		if (index < 5 - 1) {
			const nextInput = inputRefs.current[index + 1]
			if (nextInput) {
				nextInput.focus()
			}
		}
	}

	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		index: number,
	) => {
		const value = event.target.value
		if (value === "" || /^[0-9]$/.test(value)) {
			setCodes(codes.map((code, i) => (i === index ? value : code)))
			if (value) {
				focusNextInput(index)
			}
		}
	}

	const handleKeyDown = (
		event: React.KeyboardEvent<HTMLInputElement>,
		index: number,
	) => {
		if (event.key === "Backspace" && codes[index] === "" && index > 0) {
			const prevInput = inputRefs.current[index - 1]
			if (prevInput) {
				prevInput.focus()
			}
		}
	}

	useEffect(() => {
		inputRefs.current = inputRefs.current.slice(0, 5).fill(null)
	}, [])

	useEffect(() => {
		if (openModal) {
			if (counter === 0) return
			const interval = setInterval(() => {
				setCounter((prev) => prev - 1)
			}, 1000)
			return () => clearInterval(interval)
		} else {
			setCounter(59)
		}
	}, [counter, openModal])

	useEffect(() => {
		if (!openModal) {
			setCodes(Array(5).fill(""))
		}
		if (!editEmail) {
			setNewEmail("")
		}
	}, [openModal, editEmail])

	const isEveryCodeFilled = codes.every(
		(code) => code.trim() !== "" && !isNaN(Number(code)),
	)

	async function onEditEmail() {
		emailRequestCodeMutation.mutateAsync(newEmail)
	}

	async function onConfirmCode() {
		const code = codes.join("")
		emailConfirmCodeMutation.mutateAsync({ code: code, email: newEmail })
	}

	return (
		<div>
			<div className="flex justify-between">
				<div className="w-1/2">
					<h3 className="mb-2 font-semibold">E-mail</h3>
					<h4 className=" text-brand-gray-2 font-normal">
						Altere o email de acesso da plataforma
					</h4>
				</div>
				<div className=" flex flex-col w-1/2">
					<p className="text-cta-1 text-brand-gray-3 mb-2">E-mail</p>
					<InputField
						placeholder="you@example.com"
						value={user?.email || ""}
						className="w-full text-brand-gray-2"
						disabled
						rightIcon={
							<PadlockIcon className="fill-brand-gray-2" />
						}
					/>

					{!editEmail ? (
						<Button
							variant={ButtonVariant.Contained}
							className="mt-6 w-fit"
							onClick={() => setEditEmail(true)}
						>
							Alterar email
						</Button>
					) : (
						<>
							<p className="text-cta-1 text-brand-gray-3 mb-2 mt-6">
								Novo e-mail:
							</p>
							<InputField
								placeholder="example@email.com"
								value={newEmail}
								className="w-full"
								onChange={(e) => setNewEmail(e.target.value)}
							/>

							<Button
								variant={ButtonVariant.Contained}
								className="mt-6 w-fit"
								onClick={() => onEditEmail()}
								disabled={!newEmail || !validateEmail()}
							>
								Salvar
							</Button>
						</>
					)}
				</div>
			</div>

			<Modal
				openModal={openModal}
				setOpenModal={setOpenModal}
				dismissible
				size={"lg"}
			>
				<div className="flex flex-col justify-center">
					<h3 className="mb-4 font-semibold">Verifique seu e-mail</h3>

					<p className="text-cta-1 text-brand-gray-3 mb-6">
						Por favor, verifique o código enviado para{" "}
						<span className="font-bold">{user?.email}</span> e
						insira abaixo:
					</p>

					<p className="text-cta-1 text-brand-gray-3 mb-2">
						Digite o código
					</p>

					<div className="flex items-center justify-between">
						{codes.map((code, index) => (
							<InputField
								ref={(el) => (inputRefs.current[index] = el)}
								key={index}
								maxLength={1}
								value={code}
								className="w-[84px] text-center"
								onChange={(event) =>
									handleInputChange(event, index)
								}
								onKeyDown={(event) =>
									handleKeyDown(event, index)
								}
							/>
						))}
					</div>

					<Button
						variant={ButtonVariant.Contained}
						className="mt-6 w-full flex items-center justify-center"
						onClick={() => onConfirmCode()}
						disabled={!isEveryCodeFilled}
					>
						Confirmar
					</Button>

					<p className="text-cta-1 text-brand-gray-3 mt-4">
						Não recebeu?{" "}
						<span
							onClick={() => {
								if (counter === 0) {
									onEditEmail()
									setCounter(59)
								}
							}}
							className={`${
								counter === 0
									? "text-light-blue-4 cursor-pointer"
									: "text-brand-gray-2"
							} `}
						>
							{counter === 0
								? "Envie novamente"
								: `Envie novamente em ${counter}s`}
						</span>
					</p>
				</div>
			</Modal>
		</div>
	)
}

import Tag from "components/tag"
import { ProductCatalogItem, SubscriptionPlan } from "types/productCatalog"
import { formatDate } from "./formatDate"
import { ButtonVariant } from "components/button/types"

export function sanitizePlan(
	type: string = "free_plan",
	plan: SubscriptionPlan,
	isBasicPlan: boolean,
	isProfessionalSelected: boolean,
	isMonthlyPlan: boolean,
	user?: any,
	button?: {
		buttonVariant?: ButtonVariant
		buttonIcon?: JSX.Element
		buttonIsLoading?: boolean
		buttonText?: string
	},
): ProductCatalogItem {
	if (type === "free_plan") {
		return {
			...plan,
			titleColor: "text-brand-gray-2",
			button: button,
			tag: isBasicPlan ? (
				<Tag
					text="Plano atual"
					background="bg-transparent"
					textColor="text-light-blue-4"
					className="px-4 py-2 border border-light-blue-4"
				/>
			) : undefined,
		}
	}

	return {
		...plan,
		titleColor: "text-brand-yellow",
		containerClassName: "mx-4 relative ",
		button: button,
		creditsPerMonth: true,
		tag: isProfessionalSelected ? (
			<Tag
				text="Plano atual"
				background="bg-transparent"
				textColor="text-light-blue-4"
				className="px-4 py-2 border border-light-blue-4"
			/>
		) : (
			<Tag
				text={isMonthlyPlan ? "Recomendado" : "20% OFF"}
				background="bg-semi-white-3"
				textColor="text-light-blue-4"
				className="px-4 py-2"
			/>
		),
		selected: true,
		warning:
			user &&
			user?.subscription?.end_period &&
			user?.subscription?.cancel_at_period_end ? (
				<div
					className={`flex justify-center h-8 text-brand-orange absolute bottom-0  left-1/2 transform -translate-x-1/2 w-full`}
				>
					(
					<span
						className={
							user?.subscription?.end_period &&
							user?.subscription?.cancel_at_period_end
								? "block"
								: "hidden"
						}
					>
						Seu plano cancela em:{" "}
						{
							formatDate(user?.subscription?.end_period).split(
								" ",
							)[0]
						}
					</span>
					)
				</div>
			) : undefined,
	}
}

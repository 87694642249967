import AutoComplete from "components/auto-complete"

const autocompleteSuggestions = [
	"Petição Inicial",
	"Contestação",
	"Réplica",
	"Apelação",
	"Agravo de Instrumento",
	"Agravo Interno",
	"Embargos de Declaração",
	"Recurso Especial",
	"Recurso Extraordinário",
	"Ação Cautelar",
	"Mandado de Segurança",
	"Embargos à Execução",
	"Impugnação ao Cumprimento de Sentença",
	"Ação de Execução",
	"Reconvenção",
	"Pedido de Tutela Provisória",
	"Petição de Juntada",
	"Petição de Desistência",
	"Agravo Retido",
	"Contrarrazões",
	"Memoriais",
]

interface PetitionAutoCompleteProps {
	onSubmit: (value: string) => void
	className?: string
	value?: string
	isLoading?: boolean
	placeholder?: string
	removeIcons?: boolean
	onChange?: (event: string) => void
	maxLength?: number
}
const PetitionAutoComplete = ({
	onSubmit,
	className,
	value,
	isLoading,
	placeholder,
	removeIcons,
	onChange,
}: PetitionAutoCompleteProps) => {
	return (
		<AutoComplete
			optionsList={autocompleteSuggestions}
			className={className || ""}
			onSubmit={onSubmit}
			value={value}
			isLoading={isLoading}
			placeholder={placeholder}
			removeIcons={removeIcons}
			onChange={onChange}
		/>
	)
}

export default PetitionAutoComplete

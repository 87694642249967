import { useAuth0 } from "@auth0/auth0-react"
import Image from "assets/404.jpg"
import { Button } from "components/button"
import { ButtonVariant } from "components/button/types"

export default function EmailAlreadyExistsErrorPage() {
	const { logout } = useAuth0()

	async function handleLogout() {
		await logout({
			logoutParams: {
				returnTo: window.location.origin,
			},
		})
	}

	return (
		<div className="flex flex-col items-center justify-center w-full h-[100vh]">
			<img src={Image} width={347} height={305} className="mb-6" />

			<p className="text-2xl text-brand-gray-3 mb-4">
				Parece que este email já está associado a uma conta existente!
			</p>
			<p className="text-base text-brand-gray-2 mb-6">
				Faça login com sua conta atual. Se precisar de ajuda ou não
				reconhecer este aviso, contate{" "}
				<p className="text-center">suporte@lawdeck.ai</p>
			</p>

			<Button
				variant={ButtonVariant.Contained}
				className="w-fit"
				onClick={handleLogout}
			>
				Voltar à página anterior
			</Button>
		</div>
	)
}

export const orderItems = [
  {
    label: "Mais relevantes",
    value: "",
  },
  {
    label: "Mais visualizados",
    value: "-view_count",
  },
  {
    label: "Mais recentes",
    value: "publish_date",
  },
  {
    label: "Mais antigos",
    value: "-publish_date",
  },

]
import { AxiosResponse } from "axios"

import { JurisprudenceData } from "types/jurisprudence"
import axiosInstance from "axiosInstance"

const useAPI = () => {
	const emailRequestCode = async (email: string) => {
		const response: AxiosResponse<any> = await axiosInstance.post(
			`/change-email/request_code/`,
			{ email },
		)

		return response.data
	}

	const resendVerifyEmail = async () => {
		const response: AxiosResponse<any> =
			await axiosInstance.get(`/resend-verify-email`)

		return response.data
	}

	const emailConfirmCode = async ({
		code,
		email,
	}: {
		code: string
		email: string
	}) => {
		const response: AxiosResponse<any> = await axiosInstance.post(
			`/change-email/confirm/`,
			{ code, email },
		)

		return response.data
	}

	const updatePassword = async () => {
		await axiosInstance.post(`/update-password`)
	}

	const deleteAccountRequestCode = async () => {
		const response: AxiosResponse<any> = await axiosInstance.post(
			`/delete-account/request_code/`,
		)

		return response.data
	}

	const deleteAccountConfirmCode = async (code: string) => {
		const response: AxiosResponse<any> = await axiosInstance.post(
			`/delete-account/confirm/`,
			{ code },
		)
		return response.data
	}

	const searchJurisprudences = async (
		query: string,
		offset = 0,
	): Promise<JurisprudenceData> => {
		const response: AxiosResponse<JurisprudenceData> =
			await axiosInstance.get(
				`/jurisprudence/search?offset=${offset}&query=${query}`,
			)
		return response.data
	}

	const deleteAttachmentDocument = async ({
		id,
	}: {
		id: string
	}): Promise<void> => {
		const response: AxiosResponse<void> = await axiosInstance.delete(
			`/attached_documents/${id}/`,
		)
		return response.data
	}

	return {
		deleteAttachmentDocument,
		emailConfirmCode,
		deleteAccountConfirmCode,
		deleteAccountRequestCode,
		updatePassword,
		emailRequestCode,
		resendVerifyEmail,
		searchJurisprudences,
	}
}

export default useAPI

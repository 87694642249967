import { ReactNode, useState, useRef, useEffect } from "react"
import { TooltipPosition } from "./enums"

interface TooltipProps {
	text: string
	position: TooltipPosition
	className?: string
	children: ReactNode
	bg?: string
	whitoutWhiteSpaces?: boolean
	containerClassName?: string
}

const Tooltip = ({
	text,
	position,
	className,
	children,
	bg,
	whitoutWhiteSpaces,
	containerClassName,
}: TooltipProps) => {
	const [isTooltipVisible, setIsTooltipVisible] = useState(false)
	const [calculatedPosition, setCalculatedPosition] = useState<
		"above" | "below"
	>(position)
	const elementRef = useRef<HTMLDivElement>(null)

	const positionClasses = {
		above: "bottom-[120%] left-1/2 transform -translate-x-1/2",
		below: "top-[120%] right-1/2 transform translate-x-1/2",
	}

	useEffect(() => {
		const boundingClientRect = elementRef.current?.getBoundingClientRect()
		if (position === "below" && isTooltipVisible && boundingClientRect) {
			if (boundingClientRect.bottom > window.innerHeight) {
				setCalculatedPosition("above")
			} else {
				setCalculatedPosition("below")
			}
		} else if (!isTooltipVisible) {
			setCalculatedPosition(position)
		}
	}, [isTooltipVisible])

	return (
		<div className={`${containerClassName} relative`}>
			<div
				className="tooltip-trigger"
				onMouseEnter={() => setIsTooltipVisible(true)}
				onMouseLeave={() => setIsTooltipVisible(false)}
			>
				{children}
			</div>

			{isTooltipVisible && (
				<div
					className={`z-[999] tooltip absolute w-auto overflow-x-auto ${
						!whitoutWhiteSpaces && "whitespace-nowrap"
					} rounded ${
						bg ? bg : "bg-brand-gray-3"
					}  p-3 text-xs text-white ${
						positionClasses[calculatedPosition || position]
					} ${className}`}
					ref={elementRef}
				>
					{text}
				</div>
			)}
		</div>
	)
}

export default Tooltip

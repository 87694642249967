import BaseIcon from "./BaseIcon"

const SearchIcon = ({ className }: { className?: string }) => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			className={className}
		>
			<path d="M22 20.9539L16.4132 15.3671C17.7557 13.7554 18.4251 11.6882 18.2822 9.59546C18.1394 7.50276 17.1952 5.5457 15.6462 4.1314C14.0971 2.7171 12.0624 1.95445 9.96541 2.00211C7.86838 2.04976 5.87046 2.90405 4.38725 4.38725C2.90405 5.87046 2.04976 7.86838 2.00211 9.96541C1.95445 12.0624 2.7171 14.0971 4.1314 15.6462C5.5457 17.1952 7.50276 18.1394 9.59546 18.2822C11.6882 18.4251 13.7554 17.7557 15.3671 16.4132L20.9539 22L22 20.9539ZM3.50572 10.1637C3.50572 8.84685 3.89621 7.5596 4.62779 6.46471C5.35937 5.36982 6.3992 4.51645 7.61578 4.01253C8.83236 3.50861 10.171 3.37676 11.4626 3.63366C12.7541 3.89055 13.9404 4.52466 14.8715 5.45579C15.8027 6.38692 16.4368 7.57325 16.6937 8.86476C16.9506 10.1563 16.8187 11.495 16.3148 12.7115C15.8109 13.9281 14.9575 14.968 13.8626 15.6995C12.7677 16.4311 11.4805 16.8216 10.1637 16.8216C8.39847 16.8196 6.70613 16.1176 5.45795 14.8694C4.20977 13.6212 3.50768 11.9289 3.50572 10.1637Z" />
		</BaseIcon>
	)
}

export default SearchIcon

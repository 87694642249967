import image from "assets/working.png"

export default function OutOfAreaPage() {
	return (
		<div className="flex items-center justify-center flex-col h-[100vh]">
			<img src={image} alt="Booked" width={236} height={231} />

			<h3 className={"text-brand-gray-3  mb-4 mt-6 font-normal"}>
				Parece que nosso sistema está temporariamente indisponível :(
			</h3>
			<p className="text-brand-gray-2 text-cta-1">
				Não se preocupe, nossa equipe já está atuando para solucionar o
				problema o mais breve possível.
			</p>
		</div>
	)
}

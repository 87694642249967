import { useSearchParams } from "react-router-dom"
import JurisprudenceAutocomplete from "./subcomponents/autocomplete"
import JurisprudenceResultsPage from "./results-page"

export default function JurisprudencesSearchPage() {
	const [searchParams, setSearchParams] = useSearchParams()

	return searchParams.get("query") ? (
		<JurisprudenceResultsPage />
	) : (
		<div className="flex items-center justify-center w-full h-[80vh] flex-col">
			<img
				src="https://lawdeck-static.s3.sa-east-1.amazonaws.com/jurisprudence.png"
				className="w-[276px] h-[240px]"
			/>

			<p className="text-h0 font-semibold text-dark-blue-1 mb-[48px]">
				Busque jurisprudência com IA
			</p>

			<div className="relative">
				<JurisprudenceAutocomplete
					className="w-[800px]"
					onSubmit={(value) => setSearchParams({ query: value })}
					showClearIcon={false}
				/>
			</div>
		</div>
	)
}

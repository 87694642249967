import BaseIcon from "./BaseIcon"

const ArrowRight = ({
	size = "16px",
	className,
}: {
	size?: string
	className?: string
}) => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 16 16"
			className={className}
		>
			<path
				d="M10.6663 8L5.98798 13L5.33301 12.3L9.3564 8L5.33301 3.7L5.98798 3L10.6663 8Z"
				fill="fillCurrent"
			/>
		</BaseIcon>
	)
}

export default ArrowRight

import Image from "assets/404.jpg"
import { Button } from "components/button"
import { ButtonVariant } from "components/button/types"
import { useNavigate } from "react-router-dom"
import { ROUTES } from "routes"

export default function Error404Page() {
	const navigate = useNavigate()

	return (
		<div className="flex flex-col items-center justify-center w-full h-[100vh] bg-brand-white-1 fixed top-0 left-[0px] z-50">
			<img src={Image} width={347} height={305} className="mb-6" />

			<p className="text-2xl text-brand-gray-3 mb-4">
				Parece que esta página não existe!
			</p>
			<p className="text-base text-brand-gray-2 mb-6">
				Por favor, verifique o link inserido ou entre em contato com
				nosso suporte pelo email: suporte@lawdeck.ai
			</p>

			<Button
				variant={ButtonVariant.Contained}
				className="w-fit"
				onClick={() => navigate(ROUTES.basePage())}
			>
				Voltar à tela inicial
			</Button>
		</div>
	)
}

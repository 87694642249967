import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"

function handleCheckoutError(tab: Window | null) {
	tab?.close()
	showToast(
		"Erro ao processar ação, tente novamente mais tarde",
		ToastType.Error,
	)
}

export default handleCheckoutError

import UserIcon from "icons/UserIcon"
import { ReactNode, useState } from "react"
import ArrowLeft from "icons/ArrowLeft"
import AddCreditsIcon from "icons/AddCreditsIcon"
import ChangePasswordKeyIcon from "icons/ChangePasswordKeyIcon"
import BlockUserIcon from "icons/BlockUserIcon"
import UserInfo from "./UserInfo"
import BlockUserModal from "./BlockUserModal"
import AddCreditsModal from "./AddCreditsModal"
import { useNavigate } from "react-router-dom"
import DocIcon from "icons/DocIcon"
import LogIcon from "icons/LogIcon"
interface TabProps {
	icon: ReactNode
	text: string
	active?: boolean
	onClick?: () => void
}
function Tab({ icon, text, active, onClick }: TabProps) {
	const className = active
		? "pb-[2px] bg-clip-border bg-gradient-to-r from-light-blue-4 to-light-blue-5 cursor-pointer"
		: "cursor-pointer"
	return (
		<div className={className} onClick={onClick}>
			<div className="bg-ice-white pb-2 px-2">
				<div
					className={`flex ${
						active ? "text-light-blue-1" : "text-brand-gray-2"
					}`}
				>
					<div className="pr-2">{icon}</div>
					{text}
				</div>
			</div>
		</div>
	)
}

export interface InfoWithTitleProps {
	title: string
	info: string
	img?: string
	className?: string
}

export function InfoWithTitle({
	title,
	info,
	img,
	className,
}: InfoWithTitleProps) {
	return (
		<div className={`flex items-center  ${className}`}>
			{img && (
				<div className="mr-6">
					<img
						className="rounded-[48px]"
						alt="Avatar"
						src={img}
						width={48}
						height={48}
					/>
				</div>
			)}
			<div>
				<div
					className={`font-semibold text-dark-blue-1 ${
						img ? "text-[22px]" : "text-[18px]"
					}`}
				>
					{title}
				</div>
				<div className="text-brand-gray-3 text-[14px]">{info}</div>
			</div>
		</div>
	)
}

const UserDetailsPage = () => {
	const [selectedTab, setSelectedTab] = useState("user")
	const [open, setOpen] = useState(false)
	const [openAddCredits, setOpenAddCredits] = useState(false)
	const navigate = useNavigate()

	return (
		<div className=" pb-6">
			<div className="flex pb-[44px] items-center justify-between">
				<div className="cursor-pointer" onClick={() => navigate(-1)}>
					<ArrowLeft size="32px" />
				</div>
				<InfoWithTitle
					className="ml-4 min-w-max"
					title="Douglas Neves"
					info="douglas@lawdeck.ai"
					img="https://lh3.googleusercontent.com/a/ACg8ocKdBn9kHfIkUxGBVBjn413ET0Tcdtbpu3aWL3nrJSsd=s96-c"
				/>
				<div className="h-[56px] w-[1px] bg-[#F0F0F0] mx-8" />

				<InfoWithTitle
					className="ml-4 w-full"
					title="Plano"
					info="Profissional"
				/>
				<div className="h-[56px] w-[1px] bg-[#F0F0F0] mx-8" />
				<InfoWithTitle
					className="ml-4 w-full"
					title="Docs criados"
					info="327"
				/>
				<div className="h-[56px] w-[1px] bg-[#F0F0F0] mx-8" />
				<InfoWithTitle
					className="ml-4 w-full"
					title="Ltv"
					info="R$ 43,00"
				/>
				<div className="h-[56px] w-[1px] bg-[#F0F0F0] mx-8" />
				<InfoWithTitle
					className="ml-4 w-full"
					title="Créditos"
					info="1.251"
				/>
				<div className="h-[56px] w-[1px] bg-[#F0F0F0] mx-8" />
				<div className="flex w-full">
					<div
						className="w-[48px] h-[48px] bg-white flex items-center justify-center mr-2 cursor-pointer"
						onClick={() => {
							setOpenAddCredits(true)
						}}
					>
						<AddCreditsIcon />
					</div>
					<div className="w-[48px] h-[48px] bg-white flex items-center justify-center mr-2 cursor-pointer">
						<ChangePasswordKeyIcon />
					</div>
					<div
						className="w-[48px] h-[48px] bg-white flex items-center justify-center cursor-pointer"
						onClick={() => {
							setOpen(true)
						}}
					>
						<BlockUserIcon />
					</div>
				</div>
			</div>
			<div className="flex pb-6">
				<Tab
					icon={<UserIcon />}
					text="Informações do usuário"
					active={selectedTab === "user"}
					onClick={() => setSelectedTab("user")}
				/>
				<Tab
					icon={<DocIcon />}
					text="Documentos"
					active={selectedTab === "documents"}
					onClick={() => setSelectedTab("user")}
				/>

				<Tab
					icon={<LogIcon />}
					text="Logs"
					active={selectedTab === "logs"}
					onClick={() => setSelectedTab("user")}
				/>
			</div>

			<div className="bg-brand-white-1 w-auto p-6 rounded-lg">
				{selectedTab === "user" && <UserInfo />}
			</div>
			<BlockUserModal open={open} setOpen={setOpen} />
			<AddCreditsModal
				open={openAddCredits}
				setOpen={setOpenAddCredits}
			/>
		</div>
	)
}

export default UserDetailsPage

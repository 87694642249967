import _ from "lodash"
import { Subscription } from "types/currentUser"

const formatUserName = (user: {
	first_name: string | null
	last_name: string | null
	email: string | null
}) => {
	if (!user?.first_name && !user?.last_name) {
		return user?.email
	}

	const lastName = user?.last_name ? _.last(user?.last_name.split(" ")) : ""

	return `${user?.first_name} ${lastName}`
}

function formatPlanName(subscription?: Subscription) {
	if (subscription?.product_name === "Básico") return "Gratuito"
	if (subscription?.product_name === "Profissional") return "Profissional"
}

export { formatUserName, formatPlanName }

import { useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import ThesesProvider from "pages/DocumentPage/context/ThesesContext"
import ContractPage from "./steps/ContractPage"
import AnalizeDocumentPage from "pages/AnalizeDocumentPage"
import { useAtom } from "jotai"
import { useEffect, useState } from "react"
import Loading from "components/loading"
import { getDocumentStatus } from "utils/getDocumentStatus"
import ThesisContentProvider from "./context/ThesesContentContext"
import { stepAtom } from "./subcomponents/DocumentDisplayV3/atoms"
import { contractStepAtom } from "./subcomponents/contract/ContractDisplay/atoms"
import {
	openLexAtom,
	showLexAtom,
} from "pages/SidebarPages/subcomponents/atoms"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"
import Error404Page from "pages/Error404"
import PetitionPageV3 from "./steps/PetitionPageV3"
import { useFeatureFlag } from "hooks/useFeatureFlag"
import FEATURE_FLAGS from "feature-flags"
import DocumentWrapper from "./subcomponents/DocumentWrapper"

const DocumentPage = () => {
	const { documentId } = useParams()
	const [, setShowLex] = useAtom(showLexAtom)

	if (!documentId) {
		return <div>Documento não encontrado</div>
	}
	const [documentStep, setDocumentStep] = useAtom(stepAtom)
	const [contractStep] = useAtom(contractStepAtom)
	const [, setOpenLex] = useAtom(openLexAtom)
	const [enableFetch, setEnableFetch] = useState(true)

	const { data: legalDocumentsRework } = useFeatureFlag(
		FEATURE_FLAGS.legalDocumentsRework,
	)
	const { retrieveLegalDocument } = useLegalDocumentAPI()

	const { data, isError } = useQuery(
		["legalDocument", documentId],
		() => retrieveLegalDocument(documentId),
		{ retry: 1, enabled: enableFetch },
	)

	useEffect(() => {
		if (isError) {
			setEnableFetch(false)
			return
		}
		if (data) {
			const showLexVerification =
				(data.type === "contract" &&
					getDocumentStatus(data) === "completed" &&
					contractStep === 3) ||
				(data.type === "petition" &&
					getDocumentStatus(data) === "completed" &&
					documentStep === 3) ||
				data.type === "uploaded_document"

			if (
				showLexVerification ||
				(legalDocumentsRework &&
					getDocumentStatus(data) === "completed")
			) {
				setShowLex(true)
			} else {
				setShowLex(false)
			}

			if (data.type === "uploaded_document") {
				setDocumentStep(4)
			}
		}
	}, [data, getDocumentStatus(data), contractStep, documentStep, isError])

	useEffect(() => {
		setOpenLex(true)
	}, [])

	if (!data && !isError) return <Loading />

	if (isError) {
		return <Error404Page />
	}

	const getDocumentPage = () => {
		if (legalDocumentsRework && data?.type === "petition") {
			return <DocumentWrapper documentId={documentId} />
		}

		switch (data?.type) {
			case "petition":
				return <PetitionPageV3 documentId={documentId} />

			case "contract":
				return <ContractPage />
			case "uploaded_document":
				return <AnalizeDocumentPage />
			default:
				throw new Error(`Invalid document type: ${data?.type}`)
		}
	}

	return (
		<ThesisContentProvider>
			<ThesesProvider>{getDocumentPage()}</ThesesProvider>
		</ThesisContentProvider>
	)
}

export default DocumentPage

import React, { ButtonHTMLAttributes } from "react"

const ExclusionButton = ({
	className,
	children,
	disabled,
	...rest
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
	return (
		<button
			className={`flex justify-center h-[48px] text-base px-6 py-3 rounded-md items-center gap-2 cursor-pointer bg-light-red ${
				disabled
					? "!cursor-default text-brand-gray-2 bg-gradient-to-r from-brand-gray-1 to-brand-gray-1"
					: "text-white hover:bg-[#F24646] hover:shadow-exclusion-button"
			} ${className}`}
			disabled={disabled}
			{...rest}
		>
			{children}
		</button>
	)
}

export default ExclusionButton

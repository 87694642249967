import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query"
import MagicWandIcon from "icons/MagicWandIcon"
import DocumentProcessingOrComplete from "pages/DocumentPage/subcomponents/DocumentProcessingOrComplete"
import { Button } from "components/button"
import { useParams } from "react-router-dom"
import { useAtom } from "jotai"
import { useEffect } from "react"
import { getDocumentStatus } from "utils/getDocumentStatus"
import { useJwt } from "hooks/useJwt"
import {
	LegalDocumentContent,
	LDProcess,
	ProcessType,
	RetrieveLegalDocumentResponse,
	LegalDocumentType,
} from "types/legalDocument"
import Loading from "components/loading"
import { AxiosError } from "axios"
import { modalAtom } from "pages/SidebarPages/subcomponents/atoms"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"
import { ButtonColor, ButtonVariant } from "components/button/types"
import { NewDocumentComplete } from "pages/DocumentPage/subcomponents/NewDocumentComplete"
import { HeaderButtonsCompleteDocument } from "pages/DocumentPage/subcomponents/HeaderButtonsCompleteDocument"
import NewFactsStep from "pages/DocumentPage/subcomponents/NewFactsStep"
import usePetitionAPI from "hooks/usePetitionAPI"
import useCurrentUser from "hooks/useCurrentUser"
import { DocumentDisplayV3 } from "pages/DocumentPage/subcomponents/DocumentDisplayV3"
import { userInputAtom } from "./atoms"
import { stepAtom } from "pages/DocumentPage/subcomponents/DocumentDisplayV3/atoms"
import useCheckIfHasCredits from "hooks/useCheckIfHasCredits"
import { plansModalAtom } from "pages/BasePage/atoms"

const LoadingComponent = () => {
	const { documentId } = useParams()

	if (!documentId) return null

	return <Loading />
}

const PetitionPageV3 = ({ documentId }: { documentId: string }) => {
	const { generatePetitionContentV3, retrievePetition, listTheses } =
		usePetitionAPI()

	const queryClient = useQueryClient()
	const [jwt] = useJwt()
	const user = useCurrentUser()
	const [, setOpenPlansModal] = useAtom(plansModalAtom)
	const hasCredits = useCheckIfHasCredits()

	const { data, isFetched } = useQuery(
		["legalDocument", documentId],
		() => retrievePetition(documentId),
		{
			staleTime: Infinity,
		},
	)

	const { data: listThesesData } = useQuery(["listTheses", documentId], () =>
		listTheses(documentId),
	)

	const [userInput, setUserInput] = useAtom(userInputAtom)
	const [, setCurrentStep] = useAtom(stepAtom)
	const [, setOpenModalSaveDocument] = useAtom(modalAtom)

	const generateLegalDocumentContentV3Mutation = useMutation({
		mutationFn: () =>
			generatePetitionContentV3(documentId, {
				user_input: userInput,
				petition_type: data?.petition_type || "",
			}),

		onSuccess: () => {
			queryClient.setQueryData(
				["legalDocument", documentId],
				(oldDocument: RetrieveLegalDocumentResponse | undefined) => {
					if (oldDocument) {
						const process = {
							process_type: ProcessType.GenerateContent,
							max_processing_time: 360,
							start_time: new Date().toISOString(),
						} as LDProcess
						const content = { text: "" } as LegalDocumentContent
						return {
							...oldDocument,
							content,
							processes: [process],
						}
					}

					return oldDocument
				},
			)
			setCurrentStep(3)
		},
		onError: (error: AxiosError<any>) => {
			if (error.response?.status === 403) {
				showToast(
					"Você não tem créditos para concluir essa ação.",
					ToastType.Error,
				)
				setOpenPlansModal(true)
			}
		},
	})

	const onGerenerateContent = () => {
		if (!user) return

		if (!hasCredits) {
			showToast(
				"Você não tem créditos para concluir essa ação.",
				ToastType.Error,
			)
			setOpenPlansModal(true)
		} else {
			generateLegalDocumentContentV3Mutation.mutateAsync()
		}
	}

	const checkGenerateTesesButtonIsDisabled = () => {
		if (!userInput) return true

		if (userInput.length < 20) return true

		if (userInput.length > 10000) return true

		return false
	}
	const onFactsStepCancel = async () => {
		setOpenModalSaveDocument({
			open: true,
			isCancel: true,
		})
	}

	const navigationModel = {
		steps: [
			{
				subtitle: "Subtitulo Fatos",
				Component: NewFactsStep,
				primaryButton: (
					<Button
						disabled={checkGenerateTesesButtonIsDisabled()}
						className="ml-4"
						variant={ButtonVariant.Contained}
						color={ButtonColor.Secondary}
						icon={<MagicWandIcon />}
						onClick={onGerenerateContent}
						data-cy="generate-theses"
					>
						Gerar documento
					</Button>
				),
				secondaryButton: (
					<Button
						variant={ButtonVariant.Outlined}
						onClick={() => onFactsStepCancel()}
					>
						Cancelar
					</Button>
				),
				footerText: "Informações",
			},
			{
				subtitle: "Subtitulo  Teses",
				Component: LoadingComponent,
			},

			{
				subtitle: "Subtitulo  Petição",
				Component: DocumentProcessingOrComplete,
			},
			{
				headerButtons:
					getDocumentStatus(data) === "processing" ? null : (
						<HeaderButtonsCompleteDocument
							documentId={documentId}
							type={LegalDocumentType.Petition}
						/>
					),
				subtitle: "Subtitulo  Petição 2",
				Component: NewDocumentComplete,
			},
		],
	}

	useEffect(() => {
		queryClient.setQueryData(
			["listLegalDocuments", jwt],
			(oldDocuments: RetrieveLegalDocumentResponse[] | undefined) => {
				if (oldDocuments && data) {
					const newDocument = data
					const oldDocumentsFiltered = oldDocuments.filter(
						(doc) => doc.id !== documentId,
					)
					return [newDocument, ...oldDocumentsFiltered]
				}

				return oldDocuments
			},
		)
	}, [documentId, data])

	useEffect(() => {
		if (data?.user_input) {
			setUserInput(data?.user_input)
		} else {
			setUserInput("")
		}
	}, [isFetched, documentId])

	if (!listThesesData || !data) return <Loading />

	return (
		<>
			<DocumentDisplayV3
				navigationModel={navigationModel}
				documentId={documentId}
			/>
		</>
	)
}

export default PetitionPageV3

import { useEffect, useState, useRef } from "react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useAtom } from "jotai"
import { atomWithStorage } from "jotai/utils"
import { Button } from "components/button"
import MagicWandIcon from "icons/MagicWandIcon"
import CloseSquareIcon from "icons/CloseSquareIcon"
import Header from "./Header"
import { getDocumentStatus } from "utils/getDocumentStatus"
import DocumentInformationModal from "./DocumentInformationModal"
import { useJwt } from "hooks/useJwt"
import { WS_SERVER } from "config"
import {
	DocStreamWebsocketResponse,
	LegalDocumentStatus,
} from "types/legalDocument"
import { openLexAtom } from "pages/SidebarPages/subcomponents/atoms"
import { ButtonColor, ButtonVariant } from "components/button/types"
import usePetitionAPI from "hooks/usePetitionAPI"
import NewDocumentTextArea from "components/document-text-area"
import { StepProps } from "./DocumentDisplayV3"

const initialValue =
	localStorage.getItem("alreadyRead") !== null
		? JSON.parse(localStorage.getItem("alreadyRead")!)
		: []

const alreadyReadAtom = atomWithStorage<string[]>("alreadyRead", initialValue)

const DocumentProcessingOrComplete = ({ documentId }: StepProps) => {
	const { data: legalDocument } = useQuery(
		["legalDocument", documentId],
		() => retrievePetition(documentId),
	)
	const [jwt] = useJwt()
	const [alreadyRead, setAlreadyRead] = useAtom(alreadyReadAtom)
	const [openObjection, setOpenObjection] = useState(false)
	const [openInformations, setOpenInformations] = useState(false)
	const [openLex] = useAtom(openLexAtom)
	const wsRef = useRef<WebSocket>()
	const [docStreamData, setDocStreamData] =
		useState<DocStreamWebsocketResponse>()

	const { retrievePetition, generateObjection } = usePetitionAPI()

	const queryClient = useQueryClient()

	const generateObjectionMutation = useMutation({
		mutationFn: generateObjection,
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: ["legalDocument", documentId],
			})
		},
	})

	function handleNewObjection() {
		if (legalDocument?.id) {
			generateObjectionMutation.mutate(legalDocument.id)
			setOpenObjection(true)
		}
	}

	useEffect(() => {
		if (
			legalDocument &&
			!alreadyRead.find((docId: string) => docId === legalDocument?.id) &&
			getDocumentStatus(legalDocument) === "processing"
		) {
			setAlreadyRead([...alreadyRead, documentId])
		}
	}, [alreadyRead, legalDocument])

	useEffect(() => {
		const generateContentProcess =
			legalDocument?.processes &&
			legalDocument?.processes.find(
				(process) => process.process_type === "generate_content",
			)
		if (
			!generateContentProcess?.id ||
			getDocumentStatus(legalDocument) !== LegalDocumentStatus.Processing
		)
			return

		wsRef.current = new WebSocket(
			`${WS_SERVER.baseUrl}/doc-stream?token=${jwt}&process_id=${generateContentProcess?.id}`,
		)
		wsRef.current.onopen = () => {
			console.log("ws open")
		}
		wsRef.current.onmessage = (e) => {
			if (e.data === "ping") {
				wsRef.current?.send("pong")
				return
			}

			const docStreamData = JSON.parse(
				e.data,
			) as DocStreamWebsocketResponse
			setDocStreamData(docStreamData)
		}
		wsRef.current.onclose = () => {
			console.log("ws close")
			queryClient.invalidateQueries({
				queryKey: ["legalDocument", documentId],
			})
			queryClient.invalidateQueries({
				queryKey: ["legalDocumentType", documentId],
			})
		}

		return () => {
			wsRef.current?.close(1000, "finished")
		}
	}, [legalDocument?.processes, documentId])

	return (
		<div
			className={` flex  ${!openLex ? "w-full" : "w-[calc(100%-324px)]"}`}
		>
			<div className="w-full">
				<div className="flex">
					<div className="w-full">
						<Header
							legalDocument={legalDocument}
							openObjection={openObjection}
							setOpenInformations={setOpenInformations}
						/>

						<div className={"mt-8"}>
							<NewDocumentTextArea
								id={legalDocument?.id || ""}
								type={legalDocument!.type}
								text={
									getDocumentStatus(legalDocument) ===
									LegalDocumentStatus.Processing
										? docStreamData?.text
										: legalDocument?.content?.text
								}
								status={getDocumentStatus(legalDocument)}
								data-cy="document-text-area"
							/>
						</div>
					</div>
					{openObjection && legalDocument?.objection && (
						<div className="w-full ml-6">
							<div className="flex justify-between mb-8">
								<div className="flex items-center">
									<div
										className="me-2 cursor-pointer"
										onClick={() => {
											setOpenObjection(false)
										}}
									>
										<CloseSquareIcon />
									</div>
									<h3 className="mt-[3px]">
										Contestação do adversário
									</h3>
								</div>
								<div>
									<Button
										variant={ButtonVariant.Contained}
										color={ButtonColor.Secondary}
										icon={<MagicWandIcon />}
										onClick={handleNewObjection}
										disabled={
											legalDocument?.objection?.status ===
											"processing"
										}
									>
										Gerar nova contestação
									</Button>
								</div>
							</div>

							<NewDocumentTextArea
								id={legalDocument?.id || ""}
								type={legalDocument!.type}
								text={legalDocument?.objection?.text}
								status={legalDocument?.objection?.status}
							/>
						</div>
					)}
				</div>
			</div>
			<DocumentInformationModal
				setOpenModal={setOpenInformations}
				openModal={openInformations}
				title={legalDocument?.title}
				userInput={legalDocument?.user_input}
				type={legalDocument?.type}
				specificType={legalDocument?.legal_document_type}
			/>
		</div>
	)
}

export default DocumentProcessingOrComplete

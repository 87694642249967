import { Dropdown } from "components/dropdown"
import InputField from "components/input-field"
import CloseIcon from "icons/CloseIcon"
import EyeIcon from "icons/EyeIcon"
import FilterIcon from "icons/FilterIcon"
import ModelIcon from "icons/ModelIcon"
import SearchIcon from "icons/SearchIcon"
import { useEffect, useRef, useState } from "react"
import FilteredChip from "pages/MyDocumentsPage/subcomponents/FilteredChip"
import { Button } from "components/button"
import { ButtonVariant } from "components/button/types"
import FilterClearIcon from "icons/FilterClearIcon"
import { useNavigate } from "react-router-dom"
import { ROUTES } from "routes"
import useLegalDocumentTemplatesAPI from "hooks/useLegalDocumentTemplatesAPI"
import { useMutation, useQuery } from "@tanstack/react-query"
import { format } from "date-fns"
import { Template } from "types/templates"
import Loading from "components/loading"
import loading from "lotties/dotTheses.json"
import Lottie from "react-lottie"
import PlansModal from "pages/BasePage/subcomponents/PlansModal"
import FitArrowIcon from "icons/FitArrowIcon"
import { orderItems } from "./utils/orderItems"
import OutlineCheckIcon from "icons/OutlineCheckIcon"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"
import NotFoundIcon from "icons/NotFoundIcon"
import { TooltipPosition } from "components/tooltip/enums"
import Tooltip from "components/tooltip"
import UpgradeButton from "components/upgrade-button"
import useFreePlan from "hooks/useFreePlan"
import useBasicPlan from "hooks/useBasicPlan"

const LottieComponent = Lottie as any

const lottieOptions = {
	loop: true,
	autoplay: true,
	animationData: loading,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
		className: "cursor-default",
	},
}

function CheckboxFilter({ checked }: { checked: boolean }) {
	return (
		<input
			type="checkbox"
			checked={checked}
			className="rounded-sm focus:shadow-none focus:ring-0 focus:outline-none mb-1"
			onChange={() => { }}
		/>
	)
}

export default function ListModelsPage() {
	const [isSearchOpen, setIsSearchOpen] = useState(false)
	const [filterValue, setFilterValue] = useState("")
	const [focusSearchBar, setFocusSearchBar] = useState(false)
	const [checkboxValues, setCheckboxValues] = useState<string[]>([])
	const [offset, setOffset] = useState(0)
	const [templates, setTemplates] = useState<Template[]>([])
	const [openModal, setOpenModal] = useState(false)
	const [order, setOrder] = useState("")
	const { getLegalDocumentTemplates, getRetrieveTypesTemplates } =
		useLegalDocumentTemplatesAPI()
	const inputRef = useRef<HTMLInputElement>(null)
	const finalPageRef = useRef<HTMLDivElement>(null)
	const initialMount = useRef(true)
	const isFreeUser = useFreePlan()
	const isBasicUser = useBasicPlan()
	const navigate = useNavigate()
	const { createLegalDocument } = useLegalDocumentAPI()

	const { data, isRefetching, isLoading, isFetching } = useQuery(
		["listLegalDocuments", checkboxValues, offset, filterValue, order],
		() =>
			getLegalDocumentTemplates({
				area_of_law: checkboxValues,
				offset: offset,
				search: filterValue,
				ordering: order,
			}),
		{
			refetchOnWindowFocus: false,
		},
	)
	const { data: retrieveTypes } = useQuery(
		["retrieveTypes"],
		() => getRetrieveTypesTemplates(),
		{
			refetchOnWindowFocus: false,
		},
	)

	const legalDocumentMutation = useMutation({
		mutationFn: createLegalDocument,
		onSuccess: (data) => {
			navigate(ROUTES.documentDetail({ id: data.id }), {
				state: { documentAsModel: true },
			})
		},
	})
	useEffect(() => {
		if (data && data.results) {
			initialMount.current = false
			setTemplates((prev) => [...prev, ...data.results])
		}
	}, [data])

	useEffect(() => {
		if (templates.length === 0) return
		const observer = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting && data?.next) {
					const url = new URL(data.next)
					const offset = url.searchParams.get("offset")
					if (offset !== "None" && data.count === 20)
						setOffset(Number(offset))
				}
			},
			{ threshold: 1 },
		)

		if (finalPageRef.current) {
			observer.observe(finalPageRef.current)
		}

		return () => {
			if (finalPageRef.current) {
				observer.unobserve(finalPageRef.current)
			}
		}
	}, [finalPageRef.current, data?.next, templates])

	function onChangeCheckbox(value: string) {
		setTemplates([])
		setOffset(0)
		if (checkboxValues.includes(value)) {
			setCheckboxValues(checkboxValues.filter((item) => item !== value))
		} else {
			setCheckboxValues([...checkboxValues, value])
		}
	}

	const sections = [
		{
			title: "Áreas do direito",
			items: retrieveTypes
				? retrieveTypes.map((item) => ({
					icon: (
						<div>
							<CheckboxFilter
								checked={checkboxValues.includes(item)}
							/>
						</div>
					),
					label: item,
					onClick: () => onChangeCheckbox(item),
				}))
				: [],
		},
	]

	const orderItemsSection = [
		{
			title: "Ordenar por",
			items: orderItems.map((item) => ({
				icon:
					item.value === order ? (
						<OutlineCheckIcon />
					) : (
						<div className="w-4 h-4 " />
					),
				label: item.label,
				onClick: () => {
					if (item.value === order) return
					setTemplates([])
					setOffset(0)
					setOrder(item.value)
				},
			})),
		},
	]

	useEffect(() => {
		if (isSearchOpen) {
			inputRef.current?.focus()
		}
	}, [isSearchOpen])

	return (
		<div>
			<div className="flex w-full items-center justify-between">
				<h3>Modelos</h3>

				<div className="flex items-center justify-between gap-6 h-[48px]">
					{isSearchOpen ? (
						<InputField
							className=" w-[300px]"
							type="text"
							name="searchBar"
							placeholder="Buscar"
							ref={inputRef}
							value={filterValue}
							onChange={(e) => {
								setTemplates([])
								setOffset(0)
								setFilterValue(e.target.value)
							}}
							onFocus={() => {
								setFocusSearchBar(true)
							}}
							onBlur={(e) => {
								setFocusSearchBar(false)
								if (!e.target.value) {
									setIsSearchOpen(false)
								}
							}}
							leftIcon={
								<SearchIcon
									className={
										focusSearchBar || filterValue
											? "fill-current"
											: "fill-[#ACACAC]"
									}
								/>
							}
							rightIcon={
								(focusSearchBar || !!filterValue) && (
									<div className="pt-1">
										{" "}
										<div
											className="cursor-pointer"
											onFocus={() => {
												setFocusSearchBar(true)
											}}
											onClick={() => {
												setTemplates([])
												setFilterValue("")
												inputRef.current?.focus()
											}}
										>
											<CloseIcon />
										</div>
									</div>
								)
							}
						/>
					) : (
						<div
							className="cursor-pointer"
							onClick={() => {
								setIsSearchOpen(true)
							}}
						>
							<SearchIcon className="fill-dark-blue-1 hover:fill-dark-blue-2" />
						</div>
					)}

					<Dropdown
						sections={sections}
						className="w-[165px]  right-0 "
					>
						<FilterIcon className="fill-dark-blue-1 cursor-pointer" />
					</Dropdown>
					<Dropdown
						sections={orderItemsSection}
						className="w-[155px]  right-0 "
						closeOnClickOption
					>
						<div className="w-[155px] p-3 flex items-center justify-between shadow-outlined h-[48px] rounded bg-white cursor-pointer text-dark-blue-1 hover:text-dark-blue-2 ">
							<p className="text-cta-2 text-current">
								{orderItems.find((item) => order === item.value)
									?.label || "Mais relevantes"}
							</p>
							<div>
								<FitArrowIcon className="fill-current" />
							</div>
						</div>
					</Dropdown>
				</div>
			</div>

			{checkboxValues.length > 0 && (
				<div className="flex items-center gap-4 w-full flex-wrap mt-8">
					{checkboxValues?.map((filterValue) => {
						return (
							<FilteredChip
								key={filterValue}
								text={filterValue}
								onClick={() =>
									setCheckboxValues(
										checkboxValues.filter(
											(item) => item !== filterValue,
										),
									)
								}
							/>
						)
					})}
					{checkboxValues.length > 0 && (
						<div className="flex items-center h-[42px]">
							<Button
								variant={ButtonVariant.Text}
								icon={<FilterClearIcon />}
								className="px-0 py-0"
								onClick={() => setCheckboxValues([])}
							>
								Limpar
							</Button>
						</div>
					)}
				</div>
			)}
			{initialMount.current ? (
				<Loading />
			) : (
				<>
					{!isRefetching && templates.length > 0 ? (
						<div className="mt-8 flex flex-col gap-6">
							{templates.map((template) => (
								<div
									className="cursor-pointer p-6 bg-white rounded shadow-1 text-dark-blue-1 hover:text-dark-blue-2 hover:shadow-outlined-hover"
									key={template.id}
									onClick={() =>
										navigate(
											ROUTES.model({
												id: template.id,
											}),
										)
									}
								>
									<div className="flex w-full items-center justify-between ">
										<div className="flex items-center gap-2">
											{template.is_premium &&
												(isFreeUser || isBasicUser) && (
													<div
														onClick={(e) =>
															e.stopPropagation()
														}
													>
														<UpgradeButton />
													</div>
												)}
											<h4 className="text-current">
												{template.title}
											</h4>
										</div>
										<div className="flex items-center  gap-4">
											{template.view_count >= 100 && (
												<div className="flex items-center justify-center  gap-2">
													<EyeIcon
														size="20"
														className="fill-brand-gray-2"
													/>
													<p className="text-cta-2 text-brand-gray-2">
														{template.view_count}
													</p>
												</div>
											)}
											<div
												onClick={(e) => {
													e.stopPropagation()
													if (isFreeUser || (template.is_premium &&
														isBasicUser)) {
														setOpenModal(true)
													} else {
														legalDocumentMutation.mutate(
															{
																template_id:
																	template.id,
																template_type:
																	"TEMPLATE",
																user_input: "",
																title: "",
																legal_document_type:
																	"Petição Inicial",
															},
														)
													}
												}}
											>
												<Tooltip
													text="Usar como modelo"
													position={
														TooltipPosition.Above
													}
													containerClassName="mr-2"
												>
													<ModelIcon className="fill-dark-blue-1 hover:fill-dark-blue-2 cursor-pointer" />
												</Tooltip>
											</div>
										</div>
									</div>
									<p className="mt-4 text-brand-gray-3">
										{template.summary}
									</p>
									<div className="flex items-center gap-2 mt-4">
										<p className="text-cta-2 font-semibold text-dark-blue-1">
											{template.area_of_law}
										</p>
										<p className="text-cta-2  text-brand-gray-2">
											•
										</p>
										<p className="text-cta-2  text-brand-gray-2">
											Criado por: Lawdeck
										</p>
										<p className="text-cta-2  text-brand-gray-2">
											•
										</p>
										<p className="text-cta-2  text-brand-gray-2">
											Publicado em:{" "}
											{format(
												new Date(template.publish_date),
												"dd/MM/yyyy",
											)}
										</p>
									</div>
								</div>
							))}
						</div>
					) : (
						<>
							{!isFetching && !isLoading && (
								<div className="flex items-center justify-center h-[calc(100vh-200px)] flex-col mb-[150px]">
									<NotFoundIcon />
									<div className="flex items-center justify-center flex-col mt-6">
										<h3 className="text-brand-gray-3 mb-4">
											Nenhum resultado encontrado
										</h3>
										<p className="text-brand-gray-2 text-cta-1">
											Por favor, tente fazer uma nova
											busca ou ajustar seus filtros.
										</p>
									</div>
								</div>
							)}
						</>
					)}
					{!initialMount.current && isFetching && (
						<div className="w-full flex items-center justify-center mt-6">
							<LottieComponent
								options={lottieOptions}
								height={15}
								width={45}
								style={{ margin: "3.5px 0 0 0" }}
								isClickToPauseDisabled
							/>
						</div>
					)}
					<div ref={finalPageRef} />
				</>
			)}
			<PlansModal open={openModal} setOpen={setOpenModal} />
		</div>
	)
}

import { Link } from "react-router-dom"
import LogoIcon from "icons/LogoIcon"
import FEATURE_FLAGS from "feature-flags"
import posthog from "posthog-js"
import { useQuery } from "@tanstack/react-query"

interface LogoContainerProps {
	className?: string
}

const LogoContainer = ({ className }: LogoContainerProps) => {
	const { data: isAdminFeatureFlagEnabled } = useQuery(
		[FEATURE_FLAGS.isAdmin],
		() => {
			const isAdmin = posthog.isFeatureEnabled(FEATURE_FLAGS.isAdmin)
			return isAdmin || false
		},
	)

	return (
		<Link to="/" className="no-underline">
			<div className={className || `p-4 w-[140px] block`}>
				<div
					className={`flex items-center text-[20.073px] text-dark-blue-1 w-[102.102px] justify-between h-[35.12px] relative`}
				>
					<LogoIcon className="fill-light-blue-4" />

					<p className="block">Lawdeck</p>
					{isAdminFeatureFlagEnabled && (
						<div className="absolute right-[-60px] text-[gray]">
							Admin
						</div>
					)}
				</div>
			</div>
		</Link>
	)
}

export default LogoContainer

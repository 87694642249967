export enum UserType {
	Student = "STUDENT",
	SelfEmployed = "SELF_EMPLOYED",
	LawOffice = "LAW_OFFICE",
}

export enum AreaOfLaw {
	Civil = "CIVIL",
	Criminal = "CRIMINAL",
	Labor = "LABOR",
	Family = "FAMILY",
	Tax = "TAX",
	Consumer = "CONSUMER",
	Business = "BUSINESS",
	Other = "OTHER",
	International = "INTERNATIONAL",
	Administrative = "ADMINISTRATIVE",
}

export enum YearsOfExperience {
	LessThan1 = "LESS_THAN_1",
	Between1And3 = "BETWEEN_1_AND_3",
	Between3And5 = "BETWEEN_3_AND_5",
	Between5And7 = "BETWEEN_5_AND_7",
	Between7And10 = "BETWEEN_7_AND_10",
	MoreThan10 = "MORE_THAN_10",
}

import { useQuery } from "@tanstack/react-query"
import { LegalDocumentType } from "types/legalDocument"
import useContractAPI from "./useContractAPI"
import usePetitionAPI from "./usePetitionAPI"
import useUploadedDocumentAPI from "./useUploadedDocumentAPI"

export function useLegalDocument(documentId: string, type: LegalDocumentType) {
	const { retrieveUploadedDocument } = useUploadedDocumentAPI()
	const { retrievePetition } = usePetitionAPI()
	const { retrieveContract } = useContractAPI()
	const result = useQuery(
		["legalDocument", documentId],
		() => {
			if (type === LegalDocumentType.Uploaded)
				return retrieveUploadedDocument(documentId!)
			if (type === LegalDocumentType.Petition)
				return retrievePetition(documentId!)
			if (type === LegalDocumentType.Contract)
				return retrieveContract(documentId!)
		},
		{
			staleTime: Infinity,
		},
	)

	return result
}

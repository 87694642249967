import { ReactNode, createContext, useState } from "react"

export interface ClauseContent {
	id: string
	version: number
}

interface ClauseContextProps {
	clausesContent: ClauseContent[]
	setClausesContent: (params: ClauseContent[]) => void
}

interface ClauseProviderProps {
	children: ReactNode
}

export const ClauseContext = createContext<ClauseContextProps>({
	clausesContent: [],
	setClausesContent: () => {},
})

export default function ClauseContentProvider({
	children,
}: ClauseProviderProps) {
	const [clausesContent, setClausesContent] = useState<ClauseContent[]>([])

	return (
		<ClauseContext.Provider value={{ clausesContent, setClausesContent }}>
			{children}
		</ClauseContext.Provider>
	)
}

import Tag from "components/tag"
import { ProductCatalogItem, SubscriptionPlan } from "types/productCatalog"
import { formatDate } from "./formatDate"
import { ButtonVariant } from "components/button/types"
import { CurrentUser } from "types/currentUser"

interface ButtonProps {
	buttonVariant?: ButtonVariant
	buttonIcon?: JSX.Element
	buttonIsLoading?: boolean
	buttonText?: string
	buttonOnClick: () => void
}

function getTag(
	userIsBasicPlan: boolean,
	userIsProfessionalPlan: boolean,
	isMonthlyPlan: boolean,
	user?: CurrentUser,
	type?: string,
): JSX.Element | undefined {
	const billingType = user?.subscription?.billing_type

	if (type === "professional_plan") {
		if (
			billingType === "annual" &&
			!isMonthlyPlan &&
			userIsProfessionalPlan
		) {
			return (
				<Tag
					text="Plano atual"
					background="bg-transparent"
					textColor="text-light-blue-4"
					className="border border-light-blue-4"
					small
				/>
			)
		}
		if (!userIsProfessionalPlan) {
			return (
				<Tag
					text={isMonthlyPlan ? "Recomendado" : "20% OFF"}
					background="bg-semi-white-3"
					textColor="text-light-blue-4"
					small
				/>
			)
		}
	}

	if (
		type === "basic_plan" &&
		billingType === "monthly" &&
		userIsBasicPlan &&
		isMonthlyPlan
	) {
		return (
			<Tag
				text="Plano atual"
				background="bg-transparent"
				textColor="text-light-blue-4"
				className="border border-light-blue-4"
				small
			/>
		)
	}
	if (
		type === "basic_plan" &&
		billingType === "annual" &&
		userIsBasicPlan &&
		!isMonthlyPlan
	) {
		return (
			<Tag
				text="Plano atual"
				background="bg-transparent"
				textColor="text-light-blue-4"
				className="border border-light-blue-4"
				small
			/>
		)
	}

	return undefined
}

export function newSanitizePlan(
	type: string = "free_plan",
	plan: SubscriptionPlan,
	isMonthlyPlan: boolean,
	user?: CurrentUser,
	getButtonProps?: (planKey: string) => ButtonProps,
): ProductCatalogItem {
	const button = getButtonProps ? getButtonProps(type) : undefined
	const userIsProfessionalPlan =
		user?.subscription?.product_name === "Profissional"
	const userIsBasicPlan = user?.subscription?.product_name === "Básico"

	const tag = getTag(
		userIsBasicPlan,
		userIsProfessionalPlan,
		isMonthlyPlan,
		user,
		type,
	)

	const commonProps: ProductCatalogItem = {
		...plan,
		button,
		tag,
	}

	if (type === "basic_plan") {
		return {
			...commonProps,
			titleColor: "text-brand-gray-3",
		}
	}

	return {
		...commonProps,
		titleColor: "text-brand-yellow",
		containerClassName: "relative",
		creditsPerMonth: true,
		selected: true,
		warning:
			user?.subscription?.end_period &&
				user?.subscription?.cancel_at_period_end ? (
				<div className="flex justify-center h-8 text-brand-orange absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full">
					Seu plano cancela em:{" "}
					{formatDate(user.subscription.end_period).split(" ")[0]}
				</div>
			) : undefined,
	}
}
